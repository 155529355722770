import { FC, ReactNode, useMemo } from 'react';

import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import DevTools from 'src/components/DevTools/DevTools';
import MonthlyIncomeField from 'src/components/fields/Income/MonthlyIncomeField';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { useBlancoDataFormContext } from 'src/contexts/blanco/BlancoDataFormContext';
import { FormSectionContextProvider } from 'src/contexts/FormSectionContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';
import BlancoSwedenForm from 'src/types/BlancoSweden/form/BlancoSwedenForm';

interface BlancoSwedenIncomeAmountSectionProps {
  coApplicant?: boolean;
}

const BlancoSwedenIncomeAmountSection: FC<BlancoSwedenIncomeAmountSectionProps> = (props) => {
  const { coApplicant } = props;
  const { formData, updateFormField } = useBlancoDataFormContext();
  const { schemaFields } = useYupSchemaContext();

  const validationSchema = Yup.object({
    monthlyIncome: schemaFields.income.monthlyIncome,
  });

  const initialValues = useMemo(() => {
    const data = formData as BlancoSwedenForm;

    return {
      monthlyIncome: !coApplicant ? data.income.monthlyIncome : data.coApplicant.income.monthlyIncome,
    };
  }, [formData]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ values }): ReactNode => (
        <FormSectionContextProvider
          updateFormContextCallback={() => updateFormField(!coApplicant ? 'income.monthlyIncome' : 'coApplicant.income.monthlyIncome', values.monthlyIncome)}
          heading={!coApplicant ? 'What is your monthly income?' : 'What is your co-applicant\'s monthly income?'}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <MonthlyIncomeField
                name='monthlyIncome'
                coApplicant={coApplicant}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <NextStepButton alwaysVisible />
            </Grid>
            <DevTools />
          </Grid>
        </FormSectionContextProvider>
      )}
    </Formik>
  );
};

export default BlancoSwedenIncomeAmountSection;
