import { FC, ReactNode } from 'react';

import { isSweden } from 'src/utils/country/isCountry';

const Sweden: FC<{ children: ReactNode }> = (props) => {
  if (!isSweden()) {return <></>;}

  const { children } = props;

  return (
    <>
      {children}
    </>
  );
};

export default Sweden;
