import { EmploymentType } from 'src/api/zrm';


const showEmploymentStartDate = (employmentType: EmploymentType) =>
  [
    EmploymentType.Full, EmploymentType.Hourly, EmploymentType.SelfEmployed, EmploymentType.Trial,
    EmploymentType.Project, EmploymentType.Retired, EmploymentType.EarlyRetired, EmploymentType.Student,
    EmploymentType.Disabled, EmploymentType.NorwegianSickLeave,
  ].includes(employmentType);

const showEmploymentEndDate = (employmentType: EmploymentType) =>
  [
    EmploymentType.Project,
  ].includes(employmentType);

const showEmploymentRetirementDate = (employmentType: EmploymentType) =>
  [
    EmploymentType.Retired, EmploymentType.EarlyRetired, EmploymentType.Disabled, EmploymentType.NorwegianSickLeave,
  ].includes(employmentType);

export { showEmploymentEndDate, showEmploymentRetirementDate, showEmploymentStartDate };
