import { createContext, FC, ReactNode, useContext, useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { useAnalyticsContext } from 'src/contexts/AnalyticsContext';

import { useFormStepperDialogContext } from './FormStepperDialogContext';


interface FormSectionContextProps {
  validateAndGoToNextStep: () => void;
}

interface FormSectionContextProviderProps {
  updateFormContextCallback: () => void;
  children: ReactNode;
  heading?: string;
}

const FormSectionContext = createContext<FormSectionContextProps>({
  validateAndGoToNextStep: () => { },
});

const FormSectionContextProvider: FC<FormSectionContextProviderProps> = (props) => {
  const { updateFormContextCallback, children, heading } = props;

  const { t } = useTranslation();
  const { validateForm, setTouched } = useFormikContext();
  const { goToNextStep } = useFormStepperDialogContext();

  const { analyticsLogFormikErrors } = useAnalyticsContext();

  const validateAndGoToNextStep = async () => {
    const temp = await validateForm();

    // Touch all fields to show errors on fields that was not touched yet
    setTouched(Object.keys(temp).reduce((acc, key) => ({ ...acc, [key]: true }), {}));

    if (Object.keys(temp).length === 0) {
      updateFormContextCallback();
      goToNextStep();
    } else { analyticsLogFormikErrors(temp); }

  };
  // Fixes: The object passed as the value prop to the Context provider changes every render. To fix this consider wrapping it in a useMemo hook
  const value = useMemo(() => ({ validateAndGoToNextStep }), [validateAndGoToNextStep]);

  return (
    <FormSectionContext.Provider
      value={value}
    >
      {heading && (
        <Box paddingBottom={4}>
          <Typography variant='h6'>{t(heading)}</Typography>
        </Box>
      )}
      {children}
    </FormSectionContext.Provider>
  );
};

const useFormSectionContext = () => useContext(FormSectionContext);

export { FormSectionContextProvider, useFormSectionContext };
