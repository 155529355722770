import BlancoSwedenForm from 'src/types/BlancoSweden/form/BlancoSwedenForm';

import BlancoSwedenConstants from './constants';
const defaultAmount = BlancoSwedenConstants.defaultAmount;
const defaultRepaymentTime = BlancoSwedenConstants.defaultRepaymentTime;

const blancoSwedenFormDefaultData: BlancoSwedenForm = {
  startPage: {
    consent: false,
    loanAmount: defaultAmount,
    newLoanPurpose: null,
    refinance: null,
    refinanceAmount: defaultAmount,
    repaymentTime: defaultRepaymentTime,
  },
  family: {
    children: null,
    maritalStatus: null,
  },
  housing: {
    livingCost: null,
    livingSituation: null,
  },
  income: {
    employmentType: null,
    monthlyIncome: null,
  },
  contact: {
    email: '',
    phone: '',
  },
  pni: {
    pni: '',
  },
  coApplicant: {
    hasCoApplicant: null,
    family: {
      children: null,
      maritalStatus: null,
    },
    housing: {
      livingCost: null,
      livingSituation: null,
    },
    income: {
      employmentType: null,
      monthlyIncome: null,
    },
    contact: {
      email: '',
      phone: '',
    },
    pni: {
      pni: '',
    },
  },
};

export default blancoSwedenFormDefaultData;
