import { FC } from 'react';

import FormStepperSubStepsContainer from 'src/components/Stepper/subSteps/FormStepperSubStepsContainer';
import BlancoEmployerNameSection from 'src/forms/BlancoShared/sections/income/BlancoEmployerNameSection';
import BlancoEmploymentTypeSection from 'src/forms/BlancoShared/sections/income/BlancoEmploymentTypeSection';

import BlancoSwedenIncomeAmountSection from './BlancoSwedenIncomeAmountSection';

interface BlancoSwedenIncomeStepProps {
}

const BlancoSwedenIncomeStep: FC<BlancoSwedenIncomeStepProps> = () => {
  return (
    <FormStepperSubStepsContainer
      stepsInOrder={[
        <BlancoEmploymentTypeSection key='BlancoEmploymentTypeSection' />,
        <BlancoSwedenIncomeAmountSection key='BlancoSwedenIncomeAmountSection' />,
        <BlancoEmployerNameSection key='BlancoEmployerNameSection' />,
      ]}
    />
  );
};


export default BlancoSwedenIncomeStep;
