import { FC, ReactNode } from 'react';

import { Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import StyledToggleButton from 'src/components/buttons/toggle/StyledToggleButton';
import StyledToggleButtonGroup from 'src/components/buttons/toggle/StyledToggleButtonGroup';
import DevTools from 'src/components/DevTools/DevTools';
import FieldSection from 'src/components/FieldSection';
import FinishButton from 'src/components/Stepper/common/FinishButton';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { useBlancoDataFormContext } from 'src/contexts/blanco/BlancoDataFormContext';
import { FormSectionContextProvider } from 'src/contexts/FormSectionContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';


interface BlancoSwedenCoApplicantSectionSelectorProps {
}

const BlancoSwedenCoApplicantSelectorSection: FC<BlancoSwedenCoApplicantSectionSelectorProps> = () => {
  const { formData, updateFormField, saveForm } = useBlancoDataFormContext();
  const { schemaFields } = useYupSchemaContext();
  const { t } = useTranslation();


  const validationSchema = Yup.object().shape({
    hasCoApplicant: schemaFields.coApplicant.hasCoApplicant,
  });

  const initialValues = {
    hasCoApplicant: formData.coApplicant.hasCoApplicant,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ values, setFieldValue, errors, setFieldTouched, touched }): ReactNode => {
        const name = 'hasCoApplicant';
        const error = getValueByPath<string>(errors, name);
        const isTouched = getValueByPath<boolean>(touched, name);
        const value = getValueByPath<string>(values, name);

        return (
          <FormSectionContextProvider
            updateFormContextCallback={() => updateFormField('coApplicant.hasCoApplicant', values.hasCoApplicant)}
            heading='Do you want to add a co-applicant?'
          >
            <FieldSection
              description='Having two people on the loan provides security for lenders and may result in better loan terms.'
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <StyledToggleButtonGroup
                  id={name}
                  value={value}
                  exclusive
                  onChange={(e, v) => {
                    if (v !== null) {
                      setFieldValue(name, v, true);
                      // Need to touch it to show error message and onBlur is not triggered and not validate touched since that triggers a race condition with value update
                      setFieldTouched(name, true, false);

                    }
                  }}
                  className={(error && isTouched) ? 'error' : ''}
                >
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={6}
                      md={3}
                    >
                      <StyledToggleButton value={true}>
                        {t('Yes, I do')}
                      </StyledToggleButton>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                    >
                      <StyledToggleButton value={false}>
                        {t('Not right now')}
                      </StyledToggleButton>
                    </Grid>
                    {error && isTouched && (
                      <Grid
                        item
                        xs={12}
                      >
                        <Typography
                          variant='caption'
                          color='error'
                        >
                          {error}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </StyledToggleButtonGroup>
              </Grid>
              <Grid
                item
                xs={12}
              >
                {values.hasCoApplicant === false && (
                  <FinishButton
                    updateFormContextCallback={() => updateFormField('coApplicant.hasCoApplicant', values.hasCoApplicant)}
                    saveFormCallback={saveForm}
                  />
                )}
                {values.hasCoApplicant === true && (
                  <NextStepButton alwaysVisible />
                )}
              </Grid>
              <DevTools />
            </FieldSection>
          </FormSectionContextProvider>
        );
      }}
    </Formik>
  );
};


export default BlancoSwedenCoApplicantSelectorSection;