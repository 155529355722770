import { FC } from 'react';

import { BlancoApiFormContextProvider } from 'src/contexts/blanco/BlancoApiFormContext';
import { BlancoDataFormContextProvider } from 'src/contexts/blanco/BlancoDataFormContext';
import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';
import { useSharedDataContext } from 'src/contexts/SharedDataContext';
import BlancoNorwayFormDefaultData from 'src/defaults/BlancoNorway/BlancoNorwayFormDefaultData';

import BlancoNorwayFormStepperDialog from './BlancoNorwayFormStepperDialog';
import BlancoNorwayAppCreatedSection from './sections/BlancoNorwayAppCreatedSection';
import BlancoSharedStartPageSection from '../BlancoShared/sections/BlancoSharedStartPageSection';

interface BlancoNorwayStartPageProps { }

const BlancoNorwayStartPage: FC<BlancoNorwayStartPageProps> = (props) => {
  const { } = props;

  const { formData } = useSharedDataContext();
  const { appIsCreated } = useFormStepperDialogContext();

  return (
    <BlancoApiFormContextProvider>
      <BlancoDataFormContextProvider defaultData={BlancoNorwayFormDefaultData}>
        {formData && (
          <>
            {!appIsCreated && (
              <BlancoSharedStartPageSection />
            )}
            {appIsCreated && (
              <BlancoNorwayAppCreatedSection />
            )}
            <BlancoNorwayFormStepperDialog />
          </>
        )}
      </BlancoDataFormContextProvider>
    </BlancoApiFormContextProvider>
  );
};


export default BlancoNorwayStartPage;
