import { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import MonthField from '../base/MonthField';
import YearField from '../base/YearField';

interface EmploymentEndDateFieldProps {
  monthName: string;
  yearName: string;
  coApplicant?: boolean;
}

const EmploymentEndDateField: FC<EmploymentEndDateFieldProps> = (props) => {
  const { monthName, yearName, coApplicant } = props;

  const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Typography>
          {t(!coApplicant ? 'What is the end date of your employment?' : 'What is the end date of your co-applicant\'s employment?')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        md={3}
      >
        <MonthField name={monthName} />
      </Grid>
      <Grid
        item
        xs={6}
        md={3}
      >
        <YearField
          name={yearName}
          maxYear={new Date().getFullYear() + 29}
        />
      </Grid>
    </Grid>
  );
};


export default EmploymentEndDateField;
