import { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import BlancoNorwayConstants from 'src/defaults/BlancoNorway/constants';
import BlancoSwedenConstants from 'src/defaults/BlancoSweden/constants';
import { isSweden } from 'src/utils/country/isCountry';

import FieldWithSlider from './base/FieldWithSlider';

interface LoanAmountFieldProps {
  name: string;
}

const LoanAmountField: FC<LoanAmountFieldProps> = (props) => {
  const { name } = props;

  const { t } = useTranslation();
  const isCountrySweden = isSweden();

  const consts = useMemo(() => isCountrySweden ? BlancoSwedenConstants : BlancoNorwayConstants, [isCountrySweden]);

  return (
    <FieldWithSlider
      name={name}
      title={t('Loan Amount')}
      min={consts.minLoanAmount}
      max={consts.maxLoanAmount}
      resetOnFirstFocus
    />
  );
};


export default LoanAmountField;
