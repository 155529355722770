import { createContext, ReactNode, useContext, useMemo } from 'react';

import { Api } from 'src/api/zrm';

type BearerToken = string;
export type ZRMApi = Api<BearerToken>;

interface APIs {
  api: ZRMApi,
  analyticsApi: ZRMApi,
}
interface Props {
  children?: ReactNode
}

const APIContext = createContext<APIs>(null);

export const APIProvider = ({ children }: Props) => {

  const value = useMemo((): APIs => {

    const baseApiParams = { secure: true, headers:  {} };
    // as of 2024, for cookies to work in the browser, the top domain must be the same as the one that set the cookie
    // so we need to use the correct domain for the correct environment
    let baseUrl = process.env.REACT_APP_ONLINE_SE_API_URL;
    let analyticsBaseUrl = process.env.REACT_APP_ANALYTICS_SE_API_URL;

    if (window.location.hostname.endsWith('zensum.no')) {
      baseUrl = process.env.REACT_APP_ONLINE_NO_API_URL;
      analyticsBaseUrl = process.env.REACT_APP_ANALYTICS_NO_API_URL;
    }

    return {
      api: new Api({
        baseUrl: baseUrl,
        baseApiParams: { ...baseApiParams, credentials: 'include' },
      }),
      analyticsApi: new Api({
        baseUrl: analyticsBaseUrl,
        baseApiParams: { ...baseApiParams, credentials: 'include' },
      }),
    };
  }, []);

  return (
    <APIContext.Provider value={value}>
      {children}
    </APIContext.Provider>
  );
};

export default APIContext;

export const useApi = () => useContext(APIContext);
