import { FC, Fragment, ReactNode, useContext, useMemo, useState } from 'react';

import { Box, Tab, Tabs } from '@mui/material';
import { FormikContext } from 'formik';

import { useBlancoDataFormContext } from 'src/contexts/blanco/BlancoDataFormContext';
import { useSettingsContext } from 'src/contexts/SettingsContext';

import DevToolsContextData from './partial/DevToolsContextData';
import DevToolsFormErrors from './partial/DevToolsFormErrors';
import DevToolsFormTouched from './partial/DevToolsFormTouched';
import DevToolsFormValues from './partial/DevToolsFormValues';

interface TabProps {
  label: string;
  value?: number;
  component: ReactNode;
}

interface DevToolsProps {
}

const DevTools: FC<DevToolsProps> = (props) => {
  const { } = props;

  const { enableDevTools } = useSettingsContext();
  const formikContext = useContext(FormikContext);
  const { formData: blancoSwedenData } = useBlancoDataFormContext();

  const [activeTab, setActiveTab] = useState(0);

  const tabs = useMemo(() => {
    let arr: TabProps[] = [];

    if (formikContext) {
      arr.push({
        label: 'Form Values',
        component: <DevToolsFormValues />,
      });
      arr.push({
        label: 'Form Errors',
        component: <DevToolsFormErrors />,
      });
      arr.push({
        label: 'Form Touched',
        component: <DevToolsFormTouched />,
      });
    }

    if (blancoSwedenData) {
      arr.push({
        label: 'Blanco Sweden Data',
        component: <DevToolsContextData values={blancoSwedenData} />,
      });
    }

    arr = arr.map((tab, index) => ({ ...tab, value: index }));

    return arr;
  }, [formikContext, blancoSwedenData]);

  return (
    <>
      {enableDevTools && (
        <Box sx={{ width: '100%', p: 2 }}>
          <Tabs
            indicatorColor="primary"
            scrollButtons="auto"
            textColor="primary"
            value={activeTab}
            onChange={(_e, v: number) => setActiveTab(v)}
          >
            {tabs.map((tab) => (
              <Tab
                key={`header-${tab.value}`}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
          <Box sx={{ px: 2 }}>
            {tabs.map((tab) => (
              <Fragment key={`tab-${tab.value}`}>
                {tab.value === activeTab && tab.component}
              </Fragment>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};


export default DevTools;
