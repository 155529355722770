import { LoanType } from 'src/api/zrm';
import BlancoNorwayForm from 'src/types/BlancoNorway/form/BlancoNorwayForm';

import BlancoNorwayConstants from './constants';

const defaultAmount = BlancoNorwayConstants.defaultAmount;

// TODO: FIX THAT TYPE
const BlancoNorwayFormDefaultData: BlancoNorwayForm = {
  startPage: {
    consent: false,
    loanAmount: defaultAmount,
    newLoanPurpose: null,
    refinance: null,
    refinanceAmount: defaultAmount,
    repaymentTime: 5,
  },
  family: {
    children: null,
    maritalStatus: null,
  },
  housing: {
    livingCost: null,
    livingSituation: null,
  },
  income: {
    employmentType: null,
    yearlyIncome: null,
    spouseYearlyIncome: null,
    hasYearlyRentIncome: null,
    yearlyRentIncome: null,
  },
  contact: {
    email: '',
    phone: '',
  },
  pni: {
    pni: '',
  },
  coApplicant: {
    hasCoApplicant: null,
    family: {
      children: null,
      maritalStatus: null,
    },
    housing: {
      livingCost: null,
      livingSituation: null,
    },
    income: {
      employmentType: null,
      yearlyIncome: null,
      spouseYearlyIncome: null,
    },
    contact: {
      email: '',
      phone: '',
    },
    pni: {
      pni: '',
    },
  },
  loans: {
    carLoan: {
      hasLoan: null,
      monthlyPayment: null,
      rate: null,
      totalAmount: null,
      loanType: LoanType.CAR_LOAN,
    },
    mortgageLoan: {
      hasLoan: null,
      monthlyPayment: null,
      rate: null,
      totalAmount: null,
      loanType: LoanType.MORTGAGE,
    },
    otherLoan: {
      hasLoan: null,
      monthlyPayment: null,
      rate: null,
      totalAmount: null,
      loanType: LoanType.OTHER,
    },
  },
};

export default BlancoNorwayFormDefaultData;
