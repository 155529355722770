import { createContext, FC, ReactNode, useContext, useRef } from 'react';

import OnlineFormType from 'src/types/OnlineFormType';

interface FormSelectorContextProps {
  dialogRef: any;
  onlineFormType: OnlineFormType;
}

interface FormSelectorContextProviderProps {
  children: ReactNode;
  onlineFormType: OnlineFormType;
}

const FormSelectorContext = createContext<FormSelectorContextProps>({ onlineFormType: OnlineFormType.BlancoSweden, dialogRef: null });

const FormSelectorContextProvider: FC<FormSelectorContextProviderProps> = (props) => {
  const { onlineFormType, children } = props;

  const { current: dialogRef } = useRef<any>();
  
  return (
    <FormSelectorContext.Provider
      value={{
        onlineFormType,
        dialogRef,
      }}
    >
      {children}
    </FormSelectorContext.Provider>
  );
};

const useFormSelectorContext = () => useContext(FormSelectorContext);

export { FormSelectorContextProvider, useFormSelectorContext };
