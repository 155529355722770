import { Card, styled } from '@mui/material';

const FormEntryCard = styled(Card)(({ theme }) => ({
  // width: '100%', TODO: Check if this works on production
  height: '100%',
  padding: theme.spacing(3),
  boxShadow: 'none',
}));

export default FormEntryCard;
