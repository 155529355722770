import { FC } from 'react';

import FormStepperSubStepsContainer from 'src/components/Stepper/subSteps/FormStepperSubStepsContainer';

import BlancoSharedCoApplicantSectionSelector from './BlancoSharedCoApplicantSectionSelector';
import BlancoSharedContactSection from './BlancoSharedContactSection';
import BlancoSharedPNISection from './BlancoSharedPNISection';

interface BlancoSharedContactStepProps {
}

const BlancoSharedContactStep: FC<BlancoSharedContactStepProps> = () => {
  return (
    <FormStepperSubStepsContainer
      stepsInOrder={[
        <BlancoSharedContactSection key='BlancoSharedContactSection' />,
        <BlancoSharedPNISection key='BlancoSharedPNISection' />,
        <BlancoSharedCoApplicantSectionSelector key='BlancoSharedCoApplicantSectionSelector' />,
      ]}
    />
  );
};


export default BlancoSharedContactStep;
