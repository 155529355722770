import { FC, ReactNode } from 'react';

import { Divider, Grid, Link, Typography } from '@mui/material';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import FlexCenterBox from 'src/components/boxes/FlexCenterBox';
import Norway from 'src/components/countrySpecific/Norway';
import Sweden from 'src/components/countrySpecific/Sweden';
import DevTools from 'src/components/DevTools/DevTools';
import ConsentCheckbox from 'src/components/fields/ConsentCheckbox';
import LoanAmountField from 'src/components/fields/LoanAmountField';
import RefinanceFormField from 'src/components/fields/RefinanceFormField';
import RepaymentTimeField from 'src/components/fields/RepaymentTimeField';
import ContinueWithFormButton from 'src/components/FormEntry/common/ContinueWithFormButton';
import MonthlyPaymentInfo from 'src/components/FormEntry/common/MonthlyPaymentInfo';
import { useBlancoDataFormContext } from 'src/contexts/blanco/BlancoDataFormContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';

interface BlancoSharedStartPageSectionProps {
}

const BlancoSharedStartPageSection: FC<BlancoSharedStartPageSectionProps> = () => {
  const { t } = useTranslation();

  const { formData, updateFormSection } = useBlancoDataFormContext();
  const { schemaFields } = useYupSchemaContext();

  const validationSchema = Yup.object().shape({
    loanAmount: schemaFields.startPage.loanAmount,
    repaymentTime: schemaFields.startPage.repaymentTime,
    refinance: schemaFields.startPage.refinance,
    refinanceAmount: schemaFields.startPage.refinanceAmount,
    newLoanPurpose: schemaFields.startPage.newLoanPurpose,
    consent: schemaFields.startPage.consent,
  });

  return (
    <Formik
      initialValues={formData.startPage}
      onSubmit={() => { }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values }): ReactNode => (
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <LoanAmountField name='loanAmount' />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ mb: 3 }}
          >
            <RepaymentTimeField name='repaymentTime' />
            <Norway>
              {values.repaymentTime > 5 && (
                <Typography variant='caption'>
                  {t('Repayment periods over 5 years require you to refinance existing loans and credits.')}
                </Typography>
              )}
            </Norway>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ mb: 2 }}
          >
            <MonthlyPaymentInfo />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <RefinanceFormField name='refinance' />
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <ConsentCheckbox name='consent' />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FlexCenterBox>
              <ContinueWithFormButton
                updateFormContextCallback={() => updateFormSection('startPage', values)}
                name='next-step'
              />
            </FlexCenterBox>
          </Grid>
          <Sweden>
            <Grid
              item
              xs={12}
              sx={{ mb: 2 }}
            >
              <Typography sx={{ fontSize: 'small' }}>
                {/* eslint-disable react/no-unescaped-entities */}
                <Trans>
                  By clicking "Next Step" you agree to our
                  {' '}
                  <Link
                    href="https://www.zensum.se/integritetsguide/anvandaravtal"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </Link>
                  {' '}
                  and
                  {' '}
                  <Link
                    href="https://www.zensum.se/integritetsguide/integritetspolicy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </Link>
                </Trans>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography sx={{ fontSize: 'small' }}>
                <Trans>
                  Example calculation: An 8-year annuity loan of
                  {' '}
                  <strong>150 000 kr</strong>
                  {' '}
                  with a variable interest rate of
                  {' '}
                  <strong>7.97%</strong>
                  {' '}
                  (no start fee / no invoice fee) gives an APR of
                  {' '}
                  <strong>8.27%</strong>
                  , a total cost of
                  {' '}
                  <strong>182 358 kr</strong>
                  , with a monthly cost of
                  {' '}
                  <strong>2 118 kr</strong>
                  {' '}
                  (120 payments). Individual interest rate ranges from 6.63–19.95% (APR 6.93–22.9%). Repayment period is 1–10 years for new loans, and 1–15 years for refinancing loans. Updated 2024-10-09.
                </Trans>
              </Typography>
            </Grid>
          </Sweden>
          <Norway>
            <Grid
              item
              xs={12}
              sx={{ mb: 2 }}
            >
              <Typography sx={{ fontSize: 'small' }}>
                {/* eslint-disable react/no-unescaped-entities */}
                <Trans>
                  By clicking "Next Step" you agree to our
                  {' '}
                  <Link
                    href="https://www.zensum.no/brukervilkar"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </Link>
                  {' '}
                  and
                  {' '}
                  <Link
                    href="https://www.zensum.no/personvern"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </Link>
                </Trans>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography sx={{ fontSize: 'small' }}>
                <Trans>
                  Example calculation: An 5-year annuity loan of
                  {' '}
                  <strong>150 000 kr</strong>
                  {' '}
                  with a variable interest rate of
                  {' '}
                  <strong>10.90%</strong>
                  {' '}
                  (no start fee / no invoice fee) gives an APR of
                  {' '}
                  <strong>11,46%</strong>
                  , a total cost of
                  {' '}
                  <strong>195 240 kr</strong>
                  , with a monthly cost of
                  {' '}
                  <strong>3 254 kr</strong>
                  {' '}
                  . Repayment period is 1–15 years for refinancing loans, and up to 5 years for new loans. Updated 2024-10-09.
                </Trans>
              </Typography>
            </Grid>
          </Norway>
          <DevTools />
        </Grid>
      )}
    </Formik>
  );
};


export default BlancoSharedStartPageSection;
