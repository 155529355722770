import { FC } from 'react';

import { ArrowForward } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useFormSectionContext } from 'src/contexts/FormSectionContext';
import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';

interface NextStepButtonProps {
  updateFormContextCallback?: () => void;
  alwaysVisible?: boolean;
}

const NextStepButton: FC<NextStepButtonProps> = (props) => {
  const { alwaysVisible = false } = props;

  const { t } = useTranslation();
  const { validateAndGoToNextStep } = useFormSectionContext();
  const { showNextStepButton } = useFormStepperDialogContext();

  if (!alwaysVisible && !showNextStepButton) {return <></>;}

  return (
    <Button
      variant="contained"
      onClick={() => validateAndGoToNextStep()}
      endIcon={<ArrowForward />}
      sx={{ fontWeight: 'bold', width: '100%', mt: 3 }}
      size='large'
    >
      {t('Next step')}
    </Button>
  );
};

export default NextStepButton;
