import { FC, ReactNode } from 'react';

import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import DevTools from 'src/components/DevTools/DevTools';
import LivingSituationField from 'src/components/fields/LivingSituation/LivingSituationField';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { useBlancoDataFormContext } from 'src/contexts/blanco/BlancoDataFormContext';
import { FormSectionContextProvider } from 'src/contexts/FormSectionContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';

interface BlancoSharedLivingSituationSectionProps {
  coApplicant?: boolean;
}

const BlancoSharedLivingSituationSection: FC<BlancoSharedLivingSituationSectionProps> = (props) => {
  const { coApplicant } = props;
  const { formData, updateFormField } = useBlancoDataFormContext();
  const { schemaFields } = useYupSchemaContext();

  const validationSchema = Yup.object().shape({
    livingSituation: schemaFields.housing.livingSituation,
  });

  const initialValues = {
    livingSituation: !coApplicant ? formData.housing.livingSituation : formData.coApplicant.housing.livingSituation,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}
    >
      {({ values }): ReactNode => (
        <FormSectionContextProvider
          updateFormContextCallback={() => updateFormField(!coApplicant ? 'housing.livingSituation' : 'coApplicant.housing.livingSituation', values.livingSituation)}
          heading={!coApplicant ? 'Where do you live?' : 'Where does your co-applicant live?'}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <LivingSituationField
                name='livingSituation'
                coApplicant={coApplicant}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <NextStepButton />
            </Grid>
            <DevTools />
          </Grid>
        </FormSectionContextProvider>
      )}
    </Formik>
  );
};


export default BlancoSharedLivingSituationSection;
