import { FC } from 'react';

import FormStepperSubStepsContainer from 'src/components/Stepper/subSteps/FormStepperSubStepsContainer';
import BlancoSharedContactSection from 'src/forms/BlancoShared/sections/contact/BlancoSharedContactSection';
import BlancoSharedPNISection from 'src/forms/BlancoShared/sections/contact/BlancoSharedPNISection';
import BlancoSharedChildrenSection from 'src/forms/BlancoShared/sections/familyAndHousing/BlancoSharedChildrenSection';
import BlancoSharedLivingCostSection from 'src/forms/BlancoShared/sections/familyAndHousing/BlancoSharedLivingCostSection';
import BlancoSharedLivingSituationSection from 'src/forms/BlancoShared/sections/familyAndHousing/BlancoSharedLivingSituationSection';
import BlancoSharedMaritalStatusSection from 'src/forms/BlancoShared/sections/familyAndHousing/BlancoSharedMaritalStatusSection';
import BlancoEmployerNameSection from 'src/forms/BlancoShared/sections/income/BlancoEmployerNameSection';
import BlancoEmploymentTypeSection from 'src/forms/BlancoShared/sections/income/BlancoEmploymentTypeSection';

import BlancoSwedenIncomeAmountSection from '../income/BlancoSwedenIncomeAmountSection';

interface BlancoSwedenCoApplicantStepProps {
}

const BlancoSwedenCoApplicantStep: FC<BlancoSwedenCoApplicantStepProps> = () => {
  return (
    <FormStepperSubStepsContainer
      stepsInOrder={[
        <BlancoSharedMaritalStatusSection
          key='BlancoSharedMaritalStatusSection'
          coApplicant
        />,
        <BlancoSharedChildrenSection
          key='BlancoSharedChildrenSection'
          coApplicant
        />,
        <BlancoSharedLivingSituationSection
          key='BlancoSharedLivingSituationSection'
          coApplicant
        />,
        <BlancoSharedLivingCostSection
          key='BlancoSharedLivingCostSection'
          coApplicant
        />,
        <BlancoEmploymentTypeSection
          key='BlancoEmploymentTypeSection'
          coApplicant
        />,
        <BlancoSwedenIncomeAmountSection
          key='BlancoSwedenIncomeAmountSection'
          coApplicant
        />,
        <BlancoEmployerNameSection
          key='BlancoEmployerNameSection'
          coApplicant
        />,
        <BlancoSharedContactSection
          key='BlancoSharedContactSection'
          coApplicant
        />,
        <BlancoSharedPNISection
          key='BlancoSharedPNISection'
          coApplicant
        />,
      ]}
    />
  );
};


export default BlancoSwedenCoApplicantStep;
