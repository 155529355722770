import { FC, useMemo } from 'react';

import { TextField, TextFieldProps } from '@mui/material';
import { useFormikContext } from 'formik';
import { NumericFormat } from 'react-number-format';

import FieldChangeHandler from 'src/components/FieldChangeHandler/FieldChangeHandler';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';

const CurrencyField: FC<TextFieldProps> = (props) => {
  const { name, fullWidth, error, sx, onFocus, onBlur } = props;

  const { values, setFieldValue, handleBlur } = useFormikContext<{ [key: string]: any; }>();

  const value = getValueByPath<string>(values, name);

  const handleOnBlur = useMemo(() => (e: any) => {
    if (onBlur) {onBlur({ ...e, target: { ...e.target, value } });}

    handleBlur(e);
  }, [onBlur, handleBlur, value]);

  return (
    <>
      <NumericFormat
        id={name}
        thousandSeparator=" "
        decimalSeparator=","
        allowedDecimalSeparators={[',', '.']}
        customInput={TextField}
        inputProps={{ style: { textAlign: 'right', paddingRight: '4px' }, inputMode: 'numeric' }}
        InputProps={{ endAdornment: 'kr' }}
        value={value}
        onFocus={onFocus}
        onValueChange={(v) => {
          setFieldValue(name, v.floatValue);
        }}
        onBlur={handleOnBlur}
        fullWidth={fullWidth}
        error={error}
        sx={sx}
      />
      <FieldChangeHandler name={name} />
    </>
  );
};

export default CurrencyField;
