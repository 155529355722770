import { FC, useEffect } from 'react';

import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import StyledToggleButton from 'src/components/buttons/toggle/StyledToggleButton';
import StyledToggleButtonGroup from 'src/components/buttons/toggle/StyledToggleButtonGroup';
import { useFormSectionContext } from 'src/contexts/FormSectionContext';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';



interface LoanQuestionFieldProps {
  name: string;
  title: string;
}

const LoanQuestionField: FC<LoanQuestionFieldProps> = (props) => {
  const { name, title } = props;

  const { t } = useTranslation();
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext<{ [key: string]: any; }>();
  const { validateAndGoToNextStep } = useFormSectionContext();

  const value = getValueByPath<boolean>(values, name);
  const error = getValueByPath<string>(errors, name);
  const isTouched = getValueByPath<boolean>(touched, name);

  // When user clicks `NO` value, they should be taken to the next step
  useEffect(() => {
    if (value === false && isTouched) {
      setTimeout(() => {
        validateAndGoToNextStep();
      }, 100);
    }
  }, [isTouched, values]);

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Typography>
          {t(title)}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <StyledToggleButtonGroup
          id={name}
          value={value}
          exclusive
          onChange={(_e, v) => {
            if (v !== null) {
              setFieldValue(name, v, true);
              // Need to touch it to show error message and onBlur is not triggered and not validate touched since that triggers a race condition with value update
              setFieldTouched(name, true, false);
            }
          }}
          className={(error && isTouched) ? 'error' : ''}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
            >
              <StyledToggleButton value={true}>
                {t('Yes')}
              </StyledToggleButton>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <StyledToggleButton value={false}>
                {t('No')}
              </StyledToggleButton>
            </Grid>
          </Grid>
        </StyledToggleButtonGroup>
        {error && isTouched && (
          <Grid
            item
            xs={12}
            sx={{ mt: 1 }}
          >
            <Typography
              variant='caption'
              color='error'
            >
              {error}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid >
  );
};


export default LoanQuestionField;
