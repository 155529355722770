import { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { MaritalStatus } from 'src/api/zrm';
import StyledToggleButton from 'src/components/buttons/toggle/StyledToggleButton';
import StyledToggleButtonGroup from 'src/components/buttons/toggle/StyledToggleButtonGroup';
import FieldChangeHandler from 'src/components/FieldChangeHandler/FieldChangeHandler';
import FieldSection from 'src/components/FieldSection';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';


interface MaritalStatusFieldProps {
  name: string;
  coApplicant?: boolean;
}

const MaritalStatusField: FC<MaritalStatusFieldProps> = (props) => {
  const { name, coApplicant } = props;

  const { t } = useTranslation();
  const { values, setFieldValue, errors, setFieldTouched, touched } = useFormikContext<{ [key: string]: any; }>();

  const error = getValueByPath<string>(errors, name);
  const isTouched = getValueByPath<boolean>(touched, name);
  const value = getValueByPath<string>(values, name);

  return (
    <FieldSection
      description={!coApplicant ? 'Choose the option that best fits your situation.' : 'Choose the option that best fits your co-applicant\'s situation.'}
    >
      <Grid
        item
        xs={12}
      >
        <StyledToggleButtonGroup
          id={name}
          value={value}
          exclusive
          onChange={(e, v) => {
            if (v !== null) {
              setFieldValue(name, v, true);
              // Need to touch it to show error message and onBlur is not triggered and not validate touched since that triggers a race condition with value update
              setFieldTouched(name, true, false);
            }
          }}
          className={(error && isTouched) ? 'error' : ''}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
              md={4}
            >
              <StyledToggleButton value={MaritalStatus.Unmarried}>
                {t('Single')}
              </StyledToggleButton>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
            >
              <StyledToggleButton value={MaritalStatus.Married}>
                {t('Married')}
              </StyledToggleButton>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
            >
              <StyledToggleButton value={MaritalStatus.Partner}>
                {t('Partner')}
              </StyledToggleButton>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
            >
              <StyledToggleButton value={MaritalStatus.Divorced}>
                {t('Divorced')}
              </StyledToggleButton>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
            >
              <StyledToggleButton value={MaritalStatus.WidowWidower}>
                {t('Widow/Widower')}
              </StyledToggleButton>
            </Grid>
          </Grid>
        </StyledToggleButtonGroup>
      </Grid>
      {error && isTouched && (
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='caption'
            color="error"
          >
            {error}
          </Typography>
        </Grid>
      )}
      <FieldChangeHandler
        name={name}
        nextStepOnValueChange
      />
    </FieldSection>
  );
};


export default MaritalStatusField;
