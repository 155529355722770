import { FC } from 'react';

import FormStepperSubStepsContainer from 'src/components/Stepper/subSteps/FormStepperSubStepsContainer';

import BlancoSharedChildrenSection from './BlancoSharedChildrenSection';
import BlancoSharedLivingCostSection from './BlancoSharedLivingCostSection';
import BlancoSharedLivingSituationSection from './BlancoSharedLivingSituationSection';
import BlancoSharedMaritalStatusSection from './BlancoSharedMaritalStatusSection';

interface BlancoSharedFamilyAndHousingStepProps {
}

const BlancoSharedFamilyAndHousingStep: FC<BlancoSharedFamilyAndHousingStepProps> = (props) => {
  const { } = props;

  return (
    <FormStepperSubStepsContainer
      stepsInOrder={
        [
          <BlancoSharedMaritalStatusSection key='BlancoSharedMaritalStatusSection' />,
          <BlancoSharedChildrenSection key='BlancoSharedChildrenSection' />,
          <BlancoSharedLivingSituationSection key='BlancoSharedLivingSituationSection' />,
          <BlancoSharedLivingCostSection key='BlancoSharedLivingCostSection' />,
        ]}
    />
  );
};


export default BlancoSharedFamilyAndHousingStep;
