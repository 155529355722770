import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';

interface SharedDataContextProps {
  formData: any;
  setFormData: Dispatch<SetStateAction<any>>;
}

interface SharedDataContextProviderProps {
  children: ReactNode;
}

const SharedDataContext = createContext<SharedDataContextProps>({
  formData: null,
  setFormData: () => { },
});

/**
 * One place to store data that needs to be shared across multiple components.
 * Ex. We need form data to be accesed via form stepper, in order to skip steps.
 */
const SharedDataContextProvider: FC<SharedDataContextProviderProps> = (props) => {
  const { children } = props;

  const [formData, setFormData] = useState<any>(null);

  const value = {
    formData,
    setFormData,
  };

  return (
    <SharedDataContext.Provider
      value={value}
    >
      {children}
    </SharedDataContext.Provider>
  );
};

const useSharedDataContext = () => useContext(SharedDataContext);

export { SharedDataContextProvider, useSharedDataContext };
