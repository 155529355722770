import { FC, useMemo, useState } from 'react';

import { Box, Slider, styled, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import FieldChangeHandler from 'src/components/FieldChangeHandler/FieldChangeHandler';
import { useSettingsContext } from 'src/contexts/SettingsContext';
import formatCurrency from 'src/utils/format/formatCurrency';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';

import CurrencyField from './CurrencyField';


// TODO: Reusable component like this
const ParentBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

interface FieldWithSliderProps {
  name: string;
  title: string;
  min: number;
  max: number;
  resetOnFirstFocus?: boolean;
}

const FieldWithSlider: FC<FieldWithSliderProps> = (props) => {
  const { name, title, min: initialMin, max: initialMax, resetOnFirstFocus = false } = props;

  const { country } = useSettingsContext();
  const { values, handleChange, setFieldValue, handleBlur } = useFormikContext<{ [key: string]: any; }>();
  const [isEverFocused, setIsEverFocused] = useState(false);

  const value = useMemo(() => getValueByPath<number>(values, name) ?? 0, [values, name]);

  const handleFocus = useMemo(() => {
    if (!resetOnFirstFocus || isEverFocused) {return () => {};}

    return () => {
      setFieldValue(name, '');
      setIsEverFocused(true);
    };
  }, [resetOnFirstFocus, isEverFocused]);

  const onChange = useMemo(() => (e: any) => {
    setIsEverFocused(true);

    return handleChange(e);
  }, [isEverFocused, resetOnFirstFocus]);

  const { min, max }: { min: number, max: number } = useMemo(() => {
    return {
      min: initialMin || 0,
      max: initialMax || initialMin || 0,
    };
  }, [initialMin, initialMax]);

  return (
    <Box>
      <ParentBox>
        {/* TODO: this as variant to theme */}
        <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
          {title}:
        </Typography>
        <CurrencyField
          size='small'
          value={value}
          onFocus={handleFocus}
          onBlur={(e) => {
            let v = parseInt(e.target.value, 10);

            if (isNaN(value)) {v = min;}

            if (value < min) {v = min;}
            if (value > max) {v = max;}

            setFieldValue(name, v);
            handleBlur(e);
          }}
          name={name}
          sx={{ width: '150px' }}
        />
      </ParentBox>
      <Slider
        name={name}
        value={value || 0}
        onChange={onChange}
        onBlur={handleBlur}
        min={min}
        max={max}
        step={5000}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant="body2"
          sx={{ cursor: 'pointer' }}
        >
          {formatCurrency(min, country, 0)}
        </Typography>
        <Typography
          variant="body2"
          sx={{ cursor: 'pointer' }}
        >
          {formatCurrency(max, country, 0)}
        </Typography>
      </Box>
      <FieldChangeHandler name={name} />
    </Box>
  );
};


export default FieldWithSlider;
