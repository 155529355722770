import { Box, styled } from '@mui/material';

const FlexColumnCenterBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

export default FlexColumnCenterBox;
