import { FC } from 'react';

import { BlancoApiFormContextProvider } from 'src/contexts/blanco/BlancoApiFormContext';
import { BlancoDataFormContextProvider } from 'src/contexts/blanco/BlancoDataFormContext';
import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';
import { useSharedDataContext } from 'src/contexts/SharedDataContext';
import blancoSwedenFormDefaultData from 'src/defaults/BlancoSweden/BlancoSwedenFormDefaultData';

import BlancoSwedenFormStepperDialog from './BlancoSwedenFormStepperDialog';
import BlancoSwedenAppCreatedSection from './sections/BlancoSwedenAppCreatedSection';
import BlancoSharedStartPageSection from '../BlancoShared/sections/BlancoSharedStartPageSection';

interface BlancoSwedenStartPageProps { }

const BlancoSwedenStartPage: FC<BlancoSwedenStartPageProps> = (props) => {
  const { } = props;

  const { formData } = useSharedDataContext();
  const { appIsCreated } = useFormStepperDialogContext();

  return (
    <BlancoApiFormContextProvider>
      <BlancoDataFormContextProvider defaultData={blancoSwedenFormDefaultData}>
        {formData && (
          <>
            {!appIsCreated && (
              <BlancoSharedStartPageSection />
            )}
            {appIsCreated && (
              <BlancoSwedenAppCreatedSection />
            )}
            <BlancoSwedenFormStepperDialog />
          </>
        )}

      </BlancoDataFormContextProvider>
    </BlancoApiFormContextProvider>
  );
};


export default BlancoSwedenStartPage;
