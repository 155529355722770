import { FC, useEffect } from 'react';

import StepperDialog from 'src/components/dialogs/StepperDialog/StepperDialog';
import FormStepper from 'src/components/Stepper/FormStepper';
import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';
import BlancoNorwayForm from 'src/types/BlancoNorway/form/BlancoNorwayForm';
import SingleStep from 'src/types/Stepper/SingleStep';
import SkipStep, { SkipStepMode } from 'src/types/Stepper/SkipStep';
import isEmploymentNameRequired from 'src/utils/employmentType/isEmployerNameRequired';

import BlancoNorwayCoApplicantStep from './sections/coApplicant/BlancoNorwayCoApplicantStep';
import BlancoNorwayIncomeStep from './sections/income/BlancoNorwayIncomeStep';
import BlancoNorwayLoansStep from './sections/loans/BlancoNorwayLoansStep';
import BlancoSharedContactStep from '../BlancoShared/sections/contact/BlancoSharedContactStep';
import BlancoSharedFamilyAndHousingStep from '../BlancoShared/sections/familyAndHousing/BlancoSharedFamilyAndHousingStep';


const steps: SingleStep[] = [
  {
    label: 'Family',
    component: <BlancoSharedFamilyAndHousingStep />,
    subStepsCount: 4,
  },
  {
    label: 'Income',
    component: <BlancoNorwayIncomeStep />,
    subStepsCount: 4,
  },
  {
    label: 'Secured Loans',
    component: <BlancoNorwayLoansStep />,
    subStepsCount: 3,
  },
  {
    label: 'Contact',
    component: <BlancoSharedContactStep />,
    subStepsCount: 3,
  },
  {
    label: 'Co-Applicant',
    component: <BlancoNorwayCoApplicantStep />,
    subStepsCount: 4,
  },
];

const skippable: SkipStep[] = [
  {
    mode: SkipStepMode.SUB_STEP,
    stepNumber: 1,
    subStepNumber: 2,
    skipOn: (values: BlancoNorwayForm) => !isEmploymentNameRequired(values.income.employmentType),
  },
  {
    mode: SkipStepMode.SUB_STEP,
    stepNumber: 4,
    subStepNumber: 1,
    skipOn: (values: BlancoNorwayForm) => !isEmploymentNameRequired(values.coApplicant.income.employmentType),
  },
];

interface BlancoNorwayFormStepperDialogProps { }

const BlancoNorwayFormStepperDialog: FC<BlancoNorwayFormStepperDialogProps> = (props) => {
  const { } = props;

  const { setSteps, setSkippableSteps } = useFormStepperDialogContext();

  useEffect(() => {
    setSteps(steps);
    setSkippableSteps(skippable);
  }, []);

  return (
    <>
      <StepperDialog>
        <FormStepper steps={steps} />
      </StepperDialog>
    </>
  );
};


export default BlancoNorwayFormStepperDialog;
