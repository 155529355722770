import { FC, ReactNode } from 'react';

import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import DevTools from 'src/components/DevTools/DevTools';
import MaritalStatusField from 'src/components/fields/Family/MaritalStatusField';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { useBlancoDataFormContext } from 'src/contexts/blanco/BlancoDataFormContext';
import { FormSectionContextProvider } from 'src/contexts/FormSectionContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';

interface BlancoSharedMaritalStatusSectionProps {
  coApplicant?: boolean;
}

const BlancoSharedMaritalStatusSection: FC<BlancoSharedMaritalStatusSectionProps> = (props) => {
  const { coApplicant } = props;
  const { formData, updateFormField } = useBlancoDataFormContext();
  const { schemaFields } = useYupSchemaContext();

  const validationSchema = Yup.object().shape({
    maritalStatus: schemaFields.family.maritalStatus,
  });

  const initialValues = {
    maritalStatus: !coApplicant ? formData.family.maritalStatus : formData.coApplicant.family.maritalStatus,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}
    >
      {({ values }): ReactNode => (
        <FormSectionContextProvider
          updateFormContextCallback={() => updateFormField(!coApplicant ? 'family.maritalStatus' : 'coApplicant.family.maritalStatus', values.maritalStatus)}
          heading={!coApplicant ? 'What is your marital status?' : 'What is your co-applicant\'s marital status?'}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <MaritalStatusField
                name='maritalStatus'
                coApplicant={coApplicant}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <NextStepButton />
            </Grid>
            <DevTools />
          </Grid>
        </FormSectionContextProvider>
      )}
    </Formik>
  );
};


export default BlancoSharedMaritalStatusSection;
