import { styled, ToggleButton } from '@mui/material';

// TODO: Make this in theme as default toggle button style
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: '100%',
  height: '100%',
  '&.MuiToggleButtonGroup-firstButton, &.MuiToggleButtonGroup-middleButtonm &.MuiToggleButtonGroup-lastButton': {
    borderRadius: '4px',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  '&:not(.Mui-selected)': {
    color: theme.palette.text.primary,
  },
  '&.no-uppercase': {
    textTransform: 'none',
  },
}));

export default StyledToggleButton;
