import { FC } from 'react';

import { Box } from '@mui/material';


interface DevToolsContextDataProps {
  values: Record<string, any>;
}

const DevToolsContextData: FC<DevToolsContextDataProps> = (props) => {
  const { values } = props;

  return (
    <Box sx={{ width: '100%' }}>
      <pre>
        {JSON.stringify(values, null, 2)}
      </pre>
    </Box>
  );
};


export default DevToolsContextData;
