import { FC, ReactNode } from 'react';

import { Dialog, styled } from '@mui/material';

import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';
import useIsMobile from 'src/hooks/mediaQuery/useIsMobile';

import StepperDialogHeader from './parts/StepperDialogHeader';

const StyledDialog = styled(Dialog)(({ }) => ({
  '& > .MuiDialog-container': {
    alignItems: 'flex-start',
  },
}));

interface StepperDialogProps {
  children: ReactNode;
}

const StepperDialog: FC<StepperDialogProps> = (props) => {
  const { children } = props;

  const isMobile = useIsMobile();
  const { open } = useFormStepperDialogContext();

  return (
    <StyledDialog
      open={open}
      transitionDuration={0}
      maxWidth="md"
      fullScreen={isMobile}
      fullWidth
    >
      <StepperDialogHeader />
      {children}
    </StyledDialog>
  );
};


export default StepperDialog;
