import { Box, styled } from '@mui/material';

const FlexCenterBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

export default FlexCenterBox;
