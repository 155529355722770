import { Country, LoanPurposeNO, LoanPurposeSE } from 'src/api/zrm';
import LoanPurposesByCountry from 'src/types/startPage/LoanPurposesByCountry';

const loanPurposesByCountry: Record<Country, LoanPurposesByCountry> = {
  [Country.SE]: {
    mainLoanPurposes: [
      LoanPurposeSE.Car,
      LoanPurposeSE.HealthcareExpenses,
      LoanPurposeSE.Consumption,
      LoanPurposeSE.HomeRemodelling,
      LoanPurposeSE.DebtCollection,
    ],
    otherLoanPurposes: [
      LoanPurposeSE.Investment,
      LoanPurposeSE.Education,
      LoanPurposeSE.LoanOnBehalfOfOther,
      LoanPurposeSE.Travel,
      LoanPurposeSE.InvestmentAbroad,
      LoanPurposeSE.HomeDownpayment,
    ],
  },
  [Country.NO]: {
    mainLoanPurposes: [
      LoanPurposeNO.Car,
      LoanPurposeNO.HealthcareExpenses,
      LoanPurposeNO.Consumption,
      LoanPurposeNO.HomeRemodelling,
      LoanPurposeNO.DebtCollection,
    ],
    otherLoanPurposes: [
      LoanPurposeNO.Investment,
      LoanPurposeNO.Education,
      LoanPurposeNO.Travel,
      LoanPurposeNO.HomeDownpayment,
      LoanPurposeNO.Wedding,
      LoanPurposeNO.Relocation,
    ],
  },
};

export default loanPurposesByCountry;
