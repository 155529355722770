import { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface FieldSectionProps {
  description?: string;
  children: React.ReactNode;
  spacing?: number;
}

const FieldSection: FC<FieldSectionProps> = (props) => {
  const { children, description, spacing = 1 } = props;
  const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={spacing}
    >
      {description && (
        <Grid
          item
          xs={12}
        >
          <Box paddingBottom={2}>
            <Typography>{t(description)}</Typography>
          </Box>
        </Grid>
      )}
      {children}
    </Grid>
  );
};

export default FieldSection;