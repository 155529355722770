import { FC, ReactNode, useMemo } from 'react';

import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { MaritalStatus } from 'src/api/zrm';
import Norway from 'src/components/countrySpecific/Norway';
import Sweden from 'src/components/countrySpecific/Sweden';
import DevTools from 'src/components/DevTools/DevTools';
import NorwegianCitizenField from 'src/components/fields/Contact/NorwegianCitizenField';
import PNIField from 'src/components/fields/Contact/PNIField';
import FieldSection from 'src/components/FieldSection';
import FinishButton from 'src/components/Stepper/common/FinishButton';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { useBlancoDataFormContext } from 'src/contexts/blanco/BlancoDataFormContext';
import { FormSectionContextProvider } from 'src/contexts/FormSectionContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';
import { isNorway } from 'src/utils/country/isCountry';

interface BlancoSharedPNISectionProps {
  coApplicant?: boolean;
}

const BlancoSharedPNISection: FC<BlancoSharedPNISectionProps> = (props) => {
  const { coApplicant } = props;
  const { formData, updateFormSection, saveForm } = useBlancoDataFormContext();
  const { schemaFields } = useYupSchemaContext();
  const isCountryNorway = isNorway();

  const validationSchema = useMemo(() => {
    const schema: { pni: any, isNorwegianCitizen?: any } = {
      pni: schemaFields.pni.pni,
    };

    if (isCountryNorway) {
      schema.isNorwegianCitizen = schemaFields.pni.isNorwegianCitizen;
    }

    return Yup.object().shape(schema);
  }, [schemaFields, isCountryNorway]);

  const initialValues = !coApplicant ? formData.pni : formData.coApplicant.pni;

  const showCoApplicantNorway = useMemo(() => [MaritalStatus.Married, MaritalStatus.Partner].includes(formData.family.maritalStatus), [formData?.family?.maritalStatus]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ values }): ReactNode => (
        <FormSectionContextProvider
          updateFormContextCallback={() => updateFormSection(!coApplicant ? 'pni' : 'coApplicant.pni', values)}
          heading={!coApplicant ? 'Share your personal identification number' : 'Share your co-applicant\'s personal identification number'}
        >
          <FieldSection
            description={!coApplicant ? 'The application is personal, and your personal identification number is required to verify your identity.' : 'Your co-applicant\'s personal identification number is required to verify their identity.'}
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <PNIField name='pni' />
            </Grid>
            <Norway>
              <Grid
                item
                xs={12}
              >
                <NorwegianCitizenField
                  name='isNorwegianCitizen'
                  coApplicant={coApplicant}
                />
              </Grid>
            </Norway>
            <Grid
              item
              xs={12}
            >
              <Sweden>
                {!coApplicant && (
                  <NextStepButton alwaysVisible />
                )}
                {!!coApplicant && (
                  <FinishButton
                    saveFormCallback={saveForm}
                    updateFormContextCallback={() => updateFormSection(!coApplicant ? 'pni' : 'coApplicant.pni', values)}
                  />
                )}
              </Sweden>
              <Norway>
                {!coApplicant && showCoApplicantNorway && (
                  <NextStepButton alwaysVisible />
                )}
                {(!!coApplicant || !showCoApplicantNorway) && (
                  <FinishButton
                    saveFormCallback={saveForm}
                    updateFormContextCallback={() => updateFormSection(!coApplicant ? 'pni' : 'coApplicant.pni', values)}
                  />
                )}
              </Norway>
            </Grid>
            <DevTools />
          </FieldSection>
        </FormSectionContextProvider>
      )}
    </Formik>
  );
};


export default BlancoSharedPNISection;
