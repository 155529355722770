import { FC } from 'react';

import { Box } from '@mui/material';

const ZensumLogo: FC = () => {
  return (
    <Box>
      <img
        src={`${process.env.PUBLIC_URL || ''}/static/zens-logo.svg`}
        height="20"
        width="auto"
        alt="ZENSUM"
      />
    </Box>
  );
};


export default ZensumLogo;
