import { FC, useMemo } from 'react';

import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Country } from 'src/api/zrm';
import FieldChangeHandler from 'src/components/FieldChangeHandler/FieldChangeHandler';
import FieldSection from 'src/components/FieldSection';
import { useSettingsContext } from 'src/contexts/SettingsContext';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';

interface EmailFieldProps {
  name: string;
}

const norwayDomains = [
  'gmail.com',
  'hotmail.com',
  'yahoo.com',
  'outlook.com',
  'live.no',
  'hotmail.no',
  'online.no',
  'icloud.com',
  'yahoo.no',
  'wp.pl',
  'live.com',
  'msn.com',
  'me.com',
  'inbox.lv',
  'lyse.net',
  'o2.pl',
  'yahoo.fr',
  'interia.pl',
  'mail.com',
  'op.pl',
  'yahoo.co.uk',
  'abv.bg',
  'ymail.com',
  'protonmail.com',
  'live.se',
  'getmail.no',
  'broadpark.no',
  'ntebb.no',
  'onet.pl',
  'hotmail.co.uk',
  'rocketmail.com',
  'vp.pl',
  'start.no',
  'haugnett.no',
  'yahoo.es',
  'mac.com',
  'onet.eu',
  'interia.eu',
  'yahoo.se',
  'tlen.pl',
  'yahoo.de',
  'windowslive.com',
  'inbox.lt',
];

const swedenDomains = [
  'hotmail.com',
  'gmail.com',
  'live.se',
  'hotmail.se',
  'outlook.com',
  'yahoo.com',
  'telia.com',
  'icloud.com',
  'yahoo.se',
  'live.com',
  'msn.com',
  'me.com',
  'spray.se',
  'comhem.se',
  'tele2.se',
  'yahoo.fr',
  'mail.com',
  'wp.pl',
  'home.se',
  'yahoo.co.uk',
  'bahnhof.se',
  'outlook.se',
  'ymail.com',
  'malmo.se',
  'inbox.lv',
  'telia.se',
  'protonmail.com',
  'bredband2.com',
  'rocketmail.com',
  'interia.pl',
  'hotmail.fr',
  'hotmail.co.uk',
  'orebro.se',
  'windowslive.com',
  'email.com',
  'svenskakyrkan.se',
  'yahoo.es',
  'kth.se',
  'stockholm.se',
  'o2.pl',
  'aol.com',
  'ownit.nu',
  'life.se',
  'peab.se',
  'norrkoping.se',
  'kalmar.se',
  'hotmail.de',
  'mac.com',
  'volvocars.com',
  'helsingborg.se',
  'live.dk',
  'gavle.se',
];

const EmailField: FC<EmailFieldProps> = (props) => {
  const { name } = props;

  const { t } = useTranslation();
  const { values, handleChange, errors, handleBlur, touched } = useFormikContext<{ [key: string]: any; }>();
  const { country } = useSettingsContext();

  const error = getValueByPath<string>(errors, name);
  const isTouched = getValueByPath<boolean>(touched, name);

  const domains = useMemo(() => {
    switch (country) {
      case Country.NO:
        return norwayDomains;
      case Country.SE:
        return swedenDomains;
      default:
        return [];
    }
  }, [country]);

  const options = useMemo(() => {
    if (!values[name]?.includes('@')) {return [];}

    return domains
      .filter(domain => !values[name].includes('@') || domain.includes(values[name].split('@')[1]))
      .map((domain) => `${values[name].split('@')[0]}@${domain}`);
  }, [values[name], domains]);

  return (
    <FieldSection>
      <Grid
        item
        xs={12}
        md={6}
      >
        <Autocomplete
          id={name}
          disableClearable
          options={options}
          inputValue={values[name] || ''}
          onInputChange={(e, newValue) => {
            handleChange({ ...e, target: { name, value: newValue } });
          }}
          getOptionLabel={(option) => option}
          onChange={(e, newValue: string) => {
            handleChange({ ...e, target: { name, value: newValue } });
          }}
          onBlur={handleBlur}
          selectOnFocus
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('Email')}
              error={!!error && isTouched}
              type='email'
              inputProps={{ ...params.inputProps, inputMode: 'email' }}
              fullWidth
            />
          )}
        />
      </Grid>
      {error && isTouched && (
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='caption'
            color="error"
          >
            {error}
          </Typography>
        </Grid>
      )}
      <FieldChangeHandler name={name} />
    </FieldSection>
  );
};


export default EmailField;
