function getValueByPath<T>(obj: any, path: string): T {
  if (!obj || !path) {return undefined;}

  const regex = /(?<=\[)(?!")[^\]]+|(?<=\[")[^"]+|[^."[\]]+/g;

  // split by . and [] and return the value
  return path.match(regex).reduce((res, prop) => res?.[prop], obj);
}

export default getValueByPath;
