import { v4 } from 'uuid';

import { Currency, Loan } from 'src/api/zrm';
import { BlancoNorwayLoan } from 'src/types/BlancoNorway/form/BlancoNorwayLoansForm';


const mapLoans = (loans: BlancoNorwayLoan[]): Loan[] => loans.filter(loan => loan.hasLoan).map((loan) => ({
  loan_type: loan.loanType,
  amount: loan.totalAmount,
  rate: +(loan.rate / 100).toFixed(4),
  monthly_payment: loan.monthlyPayment,
  currency: Currency.NOK,
  loan_id: v4(),
  bank_id: 255, // Hardcoded for now to `Other` bank
}));

export default mapLoans;
