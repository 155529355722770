import { FC } from 'react';

import { Grid, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import FieldChangeHandler from 'src/components/FieldChangeHandler/FieldChangeHandler';
import FieldSection from 'src/components/FieldSection';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';

interface LivingCostFieldProps {
  name: string;
  coApplicant?: boolean;
}


const EmployerNameField: FC<LivingCostFieldProps> = (props) => {
  const { name, coApplicant } = props;

  const { t } = useTranslation();
  const { values, handleChange, errors, handleBlur, touched } = useFormikContext<{ [key: string]: any; }>();

  const error = getValueByPath<string>(errors, name);
  const isTouched = getValueByPath<boolean>(touched, name);
  const value = getValueByPath<string>(values, name);

  return (
    <FieldSection
      description={!coApplicant
        ? 'Enter the name of your primary employer if you have multiple. We do not contact your employer.'
        : 'Enter the name of your co-applicant\'s primary employer if they have multiple. We do not contact the employer.'
      }
    >
      <Grid
        item
        xs={12}
        md={6}
      >
        <TextField
          label={t('Company Name')}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          name={name}
          error={isTouched && !!error}
          fullWidth
        />
      </Grid>
      {error && isTouched && (
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='caption'
            color="error"
          >
            {error}
          </Typography>
        </Grid>
      )}
      <FieldChangeHandler name={name} />
    </FieldSection>
  );
};


export default EmployerNameField;
