import { styled } from '@mui/material';

import AnalyticsWrappedToggleButtonGroup from './AnalyticsWrappedToggleButtonGroup';

const StyledToggleButtonGroup = styled(AnalyticsWrappedToggleButtonGroup)(({ theme }) => ({
  width: '100%',
  '&.error button.MuiToggleButton-root': {
    borderColor: theme.palette.error.main,
    borderStyle: 'solid',
    borderWidth: '1px',
  },
}));

export default StyledToggleButtonGroup;
