import { FC, ReactNode } from 'react';

import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import DevTools from 'src/components/DevTools/DevTools';
import LivingCostField from 'src/components/fields/LivingSituation/LivingCostField';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { useBlancoDataFormContext } from 'src/contexts/blanco/BlancoDataFormContext';
import { FormSectionContextProvider } from 'src/contexts/FormSectionContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';

interface BlancoSharedLivingCostSectionProps {
  coApplicant?: boolean;
}

const BlancoSharedLivingCostSection: FC<BlancoSharedLivingCostSectionProps> = (props) => {
  const { coApplicant } = props;
  const { formData, updateFormField } = useBlancoDataFormContext();
  const { schemaFields } = useYupSchemaContext();

  const validationSchema = Yup.object().shape({
    livingCost: schemaFields.housing.livingCost,
  });

  const initialValues = {
    livingCost: !coApplicant ? formData.housing.livingCost : formData.coApplicant.housing.livingCost,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ values }): ReactNode => (
        <FormSectionContextProvider
          updateFormContextCallback={() => updateFormField(!coApplicant ? 'housing.livingCost' : 'coApplicant.housing.livingCost', values.livingCost)}
          heading={!coApplicant ? 'What is your share of living cost?' : 'What is your co-applicant\'s share of living cost?'}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <LivingCostField
                name='livingCost'
                coApplicant={coApplicant}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <NextStepButton alwaysVisible />
            </Grid>
            <DevTools />
          </Grid>
        </FormSectionContextProvider>
      )}
    </Formik>
  );
};


export default BlancoSharedLivingCostSection;
