import { FC, ReactNode, useEffect, useRef } from 'react';

import { Check } from '@mui/icons-material';
import { Grid, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import DevTools from 'src/components/DevTools/DevTools';


interface BlancoSwedenStartPageSectionProps {
}

const CustomListItem: FC<{ children: ReactNode }> = ({ children }) => (
  <ListItem
    sx={{
      alignItems: 'flex-start',
      py: 0,
    }}
  >
    <ListItemIcon sx={{ pt: 0.5 }}>
      <Check
        fontSize="small"
        color="primary"
      />
    </ListItemIcon>
    <ListItemText sx={{ '& a': { color: 'primary.main', whiteSpace: 'nowrap' } }}>
      {children}
    </ListItemText>
  </ListItem>
);

const BlancoSwedenAppCreatedSection: FC<BlancoSwedenStartPageSectionProps> = (props) => {
  const { } = props;

  const { t } = useTranslation();

  const scrollRef = useRef(null);

  // Scroll page into view due to modal scroll issue.
  // Two attempts - one when component is loaded and one after 250ms, to make sure transitions are completed
  useEffect(() => {
    scrollRef?.current?.scrollIntoView();

    setTimeout(() => {
      scrollRef?.current?.scrollIntoView();
    }, 250);
  }, []);

  return (
    <Grid
      container
      spacing={1}
      ref={scrollRef}
    >
      <Grid
        item
        xs={12}
      >
        <Typography sx={{ color: 'primary.main', fontSize: '1.5rem', fontWeight: '500', mb: 2 }}>
          {t('Thank you for your loan application!')}
        </Typography>
        <Typography sx={{ fontSize: '1.5rem', fontWeight: '500' }}>
          {t('What happens next?')}
        </Typography>
        <List sx={{ mb: 2 }}>
          <CustomListItem>
            <Trans>
              In just a few minutes, you&apos;ll get a link via SMS to log in with your BankID on
              {' '}
              <Link
                href='https://www.zensum.se/logga-in'
                target="_blank"
                rel="noreferrer"
              >
                My Pages
              </Link>.
            </Trans>
          </CustomListItem>
          <CustomListItem>
            <Trans>
              As offers from lenders come in, you&apos;ll see an overview of different loan options. The time it takes and the number of offers can vary, so hang tight!
              We know it&apos;s tempting to make a quick decision, but a bit of patience will help you find the best fit.
            </Trans>
          </CustomListItem>
          <CustomListItem>
            <Trans>
              If you need any assistance, we&apos;re here for you at
              {' '}
              <Link
                href="mailto:kundservice@zensum.se"
                rel="noreferrer"
              >
                kundservice@zensum.se
              </Link>
              {' '}
              or by phone at
              {' '}
              <Link
                href='tel:+46771304447'
                target="_blank"
                rel="noreferrer"
              >
                0771-30 44 47
              </Link>.
            </Trans>
          </CustomListItem>
          <CustomListItem>
            <Trans>
              <strong>Note!</strong>
              {' '}
              To ensure that your credit score and loan offers are not negatively affected, we recommend that you do not apply for loans with other banks or loan brokers before receiving a response from Zensum.
            </Trans>
          </CustomListItem>
        </List>
      </Grid>
      <DevTools />
    </Grid>
  );
};


export default BlancoSwedenAppCreatedSection;
