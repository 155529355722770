import { FC } from 'react';

import { Box } from '@mui/material';
import { useFormikContext } from 'formik';


interface DevToolsFormValuesProps {
}

const DevToolsFormValues: FC<DevToolsFormValuesProps> = (props) => {
  const { } = props;

  const { values } = useFormikContext<{ [key: string]: any; }>();

  return (
    <Box sx={{ width: '100%' }}>
      <pre>
        {JSON.stringify(values, null, 2)}
      </pre>
    </Box>
  );
};


export default DevToolsFormValues;
