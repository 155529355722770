
import { FC, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-redeclare
import history from 'history/browser';

import FormSelector from './components/FormEntry/FormSelector';
import { FormSelectorContextProvider } from './contexts/FormSelectorContext';
import { FormStepperDialogContextProvider } from './contexts/FormStepperDialogContext';
import { SharedDataContextProvider } from './contexts/SharedDataContext';
import OnlineFormType from './types/OnlineFormType';

interface AppProps {
  onlineFormType: OnlineFormType;
}

const App: FC<AppProps> = (props) => {
  const { onlineFormType } = props;

  // Fix edge case where user navigates to page via URL with steps in pathname
  useEffect(() => {
    if (/^\/step\d*-\d+/.test(history.location.pathname) || /^\/steg\d*-\d+/.test(history.location.pathname)) {
      history.replace('/');
    }
  }, []);

  return (
    <SharedDataContextProvider>
      <FormSelectorContextProvider onlineFormType={onlineFormType}>
        <FormStepperDialogContextProvider>
          <FormSelector />
        </FormStepperDialogContextProvider>
      </FormSelectorContextProvider>
    </SharedDataContextProvider>
  );
};

export default App;
