import { ThemeOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface TypeBackground {
    light: string;
  }
}

// TODO: Add more theme options & proper colors
const defaultThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#068466',
      light: '#a29bfe',
      dark: '#004930',
      contrastText: '#fff',
    },
    secondary: {
      main: '#670684',
      light: '#b55fb6',
      dark: '#490176',
      contrastText: '#fff',
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0)',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          backgroundColor: '#254061',
        },
      },
    },
  },
};

const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    background: {
      light: '#f5f5f5',
    },
  },
};

const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    error: {
      main: '#cf6679',
      light: '#ef5350',
      dark: '#c62828',
      contrastText: '#fff',
    },
    background: {
      paper: '#121212',
      default: '#121212',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.165), rgba(255, 255, 255, 0.165))',
        },
      },
    },
  },
};

export { darkThemeOptions, defaultThemeOptions, lightThemeOptions };

