import { createContext, FC, ReactNode, useMemo } from 'react';

import { ThemeProvider, useMediaQuery } from '@mui/material';

import { defaultDarkTheme, defaultLightTheme } from 'src/theme/default/defaultTheme';

interface ThemeControlContextProps {
}

interface ThemeControlContextProviderProps {
  children: ReactNode;
}

const ThemeControlContext = createContext<ThemeControlContextProps>({});

const ThemeControlContextProvider: FC<ThemeControlContextProviderProps> = (props) => {
  const { children } = props;

  const prefersDarkMode = false && useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(() => {
    // This code should return a theme object to use in MUI ThemeProvider
    // We do not merge different options into single theme, but provide new theme object for each variant
    // Ex. if we need different theming for AB testing, we make logic check here and return correct theme object

    return prefersDarkMode ? defaultDarkTheme : defaultLightTheme;

  }, [defaultLightTheme, defaultLightTheme, prefersDarkMode]);
  
  return (
    <ThemeControlContext.Provider value={{}}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeControlContext.Provider>
  );
};

export default ThemeControlContextProvider;
