import { FC } from 'react';

import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import FieldChangeHandler from 'src/components/FieldChangeHandler/FieldChangeHandler';
import FieldSection from 'src/components/FieldSection';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';

interface MonthFieldProps {
  name: string;
}

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const MonthField: FC<MonthFieldProps> = (props) => {
  const { name } = props;

  const { t } = useTranslation();
  const { values, handleChange, errors, handleBlur, touched } = useFormikContext<{ [key: string]: any; }>();

  const error = getValueByPath<string>(errors, name);
  const isTouched = getValueByPath<boolean>(touched, name);
  const value = getValueByPath<string>(values, name) ?? '';

  return (
    <FieldSection>
      <Grid
        item
        xs={12}
      >
        <TextField
          select
          label={t('Month')}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          name={name}
          fullWidth
          error={!!error && isTouched}
        >
          {months.map((month, index) => (
            <MenuItem
              key={month}
              value={index}
            >
              {t(month)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {error && isTouched && (
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='caption'
            color="error"
          >
            {error}
          </Typography>
        </Grid>
      )}
      <FieldChangeHandler name={name} />
    </FieldSection>
  );
};


export default MonthField;
