import { FC } from 'react';

import FormStepperSubStepsContainer from 'src/components/Stepper/subSteps/FormStepperSubStepsContainer';
import BlancoSharedContactSection from 'src/forms/BlancoShared/sections/contact/BlancoSharedContactSection';
import BlancoSharedPNISection from 'src/forms/BlancoShared/sections/contact/BlancoSharedPNISection';
import BlancoEmployerNameSection from 'src/forms/BlancoShared/sections/income/BlancoEmployerNameSection';
import BlancoEmploymentTypeSection from 'src/forms/BlancoShared/sections/income/BlancoEmploymentTypeSection';

interface BlancoNorwayCoApplicantStepProps {
}

const BlancoNorwayCoApplicantStep: FC<BlancoNorwayCoApplicantStepProps> = () => {
  return (
    <FormStepperSubStepsContainer
      stepsInOrder={[
        <BlancoEmploymentTypeSection
          key='BlancoEmploymentTypeSection'
          coApplicant
        />,
        <BlancoEmployerNameSection
          key='BlancoEmployerNameSection'
          coApplicant
        />,
        <BlancoSharedContactSection
          key='BlancoSharedContactSection'
          coApplicant
        />,
        <BlancoSharedPNISection
          key='BlancoSharedPNISection'
          coApplicant
        />,
      ]}
    />
  );
};


export default BlancoNorwayCoApplicantStep;
