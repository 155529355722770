import { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { HousingTypeNO, HousingTypeSE } from 'src/api/zrm';
import StyledToggleButton from 'src/components/buttons/toggle/StyledToggleButton';
import StyledToggleButtonGroup from 'src/components/buttons/toggle/StyledToggleButtonGroup';
import Norway from 'src/components/countrySpecific/Norway';
import Sweden from 'src/components/countrySpecific/Sweden';
import FieldChangeHandler from 'src/components/FieldChangeHandler/FieldChangeHandler';
import FieldSection from 'src/components/FieldSection';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';


interface LivingSituationFieldProps {
  name: string;
  coApplicant?: boolean;
}

const HousingType = HousingTypeSE || HousingTypeNO;

const LivingSituationField: FC<LivingSituationFieldProps> = (props) => {
  const { name, coApplicant } = props;

  const { t } = useTranslation();
  const { values, setFieldValue, errors, setFieldTouched, touched } = useFormikContext<{ [key: string]: any; }>();

  const error = getValueByPath<string>(errors, name);
  const isTouched = getValueByPath<boolean>(touched, name);
  const value = getValueByPath<string>(values, name);

  return (
    <FieldSection
      description={!coApplicant ? 'Select the type of housing where you are currently registered.' : 'Select the type of housing where your co-applicant is currently registered.'}
    >
      <Grid
        item
        xs={12}
      >
        <StyledToggleButtonGroup
          id={name}
          value={value}
          exclusive
          onChange={(e, v) => {
            if (v !== null) {
              setFieldValue(name, v, true);
              // Need to touch it to show error message and onBlur is not triggered and not validate touched since that triggers a race condition with value update
              setFieldTouched(name, true, false);
            }
          }}
          className={(error && isTouched) ? 'error' : ''}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
              md={3}
            >
              <StyledToggleButton value={HousingType.RENTED}>
                {t('Rental')}
              </StyledToggleButton>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
            >
              <StyledToggleButton value={HousingType.OWN_HOUSE}>
                {t('Own house')}
              </StyledToggleButton>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
            >
              <StyledToggleButton value={HousingType.CONDOMINIUM}>
                {t('Condominium')}
              </StyledToggleButton>
            </Grid>
            <Sweden>
              <Grid
                item
                xs={6}
                md={3}
              >
                <StyledToggleButton value={HousingTypeSE.LIVE_IN}>
                  {t('Lodger')}
                </StyledToggleButton>
              </Grid>
            </Sweden>
            <Norway>
              <Grid
                item
                xs={6}
                md={3}
              >
                <StyledToggleButton value={HousingTypeNO.PARENTS}>
                  {t('Parents')}
                </StyledToggleButton>
              </Grid>
            </Norway>
          </Grid>
        </StyledToggleButtonGroup>
      </Grid>
      {error && (
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='caption'
            color="error"
          >
            {error}
          </Typography>
        </Grid>
      )}
      <FieldChangeHandler
        name={name}
        nextStepOnValueChange
      />
    </FieldSection>
  );
};


export default LivingSituationField;
