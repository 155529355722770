import { FC, useMemo } from 'react';

import { Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import FlexCenterBox from 'src/components/boxes/FlexCenterBox';
import { useSettingsContext } from 'src/contexts/SettingsContext';
import defaultInterestRates from 'src/defaults/calc/defaultInterestRates';
import calculateMonthlyPayment from 'src/utils/calc/calculateMonthlyPayment';
import formatCurrency from 'src/utils/format/formatCurrency';
import { getBuildDateTime } from 'src/utils/getBuildDateTime';
import { getCommitSha } from 'src/utils/getCommitSha';

interface MonthlyPaymentInfoProps {
}

const MonthlyPaymentInfo: FC<MonthlyPaymentInfoProps> = (props) => {
  const { } = props;

  const { t } = useTranslation();
  const { country } = useSettingsContext();
  const { values } = useFormikContext<{ [key: string]: any; }>();

  const monthlyPayment = useMemo(() => calculateMonthlyPayment(values.loanAmount, defaultInterestRates[country].nominal, values.repaymentTime), [values.loanAmount, values.repaymentTime]);

  return (
    <FlexCenterBox>
      <Typography title={getBuildDateTime() + ' - ' + getCommitSha()}>{t('Monthly payment')}: {' '}
        <Typography
          component={'span'}
          sx={{ fontWeight: 'bold' }}
        >
          {formatCurrency(monthlyPayment, country, 0)}
        </Typography>
      </Typography>
    </FlexCenterBox>
  );
};

export default MonthlyPaymentInfo;
