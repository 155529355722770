import { FC, useEffect } from 'react';

import { useFormikContext } from 'formik';

import { useFormSectionContext } from 'src/contexts/FormSectionContext';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';

interface FieldChangeHandlerProps {
  name: string;
  nextStepOnValueChange?: boolean;
}

// TODO: Test this component
const FieldChangeHandler: FC<FieldChangeHandlerProps> = (props) => {
  const { name, nextStepOnValueChange = false } = props;

  const { touched } = useFormikContext<{ [key: string]: any; }>();
  const { validateAndGoToNextStep } = useFormSectionContext();

  const isTouched = getValueByPath<boolean>(touched, name);

  useEffect(() => {
    if (nextStepOnValueChange && isTouched) {
      setTimeout(() => {
        validateAndGoToNextStep();
      }, 100);
    }
  }, [isTouched]);

  return (
    <></>
  );
};


export default FieldChangeHandler;
