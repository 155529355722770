import { FC, ReactNode } from 'react';

import { isNorway } from 'src/utils/country/isCountry';

const Norway: FC<{ children: ReactNode }> = (props) => {
  if (!isNorway()) {return <></>;}

  const { children } = props;

  return (
    <>
      {children}
    </>
  );
};

export default Norway;
