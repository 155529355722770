import { Country } from 'src/api/zrm';

const defaultInterestRates = {
  [Country.SE]: {
    nominal: 0.0797,
    apr: 0.0827,
  },
  [Country.NO]: {
    nominal: 0.1090,
    apr: 0.1146,
  },
};

export default defaultInterestRates;
