import { FC } from 'react';

import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import FieldSection from 'src/components/FieldSection';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';

import CurrencyField from '../base/CurrencyField';

interface LoanTotalAmountFieldProps {
  name: string;
}

const LoanTotalAmountField: FC<LoanTotalAmountFieldProps> = (props) => {
  const { name } = props;

  const { values, errors, touched } = useFormikContext<{ [key: string]: any; }>();

  const error = getValueByPath<string>(errors, name);
  const isTouched = getValueByPath<boolean>(touched, name);
  const value = getValueByPath<string>(values, name);

  return (
    <FieldSection
      description='What is the total amount of the loan?'
      spacing={0}
    >
      <Grid
        item
        xs={12}
        md={6}
      >
        <CurrencyField
          value={value}
          name={name}
          error={!!error && isTouched}
          fullWidth
        />
      </Grid>
      {error && isTouched && (
        <Grid
          item
          xs={12}
        >
          <Typography
            variant='caption'
            color="error"
          >
            {error}
          </Typography>
        </Grid>
      )}
    </FieldSection>
  );
};


export default LoanTotalAmountField;
