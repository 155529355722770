import { FC, useMemo } from 'react';

import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { showEmploymentRetirementDate } from 'src/utils/employmentType/employmentTypeRequiredDates';

import MonthField from '../base/MonthField';
import YearField from '../base/YearField';

interface EmploymentDateFieldProps {
  monthName: string;
  yearName: string;
  coApplicant?: boolean;
}

const EmploymentDateField: FC<EmploymentDateFieldProps> = (props) => {
  const { monthName, yearName, coApplicant } = props;
  const { values } = useFormikContext<{ [key: string]: any; }>();

  const { t } = useTranslation();

  const employmentType = useMemo(() => values.employmentType, [values.employmentType]);

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Typography>
          {showEmploymentRetirementDate(employmentType) ? (
            <>
              {t(!coApplicant ? 'What is your retirement start date?' : 'What is your co-applicant\'s retirement start date?')}
            </>
          ) : (
            <>
              {t(!coApplicant ? 'What is your employment start date?' : 'What is your co-applicant\'s employment start date?')}
            </>
          )}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        md={3}
      >
        <MonthField name={monthName} />
      </Grid>
      <Grid
        item
        xs={6}
        md={3}
      >
        <YearField name={yearName} />
      </Grid>
    </Grid>
  );
};


export default EmploymentDateField;
