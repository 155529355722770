import { createContext, FC, ReactNode, useContext, useRef } from 'react';

import DataLoader from 'dataloader';

import { BankAccount, Email, ModelsValidatorServicePayloadPhoneNumber, PNI, ValidatorServiceResponseModel } from 'src/api/zrm';

import { useApi } from './APIContext';


type ValidatorPayload = Email | ModelsValidatorServicePayloadPhoneNumber | PNI | BankAccount;

interface ValidatorContextProps {
  externalValidator: (payload: ValidatorPayload) => Promise<boolean>;
}

const ValidatorContext = createContext<ValidatorContextProps>({
  externalValidator: async () => false,
});

export const ValidatorContextProvider: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;

  const { api } = useApi();

  const dataLoaderRef = useRef<DataLoader<ValidatorPayload, ValidatorServiceResponseModel>>(null);
  const externalValidatorRef = useRef<(payload: ValidatorPayload) => Promise<boolean>>(() => Promise.resolve(false));

  dataLoaderRef.current = new DataLoader(
    (payloads) => Promise.all(
      payloads.map(async (payload: ValidatorPayload) => api.validator.validateSafelyValidatorSafePost(payload)
        .then(({ data }) => data)
        .catch((err) => dataLoaderRef.current.clear(payload) && Promise.reject(err))),
    ),
    {
      batch: false,
      cacheKeyFn: (payload) => JSON.stringify(payload),
    },
  );

  externalValidatorRef.current = async (payload: ValidatorPayload): Promise<boolean> => dataLoaderRef.current
    .load(payload)
    .then(({ valid }) => valid)
    .catch(() => false);

  return (
    <ValidatorContext.Provider
      value={{
        externalValidator: externalValidatorRef.current,
      }}
    >
      {children}
    </ValidatorContext.Provider>
  );
};

export const useValidatorContext = (): ValidatorContextProps => useContext(ValidatorContext);
