import { FC } from 'react';

import { Close, KeyboardBackspace } from '@mui/icons-material';
import { Box, IconButton, styled } from '@mui/material';

import ZensumLogo from 'src/components/logos/ZensumLogo';
import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';

const ParentBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: 4,
});

const LogoBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  paddingRight: '40px',
});

const StepperDialogHeader: FC = () => {

  const { closeDialog, currentStep, currentSubStep, goToPreviousStep } = useFormStepperDialogContext();

  const handleClick = () => {
    if (currentStep === 0 && currentSubStep === 0) { closeDialog({}); } else { goToPreviousStep(); }
  };

  return (
    <ParentBox>
      <IconButton onClick={handleClick}>
        {(currentStep !== 0 || currentSubStep !== 0) ? <KeyboardBackspace /> : <Close />}
      </IconButton>
      <LogoBox>
        <ZensumLogo />
      </LogoBox>
    </ParentBox>
  );
};


export default StepperDialogHeader;
