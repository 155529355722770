import { Constants } from '../Constants';

const BlancoSwedenConstants: Constants = {
  maxLoanAmount: 600_000,
  minLoanAmount: 20_000,
  maxLivingCost: 100_000,
  defaultAmount: 150_000,
  defaultRepaymentTime: 8,
  maxMonthlyIncome: 1_000_000,
  minMonthlyIncome: 10_000,
  maxYearlyIncome: 12_000_000,
  minYearlyIncome: 200_000,
};

export default BlancoSwedenConstants;