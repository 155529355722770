/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AML */
export interface AML {
  channel?: Channel | null;
  /** Comment */
  comment?: string | AMLComment | null;
  review_status: AMLReviewStatus;
  risk_level: AMLRiskLevel;
  /** Is Identity Checked */
  is_identity_checked?: boolean | null;
  /** Is Pep Or Sanctioned */
  is_pep_or_sanctioned?: boolean | null;
}

/** AMLBlancoPurpose */
export enum AMLBlancoPurpose {
  RefinanceLoan = "refinance_loan",
  NewLoan = "new_loan",
  RefinanceAndNewLoan = "refinance_and_new_loan",
}

/** AMLBlancoResponse */
export interface AMLBlancoResponse {
  channel?: Channel | null;
  /** Comment */
  comment?: string | AMLComment | null;
  review_status: AMLReviewStatus;
  risk_level: AMLRiskLevel;
  /** Is Identity Checked */
  is_identity_checked?: boolean | null;
  /** Is Pep Or Sanctioned */
  is_pep_or_sanctioned?: boolean | null;
  /** Purpose */
  purpose?: string | null;
  /** Purpose When New Loan */
  purpose_when_new_loan?: string | null;
}

/** AMLComment */
export interface AMLComment {
  /** User Id */
  user_id: string;
  /** Comment */
  comment: string;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** Comment Id */
  comment_id: string;
  /**
   * Action
   * @default "AML_COMMENT"
   */
  action?: string;
}

/** AMLInsertion */
export interface AMLInsertion {
  channel?: Channel | null;
  /** Comment */
  comment?: string | null;
}

/** AMLReviewPayload */
export interface AMLReviewPayload {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  review_status: AMLReviewStatus;
  /** Comment */
  comment: string;
}

/** AMLReviewStatus */
export enum AMLReviewStatus {
  Approved = "approved",
  Rejected = "rejected",
  NotReviewed = "not_reviewed",
}

/** AMLRiskLevel */
export enum AMLRiskLevel {
  Medium = "medium",
  High = "high",
  Unknown = "unknown",
}

/** AcceptOffer */
export interface AcceptOffer {
  product: Product;
  country: Country;
  /**
   * Offer Id
   * @format uuid
   */
  offer_id: string;
  /**
   * Synchronous
   * @default true
   */
  synchronous?: boolean;
}

/** AcceptOfferPayload */
export interface AcceptOfferPayload {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Offer Id */
  offer_id: string;
  /** Bank Account Number */
  bank_account_number?: string | null;
  /** Bank Clearing Number */
  bank_clearing_number?: string | null;
}

/** AcceptOfferPayloadV2 */
export interface AcceptOfferPayloadV2 {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /**
   * Offer Id
   * @format uuid
   */
  offer_id: string;
  /** Bank Account Number */
  bank_account_number?: string | null;
  /** Bank Clearing Number */
  bank_clearing_number?: string | null;
}

/** AcceptTermsResponse */
export interface AcceptTermsResponse {
  /**
   * Psp Iframe
   * The URL to the Payment Service Provider iframe
   */
  psp_iframe?: string | null;
  /**
   * Psp External Url
   * The URL to the Payment Service Provider external page
   */
  psp_external_url?: string | null;
}

/** AcceptTermsResponseCustomer */
export interface AcceptTermsResponseCustomer {
  /**
   * Psp Iframe
   * The URL to the Payment Service Provider iframe
   */
  psp_iframe?: string | null;
  /**
   * Psp External Url
   * The URL to the Payment Service Provider external page
   */
  psp_external_url?: string | null;
  currency: Currency;
  /**
   * Date Created
   * @format date-time
   */
  date_created: string;
  /** Signup Date */
  signup_date?: string | null;
  /** Signup Url */
  signup_url?: string | null;
  /** Insurance Id */
  insurance_id: string;
  /** Encrypted Insurance Id */
  encrypted_insurance_id?: string | null;
  insurance_type: InsuranceType;
  /** Insured Amount */
  insured_amount: number;
  /** Premium Amount */
  premium_amount: number;
  status: InsuranceStatus;
  substatus: InsuranceSubstatus;
  last_charge?: LastCharge | null;
  mandate?: MandateStatus | null;
  insurance_group: InsuranceGroup;
}

/** AcceptedLoans */
export interface AcceptedLoans {
  /** Amount */
  amount: number;
  /** Sum */
  sum: number;
  /** Accepted Per Meeting */
  accepted_per_meeting: number;
  /** Number Of Meetings */
  number_of_meetings: number;
  currency: Currency;
}

/** Account */
export interface Account {
  /** Account Number */
  account_number: string;
  /** Clearing Number */
  clearing_number?: string | null;
  /** Bank Id */
  bank_id?: number | null;
  /** Bank Name */
  bank_name?: string | null;
}

/** Action */
export enum Action {
  CREATED = "CREATED",
  UPDATE = "UPDATE",
  CUSTOMER_ADDED = "CUSTOMER_ADDED",
  FIRST_TIME_CUSTOMER = "FIRST_TIME_CUSTOMER",
  INTERNAL_COMMENT_ADDED = "INTERNAL_COMMENT_ADDED",
  ACTIVATED = "ACTIVATED",
  HANDLER_ASSIGNED = "HANDLER_ASSIGNED",
  FIRST_OFFER_RECEIVED = "FIRST_OFFER_RECEIVED",
  OFFER_RECEIVED = "OFFER_RECEIVED",
  CANCELED = "CANCELED",
  PROPERTY_ADDED = "PROPERTY_ADDED",
  DOCUMENT_ATTACHED = "DOCUMENT_ATTACHED",
  VERIFIED_BANK_ID = "VERIFIED_BANK_ID",
  AML_REVIEW = "AML_REVIEW",
  DEACTIVATED = "DEACTIVATED",
}

/** ActionPayload */
export interface ActionPayload {
  /** Notification Ids */
  notification_ids: string[];
  action: NotificationEvent;
}

/** AdditionalCostPerPerson */
export interface AdditionalCostPerPerson {
  /** Number Of People */
  number_of_people: number;
  /** Cost */
  cost: number;
}

/** Address */
export interface Address {
  /** Basicareaname */
  BasicAreaName?: string | null;
  /** Basicareanumber */
  BasicAreaNumber?: string | null;
  /** Concatenatedaddress */
  ConcatenatedAddress?: string | null;
  /** Fulladdress */
  FullAddress?: string | null;
  /** Municipality */
  Municipality?: string | null;
  /** Postcode */
  Postcode?: string | null;
  /** Postcodearea */
  PostcodeArea?: string | null;
  /** Rest */
  Rest?: string | null;
  /** Streetletter */
  StreetLetter?: string | null;
  /** Streetname */
  StreetName?: string | null;
  /** Streetnumber */
  StreetNumber?: string | null;
}

/** Affiliate */
export interface Affiliate {
  /** Name */
  name: string;
  /** Website */
  website?: string | null;
  /** Affiliate Parent */
  affiliate_parent?: string | null;
  /** Partner Url */
  partner_url?: string[] | null;
  country?: Country | null;
  /** Webhook Url */
  webhook_url?: string | null;
  /** Network */
  network?: boolean | null;
  publisher_data?: PublisherData | null;
  /** Affiliate Id */
  affiliate_id: string;
  /** Creator */
  creator: string;
  status: ModelsAffiliateAffiliateAffiliateStatus;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Deactivated At */
  deactivated_at?: string | null;
  /** Campaign */
  campaign?: ModelsAffiliateAffiliateCampaign[] | null;
}

/** AffiliateApiLeadSource */
export interface AffiliateApiLeadSource {
  /**
   * Type
   * @default "AFFILIATE_API"
   */
  type?: "AFFILIATE_API";
  /** External Id */
  external_id?: string | null;
  /** Submission Id */
  submission_id?: string | null;
  /** Affiliate Id */
  affiliate_id?: string | null;
}

/** AffiliateApplicantRole */
export enum AffiliateApplicantRole {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  CO_APPLICANT = "CO_APPLICANT",
  GUARANTOR = "GUARANTOR",
}

/** AffiliateApplyingWith */
export enum AffiliateApplyingWith {
  PARENT = "PARENT",
  SPOUSE = "SPOUSE",
  ALONE = "ALONE",
  COHABITANT = "COHABITANT",
}

/** AffiliateBlancoLoan */
export interface AffiliateBlancoLoan {
  /** Amount */
  amount?: number | null;
  loan_type?: AffiliateLoanType | null;
  /** Responsibility */
  responsibility?: AffiliateResponsibilitySE | AffiliateResponsibilityNO | null;
  /** Creditor Id */
  creditor_id?: number | null;
  /** Refinance */
  refinance?: boolean | null;
  /** Monthly Payment */
  monthly_payment?: number | null;
  /** Rate */
  rate?: number | null;
}

/** AffiliateBlancoLoanForMortgage */
export interface AffiliateBlancoLoanForMortgage {
  /** Amount */
  amount?: number | null;
  loan_type?: AffiliateLoanType | null;
  /** Responsibility */
  responsibility?: AffiliateResponsibilitySE | AffiliateResponsibilityNO | null;
  /** Creditor Id */
  creditor_id?: number | null;
  [key: string]: any;
}

/** AffiliateBlancoPurposeForNewLoan */
export enum AffiliateBlancoPurposeForNewLoan {
  CAR_BOAT_OR_MC = "CAR_BOAT_OR_MC",
  CONSUMPTION = "CONSUMPTION",
  PROPERTY_RENOVATION = "PROPERTY_RENOVATION",
  LOAN_FOR_SOMEBODY_ELSE = "LOAN_FOR_SOMEBODY_ELSE",
  INVESTMENT = "INVESTMENT",
  EDUCATION = "EDUCATION",
  HEALTH_CARE = "HEALTH_CARE",
  DEBT_COLLECTION = "DEBT_COLLECTION",
  INVESTMENT_ABROAD = "INVESTMENT_ABROAD",
  DIVORCE = "DIVORCE",
  BUY_PROPERTY = "BUY_PROPERTY",
}

/** AffiliateBuyingInfo */
export interface AffiliateBuyingInfo {
  /** Property Price */
  property_price?: number | null;
  /** Buying Date */
  buying_date?: string | null;
  /** New Production */
  new_production?: boolean | null;
}

/** AffiliateCallback */
export interface AffiliateCallback {
  status: ModelsAffiliatePartnerApplicationAffiliateStatus;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** External Id */
  external_id: string;
  /** Submission Id */
  submission_id: string;
  /**
   * Message
   * Optional message
   */
  message?: string | null;
  /** Success */
  success?: boolean | null;
}

/** AffiliateCar */
export interface AffiliateCar {
  /** Monthly Cost */
  monthly_cost?: number | null;
}

/** AffiliateCars */
export interface AffiliateCars {
  /** Owned */
  owned?: number | null;
  /** Leased */
  leased?: AffiliateCar[] | null;
}

/** AffiliateCreateBlancoApplication */
export interface AffiliateCreateBlancoApplication {
  /**
   * External Id
   * Can be any string. The given value be sent back as part of the webhook request
   */
  external_id: string;
  country: Country;
  /**
   * Book Meeting At
   * Time slot when to book meeting with customer. Has to be a available slot based on the response from the /bookings endpoint
   * @format date-time
   */
  book_meeting_at: string;
  /** Applicants */
  applicants: OptionalAffiliateApplicantBlanco[];
  /**
   * Current Loans
   * Current loan situation
   */
  current_loans: AffiliateBlancoLoan[];
  /** Children In The Household */
  children_in_the_household: number;
  /** Children In The Household Full Time */
  children_in_the_household_full_time: number;
  /**
   * Desired Payback Time
   * The time customer would like to pay back loan (in months)
   */
  desired_payback_time?: number | null;
  /**
   * Desired Amount
   * Total desired amount customer wants to borrow. This is refinance and new loan amounts
   */
  desired_amount: number;
  /** Used when customer wants to borrow money for purpose different than refinancing existing loans */
  new_loan?: AffiliateNewLoan | null;
  [key: string]: any;
}

/** AffiliateCreditor */
export interface AffiliateCreditor {
  /** Name */
  name: string;
  /** Creditor Id */
  creditor_id: number;
}

/** AffiliateData */
export interface AffiliateData {
  /** Name */
  name: string;
  /** Website */
  website?: string | null;
  /** Affiliate Parent */
  affiliate_parent?: string | null;
  /** Partner Url */
  partner_url?: string[] | null;
  country?: Country | null;
  /** Webhook Url */
  webhook_url?: string | null;
  /** Network */
  network?: boolean | null;
  publisher_data?: PublisherData | null;
}

/** AffiliateEmployer */
export interface AffiliateEmployer {
  /**
   * Name
   * Name of the employer.
   */
  name: string;
  /** Type */
  type: "FULL" | "TRIAL" | "HOURLY" | "PROJECT" | "SELF_EMPLOYED" | "COMPANY_OWNER";
  /**
   * Start Date
   * @format date
   */
  start_date: string;
  /**
   * End Date
   * Used in cases when the type is PROJECT
   */
  end_date?: string | null;
  /**
   * Phone
   * Employer phone number
   */
  phone?: string | null;
  /** Organization Number */
  organization_number?: string | null;
  /** Profession */
  profession?: string | null;
}

/** AffiliateHousingType */
export enum AffiliateHousingType {
  RENTED = "RENTED",
  OWN_APARTMENT = "OWN_APARTMENT",
  OWN_HOUSE = "OWN_HOUSE",
  LIVE_IN = "LIVE_IN",
}

/** AffiliateIncome */
export interface AffiliateIncome {
  /**
   * Amount
   * @exclusiveMin 0
   */
  amount: number;
  type: AffiliateIncomeType;
  /** Name */
  name: string;
}

/** AffiliateIncomeType */
export enum AffiliateIncomeType {
  RENT = "RENT",
  OTHER = "OTHER",
  EMPLOYMENT = "EMPLOYMENT",
  PARTNER = "PARTNER",
}

/** AffiliateLeadCreationPayload */
export interface AffiliateLeadCreationPayload {
  desired_mortgage_loan?: AffiliateNewMortgageLoan | null;
  current_mortgage_loan?: AffiliateRefinanceInfo | null;
  buying_info?: AffiliateBuyingInfo | null;
  /** Other Loans */
  other_loans?: AffiliateBlancoLoanForMortgage[] | null;
  /** Other Properties To Keep */
  other_properties_to_keep?: AffiliateOtherProperty[] | null;
  co_applicant_relationship_to_main_applicant?: AffiliateMortgageRelationshipStatus | null;
  cars?: AffiliateCars | null;
  /** External Id */
  external_id: string;
  /** Applicants */
  applicants: OptionalAffiliateApplicant[];
  property?: AffiliateProperty | null;
  /** Children In The Household */
  children_in_the_household?: number | null;
  /** Comment */
  comment?: string | null;
  country: Country;
  /**
   * Meeting Start
   * @format date-time
   */
  meeting_start: string;
}

/** AffiliateLoanType */
export enum AffiliateLoanType {
  CREDIT_CARD = "CREDIT_CARD",
  UNSECURED_LOAN = "UNSECURED_LOAN",
  CAR_LOAN = "CAR_LOAN",
  CHECK_CREDIT = "CHECK_CREDIT",
  OTHER = "OTHER",
  STUDENT_LOAN = "STUDENT_LOAN",
  INSTALLMENT = "INSTALLMENT",
  TOP_MORTGAGE_LOAN = "TOP_MORTGAGE_LOAN",
  MEMBER_LOAN = "MEMBER_LOAN",
  MORTGAGE = "MORTGAGE",
}

/** AffiliateMaritalStatus */
export enum AffiliateMaritalStatus {
  UNMARRIED = "UNMARRIED",
  MARRIED = "MARRIED",
  PARTNER = "PARTNER",
}

/** AffiliateMortgageLoanType */
export enum AffiliateMortgageLoanType {
  REFINANCE = "REFINANCE",
  PRE_APPROVAL = "PRE_APPROVAL",
  BUY = "BUY",
}

/** AffiliateMortgageRelationshipStatus */
export enum AffiliateMortgageRelationshipStatus {
  SPOUSE = "SPOUSE",
  CHILD = "CHILD",
  PARENT = "PARENT",
  PARTNER = "PARTNER",
}

/** AffiliateNetworkCallbackEvent */
export interface AffiliateNetworkCallbackEvent {
  /** Affiliate Network Id */
  affiliate_network_id: string;
  callback_trigger: PersonStatus;
  /** Callback Url */
  callback_url: string;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  status: AffiliateNetworkCallbackStatus;
  /** Publisher Id */
  publisher_id: string;
  /** Application Origin */
  application_origin?: string | null;
  product?: Product | null;
  /** Zid */
  zid?: string | null;
  /** Ignored Reason */
  ignored_reason?: string | null;
}

/** AffiliateNetworkCallbackStatus */
export enum AffiliateNetworkCallbackStatus {
  SENT = "SENT",
  FAILED = "FAILED",
  PENDING = "PENDING",
  DUPLICATE = "DUPLICATE",
  NOT_SENT = "NOT_SENT",
  IGNORED = "IGNORED",
}

/** AffiliateNewLoan */
export interface AffiliateNewLoan {
  /** Desired Amount */
  desired_amount: number;
  loan_purpose: AffiliateBlancoPurposeForNewLoan;
  [key: string]: any;
}

/** AffiliateNewMortgageLoan */
export interface AffiliateNewMortgageLoan {
  /** Mortgage Loan Amount */
  mortgage_loan_amount?: number | null;
  /** Down Payment */
  down_payment?: number | null;
  /** Down Payment Loan Amount */
  down_payment_loan_amount?: number | null;
  /** Bridge Loan Amount */
  bridge_loan_amount?: number | null;
  /** Disbursement Date */
  disbursement_date?: string | null;
  applying_with?: AffiliateApplyingWith | null;
  loan_purpose?: AffiliateMortgageLoanType | null;
  buying_situation?: AffiliateSituation | null;
  /** Private Loan Amount */
  private_loan_amount?: number | null;
}

/** AffiliateNotEmployed */
export interface AffiliateNotEmployed {
  /** Type */
  type: "RETIRED" | "EARLY_RETIRED" | "NO_WORK";
  /**
   * Start Date
   * @format date
   */
  start_date: string;
}

/** AffiliateOptionsResponse */
export interface AffiliateOptionsResponse {
  /**
   * Responsibility
   * List of possible loan responsibilities
   */
  responsibility: EnumDescription[];
  /**
   * Marital Status
   * List of possible marital statuses
   */
  marital_status: EnumDescription[];
  /**
   * Income Type
   * List of possible income types
   */
  income_type?: EnumDescription[] | null;
  /**
   * Employer Type
   * List of possible employer types
   */
  employer_type: EnumDescription[];
  /**
   * Housing Type
   * List of possible housing types
   */
  housing_type: EnumDescription[];
  /**
   * Loan Type
   * List of possible loan types
   */
  loan_type: EnumDescription[];
  /**
   * Property Type
   * List of possible property types
   */
  property_type: EnumDescription[];
  /**
   * Co Applicant Relationship To Main Applicant
   * List of possible co-applicant relationships
   */
  co_applicant_relationship_to_main_applicant: EnumDescription[];
  /**
   * Loan Purpose
   * List of possible loan purposes
   */
  loan_purpose: EnumDescription[];
  /**
   * Applying With
   * List of possible applying with types
   */
  applying_with: EnumDescription[];
  /**
   * Situation
   * List of possible situation types
   */
  situation: EnumDescription[];
  /**
   * Rate Type
   * List of possible rate types
   */
  rate_type: EnumDescription[];
  /**
   * Currently Living In
   * List of possible current housing types
   */
  currently_living_in: EnumDescription[];
  /** Creditors */
  creditors: AffiliateCreditor[];
}

/** AffiliateOtherProperty */
export interface AffiliateOtherProperty {
  /**
   * Monthly Cost
   * @default 0
   */
  monthly_cost?: number | null;
  /** Mortgage Amount */
  mortgage_amount?: number | null;
  property_type: any;
  /** Estimated Value */
  estimated_value?: number | null;
  /** Property Ownership Share */
  property_ownership_share?: number | null;
  /** Loan Ownership Share */
  loan_ownership_share?: number | null;
}

/** AffiliateProperty */
export interface AffiliateProperty {
  property_type: AffiliatePropertyType;
  /** Estimated Value */
  estimated_value?: number | null;
  /** Housing Monthly Cost */
  housing_monthly_cost?: number | null;
  /** Square Meters */
  square_meters?: number | null;
  address?: AffiliatePropertyAddress | null;
}

/** AffiliatePropertyAddress */
export interface AffiliatePropertyAddress {
  /**
   * Street Address
   * Street address of property
   */
  street_address?: string | null;
  /**
   * City
   * City of the property
   */
  city?: string | null;
  /**
   * Postal Code
   * Postal code of the property
   */
  postal_code?: string | null;
  /**
   * Municipality Code
   * Municipality of the property (Sweden's municipalities)
   */
  municipality_code?: string | null;
}

/** AffiliatePropertyType */
export enum AffiliatePropertyType {
  CONDOMINIUM = "CONDOMINIUM",
  HOUSE = "HOUSE",
  VACATION_HOME = "VACATION_HOME",
}

/** AffiliateRatePart */
export interface AffiliateRatePart {
  rate_type: any;
  /** Amount */
  amount?: number | null;
  /** Rate */
  rate?: number | null;
  /** Monthly Payment */
  monthly_payment?: number | null;
  /** Until */
  until?: string | null;
  /** Loan Number */
  loan_number?: string | null;
  /** Condition Rollover Date */
  condition_rollover_date?: string | null;
  /** Monthly Amortisation */
  monthly_amortisation?: number | null;
}

/** AffiliateRateType */
export enum AffiliateRateType {
  FIXED = "FIXED",
  FLEXIBLE = "FLEXIBLE",
  MIXED = "MIXED",
}

/** AffiliateRefinanceInfo */
export interface AffiliateRefinanceInfo {
  /** Current Creditor Id */
  current_creditor_id?: number | null;
  /** Amount */
  amount?: number | null;
  /** Rate */
  rate?: number | null;
  /** Monthly Payment */
  monthly_payment?: number | null;
  /** Rate Parts */
  rate_parts?: AffiliateRatePart[] | null;
  rate_type?: null;
  /** Ownership Share Of Loan */
  ownership_share_of_loan?: number | null;
}

/** AffiliateResponse */
export interface AffiliateResponse {
  /** Submission Id */
  submission_id: string;
}

/** AffiliateResponsibilityNO */
export enum AffiliateResponsibilityNO {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  CO_APPLICANT = "CO_APPLICANT",
  SHARED = "SHARED",
}

/** AffiliateResponsibilitySE */
export enum AffiliateResponsibilitySE {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  CO_APPLICANT = "CO_APPLICANT",
  SHARED = "SHARED",
  GUARANTOR = "GUARANTOR",
}

/** AffiliateSituation */
export enum AffiliateSituation {
  SOLD = "SOLD",
  WILL_SELL = "WILL_SELL",
  ONLY_BUY = "ONLY_BUY",
}

/** AllDisbursedLoans */
export interface AllDisbursedLoans {
  /** Amount */
  amount: number;
  /** Sum */
  sum: number;
  /** Average Sum */
  average_sum: number;
  currency: Currency;
}

/** AllInsurances */
export interface AllInsurances {
  /** Seller Id */
  seller_id?: string[] | null;
  /** Total Premium Amount */
  total_premium_amount: number;
  /** Total Count */
  total_count: number;
  /** Total Insured Amount */
  total_insured_amount: number;
  /** Cancelled Insurances Within Period */
  cancelled_insurances_within_period: number;
  currency?: Currency | null;
}

/** AllOptionalZoordinates */
export interface AllOptionalZoordinates {
  Product?: ZProduct | null;
  Country?: ZCountry | null;
  Office?: ZOffice | null;
  EmploymentType?: ZEmploymentType | null;
  Department?: ZDepartment | null;
}

/** AmortizationPlan */
export enum AmortizationPlan {
  FIXED_RATE = "FIXED_RATE",
  STRAIGHT_LINE = "STRAIGHT_LINE",
}

/** AmortizationRule */
export enum AmortizationRule {
  FI2018 = "FI2018",
  FI2016 = "FI2016",
  SKB70 = "SKB70",
  OTHER = "OTHER",
}

/** Apartment */
export interface Apartment {
  /** Apartment Number */
  apartment_number: string;
}

/** ApiLeadSource */
export interface ApiLeadSource {
  /**
   * Type
   * @default "ZRM_API"
   */
  type?: "ZRM_API";
}

/**
 * AppBase
 * Application model returned by apps-mgr
 */
export interface AppBase {
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  /** Applicants */
  applicants?: BareApplicantWithRole[] | null;
  /** Property Id */
  property_id?: string | null;
  discount?: Discount | null;
  /** Loans */
  loans?: Loan[] | null;
  /** Other Mortgages */
  other_mortgages?: MortgageLoan[] | null;
  status?: AppsmgrModelsStatusStatus | null;
  substatus?: Substatus | null;
  bank_status?: BankStatus | null;
  /** Children In The Household */
  children_in_the_household?: number | null;
  /** Children In The Household Fulltime */
  children_in_the_household_fulltime?: number | null;
  /** Other Properties To Keep */
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  brokerage?: Brokerage | null;
  product: Product;
  country: Country;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Offers */
  offers?: Offer[] | null;
  /** Date Created */
  date_created?: string | null;
  /** Date Updated */
  date_updated?: string | null;
  [key: string]: any;
}

/** AppState */
export enum AppState {
  DRAFT = "DRAFT",
  COMPLETE = "COMPLETE",
  OFFERED = "OFFERED",
  ACCEPTED = "ACCEPTED",
  DISBURSED = "DISBURSED",
}

/** AppStateLabel */
export enum AppStateLabel {
  LATE_DENIAL = "LATE_DENIAL",
}

/** ApplicantDocument */
export interface ApplicantDocument {
  /** Document Id */
  document_id: string;
  /** Document Type */
  document_type: string | DocumentType;
}

/** ApplicantNO */
export interface ApplicantNO {
  /**
   * Country
   * @default "NO"
   */
  country?: "NO";
  /** Pni */
  pni: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /** Email */
  email?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Additional Phone Numbers */
  additional_phone_numbers?: string[] | null;
  /** Monthly Property Cost */
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;
  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  /** Post Code */
  post_code?: string | null;
  /** City */
  city?: string | null;
  /** Incomes */
  incomes?: Income[] | null;
  /** Net Monthly Income */
  net_monthly_income?: number | null;
  account?: Account | null;
  /** Street Address */
  street_address?: string | null;
  /**
   * Paying Child Support
   * Number of children to pay child support for
   */
  paying_child_support?: number | null;
  /**
   * Receiving Child Support
   * Number of children to receive child support for
   */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  /** Consents Popup Dismissed Timestamp */
  consents_popup_dismissed_timestamp?: string | null;
  /** Partner Pni */
  partner_pni?: string | null;
  /** Partner Name */
  partner_name?: string | null;
  /** Municipality Code */
  municipality_code?: string | null;
  /** Municipality */
  municipality?: string | null;
  /** Is Pep */
  is_pep?: boolean | null;
  /** Age */
  age?: number | null;
  /** Other Phones */
  other_phones?: AppsmgrModelsPhonePhoneNumber[] | null;
  /** Sverker Id */
  sverker_id?: number | null;
  /** Children */
  children?: number | null;
  /** Citizen */
  citizen?: number | null;
  education?: EducationType | null;
  /** Monthly Partner Income */
  monthly_partner_income?: number | null;
  /** Immigrated At */
  immigrated_at?: string | null;
  /** Fullname */
  fullname?: string | null;
  /** Documents */
  documents?: ApplicantDocument[] | null;
  /** Verified Credits */
  verified_credits?: boolean | null;
  /** Housing Since */
  housing_since?: string | null;
  /** Deceased */
  deceased?: boolean | null;
  /** Emigrated */
  emigrated?: boolean | null;
  /** Is Sanction */
  is_sanction?: boolean | null;
  /** Is Rca */
  is_rca?: boolean | null;
  /** Date Created */
  date_created?: string | null;
  /** Date Updated */
  date_updated?: string | null;
  /** Last Credit Check */
  last_credit_check?: string | null;
  /** Last Externally Verified Credits */
  last_externally_verified_credits?: string | null;
  /** Last Property Valuation */
  last_property_valuation?: string | null;
  /** Pep Quality */
  pep_quality?: boolean | null;
  /**
   * Cluster
   * A cluster is a group of customers displayed with a color
   */
  cluster?: string | null;
  /**
   * Is Blocked
   * Indicates if the customer is blocked
   * @default false
   */
  is_blocked?: boolean;
  housing_type?: HousingTypeNO | null;
  employer?: EmployerNO | null;
  /** Ev Estate */
  ev_estate?: EvEstate[] | null;
  /** Ev Estates */
  ev_estates?: EvProperty[] | null;
  /** Citizenship Iso Codes */
  citizenship_iso_codes?: string[] | null;
  /** Externally Verified Credits */
  externally_verified_credits?: ExternallyVerifiedCredit[] | null;
  /**
   * Customer Id
   * @format uuid
   */
  customer_id: string;
  credit_check?: CreditCheckNO | null;
  external_block_check?: ExternalBlockCheckNO | null;
  block_list?: BlockList | null;
  gender?: Gender | null;
  /**
   * Gr Consent
   * @default false
   */
  gr_consent?: boolean;
  /** Last Externally Verified Credits Consent */
  last_externally_verified_credits_consent?: string | null;
}

/** ApplicantRole */
export enum ApplicantRole {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  CO_APPLICANT = "CO_APPLICANT",
  GUARANTOR = "GUARANTOR",
}

/** ApplicantSE */
export interface ApplicantSE {
  /**
   * Country
   * @default "SE"
   */
  country?: "SE";
  /** Pni */
  pni: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /** Email */
  email?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Additional Phone Numbers */
  additional_phone_numbers?: string[] | null;
  /** Monthly Property Cost */
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;
  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  /** Post Code */
  post_code?: string | null;
  /** City */
  city?: string | null;
  /** Incomes */
  incomes?: Income[] | null;
  /** Net Monthly Income */
  net_monthly_income?: number | null;
  account?: Account | null;
  /** Street Address */
  street_address?: string | null;
  /**
   * Paying Child Support
   * Number of children to pay child support for
   */
  paying_child_support?: number | null;
  /**
   * Receiving Child Support
   * Number of children to receive child support for
   */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  /** Consents Popup Dismissed Timestamp */
  consents_popup_dismissed_timestamp?: string | null;
  /** Partner Pni */
  partner_pni?: string | null;
  /** Partner Name */
  partner_name?: string | null;
  /** Municipality Code */
  municipality_code?: string | null;
  /** Municipality */
  municipality?: string | null;
  /** Is Pep */
  is_pep?: boolean | null;
  /** Age */
  age?: number | null;
  /** Other Phones */
  other_phones?: AppsmgrModelsPhonePhoneNumber[] | null;
  /** Sverker Id */
  sverker_id?: number | null;
  /** Children */
  children?: number | null;
  /** Citizen */
  citizen?: number | null;
  education?: EducationType | null;
  /** Monthly Partner Income */
  monthly_partner_income?: number | null;
  /** Immigrated At */
  immigrated_at?: string | null;
  /** Fullname */
  fullname?: string | null;
  /** Documents */
  documents?: ApplicantDocument[] | null;
  /** Verified Credits */
  verified_credits?: boolean | null;
  /** Housing Since */
  housing_since?: string | null;
  /** Deceased */
  deceased?: boolean | null;
  /** Emigrated */
  emigrated?: boolean | null;
  /** Is Sanction */
  is_sanction?: boolean | null;
  /** Is Rca */
  is_rca?: boolean | null;
  /** Date Created */
  date_created?: string | null;
  /** Date Updated */
  date_updated?: string | null;
  /** Last Credit Check */
  last_credit_check?: string | null;
  /** Last Externally Verified Credits */
  last_externally_verified_credits?: string | null;
  /** Last Property Valuation */
  last_property_valuation?: string | null;
  /** Pep Quality */
  pep_quality?: any;
  /**
   * Cluster
   * A cluster is a group of customers displayed with a color
   */
  cluster?: string | null;
  /**
   * Is Blocked
   * Indicates if the customer is blocked
   * @default false
   */
  is_blocked?: boolean;
  housing_type?: HousingTypeSE | null;
  employer?: EmployerSE | null;
  /** Census Registration Year */
  census_registration_year?: string | null;
  /** Credit Regulation Information */
  credit_regulation_information?: CreditRegulationInformation[] | null;
  /** Credit Regulations */
  credit_regulations?: CreditRegulation[] | null;
  /** Case Collection */
  case_collection?: CaseCollectionItem[] | null;
  /** Current Section Of Case */
  current_section_of_case?: CurrentSectionOfCaseItem[] | null;
  /** Current Frame */
  current_frame?: CurrentFrameItem[] | null;
  /** Html */
  html?: string | null;
  /** Last Queries */
  last_queries?: LastQuery[] | null;
  /** Notes */
  notes?: Note[] | null;
  /** Tax */
  tax?: TaxItem[] | null;
  /** Tax Thousands */
  tax_thousands?: TaxThousand[] | null;
  /** Fraudster */
  fraudster?: boolean | null;
  /** Investigation Barrier */
  investigation_barrier?: boolean | null;
  /** Uc Interall */
  uc_interall?: string | null;
  /** Date Of Birth */
  date_of_birth?: string | null;
  /** Prenup Text */
  prenup_text?: string | null;
  /** Uc Internal 2 */
  uc_internal_2?: null;
  /** Parish */
  parish?: string | null;
  /** Parish Code */
  parish_code?: string | null;
  /** Blocked */
  blocked?: boolean | null;
  /** Protected Address */
  protected_address?: boolean | null;
  /** Protected Identity */
  protected_identity?: boolean | null;
  /** Marital Status Date */
  marital_status_date?: string | null;
  /** Minor */
  minor?: string | null;
  /** Lost Id Documents */
  lost_id_documents?: boolean | null;
  /** County */
  county?: string | null;
  /** County Code */
  county_code?: string | null;
  /**
   * Customer Id
   * @format uuid
   */
  customer_id: string;
  credit_check?: CreditCheckSE | null;
  block_list?: BlockList | null;
  gender?: Gender | null;
}

/**
 * Application
 * Application model in ZRM
 */
export interface Application {
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  /** Applicants */
  applicants?: BareApplicantWithRole[] | null;
  /** Property Id */
  property_id?: string | null;
  discount?: Discount | null;
  /** Loans */
  loans?: Loan[] | null;
  /** Other Mortgages */
  other_mortgages?: MortgageLoan[] | null;
  status?: AppsmgrModelsStatusStatus | null;
  substatus?: Substatus | null;
  bank_status?: BankStatus | null;
  /** Children In The Household */
  children_in_the_household?: number | null;
  /** Children In The Household Fulltime */
  children_in_the_household_fulltime?: number | null;
  /** Other Properties To Keep */
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  brokerage?: Brokerage | null;
  product: Product;
  country: Country;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Offers */
  offers?: Offer[] | null;
  /** Date Created */
  date_created?: string | null;
  /** Date Updated */
  date_updated?: string | null;
  meta?: ApplicationMapping | null;
}

/** ApplicationAssigned */
export interface ApplicationAssigned {
  /** Type */
  type: "APPLICATION_ASSIGNED";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  status: NotificationEvent;
  data: ApplicationAssignedData;
  /** Notification Id */
  notification_id: string;
  /** User Id */
  user_id: string | null;
}

/** ApplicationAssignedData */
export interface ApplicationAssignedData {
  /** Application Id */
  application_id: string;
}

/** ApplicationCreatedResponse */
export interface ApplicationCreatedResponse {
  product: Product;
  country: Country;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
}

/** ApplicationCreatedResponseExt */
export interface ApplicationCreatedResponseExt {
  product: Product;
  country: Country;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
}

/** ApplicationDisbursed */
export interface ApplicationDisbursed {
  /** Type */
  type: "APPLICATION_DISBURSED";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  status: NotificationEvent;
  data: ApplicationDisbursedData;
  /** Notification Id */
  notification_id: string;
  /** User Id */
  user_id: string | null;
}

/** ApplicationDisbursedData */
export interface ApplicationDisbursedData {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
}

/** ApplicationMapping */
export interface ApplicationMapping {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Handler Id */
  handler_id?: string | null;
  /** Creator Id */
  creator_id?: string | null;
  /** New App Checklist */
  new_app_checklist?: string[] | null;
  /** New App Options */
  new_app_options?: Option[] | null;
  /** Events */
  events?: ModelsApplicationMappingApplicationMappingEvent[] | null;
  /** Documents */
  documents?: DocumentAssignation[] | null;
  source?: ApplicationSource | null;
  /** Lead Id */
  lead_id?: string | null;
  aml: AML;
  /** Bank Id Verifications */
  bank_id_verifications?: BankIDSigned[] | null;
  /** Internal Comments */
  internal_comments: (ModelsApplicationMappingCommentsInternalComment | BankIDSigned)[];
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  product: Product;
  country: Country;
}

/** ApplicationMappingWithNamesInComments */
export interface ApplicationMappingWithNamesInComments {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Handler Id */
  handler_id?: string | null;
  /** Creator Id */
  creator_id?: string | null;
  /** New App Checklist */
  new_app_checklist?: string[] | null;
  /** New App Options */
  new_app_options?: Option[] | null;
  /** Events */
  events?: ModelsApplicationMappingApplicationMappingEvent[] | null;
  /** Documents */
  documents?: DocumentAssignation[] | null;
  source?: ApplicationSource | null;
  /** Lead Id */
  lead_id?: string | null;
  aml: AML;
  /** Bank Id Verifications */
  bank_id_verifications?: BankIDSigned[] | null;
  /** Internal Comments */
  internal_comments: (InternalCommentWithName | BankIDSignedWithNames)[];
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  product: Product;
  country: Country;
}

/** ApplicationPayload */
export interface ApplicationPayload {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
}

/** ApplicationQuery */
export interface ApplicationQuery {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
}

/** ApplicationReview */
export enum ApplicationReview {
  TryAgainLater = "tryAgainLater",
  EitherOr = "eitherOr",
  LikingZensum = "likingZensum",
  DislikesZensum = "dislikesZensum",
  GoodCase = "goodCase",
  OkayCase = "okayCase",
  BadCase = "badCase",
  CreditNotes = "creditNotes",
  MostHappy = "mostHappy",
  Happy = "happy",
  KindaSortaHappy = "kindaSortaHappy",
  Other = "other",
}

/** ApplicationSource */
export enum ApplicationSource {
  BLANCO = "BLANCO",
  KH_BLANCO = "KH_BLANCO",
  LOG_BLANCO = "LOG_BLANCO",
  HL_BLANCO = "HL_BLANCO",
  WINBACK = "WINBACK",
  DIRECT = "DIRECT",
  RECOMMENDATION = "RECOMMENDATION",
  AFFILIATE_PARTNER = "AFFILIATE_PARTNER",
  TF_AGENCY = "TF_AGENCY",
  AFFILIATE_API = "AFFILIATE_API",
  ONLINE_LEAD = "ONLINE_LEAD",
  OTHER = "OTHER",
  LIKVIDUM = "LIKVIDUM",
}

/** ApplicationWithNamesInComments */
export interface ApplicationWithNamesInComments {
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  /** Applicants */
  applicants?: BareApplicantWithRole[] | null;
  /** Property Id */
  property_id?: string | null;
  discount?: Discount | null;
  /** Loans */
  loans?: Loan[] | null;
  /** Other Mortgages */
  other_mortgages?: MortgageLoan[] | null;
  status?: AppsmgrModelsStatusStatus | null;
  substatus?: Substatus | null;
  bank_status?: BankStatus | null;
  /** Children In The Household */
  children_in_the_household?: number | null;
  /** Children In The Household Fulltime */
  children_in_the_household_fulltime?: number | null;
  /** Other Properties To Keep */
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  brokerage?: Brokerage | null;
  product: Product;
  country: Country;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Offers */
  offers?: Offer[] | null;
  /** Date Created */
  date_created?: string | null;
  /** Date Updated */
  date_updated?: string | null;
  meta: ApplicationMappingWithNamesInComments;
}

/** ApplyingWith */
export enum ApplyingWith {
  PARENT = "PARENT",
  SPOUSE = "SPOUSE",
  ALONE = "ALONE",
  COHABITANT = "COHABITANT",
}

/** AppsSearchResult */
export interface AppsSearchResult {
  /** Search Types */
  search_types: Record<string, SearchType[]>;
  /** Apps */
  apps: AppBase[];
}

/** AssignApplicationPayload */
export interface AssignApplicationPayload {
  /** Handler Id */
  handler_id: string;
}

/** Assignee */
export interface Assignee {
  /** Handler Id */
  handler_id?: string | null;
  /** Name */
  name?: string | null;
}

/** Available */
export interface Available {
  /** Available */
  available: ModelsDialMaker2CampaignAggregateCampaign[];
  /** Joined Campaigns */
  joined_campaigns: JoinedCampaignExt[];
}

/** AvailableBookings */
export interface AvailableBookings {
  /** Available Slots */
  available_slots: Slots[];
}

/** BalconyType */
export enum BalconyType {
  BALCONY = "BALCONY",
  PATIO = "PATIO",
  NONE = "NONE",
}

/** Bank */
export interface Bank {
  /** Bank Id */
  bank_id: number;
  country: Country;
  /** Name */
  name: string;
}

/** BankAPIDrivenKALP */
export interface BankAPIDrivenKALP {
  /** Bank Id */
  bank_id: number;
  country: Country;
  /** Name */
  name: string;
  /** Api Driven */
  api_driven: "true";
}

/** BankAccount */
export interface BankAccount {
  /** Clearing Number */
  clearing_number?: string | null;
  /** Account Number */
  account_number: string;
}

/** BankApplicant */
export interface BankApplicant {
  /** Customer */
  customer:
    | ({
        country: "NO";
      } & LimitedApplicantNO)
    | ({
        country: "SE";
      } & LimitedApplicantSE);
  role: ApplicantRole;
}

/** BankBaseApp */
export interface BankBaseApp {
  product: Product;
  country: Country;
  /** Applicants */
  applicants: BankApplicant[];
  /** Application Id */
  application_id: string;
  /**
   * Date Created
   * @format date-time
   */
  date_created: string;
  /**
   * Date Updated
   * @format date-time
   */
  date_updated: string;
  status: AppsmgrModelsStatusStatus;
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  /** Property */
  property?: Condominium | House | VacationHome | null;
  /** Loans */
  loans?: Loan[] | null;
  /** Other Mortgages */
  other_mortgages?: MortgageLoan[] | null;
  /** Children In The Household */
  children_in_the_household?: number | null;
  /** Children In The Household Fulltime */
  children_in_the_household_fulltime?: number | null;
  /** Other Properties To Keep */
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  bank_status: BankStatus;
}

/** BankComment */
export interface BankComment {
  /** Comment */
  comment: string;
  /** Application Id */
  application_id: string;
  /** Bank Id */
  bank_id: number;
}

/** BankCommunication */
export interface BankCommunication {
  /** Events */
  events: (
    | BankCommunicationEventOffer
    | BankCommunicationEvent
    | BankCommunicationEventDocumentInfo
    | BankCommunicationEventCommentAdded
    | BankCommunicationEventBankMessage
    | BankCommunicationEventInvalidInvite
    | BankCommunicationEventDocumentSent
  )[];
  /** Bank Id */
  bank_id: number;
  /** Bank Name */
  bank_name?: string | null;
  /** Actions */
  actions: IntegrationAction[];
  /** Encoded App Id */
  encoded_app_id: string;
  /** Last External Id */
  last_external_id?: string | null;
}

/** BankCommunicationData */
export type BankCommunicationData = Record<string, any>;

/** BankCommunicationDataCommentAdded */
export interface BankCommunicationDataCommentAdded {
  /** Comment */
  comment: string;
  /** User Name */
  user_name?: string | null;
  /** User Name Shown To Bank */
  user_name_shown_to_bank?: string | null;
}

/** BankCommunicationDataDocumentInfo */
export interface BankCommunicationDataDocumentInfo {
  /** Documents */
  documents: DocRequest[];
}

/** BankCommunicationDataDocumentSent */
export interface BankCommunicationDataDocumentSent {
  /** Document Id */
  document_id: string;
  /** Customer Id */
  customer_id: string;
}

/** BankCommunicationDataInvalidInvite */
export interface BankCommunicationDataInvalidInvite {
  /** Messages */
  messages: string[];
}

/** BankCommunicationDataOffer */
export interface BankCommunicationDataOffer {
  /**
   * Offeredamount
   * @exclusiveMin 0
   */
  offeredAmount: number;
  currency: Currency;
  /** Bindingperiodmonth */
  bindingPeriodMonth: number;
  /** Interestrate */
  interestRate: number;
  /** Discount */
  discount: number;
  amortizationRule: AmortizationRule;
  /** Monthlyamortization */
  monthlyAmortization: number;
  /** Invoicefee */
  invoiceFee?: number | null;
  /** Setupfee */
  setupFee?: number | null;
  /** Autogirofee */
  autogiroFee?: number | null;
  /** Amortizationlength */
  amortizationLength?: number | null;
  /** Firstmonthlypayment */
  firstMonthlyPayment: number;
}

/** BankCommunicationEvent */
export interface BankCommunicationEvent {
  direction: Direction;
  /** Event Id */
  event_id: string;
  type: BankEventType;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  data: BankCommunicationData;
  /** User Id */
  user_id?: string | null;
}

/** BankCommunicationEventBankMessage */
export interface BankCommunicationEventBankMessage {
  direction: Direction;
  /** Event Id */
  event_id: string;
  /** Type */
  type: "MESSAGE";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  data: BankCommunicationDataCommentAdded;
  /** User Id */
  user_id?: string | null;
}

/** BankCommunicationEventCommentAdded */
export interface BankCommunicationEventCommentAdded {
  direction: Direction;
  /** Event Id */
  event_id: string;
  /** Type */
  type: "COMMENT_TO_BANK_ADDED" | "COMMENT_ADDED";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  data: BankCommunicationDataCommentAdded;
  /** User Id */
  user_id?: string | null;
}

/** BankCommunicationEventDocumentInfo */
export interface BankCommunicationEventDocumentInfo {
  direction: Direction;
  /** Event Id */
  event_id: string;
  /** Type */
  type: "DOCUMENT_INFO";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  data: BankCommunicationDataDocumentInfo;
  /** User Id */
  user_id?: string | null;
}

/** BankCommunicationEventDocumentSent */
export interface BankCommunicationEventDocumentSent {
  direction: Direction;
  /** Event Id */
  event_id: string;
  /** Type */
  type: "DOCUMENT_TO_BANK_ADDED";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  data: BankCommunicationDataDocumentSent;
  /** User Id */
  user_id?: string | null;
}

/** BankCommunicationEventInvalidInvite */
export interface BankCommunicationEventInvalidInvite {
  direction: Direction;
  /** Event Id */
  event_id: string;
  /** Type */
  type: "INVITE_APP_INVALID";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  data: BankCommunicationDataInvalidInvite;
  /** User Id */
  user_id?: string | null;
}

/** BankCommunicationEventOffer */
export interface BankCommunicationEventOffer {
  direction: Direction;
  /** Event Id */
  event_id: string;
  /** Type */
  type: "OFFER";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  data: BankCommunicationDataOffer;
  /** User Id */
  user_id?: string | null;
}

/** BankDisbursePayload */
export interface BankDisbursePayload {
  /** Mortgage Amount */
  mortgage_amount?: number | null;
  /** Bridge Loan Amount */
  bridge_loan_amount?: number | null;
  /** Disburse Date */
  disburse_date?: string | null;
  /** Application Id */
  application_id: string;
}

/** BankEvent */
export interface BankEvent {
  direction: Direction;
  /**
   * Event Id
   * @format uuid4
   */
  event_id: string;
  type: BankEventType;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  data: BankEventData;
  /** User Id */
  user_id?: string | null;
}

/** BankEventCommentAdded */
export interface BankEventCommentAdded {
  direction: Direction;
  /**
   * Event Id
   * @format uuid4
   */
  event_id: string;
  /** Type */
  type: "COMMENT_TO_BANK_ADDED" | "COMMENT_ADDED";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  data: BankEventDataCommentAdded;
  /** User Id */
  user_id?: string | null;
}

/** BankEventData */
export type BankEventData = Record<string, any>;

/** BankEventDataCommentAdded */
export interface BankEventDataCommentAdded {
  /** Comment */
  comment: string;
  /** User Name */
  user_name?: string | null;
  /** User Name Shown To Bank */
  user_name_shown_to_bank?: string | null;
}

/** BankEventDataDocumentInfo */
export interface BankEventDataDocumentInfo {
  /** Documents */
  documents: DocRequest[];
}

/** BankEventDataDocumentSent */
export interface BankEventDataDocumentSent {
  /** Document Id */
  document_id: string;
  /** Customer Id */
  customer_id: string;
}

/** BankEventDataInvalidInvite */
export interface BankEventDataInvalidInvite {
  /** Messages */
  messages: string[];
}

/** BankEventDataOffer[CreditCardOffer] */
export interface BankEventDataOfferCreditCardOffer {
  offer: CreditCardOffer;
}

/** BankEventDataOffer[MortgageOfferExt] */
export interface BankEventDataOfferMortgageOfferExt {
  offer: MortgageOfferExt;
}

/** BankEventDocumentInfo */
export interface BankEventDocumentInfo {
  direction: Direction;
  /**
   * Event Id
   * @format uuid4
   */
  event_id: string;
  /** Type */
  type: "DOCUMENT_INFO";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  data: BankEventDataDocumentInfo;
  /** User Id */
  user_id?: string | null;
}

/** BankEventDocumentSent */
export interface BankEventDocumentSent {
  direction: Direction;
  /**
   * Event Id
   * @format uuid4
   */
  event_id: string;
  /** Type */
  type: "DOCUMENT_TO_BANK_ADDED";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  data: BankEventDataDocumentSent;
  /** User Id */
  user_id?: string | null;
}

/** BankEventInvalidInvite */
export interface BankEventInvalidInvite {
  direction: Direction;
  /**
   * Event Id
   * @format uuid4
   */
  event_id: string;
  /** Type */
  type: "INVITE_APP_INVALID";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  data: BankEventDataInvalidInvite;
  /** User Id */
  user_id?: string | null;
}

/** BankEventMessage */
export interface BankEventMessage {
  direction: Direction;
  /**
   * Event Id
   * @format uuid4
   */
  event_id: string;
  /** Type */
  type: "MESSAGE";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  data: BankEventDataCommentAdded;
  /** User Id */
  user_id?: string | null;
}

/** BankEventOffer[CreditCardOffer] */
export interface BankEventOfferCreditCardOffer {
  direction: Direction;
  /**
   * Event Id
   * @format uuid4
   */
  event_id: string;
  /** Type */
  type: "OFFER";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  data: BankEventDataOfferCreditCardOffer;
  /** User Id */
  user_id?: string | null;
}

/** BankEventOffer[MortgageOfferExt] */
export interface BankEventOfferMortgageOfferExt {
  direction: Direction;
  /**
   * Event Id
   * @format uuid4
   */
  event_id: string;
  /** Type */
  type: "OFFER";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  data: BankEventDataOfferMortgageOfferExt;
  /** User Id */
  user_id?: string | null;
}

/** BankEventType */
export enum BankEventType {
  INVITE_REQUESTED = "INVITE_REQUESTED",
  INVITE_FILTERS_SUCCESS = "INVITE_FILTERS_SUCCESS",
  INVITE_FILTERED_OUT = "INVITE_FILTERED_OUT",
  INVITE_APP_INVALID = "INVITE_APP_INVALID",
  INVITE_SEND_FAILED = "INVITE_SEND_FAILED",
  INVITE_SEND_FAILED_TRANSIENT = "INVITE_SEND_FAILED_TRANSIENT",
  INVITE_SEND_SUCCESS = "INVITE_SEND_SUCCESS",
  INVITE_POSTPONED = "INVITE_POSTPONED",
  SEND_POSTPONED = "SEND_POSTPONED",
  INVITE_CANCELLED = "INVITE_CANCELLED",
  INVITE_DENIED = "INVITE_DENIED",
  INVITE_SENT = "INVITE_SENT",
  OFFER = "OFFER",
  ACCEPT_REQUESTED = "ACCEPT_REQUESTED",
  ACCEPT_SEND_FAILED = "ACCEPT_SEND_FAILED",
  ACCEPT_SEND_FAILED_TRANSIENT = "ACCEPT_SEND_FAILED_TRANSIENT",
  ACCEPT_SEND_SUCCESS = "ACCEPT_SEND_SUCCESS",
  ACCEPT = "ACCEPT",
  MESSAGE = "MESSAGE",
  STATUS_UPDATE = "STATUS_UPDATE",
  DOCUMENT_INFO = "DOCUMENT_INFO",
  COMMENT_TO_BANK_ADDED = "COMMENT_TO_BANK_ADDED",
  COMMENT_ADDED = "COMMENT_ADDED",
  COMMENT_TO_BANK_SEND_SUCCESS = "COMMENT_TO_BANK_SEND_SUCCESS",
  COMMENT_TO_BANK_SEND_FAILED = "COMMENT_TO_BANK_SEND_FAILED",
  DISBURSEMENT = "DISBURSEMENT",
  DEBENTURESIGNED = "DEBENTURE_SIGNED ",
  CANCEL_REQUESTED = "CANCEL_REQUESTED",
  CANCEL_SEND_FAILED = "CANCEL_SEND_FAILED",
  CANCEL_SEND_FAILED_TRANSIENT = "CANCEL_SEND_FAILED_TRANSIENT",
  CANCEL_SEND_SUCCESS = "CANCEL_SEND_SUCCESS",
  REJECTION = "REJECTION",
  DOCUMENT_TO_BANK_ADDED = "DOCUMENT_TO_BANK_ADDED",
  DOCUMENT_TO_BANK_SEND_SUCCESS = "DOCUMENT_TO_BANK_SEND_SUCCESS",
  DOCUMENT_TO_BANK_SEND_FAILED = "DOCUMENT_TO_BANK_SEND_FAILED",
  DEACTIVATED = "DEACTIVATED",
  REACTIVATED = "REACTIVATED",
  SIGN_STATUS = "SIGN_STATUS",
  SIGN_REQUEST = "SIGN_REQUEST",
  SIGN_SEND_FAILED = "SIGN_SEND_FAILED",
  SIGN_SEND_FAILED_TRANSIENT = "SIGN_SEND_FAILED_TRANSIENT",
  SIGN_SEND_SUCCESS = "SIGN_SEND_SUCCESS",
  LEFT_TO_LIVE = "LEFT_TO_LIVE",
}

/** BankEvents[CreditCardOffer] */
export interface BankEventsCreditCardOffer {
  /** Events */
  events: (
    | BankEventOfferCreditCardOffer
    | BankEvent
    | BankEventDocumentInfo
    | BankEventCommentAdded
    | BankEventMessage
    | BankEventInvalidInvite
    | BankEventDocumentSent
  )[];
  /** Bank Id */
  bank_id: number;
  /** Bank Name */
  bank_name?: string | null;
  /** Actions */
  actions: IntegrationAction[];
  /** Encoded App Id */
  encoded_app_id: string;
  /** Last External Id */
  last_external_id?: string | null;
}

/** BankEvents[MortgageOfferExt] */
export interface BankEventsMortgageOfferExt {
  /** Events */
  events: (
    | BankEventOfferMortgageOfferExt
    | BankEvent
    | BankEventDocumentInfo
    | BankEventCommentAdded
    | BankEventMessage
    | BankEventInvalidInvite
    | BankEventDocumentSent
  )[];
  /** Bank Id */
  bank_id: number;
  /** Bank Name */
  bank_name?: string | null;
  /** Actions */
  actions: IntegrationAction[];
  /** Encoded App Id */
  encoded_app_id: string;
  /** Last External Id */
  last_external_id?: string | null;
}

/** BankIDLoginResponse */
export interface BankIDLoginResponse {
  /** Token */
  token: string;
}

/** BankIDPayload */
export interface BankIDPayload {
  /** Customer Id */
  customer_id?: string | null;
  /** Pni */
  pni?: string | null;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
}

/** BankIDSigned */
export interface BankIDSigned {
  /** User Id */
  user_id: string;
  /**
   * Comment
   * @default "identified with Bank ID"
   */
  comment?: string;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** Comment Id */
  comment_id: string;
  /** Action */
  action: "VERIFIED_BANK_ID";
  /** Customer Id */
  customer_id: string;
}

/** BankIDSignedWithNames */
export interface BankIDSignedWithNames {
  /** User Id */
  user_id: string;
  /**
   * Comment
   * @default "identified with Bank ID"
   */
  comment?: string;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** Comment Id */
  comment_id: string;
  /** Action */
  action: "VERIFIED_BANK_ID";
  /** Customer Id */
  customer_id: string;
  /** User Name */
  user_name: string;
  /** User Avatar */
  user_avatar: string;
  /** Customer Name */
  customer_name: string;
}

/** BankKALP */
export interface BankKALP {
  /**
   * Current Property Interest
   * The current mortgage the customer has
   */
  current_property_interest: number;
  /** Current Property Amortization */
  current_property_amortization: number;
  /** Other Properties To Keep Interest */
  other_properties_to_keep_interest: number;
  /** Other Properties To Keep Amortization */
  other_properties_to_keep_amortization: number;
  /** Other Properties To Keep Fixed Monthly Running Cost */
  other_properties_to_keep_fixed_monthly_running_cost: Record<string, number | null>;
  /** Other Properties To Keep Additional Monthly Running Cost */
  other_properties_to_keep_additional_monthly_running_cost: Record<string, number | null>;
  /** Other Loans Interest */
  other_loans_interest: number;
  /**
   * Other Loans Amortization Terms In Months
   * 96 months is 8 years
   */
  other_loans_amortization_terms_in_months: number;
  /** Other Loans Type Exclude */
  other_loans_type_exclude: LoanType[];
  /** Other Properties To Keep Amortization Property Types */
  other_properties_to_keep_amortization_property_types: PropertyTypeOutput[];
  /** Cost Per Person */
  cost_per_person: CostPerPerson[];
  /** Additional Cost Per Person In Household */
  additional_cost_per_person_in_household: AdditionalCostPerPerson[];
  /** Child Benefit */
  child_benefit: ChildBenefit[];
  /** Property Tax Per Property */
  property_tax_per_property: Record<string, number | null>;
  tax_deductions: TaxDeduction;
  cars?: CarsOutput | null;
  /** Bank Id */
  bank_id: number;
  country: any;
  /** Name */
  name: string;
  /** Api Driven */
  api_driven: "false";
}

/** BankMessageReceived */
export interface BankMessageReceived {
  /** Type */
  type: "BANK_MESSAGE_RECEIVED";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  status: NotificationEvent;
  data: BankMessageReceivedData;
  /** Notification Id */
  notification_id: string;
  /** User Id */
  user_id: string | null;
}

/** BankMessageReceivedData */
export interface BankMessageReceivedData {
  /** Application Id */
  application_id: string;
  /** Bank Id */
  bank_id: number;
}

/** BankOffer */
export interface BankOffer {
  /**
   * Offeredamount
   * @exclusiveMin 0
   */
  offeredAmount: number;
  currency: Currency;
  /** Bindingperiodmonth */
  bindingPeriodMonth: number;
  /** Interestrate */
  interestRate: number;
  /** Discount */
  discount: number;
  amortizationRule: AmortizationRule;
  /** Monthlyamortization */
  monthlyAmortization: number;
  /** Invoicefee */
  invoiceFee?: number | null;
  /** Setupfee */
  setupFee?: number | null;
  /** Autogirofee */
  autogiroFee?: number | null;
  /** Amortizationlength */
  amortizationLength?: number | null;
}

/** BankOfferPayload */
export interface BankOfferPayload {
  /** Bank Id */
  bank_id: number;
  /** Bank Reference Id */
  bank_reference_id?: string | null;
  offer: BankOffer;
  /** Application Id */
  application_id: string;
}

/** BankRejectPayload */
export interface BankRejectPayload {
  /** Application Id */
  application_id: string;
  /** Reason */
  reason: string;
}

/** BankStatus */
export enum BankStatus {
  NEW = "NEW",
  ONGOING = "ONGOING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  DISBURSED = "DISBURSED",
}

/** BankUserWithBankNames */
export interface BankUserWithBankNames {
  /** User Id */
  user_id: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /**
   * Username
   * @format email
   */
  username: string;
  /**
   * Deactivated
   * @default false
   */
  deactivated?: boolean;
  /** Banks */
  banks: Integration[];
}

/** BanksToExcludeInsuranceOfferingFor */
export interface BanksToExcludeInsuranceOfferingFor {
  /** Se */
  SE: number[];
  /** No */
  NO: number[];
}

/**
 * BareApplicantWithRole
 * Used to store applicant data in the application object in MongoDB
 */
export interface BareApplicantWithRole {
  /**
   * Customer Id
   * @format uuid4
   */
  customer_id: string;
  role: ApplicantRole;
}

/** BaseComment */
export interface BaseComment {
  /** Comment */
  comment: string;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Bank Id */
  bank_id: number;
}

/** BaseFormPreferencePayload */
export interface BaseFormPreferencePayload {
  /** Form Id */
  form_id: string;
  product: Product;
  country: Country;
}

/** BasicSubmissionReview */
export interface BasicSubmissionReview {
  /** Submission Id */
  submission_id: string;
  /** Handled */
  handled?: boolean | null;
  /**
   * Reviews
   * @default []
   */
  reviews?: Reviewer[];
  /**
   * Success
   * @default false
   */
  success?: boolean;
  /**
   * Ongoing
   * @default false
   */
  ongoing?: boolean;
  /**
   * Submission Timestamp
   * @format date-time
   */
  submission_timestamp: string;
  /** Raw Data */
  raw_data: object;
  country: Country;
  product: Product;
  /** Request Id */
  request_id: string;
  /** @default {} */
  checklist?: Checklist;
  /**
   * Application Id
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id?: string | null;
}

/** BigQueryCampaignSource */
export interface BigQueryCampaignSource {
  /**
   * Type
   * @default "BIG_QUERY"
   */
  type?: "BIG_QUERY";
  /** Query */
  query: string;
  /**
   * Mapping
   * @default {}
   */
  mapping?: Record<string, string>;
  /** Imported At */
  imported_at?: string | null;
}

/** BlancoAMLPatchPayload */
export interface BlancoAMLPatchPayload {
  channel?: Channel | null;
  purpose?: AMLBlancoPurpose | null;
  purpose_when_new_loan?: LoanPurpose | null;
  country?: Country | null;
  /** Comment */
  comment?: string | null;
}

/** BlancoAMLReviewPayload */
export interface BlancoAMLReviewPayload {
  /** Comment */
  comment?: string | null;
  aml_review: AMLReviewStatus;
  country: Country;
}

/** BlancoGoal */
export interface BlancoGoal {
  type_event: GoalType;
  /** User Id */
  user_id?: string[] | null;
  country: Country;
  /**
   * Year
   * What year the goal is set for
   */
  year: number;
  /**
   * Month
   * Month from 1-12
   */
  month: number;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /**
   * Sent Loans
   * Count of sent loans
   */
  sent_loans: number;
  /**
   * Return Rate
   * A procent of the return rate of sent loans between 1-100
   */
  return_rate: number;
  /**
   * Disbursed Loans Count
   * The count of disbursed loans
   */
  disbursed_loans_count: number;
  /**
   * Working Days
   * Working days of the handler for chosen month
   */
  working_days: number;
  /**
   * Sent Loans Per Day
   * Count of sent loans per day
   */
  sent_loans_per_day: number;
  /**
   * Disbursed Amount
   * The amount of all disbursed loans in the chosen currency
   */
  disbursed_amount: number;
  /**
   * Sent Loans Amount
   * The amount of all sent loans in chosen currency
   */
  sent_loans_amount: number;
  /**
   * Signed Insurances
   * The count of signed insurances
   */
  signed_insurances: number;
  /**
   * Total Premium
   * The total premium of all signed insurances
   */
  total_premium: number;
  /**
   * Average Loan Amount
   * Average loan amount for chosen period, usually set from previous months
   */
  average_loan_amount: number;
  /**
   * Average Premium
   * Average premium of the insurances, usually set from previous months
   */
  average_premium: number;
  /**
   * Mortgage Leads
   * Amount of leads sent to Mortgage
   */
  mortgage_leads?: number | null;
}

/** BlancoGoalMb */
export interface BlancoGoalMb {
  type_event: GoalType;
  /** User Id */
  user_id?: string[] | null;
  country: Country;
  /**
   * Year
   * What year the goal is set for
   */
  year: number;
  /**
   * Month
   * Month from 1-12
   */
  month: number;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /**
   * Applications
   * Number of created applications
   */
  applications: number;
  /**
   * Working Days
   * Working days of the handler for chosen month
   */
  working_days: number;
  /**
   * Disbursed Loans Count
   * Number of disbursed loans
   */
  disbursed_loans_count: number;
  /**
   * Disbursed Loans Amount
   * The amount of disbursed loans
   */
  disbursed_loans_amount: number;
  /**
   * Co Applicant Percentage
   * The percentage of applications sent with a co-applicant
   */
  co_applicant_percentage: number | null;
  /**
   * Average Loan Amount
   * Average loan amount for chosen period, usually set from previous months
   */
  average_loan_amount: number;
  /**
   * Applications Per Day
   * Count of created applications per day
   */
  applications_per_day: number;
  /**
   * Gr Percentage
   * Percent of applications that have GR
   */
  gr_percentage: number | null;
  /**
   * Disbursement Rate
   * Percent of sent applications that get disbursed
   */
  disbursement_rate: number;
}

/** BlancoGoalMbPayload */
export interface BlancoGoalMbPayload {
  /**
   * Year
   * What year the goal is set for
   */
  year: number;
  /**
   * Month
   * Month from 1-12
   */
  month: number;
  /** User Ids */
  user_ids?: string[] | null;
  /**
   * Applications
   * Number of created applications
   */
  applications: number;
  /**
   * Working Days
   * Working days of the handler for chosen month, example: 20
   */
  working_days: number;
  /**
   * Disbursed Loans Count
   * Number of disbursed loans
   */
  disbursed_loans_count: number;
  /**
   * Disbursed Loans Amount
   * The amount of disbursed loans
   */
  disbursed_loans_amount: number;
  /**
   * Co Applicant Percentage
   * The percentage of applications sent with a co-applicant
   */
  co_applicant_percentage: number;
  /**
   * Average Loan Amount
   * Average loan amount for chosen period, usually set from previous months
   */
  average_loan_amount: number;
  /**
   * Applications Per Day
   * Count of created applications per day
   */
  applications_per_day: number;
  /**
   * Gr Percentage
   * Percent of applications that have GR
   */
  gr_percentage: number | null;
  /**
   * Disbursement Rate
   * Percent of sent applications that get disbursed
   */
  disbursement_rate: number;
}

/** BlancoGoalPayload */
export interface BlancoGoalPayload {
  /**
   * Year
   * What year the goal is set for
   */
  year: number;
  /**
   * Month
   * Month from 1-12
   */
  month: number;
  /** User Ids */
  user_ids?: string[] | null;
  /**
   * Sent Loans
   * The count of sent loans
   */
  sent_loans: number;
  /**
   * Return Rate
   * A procent of the return rate of sent loans between 1-100
   */
  return_rate: number;
  /**
   * Working Days
   * Working days of the handler for chosen month, example: 20
   */
  working_days: number;
  /**
   * Signed Insurances
   * The count of signed insurances
   */
  signed_insurances: number;
  /**
   * Average Premium
   * An amount in the local currency
   */
  average_premium: number;
  /**
   * Sent Loans Per Day
   * Count of sent loans per day
   */
  sent_loans_per_day: number;
  /**
   * Disbursed Amount
   * The amount of all disbursed loans in the chosen currency
   */
  disbursed_amount: number;
  /**
   * Average Loan Amount
   * Average loan amount for chosen period, usually set from previous months
   */
  average_loan_amount: number;
  /**
   * Disbursed Loans Count
   * The count of disbursed loans
   */
  disbursed_loans_count: number;
  /**
   * Sent Loans Amount
   * The amount of all sent loans in chosen currency
   */
  sent_loans_amount: number;
  /**
   * Total Premium
   * The total premium of all signed insurances
   */
  total_premium: number;
  /**
   * Mortgage Leads
   * The amount of leads sent to mortgage
   */
  mortgage_leads?: number | null;
}

/** BlancoLoan */
export interface BlancoLoan {
  /**
   * Desired Amount
   * @default 0
   */
  desired_amount?: number | null;
  /** Loan Purpose */
  loan_purpose?: LoanPurposeNO | LoanPurposeSE | null;
}

/** BlockCustomerFieldManuallyPayload */
export interface BlockCustomerFieldManuallyPayload {
  country: Country;
  /** Reason */
  reason: string;
  field: BlockField;
  /** Blocked */
  blocked: boolean;
  /** Values */
  values: string[];
}

/** BlockCustomerFieldManuallyResponse */
export interface BlockCustomerFieldManuallyResponse {
  /** Result */
  result: BlockCustomerFieldManuallyResponseModel[];
}

/** BlockCustomerFieldManuallyResponseModel */
export interface BlockCustomerFieldManuallyResponseModel {
  /** Value */
  value: string;
  /** Blocked */
  blocked?: boolean | null;
}

/** BlockField */
export enum BlockField {
  Phone = "phone",
  Pni = "pni",
  Email = "email",
}

/** BlockList */
export interface BlockList {
  /** Pni */
  pni?: boolean | null;
  /** Phone Number */
  phone_number?: boolean | null;
  /** Email */
  email?: boolean | null;
}

/** Body_activate_campaign_dialmaker_campaign_activate_post */
export interface BodyActivateCampaignDialmakerCampaignActivatePost {
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
}

/** Body_admin_dialmaker_admin_outcomes_post */
export interface BodyAdminDialmakerAdminOutcomesPost {
  /** Campaign Ids */
  campaign_ids: string[];
  /** User Ids */
  user_ids?: string[] | null;
}

/** Body_call_back_dialmaker_card_outcome_call_back_post */
export interface BodyCallBackDialmakerCardOutcomeCallBackPost {
  /**
   * Lead Id
   * @format uuid
   */
  lead_id: string;
  /** When */
  when?: string | null;
  /** Comment */
  comment?: string | null;
  /**
   * Personal Callback
   * @default true
   */
  personal_callback?: boolean;
  /**
   * Get Next
   * @default true
   */
  get_next?: boolean;
}

/** Body_call_back_dialmaker_card_outcome_later_post */
export interface BodyCallBackDialmakerCardOutcomeLaterPost {
  /**
   * Lead Id
   * @format uuid
   */
  lead_id: string;
  /** When */
  when?: string | null;
  /** Comment */
  comment?: string | null;
  /**
   * Personal Callback
   * @default true
   */
  personal_callback?: boolean;
  /**
   * Get Next
   * @default true
   */
  get_next?: boolean;
}

/** Body_call_discards_dialmaker_card_outcome_discard_post */
export interface BodyCallDiscardsDialmakerCardOutcomeDiscardPost {
  /**
   * Lead Id
   * @format uuid
   */
  lead_id: string;
  /** Outcome */
  outcome: string;
  /**
   * Get Next
   * @default true
   */
  get_next?: boolean;
  /** Comment */
  comment?: string | null;
}

/** Body_call_failure_dialmaker_card_outcome_failure_post */
export interface BodyCallFailureDialmakerCardOutcomeFailurePost {
  /**
   * Lead Id
   * @format uuid
   */
  lead_id: string;
  /** Outcome */
  outcome: string;
  /**
   * Get Next
   * @default true
   */
  get_next?: boolean;
  /** Comment */
  comment?: string | null;
}

/** Body_call_no_contact_dialmaker_card_outcome_no_contact_post */
export interface BodyCallNoContactDialmakerCardOutcomeNoContactPost {
  /**
   * Lead Id
   * @format uuid
   */
  lead_id: string;
  /** Outcome */
  outcome: string;
  /**
   * Get Next
   * @default true
   */
  get_next?: boolean;
  /** Comment */
  comment?: string | null;
}

/** Body_call_success_dialmaker_card_outcome_success_post */
export interface BodyCallSuccessDialmakerCardOutcomeSuccessPost {
  /**
   * Lead Id
   * @format uuid
   */
  lead_id: string;
  /** Comment */
  comment?: string | null;
  /**
   * Get Next
   * @default true
   */
  get_next?: boolean;
}

/** Body_create_url_url_shortener_url_post */
export interface BodyCreateUrlUrlShortenerUrlPost {
  /** Url Name */
  url_name: string;
  /**
   * Target Url
   * @format uri
   * @minLength 1
   */
  target_url: string;
  utm_tags: UtmTags | null;
}

/** Body_deactivate_campaign_dialmaker_campaign_deactivate_post */
export interface BodyDeactivateCampaignDialmakerCampaignDeactivatePost {
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
}

/** Body_delete_extraction_dialmaker_campaign_extraction_delete */
export interface BodyDeleteExtractionDialmakerCampaignExtractionDelete {
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
  /**
   * Extraction Id
   * @format uuid
   */
  extraction_id: string;
  /**
   * Force
   * Force unextract already assigned extractions
   * @default false
   */
  force?: boolean;
}

/** Body_elk46_call_webhook_message_elk46_call_post */
export interface BodyElk46CallWebhookMessageElk46CallPost {
  /** To */
  to: string;
  /** Callid */
  callid: string;
}

/** Body_export_and_extract_leads_url_dialmaker_campaign_export_post */
export interface BodyExportAndExtractLeadsUrlDialmakerCampaignExportPost {
  /**
   * Campaign Id
   * Campaign ID to export leads from
   * @format uuid
   */
  campaign_id: string;
  /**
   * Leads Count
   * Number of leads to extract and export
   */
  leads_count: number;
}

/** Body_export_and_extract_leads_url_download_dialmaker_campaign_extraction_export_post */
export interface BodyExportAndExtractLeadsUrlDownloadDialmakerCampaignExtractionExportPost {
  /**
   * Extraction Id
   * Extraction ID to export leads from
   * @format uuid
   */
  extraction_id: string;
}

/** Body_extract_campaigns_dialmaker_campaign_extract_post */
export interface BodyExtractCampaignsDialmakerCampaignExtractPost {
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
  /** Number Of Leads */
  number_of_leads: number;
}

/** Body_find_campaigns_dialmaker_campaign_find_post */
export interface BodyFindCampaignsDialmakerCampaignFindPost {
  filter?: Filter | null;
  order?: Order | null;
}

/** Body_get_mail_statistics_dialmaker_campaign_mail_statistics_post */
export interface BodyGetMailStatisticsDialmakerCampaignMailStatisticsPost {
  /** Campaign Ids */
  campaign_ids: string[];
  /** Percentage */
  percentage: boolean;
  /**
   * Start
   * @format date-time
   */
  start: string;
  /**
   * End
   * @format date-time
   */
  end: string;
}

/** Body_get_outcomes_dialmaker_card_outcomes_post */
export interface BodyGetOutcomesDialmakerCardOutcomesPost {
  /**
   * Lead Id
   * @format uuid
   */
  lead_id: string;
}

/** Body_get_variables_template_variables_dialmaker_mail_template_variables_post */
export interface BodyGetVariablesTemplateVariablesDialmakerMailTemplateVariablesPost {
  /** Template Slug */
  template_slug: string;
  /** Campaign Id */
  campaign_id: string | null;
}

/** Body_import_leads_dialmaker_campaign_import_post */
export interface BodyImportLeadsDialmakerCampaignImportPost {
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
  /**
   * Source Id
   * Source ID to import from, e.g. sheet or query id for campaign source
   */
  source_id?: string | null;
}

/** Body_join_campaign_dialmaker_campaigns_join_post */
export interface BodyJoinCampaignDialmakerCampaignsJoinPost {
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
  /** Color */
  color: number;
}

/** Body_leave_campaign_dialmaker_campaigns_leave_post */
export interface BodyLeaveCampaignDialmakerCampaignsLeavePost {
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
}

/** Body_lock_dialmaker_card_lock_post */
export interface BodyLockDialmakerCardLockPost {
  /**
   * Lead Id
   * @format uuid
   */
  lead_id: string;
  /**
   * Force
   * @default false
   */
  force?: boolean;
}

/** Body_message_opened_mandrill_webhook_message_post */
export interface BodyMessageOpenedMandrillWebhookMessagePost {
  /** Mandrill Events */
  mandrill_events: any;
}

/** Body_pause_campaign_dialmaker_campaigns_pause_post */
export interface BodyPauseCampaignDialmakerCampaignsPausePost {
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
}

/** Body_play_campaign_dialmaker_campaigns_play_post */
export interface BodyPlayCampaignDialmakerCampaignsPlayPost {
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
}

/** Body_save_outcomes_dialmaker_misc_outcomes_post */
export interface BodySaveOutcomesDialmakerMiscOutcomesPost {
  outcomes: Outcomes;
}

/** Body_search_dialmaker_card_search_post */
export interface BodySearchDialmakerCardSearchPost {
  /** Search */
  search: string;
}

/** Body_slack_callback_slack_callback_post */
export interface BodySlackCallbackSlackCallbackPost {
  /** Payload */
  payload: any;
}

/** Body_stats_dialmaker_admin_outcomes_users_post */
export interface BodyStatsDialmakerAdminOutcomesUsersPost {
  /** User Ids */
  user_ids: string[];
  /**
   * Start Date
   * @format date-time
   */
  start_date: string;
  /**
   * End Date
   * @format date-time
   */
  end_date: string;
}

/** Body_store_events_form_analytics_store_events_post */
export interface BodyStoreEventsFormAnalyticsStoreEventsPost {
  /** Event List */
  event_list: OnlineFormsEvent[];
}

/** Body_submission_jotform_webhook__form_id__post */
export interface BodySubmissionJotformWebhookFormIdPost {
  /**
   * Submissionid
   * @default ""
   */
  submissionID?: string;
}

/** Body_update_dialmaker_card_update_post */
export interface BodyUpdateDialmakerCardUpdatePost {
  /**
   * Lead Id
   * @format uuid
   */
  lead_id: string;
  data: UpdateLeadData;
}

/** Body_upload_attachment_dialmaker_campaign_image_upload_post */
export interface BodyUploadAttachmentDialmakerCampaignImageUploadPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_upload_attachment_message_attachments_upload_post */
export interface BodyUploadAttachmentMessageAttachmentsUploadPost {
  /**
   * File
   * @format binary
   */
  file: File;
  /** File Name */
  file_name: string;
}

/** Body_upload_dialmaker_sheet__campaign_id__upload_with_preview_post */
export interface BodyUploadDialmakerSheetCampaignIdUploadWithPreviewPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_view_dialmaker_card_view_post */
export interface BodyViewDialmakerCardViewPost {
  /**
   * Lead Id
   * @format uuid
   */
  lead_id: string;
}

/** BookableSlot */
export interface BookableSlot {
  /**
   * Start Date
   * @format date-time
   */
  start_date: string;
  /**
   * End Date
   * @format date-time
   */
  end_date: string;
  /**
   * Available Slots
   * @default 0
   */
  available_slots?: number | null;
}

/** BookingPayload */
export interface BookingPayload {
  /**
   * Timeid
   * @format date-time
   */
  timeId: string;
  /** Phonenumber */
  phoneNumber: string;
  /**
   * Email
   * @format email
   */
  email: string;
}

/** BooliPropertySearchResult */
export interface BooliPropertySearchResult {
  /** Id */
  id: string;
  /** Streetguid */
  streetGuid: string;
  /** Streetname */
  streetName: string;
  /** Streetnumber */
  streetNumber: string;
  /** Postaddress */
  postAddress?: string | null;
  /** Postcode */
  postCode: string;
  /** Longitude */
  longitude: number;
  /** Latitude */
  latitude: number;
  /**
   * Housingcoopid
   * @default ""
   */
  housingCoopId?: string;
  /** Hasresidences */
  hasResidences: boolean;
}

/** BooliSearchPayload */
export interface BooliSearchPayload {
  country: Country;
  /** Street Name */
  street_name: string;
  /** Post Code */
  post_code: string;
  /** Street Number */
  street_number?: string | null;
}

/** BooliValuationPayload */
export interface BooliValuationPayload {
  /** Property Id */
  property_id: string;
  /** Living Area */
  living_area: number;
}

/** Brokerage */
export interface Brokerage {
  /** Agency */
  agency?: string | null;
  /** Broker */
  broker?: string | null;
  /** Email */
  email?: string | null;
  /** Phone */
  phone?: string | null;
}

/** Budget */
export interface Budget {
  type_event: GoalType;
  /** User Id */
  user_id?: string[] | null;
  country: Country;
  /**
   * Year
   * What year the goal is set for
   */
  year: number;
  /**
   * Month
   * Month from 1-12
   */
  month: number;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /**
   * Applications
   * Budget of applications created
   */
  applications: number;
  /**
   * Average Loan Amount
   * Budget of average loan amount
   */
  average_loan_amount: number;
  /**
   * Disbursed Loans Count
   * Budget of number of disbursed loans
   */
  disbursed_loans_count: number;
  /**
   * Disbursed Loans Amount
   * Budget of the amount of disbursed loans in countries currency
   */
  disbursed_loans_amount: number;
  /**
   * Disbursement Rate
   * Budget of disbursement rate
   */
  disbursement_rate: number;
}

/** BudgetPayload */
export interface BudgetPayload {
  /**
   * Year
   * What year the goal is set for
   */
  year: number;
  /**
   * Month
   * Month from 1-12
   */
  month: number;
  /** User Ids */
  user_ids?: string[] | null;
  /**
   * Applications
   * Budget of applications created
   */
  applications: number;
  /**
   * Average Loan Amount
   * Budget of average loan amount
   */
  average_loan_amount: number;
  /**
   * Disbursed Loans Count
   * Budget of number of disbursed loans
   */
  disbursed_loans_count: number;
  /**
   * Disbursed Loans Amount
   * Budget of the amount of disbursed loans in countries currency
   */
  disbursed_loans_amount: number;
  /**
   * Disbursement Rate
   * Budget of disbursement rate
   */
  disbursement_rate: number;
}

/** BuyingInfo */
export interface BuyingInfo {
  /** Property Price */
  property_price?: number | null;
  /** Buying Date */
  buying_date?: string | null;
  /** New Production */
  new_production?: boolean | null;
  /** First Owner */
  first_owner?: boolean | null;
  /** Down Payment */
  down_payment?: number | null;
}

/** CTATags */
export enum CTATags {
  MISSING2PAYMENTS = "MISSING_2_PAYMENTS",
  MISSING3PAYMENTS = "MISSING_3_PAYMENTS",
  AUTOMATICALLY_CANCELED = "AUTOMATICALLY_CANCELED",
  INACTIVE_MANDATE = "INACTIVE_MANDATE",
}

/** Cadastre */
export interface Cadastre {
  /** Bnr */
  BNr?: number | null;
  /** Fnr */
  FNr?: number | null;
  /** Gnr */
  GNr?: number | null;
  /** Knr */
  KNr?: number | null;
  /** Snr */
  SNr?: number | null;
}

/** CallOutcomeCallBack */
export interface CallOutcomeCallBack {
  /** User Id */
  user_id?: string | null;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /**
   * Type
   * @default "CALL_BACK"
   */
  type?: "CALL_BACK";
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
  /** Comment */
  comment?: string | null;
  /** When */
  when?: string | null;
  /**
   * Personal Callback
   * @default false
   */
  personal_callback?: boolean;
}

/** CallOutcomeDiscard */
export interface CallOutcomeDiscard {
  /** User Id */
  user_id?: string | null;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /**
   * Type
   * @default "DISCARD"
   */
  type?: "DISCARD";
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
  /** Comment */
  comment?: string | null;
  /** Outcome */
  outcome: string;
}

/** CallOutcomeFailure */
export interface CallOutcomeFailure {
  /** User Id */
  user_id?: string | null;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /**
   * Type
   * @default "FAILURE"
   */
  type?: "FAILURE";
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
  /** Comment */
  comment?: string | null;
  /** Outcome */
  outcome: string;
}

/** CallOutcomeNoContact */
export interface CallOutcomeNoContact {
  /** User Id */
  user_id?: string | null;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /**
   * Type
   * @default "NO_CONTACT"
   */
  type?: "NO_CONTACT";
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
  /** Comment */
  comment?: string | null;
  /** Outcome */
  outcome: string;
}

/** CallOutcomeSuccess */
export interface CallOutcomeSuccess {
  /** User Id */
  user_id?: string | null;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /**
   * Type
   * @default "SUCCESS"
   */
  type?: "SUCCESS";
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
  /** Comment */
  comment?: string | null;
}

/** CallingOrder */
export interface CallingOrder {
  /**
   * H00
   * @default 0
   */
  h00?: number;
  /**
   * H01
   * @default 0
   */
  h01?: number;
  /**
   * H02
   * @default 0
   */
  h02?: number;
  /**
   * H03
   * @default 0
   */
  h03?: number;
  /**
   * H04
   * @default 0
   */
  h04?: number;
  /**
   * H05
   * @default 0
   */
  h05?: number;
  /**
   * H06
   * @default 0
   */
  h06?: number;
  /**
   * H07
   * @default 0
   */
  h07?: number;
  /**
   * H08
   * @default 0
   */
  h08?: number;
  /**
   * H09
   * @default 0
   */
  h09?: number;
  /**
   * H10
   * @default 0
   */
  h10?: number;
  /**
   * H11
   * @default 0
   */
  h11?: number;
  /**
   * H12
   * @default 0
   */
  h12?: number;
  /**
   * H13
   * @default 0
   */
  h13?: number;
  /**
   * H14
   * @default 0
   */
  h14?: number;
  /**
   * H15
   * @default 0
   */
  h15?: number;
  /**
   * H16
   * @default 0
   */
  h16?: number;
  /**
   * H17
   * @default 0
   */
  h17?: number;
  /**
   * H18
   * @default 0
   */
  h18?: number;
  /**
   * H19
   * @default 0
   */
  h19?: number;
  /**
   * H20
   * @default 0
   */
  h20?: number;
  /**
   * H21
   * @default 0
   */
  h21?: number;
  /**
   * H22
   * @default 0
   */
  h22?: number;
  /**
   * H23
   * @default 0
   */
  h23?: number;
  /** Timezone */
  timezone: string;
}

/** CampaignData */
export interface CampaignData {
  url_target: Product;
  /** Valid To */
  valid_to?: string | null;
  /** Url */
  url?: string | null;
}

/** CampaignExport */
export interface CampaignExport {
  /**
   * Export Id
   * @format uuid
   */
  export_id: string;
  /**
   * Extraction Id
   * @format uuid
   */
  extraction_id: string;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
}

/** CampaignExtraction */
export interface CampaignExtraction {
  /**
   * Extraction Id
   * @format uuid
   */
  extraction_id: string;
  /** Extracted By */
  extracted_by?: string | null;
  /**
   * Leads Count
   * @min 0
   */
  leads_count: number;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
}

/** CampaignExtractionsStats */
export interface CampaignExtractionsStats {
  /** Already Extracted */
  already_extracted: number;
  /** Without Outcome */
  without_outcome: number;
  /** Exported Without Outcome */
  exported_without_outcome: number;
  /** Available To Call */
  available_to_call: number;
  /** Left To Extract */
  left_to_extract?: number | null;
}

/** CampaignMailOverview */
export interface CampaignMailOverview {
  /** Currently In Campaign */
  currently_in_campaign: number;
}

/** CampaignMailStats */
export interface CampaignMailStats {
  /**
   * Total Opens
   * @default 0
   */
  total_opens?: number;
  /**
   * Total Clicks
   * @default 0
   */
  total_clicks?: number;
  /**
   * Unique Opens
   * @default 0
   */
  unique_opens?: number;
  /**
   * Unique Clicks
   * @default 0
   */
  unique_clicks?: number;
  /**
   * Sent
   * @default 0
   */
  sent?: number;
  /**
   * Hard Bounce
   * @default 0
   */
  hard_bounce?: number;
  /**
   * Soft Bounce
   * @default 0
   */
  soft_bounce?: number;
  /**
   * Spam
   * @default 0
   */
  spam?: number;
  /**
   * Unsubscribed
   * @default 0
   */
  unsubscribed?: number;
  /**
   * Redirects
   * @default 0
   */
  redirects?: number;
  /**
   * Unique Redirects
   * @default 0
   */
  unique_redirects?: number;
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
  /** Mailings */
  mailings: CampaignMailingStats[];
  /** Last Sent */
  last_sent?: string | null;
}

/** CampaignMailingStats */
export interface CampaignMailingStats {
  /**
   * Total Opens
   * @default 0
   */
  total_opens?: number;
  /**
   * Total Clicks
   * @default 0
   */
  total_clicks?: number;
  /**
   * Unique Opens
   * @default 0
   */
  unique_opens?: number;
  /**
   * Unique Clicks
   * @default 0
   */
  unique_clicks?: number;
  /**
   * Sent
   * @default 0
   */
  sent?: number;
  /**
   * Hard Bounce
   * @default 0
   */
  hard_bounce?: number;
  /**
   * Soft Bounce
   * @default 0
   */
  soft_bounce?: number;
  /**
   * Spam
   * @default 0
   */
  spam?: number;
  /**
   * Unsubscribed
   * @default 0
   */
  unsubscribed?: number;
  /**
   * Redirects
   * @default 0
   */
  redirects?: number;
  /**
   * Unique Redirects
   * @default 0
   */
  unique_redirects?: number;
  /**
   * Mailing Id
   * @format uuid
   */
  mailing_id: string;
  /** Batches */
  batches: MailBatchStats[];
  /**
   * Last Sent
   * @format date-time
   */
  last_sent: string;
}

/** CampaignOngoingExtraction */
export interface CampaignOngoingExtraction {
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
  /**
   * Job Id
   * @format uuid
   */
  job_id: string;
  /** Job Status */
  job_status?: string | null;
  /**
   * Extraction Id
   * @format uuid
   */
  extraction_id: string;
  /** Leads Count */
  leads_count: number;
  /** User Id */
  user_id: string;
  /**
   * Unextraction
   * @default false
   */
  unextraction?: boolean;
}

/** CampaignStats */
export interface CampaignStats {
  /** Active */
  active: number;
}

/** CampaignType */
export enum CampaignType {
  COLD = "COLD",
  POOL = "POOL",
  PROCESS = "PROCESS",
}

/** CancelOfferPayload */
export interface CancelOfferPayload {
  reason: CancelReason;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Bank Id */
  bank_id: number;
  /**
   * Synchronous
   * @default true
   */
  synchronous?: boolean;
}

/** CancelReason */
export enum CancelReason {
  BETTER_INTEREST_OTHER_BANK = "BETTER_INTEREST_OTHER_BANK",
  OFFERED_AMOUNT_TOO_LOW = "OFFERED_AMOUNT_TOO_LOW",
  NO_DEAL = "NO_DEAL",
  OTHER_REASON = "OTHER_REASON",
}

/** CancellationTrigger */
export enum CancellationTrigger {
  CUSTOMER_REQUEST = "CUSTOMER_REQUEST",
  MANDATE_CANCELLED = "MANDATE_CANCELLED",
  BY_AXA = "BY_AXA",
  END_OF_INSURANCE_LIFETIME = "END_OF_INSURANCE_LIFETIME",
  BECAUSE_OF_CUSTOMER_AGE = "BECAUSE_OF_CUSTOMER_AGE",
  FAILED_PAYMENTS = "FAILED_PAYMENTS",
  DISEASED_CUSTOMER = "DISEASED_CUSTOMER",
  OTHER = "OTHER",
}

/** Car */
export interface Car {
  /** Monthlycost */
  monthlyCost?: number | null;
}

/** CarCost */
export interface CarCost {
  /**
   * Actual Cost
   * @default false
   */
  actual_cost?: boolean;
  /** Fixed Cost */
  fixed_cost: number;
}

/** Cars */
export interface CarsInput {
  /** Owned */
  owned?: number | null;
  /** Leased */
  leased?: Car[] | null;
}

/** Cars */
export interface CarsOutput {
  per_leased: CarCost;
  per_owned: CarCost;
}

/** CaseCollectionItem */
export interface CaseCollectionItem {
  /** Uc Type Template Descition */
  uc_type_template_descition?: string | null;
  /** Year */
  year?: string | null;
}

/** Channel */
export enum Channel {
  Inbound = "inbound",
  Outbound = "outbound",
  Online = "online",
}

/** Checklist */
export interface Checklist {
  status?: SubmissionStatus | null;
  form_validation?: ModelsOnlineLoanApplicationStatus | null;
  get_customers?: ModelsOnlineLoanApplicationStatus | null;
  has_recent_app_check?: ModelsOnlineLoanApplicationStatus | null;
  has_recent_app_email?: ModelsOnlineLoanApplicationStatus | null;
  created_application?: ModelsOnlineLoanApplicationStatus | null;
  not_using_test_pni?: ModelsOnlineLoanApplicationStatus | null;
  quick_application?: ModelsOnlineLoanApplicationStatus | null;
  parsed_application?: ModelsOnlineLoanApplicationStatus | null;
  sms_blanco_app_creation?: ModelsOnlineLoanApplicationStatus | null;
  converted_to_blanco_model?: ModelsOnlineLoanApplicationStatus | null;
  created_blanco_application?: ModelsOnlineLoanApplicationStatus | null;
}

/** ChildBenefit */
export interface ChildBenefit {
  /** Number Of Children */
  number_of_children: number;
  /** Benefit */
  benefit: number;
}

/** ClickEvent */
export interface ClickEvent {
  /** Email Key */
  email_key?: string | null;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
}

/** CommentPayload */
export interface CommentPayload {
  /**
   * Comment
   * @minLength 5
   * @maxLength 999
   */
  comment: string;
}

/** Condominium */
export interface Condominium {
  /** Property Id */
  property_id: string;
  /** Value */
  value?: number | null;
  /** Booli Value */
  booli_value?: number | null;
  /** External Id */
  external_id?: string | null;
  /** Property Type */
  property_type: "CONDOMINIUM";
  country: Country;
  /** Street Address */
  street_address?: string | null;
  /** Post Code */
  post_code?: string | null;
  /** City */
  city?: string | null;
  /** Other Owner */
  other_owner?: boolean | null;
  /** Monthly Cost */
  monthly_cost?: number | null;
  /** Square Meter Living Area */
  square_meter_living_area?: number | null;
  /** Municipality */
  municipality?: string | null;
  /** Municipality Code */
  municipality_code?: string | null;
  /** Estimated Value */
  estimated_value?: number | null;
  /** Latitude */
  latitude?: number | null;
  /** Longitude */
  longitude?: number | null;
  ev_partial?: EvPartialCommon | null;
  /** Apartment Number */
  apartment_number?: string | null;
  /** Elevator */
  elevator?: boolean | null;
  /** Organization Number */
  organization_number?: string | null;
  /** Organization Name */
  organization_name?: string | null;
  /** Floor */
  floor?: number | null;
  balcony_type?: BalconyType | null;
  /** Rooms */
  rooms?: number | null;
  [key: string]: any;
}

/** CondominiumItem */
export interface CondominiumItem {
  /**
   * Organizationnumber
   * Housing association organization number
   */
  organizationNumber?: string | null;
  /**
   * Organizationname
   * Housing association organization name
   */
  organizationName?: string | null;
  /**
   * Apartmentnumber
   * Apartment number in housing association
   */
  apartmentNumber: string | null;
  /**
   * Floor
   * Floor number. Decimal, could be negative. Example: 1,0, -1,0 or 1,5.
   */
  floor?: number | null;
  /**
   * Elevator
   * If there an elevator in apartment building?
   */
  elevator?: boolean | null;
  /** Balcony type */
  balconyType?: BalconyType | null;
}

/** Config */
export interface Config {
  /** Opening Hours */
  opening_hours: ValueListItem[];
  customer_support_phone: ValueListItem;
  /**
   * Customer Support Email
   * @format email
   */
  customer_support_email: string;
  /**
   * Blanco Form Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  blanco_form_url: string;
  /**
   * Credit Card Form Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  credit_card_form_url: string;
  /**
   * Mortgage Form Url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  mortgage_form_url: string;
  /** Office Address */
  office_address: string;
  customer_support_sms: ValueListItem;
}

/** Consents */
export interface Consents {
  /** Email Marketing */
  email_marketing: boolean;
  /** Sms Marketing */
  sms_marketing: boolean;
  /** Customer Club Marketing */
  customer_club_marketing: boolean;
}

/** ConsentsPayload */
export interface ConsentsPayload {
  consents?: Consents | null;
  /**
   * Dismissed Consents Popup
   * @default false
   */
  dismissed_consents_popup?: boolean;
}

/** ContactFormPayload */
export interface ContactFormPayload {
  /** Givenname */
  givenName: string;
  /** Lastname */
  lastName: string;
  /** Phonenumber */
  phoneNumber: string;
  /**
   * Email
   * @format email
   */
  email: string;
  /** Message */
  message: string;
}

/** ContactType */
export enum ContactType {
  Phone = "phone",
  Mail = "mail",
}

/** Conversation */
export interface Conversation {
  /** Customer Id */
  customer_id: string;
  /**
   * Application Id Or Lead Id
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id_or_lead_id?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Pni */
  pni?: string | null;
  /** Open */
  open: boolean;
}

/** ConvertPayload */
export interface ConvertPayload {
  /** Lead Id */
  lead_id: string;
}

/** ConvertResult */
export interface ConvertResult {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
}

/** ConvertToBlancoStatus */
export enum ConvertToBlancoStatus {
  Success = "success",
  Failure = "failure",
  InProgress = "in_progress",
  NotEligible = "not_eligible",
  Enqueued = "enqueued",
}

/** CostPerPerson */
export interface CostPerPerson {
  /** Cost */
  cost: number;
  /** From And Including Age */
  from_and_including_age: number;
  /** To And Including Age */
  to_and_including_age: number;
}

/** Country */
export enum Country {
  SE = "SE",
  NO = "NO",
}

/** CreateAndUpdateEmployeeRequest */
export interface CreateAndUpdateEmployeeRequest {
  /** Email */
  email?: string | null;
  /** First Name */
  first_name?: string | null;
  /** Last Name */
  last_name?: string | null;
  /** Zoordinates */
  zoordinates: Zoordinates[];
  /** Phone Number */
  phone_number?: string | null;
  /** Telavox Ids */
  telavox_ids?: string[] | null;
  /** Sverker Usernames */
  sverker_usernames?: SverkerUsername[] | null;
  /** Roles */
  roles: RoleType[];
}

/** CreateApplicantNO */
export interface CreateApplicantNO {
  /**
   * Country
   * @default "NO"
   */
  country?: "NO";
  /** Pni */
  pni: string;
  /** First Name */
  first_name?: string | null;
  /** Last Name */
  last_name?: string | null;
  /** Email */
  email?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Additional Phone Numbers */
  additional_phone_numbers?: string[] | null;
  /** Monthly Property Cost */
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;
  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  /** Post Code */
  post_code?: string | null;
  /** City */
  city?: string | null;
  /** Incomes */
  incomes?: Income[] | null;
  /** Net Monthly Income */
  net_monthly_income?: number | null;
  account?: Account | null;
  /** Street Address */
  street_address?: string | null;
  /**
   * Paying Child Support
   * Number of children to pay child support for
   */
  paying_child_support?: number | null;
  /**
   * Receiving Child Support
   * Number of children to receive child support for
   */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  /** Consents Popup Dismissed Timestamp */
  consents_popup_dismissed_timestamp?: string | null;
  /** Partner Pni */
  partner_pni?: string | null;
  /** Partner Name */
  partner_name?: string | null;
  /** Municipality Code */
  municipality_code?: string | null;
  /** Municipality */
  municipality?: string | null;
  /** Is Pep */
  is_pep?: boolean | null;
  /** Age */
  age?: number | null;
  /** Other Phones */
  other_phones?: AppsmgrModelsPhonePhoneNumber[] | null;
  /** Sverker Id */
  sverker_id?: number | null;
  /** Children */
  children?: number | null;
  /** Citizen */
  citizen?: number | null;
  education?: EducationType | null;
  /** Monthly Partner Income */
  monthly_partner_income?: number | null;
  /** Immigrated At */
  immigrated_at?: string | null;
  /** Fullname */
  fullname?: string | null;
  /** Documents */
  documents?: ApplicantDocument[] | null;
  /** Verified Credits */
  verified_credits?: boolean | null;
  /** Housing Since */
  housing_since?: string | null;
  /** Deceased */
  deceased?: boolean | null;
  /** Emigrated */
  emigrated?: boolean | null;
  /** Is Sanction */
  is_sanction?: boolean | null;
  /** Is Rca */
  is_rca?: boolean | null;
  /** Date Created */
  date_created?: string | null;
  /** Date Updated */
  date_updated?: string | null;
  /** Last Credit Check */
  last_credit_check?: string | null;
  /** Last Externally Verified Credits */
  last_externally_verified_credits?: string | null;
  /** Last Property Valuation */
  last_property_valuation?: string | null;
  /** Pep Quality */
  pep_quality?: boolean | null;
  /**
   * Cluster
   * A cluster is a group of customers displayed with a color
   */
  cluster?: string | null;
  /**
   * Is Blocked
   * Indicates if the customer is blocked
   * @default false
   */
  is_blocked?: boolean;
  housing_type?: HousingTypeNO | null;
  employer?: OptionalEmployerNO | null;
  /** Ev Estate */
  ev_estate?: EvEstate[] | null;
  /** Ev Estates */
  ev_estates?: EvProperty[] | null;
  /** Citizenship Iso Codes */
  citizenship_iso_codes?: string[] | null;
  /** Externally Verified Credits */
  externally_verified_credits?: ExternallyVerifiedCredit[] | null;
}

/**
 * CreateApplicationPayloadExt
 * Create application payload extended with fields for ZRM
 */
export interface CreateApplicationPayloadExt {
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  /** Applicants */
  applicants?: BareApplicantWithRole[] | null;
  /** Property Id */
  property_id?: string | null;
  discount?: Discount | null;
  /** Loans */
  loans?: Loan[] | null;
  /** Other Mortgages */
  other_mortgages?: MortgageLoan[] | null;
  status?: AppsmgrModelsStatusStatus | null;
  substatus?: Substatus | null;
  bank_status?: BankStatus | null;
  /** Children In The Household */
  children_in_the_household?: number | null;
  /** Children In The Household Fulltime */
  children_in_the_household_fulltime?: number | null;
  /** Other Properties To Keep */
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  brokerage?: Brokerage | null;
  product: Product;
  country: Country;
  /** Internal Comment */
  internal_comment?: string | null;
  /** New App Options */
  new_app_options?: Option[] | null;
  /** New App Checklist */
  new_app_checklist?: string[] | null;
  source?: ApplicationSource | null;
  aml?: AMLInsertion | null;
}

/** CreateCampaignData */
export interface CreateCampaignData {
  /** Name */
  name: string;
  /** Description */
  description?: string | null;
  /** Source */
  source:
    | ({
        type: "BIG_QUERY";
      } & BigQueryCampaignSource)
    | ({
        type: "PROCESS";
      } & ProcessCampaignSource)
    | ({
        type: "SHEET";
      } & SheetCampaignSource);
  /** File Id */
  file_id?: string | null;
  exclusion_rules: ExclusionRules;
  /** Daily Extraction Limit */
  daily_extraction_limit?: number | null;
  /** Comment Template */
  comment_template?: string | null;
  mail_policy?: MailSendPolicy | null;
  template?: EmailTemplate | null;
  /** Template Vars */
  template_vars?: string[] | null;
  retry_policy?: RetryPolicy | null;
  /** Auto Dial */
  auto_dial?: boolean | null;
  /** Personal Callbacks */
  personal_callbacks?: boolean | null;
  /** No Contact Outcomes */
  no_contact_outcomes?: string[] | null;
  /** Failure Outcomes */
  failure_outcomes?: string[] | null;
  /** Discard Outcomes */
  discard_outcomes?: string[] | null;
  /**
   * Show Call History
   * @default true
   */
  show_call_history?: boolean | null;
  type: CampaignType;
  country: Country;
  /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
  product: ExtendedProduct;
  department?: ZDepartment | null;
  contact_type: ContactType;
}

/**
 * CreateCreditCardApplicationApplicantPayloadExt
 * Create application and applicant payload extended with fields for ZRM
 */
export interface CreateCreditCardApplicationApplicantPayloadExt {
  country: Country;
  data: CreditCardApplicationDataCreate;
  applicant: CreateApplicantNO;
  /** Internal Comment */
  internal_comment?: string | null;
  /** New App Options */
  new_app_options?: Option[] | null;
  /** New App Checklist */
  new_app_checklist?: string[] | null;
  source?: ApplicationSource | null;
  aml?: AMLInsertion | null;
}

/**
 * CreateCreditCardApplicationPayloadExt
 * Create application payload extended with fields for ZRM
 */
export interface CreateCreditCardApplicationPayloadExt {
  country: Country;
  data: CreditCardApplicationData;
  /** Internal Comment */
  internal_comment?: string | null;
  /** New App Options */
  new_app_options?: Option[] | null;
  /** New App Checklist */
  new_app_checklist?: string[] | null;
  source?: ApplicationSource | null;
  aml?: AMLInsertion | null;
}

/**
 * CreateCreditCardApplicationPayloadWithApplicant
 * Payload to send to apps-mgr - create application and applicant
 */
export interface CreateCreditCardApplicationPayloadWithApplicant {
  country: Country;
  data: CreditCardApplicationDataCreate;
  applicant: CreateApplicantNO;
}

/** CreateCustomerPayload */
export interface CreateCustomerPayload {
  /** Reason */
  reason: string;
  country: Country;
  /** Pni */
  pni: string;
  data: CustomerData;
}

/** CreateInsuranceCustomer */
export interface CreateInsuranceCustomer {
  insurance_type: InsuranceType;
  /** Insured Amount */
  insured_amount: number;
  /** Premium Amount */
  premium_amount: number;
  /**
   * Email
   * @format email
   */
  email: string;
  /** Phone Number */
  phone_number: string;
  /** Success Url */
  success_url: string;
  /** Fail Url */
  fail_url: string;
}

/** CreateInsuranceCustomerV1 */
export interface CreateInsuranceCustomerV1 {
  insuranceType: InsuranceType;
  /** Insuredamount */
  insuredAmount: number;
  /** Premium */
  premium: number;
  /** Pni */
  pni: string;
  /** Email */
  email: string;
  /** Phonenumber */
  phoneNumber: string;
}

/** CreateLeadPayload */
export interface CreateLeadPayload {
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  /** Applicants */
  applicants?: BareApplicantWithRole[] | null;
  /** Property Id */
  property_id?: string | null;
  discount?: Discount | null;
  /** Loans */
  loans?: Loan[] | null;
  /** Other Mortgages */
  other_mortgages?: MortgageLoan[] | null;
  status?: AppsmgrModelsStatusStatus | null;
  substatus?: Substatus | null;
  bank_status?: BankStatus | null;
  /** Children In The Household */
  children_in_the_household?: number | null;
  /** Children In The Household Fulltime */
  children_in_the_household_fulltime?: number | null;
  /** Other Properties To Keep */
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  brokerage?: Brokerage | null;
  /** Applicant */
  applicant:
    | ({
        country: "NO";
      } & LimitedApplicantNO)
    | ({
        country: "SE";
      } & LimitedApplicantSE);
  /** Co Applicant */
  co_applicant?:
    | (
        | ({
            country: "NO";
          } & LimitedApplicantNO)
        | ({
            country: "SE";
          } & LimitedApplicantSE)
      )
    | null;
  property?: ExternalProperty | null;
  /** Comment */
  comment: string;
  source: ApplicationSource;
  product: Product;
  country: Country;
  [key: string]: any;
}

/** CreateMeetingPayload */
export interface CreateMeetingPayload {
  /**
   * Meeting Start
   * @format date-time
   */
  meeting_start: string;
  /**
   * Meeting End
   * @format date-time
   */
  meeting_end: string;
  /** Handler Id */
  handler_id?: string | null;
  /** Meeting Notes */
  meeting_notes?: string | null;
  /** Meeting Subject */
  meeting_subject: string | null;
  /** Customer Id */
  customer_id?: string | null;
  /**
   * Application Id
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
}

/** CreateTMCampaign */
export interface CreateTMCampaign {
  /** Query */
  query: string;
  country: Country;
  product: Product;
  department: ZDepartment;
  /** Description */
  description: string;
  /**
   * Ignore Days Since Last Extraction
   * Number of days since last extraction to ignore customer_id
   * @default 30
   */
  ignore_days_since_last_extraction?: number;
}

/** CreateUser */
export interface CreateUser {
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /**
   * Username
   * @format email
   */
  username: string;
  /** Banks */
  banks: IntegrationId[];
}

/** CreateUserResp */
export interface CreateUserResp {
  /** Token */
  token: string;
}

/** CreditCardApplicationData */
export interface CreditCardApplicationData {
  /** Desired Credit Limit */
  desired_credit_limit: number;
  /** Car */
  car: boolean;
  /**
   * Student Loans Amount
   * @default 0
   */
  student_loans_amount?: number;
  /**
   * Student Loans Monthly Payment
   * @default 0
   */
  student_loans_monthly_payment?: number;
  /**
   * Car Boat Mc Loans Amount
   * @default 0
   */
  car_boat_mc_loans_amount?: number;
  /**
   * Car Boat Mc Loans Monthly Payment
   * @default 0
   */
  car_boat_mc_loans_monthly_payment?: number;
  /**
   * Total Consumer Loans Amount
   * @default 0
   */
  total_consumer_loans_amount?: number;
  /**
   * Total Consumer Loans Monthly Payment
   * @default 0
   */
  total_consumer_loans_monthly_payment?: number;
  /**
   * Mortgage Loans Amount
   * @default 0
   */
  mortgage_loans_amount?: number;
  /**
   * Mortgage Loans Monthly Payment
   * @default 0
   */
  mortgage_loans_monthly_payment?: number;
  /**
   * Credit Card Debt Amount
   * @default 0
   */
  credit_card_debt_amount?: number;
  /**
   * Credit Card Debt Monthly Payment
   * @default 0
   */
  credit_card_debt_monthly_payment?: number;
  /**
   * Children In The Household Fulltime
   * @default 0
   */
  children_in_the_household_fulltime?: number;
  /**
   * Applicant Id
   * @format uuid
   */
  applicant_id: string;
}

/** CreditCardApplicationDataCreate */
export interface CreditCardApplicationDataCreate {
  /** Desired Credit Limit */
  desired_credit_limit: number;
  /** Car */
  car: boolean;
  /**
   * Student Loans Amount
   * @default 0
   */
  student_loans_amount?: number;
  /**
   * Student Loans Monthly Payment
   * @default 0
   */
  student_loans_monthly_payment?: number;
  /**
   * Car Boat Mc Loans Amount
   * @default 0
   */
  car_boat_mc_loans_amount?: number;
  /**
   * Car Boat Mc Loans Monthly Payment
   * @default 0
   */
  car_boat_mc_loans_monthly_payment?: number;
  /**
   * Total Consumer Loans Amount
   * @default 0
   */
  total_consumer_loans_amount?: number;
  /**
   * Total Consumer Loans Monthly Payment
   * @default 0
   */
  total_consumer_loans_monthly_payment?: number;
  /**
   * Mortgage Loans Amount
   * @default 0
   */
  mortgage_loans_amount?: number;
  /**
   * Mortgage Loans Monthly Payment
   * @default 0
   */
  mortgage_loans_monthly_payment?: number;
  /**
   * Credit Card Debt Amount
   * @default 0
   */
  credit_card_debt_amount?: number;
  /**
   * Credit Card Debt Monthly Payment
   * @default 0
   */
  credit_card_debt_monthly_payment?: number;
  /**
   * Children In The Household Fulltime
   * @default 0
   */
  children_in_the_household_fulltime?: number;
}

/** CreditCardApplicationExtended */
export interface CreditCardApplicationExtended {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** Creator */
  creator?: string | null;
  data: CreditCardApplicationData;
  /**
   * Active
   * @default true
   */
  active?: boolean;
  /** @default "COMPLETE" */
  state?: AppState;
  /** Created At */
  created_at?: string | null;
  /** Applicant */
  applicant:
    | ({
        country: "NO";
      } & ApplicantNO)
    | ({
        country: "SE";
      } & ApplicantSE);
}

/** CreditCardApplicationWithNamesInComments */
export interface CreditCardApplicationWithNamesInComments {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** Creator */
  creator?: string | null;
  data: CreditCardApplicationData;
  /**
   * Active
   * @default true
   */
  active?: boolean;
  /** @default "COMPLETE" */
  state?: AppState;
  /** Created At */
  created_at?: string | null;
  /** Applicant */
  applicant:
    | ({
        country: "NO";
      } & ApplicantNO)
    | ({
        country: "SE";
      } & ApplicantSE);
  meta: ApplicationMappingWithNamesInComments;
}

/** CreditCardBankEventsResponse */
export interface CreditCardBankEventsResponse {
  /** Bank Events */
  bank_events: BankEventsCreditCardOffer[];
}

/** CreditCardOffer */
export interface CreditCardOffer {
  /** @default "FIXED_RATE" */
  amortizationRule?: AmortizationPlan;
  /**
   * Offeredamount
   * @default 0
   */
  offeredAmount?: number;
  /** Minofferedamount */
  minOfferedAmount?: number | null;
  /** Maxofferedamount */
  maxOfferedAmount?: number | null;
  /** Mustrefinance */
  mustRefinance?: number | null;
  /** Monthlypayment */
  monthlyPayment?: number | null;
  /** Termmonths */
  termMonths?: number | null;
  /**
   * Termfee
   * @default 0
   */
  termFee?: number;
  /**
   * Arrangementfee
   * @default 0
   */
  arrangementFee?: number;
  /**
   * Invoicefee
   * @default 0
   */
  invoiceFee?: number;
  /** Ratepercent */
  ratePercent?: number | null;
}

/**
 * CreditCardTerms
 * General terms for credit card offers.
 */
export interface CreditCardTerms {
  /** Withdrawal Fee */
  withdrawal_fee: string;
  /** Currency Surcharge */
  currency_surcharge: number;
  /** Annual Fee */
  annual_fee: number;
  /** Apr */
  apr: number;
  /**
   * Apr Calculation Description
   * @default ""
   */
  apr_calculation_description?: string;
  /** Grace Period Days */
  grace_period_days: number;
  /**
   * Purchase Fee
   * @default 0
   */
  purchase_fee?: number;
  /**
   * E Invoice Fee
   * @default 0
   */
  e_invoice_fee?: number;
}

/** CreditCheckNO */
export interface CreditCheckNO {
  /** Earnedincome */
  earnedIncome: number;
  /** Mostrecentnotedate */
  mostRecentNoteDate?: string | null;
  /**
   * Creditnotes
   * @default 0
   */
  creditNotes?: number;
  /**
   * Expired
   * @default false
   */
  expired?: boolean;
  /** Expiration Date */
  expiration_date?: string | null;
  /** Debt Equity Ratio */
  debt_equity_ratio?: number | null;
  /** Debt Equtiy Year */
  debt_equtiy_year?: number | null;
  remarks?: Debt | null;
  /** Cdg2 Risk */
  cdg2_risk?: number | null;
  /** Cdg2 Score */
  cdg2_score?: number | null;
}

/** CreditCheckSE */
export interface CreditCheckSE {
  /** Earnedincome */
  earnedIncome: number;
  /** Mostrecentnotedate */
  mostRecentNoteDate?: string | null;
  /**
   * Creditnotes
   * @default 0
   */
  creditNotes?: number;
  /**
   * Expired
   * @default false
   */
  expired?: boolean;
  /** Expiration Date */
  expiration_date?: string | null;
  /** Riskassessment */
  riskAssessment?: number | null;
  /**
   * Kfmdebt
   * @default 0
   */
  kfmDebt?: number;
  /** Kfmdebtpublic */
  kfmDebtPublic?: number | null;
  /** Kfmdebtprivate */
  kfmDebtPrivate?: number | null;
  /**
   * Numprevious
   * @default 0
   */
  numPrevious?: number;
  /** Score */
  score?: number | null;
  /** Inquiries */
  inquiries?: number | null;
  /** Issuers */
  issuers?: string[] | null;
}

/** CreditNO */
export interface CreditNO {
  /** Sum */
  sum: number;
  /** Monthly Payment */
  monthly_payment: number;
  /** Bank Id */
  bank_id: number;
  /** Liable */
  liable?: string | null;
  /** Rate */
  rate: number;
  /** Refinance Amount */
  refinance_amount?: number | null;
  /** Monthly Amortisation */
  monthly_amortisation?: number | null;
  responsibility?: null;
  type: CreditTypeNO;
}

/** CreditRegulation */
export interface CreditRegulation {
  /** Accounting Controlls Summary */
  accounting_controlls_summary?: string | null;
  /** Allow Casuation Storage */
  allow_casuation_storage?: string | null;
  /** Cause Table */
  cause_table?: string | null;
  /** Risk */
  risk?: string | null;
  /** Risk Applicant */
  risk_applicant?: string | null;
  /** Risk Applicant 2 */
  risk_applicant_2?: string | null;
  /** Template Description */
  template_description?: string | null;
  /** Uc Internal Information */
  uc_internal_information?: string | null;
  /** Year */
  year?: string | null;
}

/** CreditRegulationInformation */
export interface CreditRegulationInformation {
  /** Account Number */
  account_number?: string | null;
  /** Decision Template */
  decision_template?: string | null;
  /** No Decimals */
  no_decimals?: string | null;
  /** Order Template */
  order_template?: string | null;
  /** Template Name */
  template_name?: string | null;
  /** Template Response */
  template_response?: string | null;
  /** Year */
  year?: string | null;
}

/** CreditResponsibility */
export enum CreditResponsibility {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  CO_APPLICANT = "CO_APPLICANT",
  SHARED = "SHARED",
  GUARANTOR = "GUARANTOR",
}

/** CreditSE */
export interface CreditSE {
  /** Sum */
  sum: number;
  /** Monthly Payment */
  monthly_payment: number;
  /** Bank Id */
  bank_id: number;
  /** Liable */
  liable?: string | null;
  /** Rate */
  rate: number;
  /** Refinance Amount */
  refinance_amount?: number | null;
  /** Monthly Amortisation */
  monthly_amortisation?: number | null;
  responsibility?: null;
  type: CreditTypeSE;
}

/** CreditTypeNO */
export enum CreditTypeNO {
  CREDITCARD = "CREDITCARD",
  CARLOAN = "CARLOAN",
  PRIVATELOAN = "PRIVATELOAN",
  CHECKCREDIT = "CHECKCREDIT",
  UNKNOWN = "UNKNOWN",
  STUDENTLOAN = "STUDENTLOAN",
  MORTGAGE = "MORTGAGE",
}

/** CreditTypeSE */
export enum CreditTypeSE {
  CREDITCARD = "CREDITCARD",
  INSTALLMENT = "INSTALLMENT",
  CARLOAN = "CARLOAN",
  TOPLOAN = "TOPLOAN",
  PRIVATELOAN = "PRIVATELOAN",
  MEMBERLOAN = "MEMBERLOAN",
  CHECKCREDIT = "CHECKCREDIT",
  UNKNOWN = "UNKNOWN",
  MORTGAGE = "MORTGAGE",
}

/** CreditorOffer[CreditCardOffer, CreditCardTerms] */
export interface CreditorOfferCreditCardOfferCreditCardTerms {
  country: Country;
  /** Bank Id */
  bank_id: number;
  /** Creditor Name */
  creditor_name: string;
  /**
   * Offer Id
   * @format uuid4
   */
  offer_id: string;
  offer: CreditCardOffer;
  /** General terms for credit card offers. */
  terms: CreditCardTerms;
  /** Acceptable */
  acceptable: boolean;
  /**
   * Is Accepted
   * @default false
   */
  is_accepted?: boolean;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** App Identifier */
  app_identifier: string;
}

/** Currency */
export enum Currency {
  EUR = "EUR",
  SEK = "SEK",
  NOK = "NOK",
}

/** CurrentFrameItem */
export interface CurrentFrameItem {
  /** Frame Information */
  frame_information?: string | null;
  /** Uc Divisions */
  uc_divisions?: string | null;
  /** Uc Post Type Current */
  uc_post_type_current?: string | null;
  /** Uc Quick Choice */
  uc_quick_choice?: null;
  /** Year */
  year?: string | null;
}

/** CurrentSectionOfCaseItem */
export interface CurrentSectionOfCaseItem {
  /** Additional Order */
  additional_order?: string | null;
  /** Editor Program */
  editor_program?: string | null;
  /** Order Date */
  order_date?: string | null;
  /** Person Name Stored */
  person_name_stored?: string | null;
  /** Report Language */
  report_language?: string | null;
  /** Subscriber Name */
  subscriber_name?: string | null;
  /** Uc Archive */
  uc_archive?: string | null;
  /** Uc Divisions 1 */
  uc_divisions_1?: string | null;
  /** Uc Divisions 2 */
  uc_divisions_2?: string | null;
  /** Uc Divisions 3 */
  uc_divisions_3?: string | null;
  /** Uc Divisions 4 */
  uc_divisions_4?: string | null;
  /** Uc Divisions 5 */
  uc_divisions_5?: string | null;
  /** Uc Divisions 6 */
  uc_divisions_6?: string | null;
  /** Uc Divisions 7 */
  uc_divisions_7?: string | null;
  /** Uc Divisions 8 */
  uc_divisions_8?: string | null;
  /** Uc More Info */
  uc_more_info?: string | null;
  /** Uc Role */
  uc_role?: string | null;
  /** Uc Type */
  uc_type?: string | null;
  /** Uc Type Internal */
  uc_type_internal?: string | null;
  /** Uc Type Text */
  uc_type_text?: string | null;
  /** Year */
  year?: string | null;
}

/** CustomerApplication */
export interface CustomerApplication {
  country: Country;
  product: Product;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Is Deactivated */
  is_deactivated: boolean;
  /** Date Created */
  date_created?: string | null;
  /** Offers */
  offers?: CustomerOffer[] | null;
  /** Credits */
  credits: CreditSE[] | CreditNO[];
  applicant: CustomerCustomer;
  co_applicant?: CustomerCustomer | null;
}

/** CustomerApplicationV2 */
export interface CustomerApplicationV2 {
  country: Country;
  product: Product;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Is Deactivated */
  is_deactivated: boolean;
  /** Date Created */
  date_created?: string | null;
  /**
   * Offers
   * @default []
   */
  offers?: CustomerOfferV2[];
}

/** CustomerCustomer */
export interface CustomerCustomer {
  /** Customer Id */
  customer_id?: string | null;
  country: Country;
  /** Pni */
  pni: string;
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /** Sverker Id */
  sverker_id?: number | null;
  /** Email */
  email?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  bank_account?: Account | null;
  /** Employer */
  employer?: object | null;
}

/** CustomerData */
export interface CustomerData {
  /** Phone Number */
  phone_number?: string | null;
  /** Email */
  email?: string | null;
  account?: Account | null;
  /**
   * Children
   * @min 0
   * @default 0
   */
  children?: number;
  /** Employer */
  employer?: EmployerSE | EmployerNO | null;
  /** Incomes */
  incomes?: Income[] | null;
  /** Net Monthly Income */
  net_monthly_income?: number | null;
  /** Monthly Property Cost */
  monthly_property_cost?: number | null;
  /** Housing Type */
  housing_type?: HousingTypeSE | HousingTypeNO | null;
  /** Paying Child Support */
  paying_child_support?: number | null;
  /** Receiving Child Support */
  receiving_child_support?: number | null;
  /** Marital status of a person */
  marital_status?: MaritalStatus | null;
  /** Official marital status of a person */
  registered_marital_status?: MaritalStatus | null;
  education?: EducationType | null;
}

/** CustomerDocument */
export interface CustomerDocument {
  /**
   * Name
   * Name of the document
   */
  name: string;
  /**
   * Mime Type
   * MIME type of the document
   */
  mime_type: string;
  /**
   * Document Type
   * The type of document you're uploading, will be used as key on the customer
   */
  document_type: string;
  /**
   * Customer Id
   * ID of the customer to attach the document to
   * @format uuid
   */
  customer_id: string;
  /**
   * Data
   * Base64 encoded data of the document
   */
  data: string;
  /**
   * Document Id
   * ID of the document
   * @format uuid
   */
  document_id: string;
  /**
   * Timestamp
   * Document creation time
   * @format date-time
   */
  timestamp: string;
}

/** CustomerIDGetCustomerPayload */
export interface CustomerIDGetCustomerPayload {
  /** Reason */
  reason: string;
  /** Customer Id */
  customer_id: string;
}

/** CustomerIDsGetCustomerPayload */
export interface CustomerIDsGetCustomerPayload {
  /** Reason */
  reason: string;
  /** Customer Ids */
  customer_ids: string[];
}

/** CustomerOffer */
export interface CustomerOffer {
  amortizationRule?: AmortizationPlan | null;
  /** Offeredamount */
  offeredAmount: number;
  /** Minofferedamount */
  minOfferedAmount?: number | null;
  /** Maxofferedamount */
  maxOfferedAmount?: number | null;
  /** Mustrefinance */
  mustRefinance?: number | null;
  /** Monthlypayment */
  monthlyPayment?: number | null;
  /** Termmonths */
  termMonths?: number | null;
  /** Termfee */
  termFee: number;
  /** Arrangementfee */
  arrangementFee: number;
  /** Invoicefee */
  invoiceFee: number;
  /** Ratepercent */
  ratePercent: number;
  /** Effectiveratepercent */
  effectiveRatePercent: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /** Bank Id */
  bank_id: number;
  /**
   * Offer Id
   * @format uuid
   */
  offer_id: string;
  /** Need More Info */
  need_more_info: boolean;
  status: OfferStatus;
  /** Invite Id */
  invite_id?: number | null;
  /** Sverker Offer Id */
  sverker_offer_id: number;
  /** Application Id */
  application_id: number;
  /** External Offer Id */
  external_offer_id?: string | null;
  /** Accepted At */
  accepted_at?: string | null;
  /** Disbursed At */
  disbursed_at?: string | null;
}

/** CustomerOfferV2 */
export interface CustomerOfferV2 {
  amortizationRule?: AmortizationPlan | null;
  /** Offeredamount */
  offeredAmount: number;
  /** Minofferedamount */
  minOfferedAmount?: number | null;
  /** Maxofferedamount */
  maxOfferedAmount?: number | null;
  /** Mustrefinance */
  mustRefinance?: number | null;
  /**
   * Monthlypayment
   * @default 0
   */
  monthlyPayment?: number;
  /** Termmonths */
  termMonths?: number | null;
  /** Termfee */
  termFee: number;
  /** Arrangementfee */
  arrangementFee: number;
  /** Invoicefee */
  invoiceFee: number;
  /** Ratepercent */
  ratePercent: number;
  /** Effectiveratepercent */
  effectiveRatePercent: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /** Bank Id */
  bank_id: number;
  /**
   * Offer Id
   * @format uuid
   */
  offer_id: string;
  /** Need More Info */
  need_more_info: boolean;
  status: OfferStatus;
  /** Invite Id */
  invite_id?: number | null;
  /** Sverker Offer Id */
  sverker_offer_id: number;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** External Offer Id */
  external_offer_id?: string | null;
  /** Accepted At */
  accepted_at?: string | null;
  /** Disbursed At */
  disbursed_at?: string | null;
  /**
   * Acceptable
   * @default true
   */
  acceptable?: boolean;
  currency?: Currency | null;
  /**
   * Total Cost
   * @default "0"
   */
  total_cost?: number;
  /**
   * Total Fees Amount
   * @default "0"
   */
  total_fees_amount?: number;
  /**
   * Total Interest Amount
   * @default "0"
   */
  total_interest_amount?: number;
}

/** DMLead */
export interface DMLead {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /** Version */
  version: number;
  data: LeadData;
  /** Campaign Id */
  campaign_id?: string | null;
  /** Extraction Id */
  extraction_id?: string | null;
  /** Extraction of this lead */
  extraction: ModelsDialMaker2LeadDataModelsLeadExtraction | null;
  /** Extracted At */
  extracted_at?: string | null;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Export Id */
  export_id?: string | null;
  /** Exported At */
  exported_at?: string | null;
  status: LeadStatus;
  /** Call Outcomes */
  call_outcomes: (
    | ({
        type: "CALL_BACK";
      } & CallOutcomeCallBack)
    | ({
        type: "DISCARD";
      } & CallOutcomeDiscard)
    | ({
        type: "FAILURE";
      } & CallOutcomeFailure)
    | ({
        type: "NO_CONTACT";
      } & CallOutcomeNoContact)
    | ({
        type: "SUCCESS";
      } & CallOutcomeSuccess)
  )[];
  /** Assigned To */
  assigned_to?: string | null;
  /** Assigned At */
  assigned_at?: string | null;
  retry_policy?: RetryPolicy | null;
  /** Call At */
  call_at?: string | null;
  personal_callback?: PersonalCallback | null;
  last_call_outcome?: OutcomeType | null;
  /** Last Call Outcome At */
  last_call_outcome_at?: string | null;
}

/** DMLeadCampaignData */
export interface DMLeadCampaignData {
  /** Campaign Name */
  campaign_name?: string | null;
  /** Image Id */
  image_id?: string | null;
  /** Campaign Color */
  campaign_color?: number | null;
  /** Auto Dial */
  auto_dial?: boolean | null;
  /** Show Call History */
  show_call_history?: boolean | null;
}

/** DMLeadExt */
export interface DMLeadExt {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /** Version */
  version: number;
  data: LeadData;
  /** Campaign Id */
  campaign_id?: string | null;
  /** Extraction Id */
  extraction_id?: string | null;
  /** Extraction of this lead */
  extraction: ModelsDialMaker2LeadDataModelsLeadExtraction | null;
  /** Extracted At */
  extracted_at?: string | null;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Export Id */
  export_id?: string | null;
  /** Exported At */
  exported_at?: string | null;
  status: LeadStatus;
  /** Call Outcomes */
  call_outcomes: (
    | ({
        type: "CALL_BACK";
      } & CallOutcomeCallBack)
    | ({
        type: "DISCARD";
      } & CallOutcomeDiscard)
    | ({
        type: "FAILURE";
      } & CallOutcomeFailure)
    | ({
        type: "NO_CONTACT";
      } & CallOutcomeNoContact)
    | ({
        type: "SUCCESS";
      } & CallOutcomeSuccess)
  )[];
  /** Assigned To */
  assigned_to?: string | null;
  /** Assigned At */
  assigned_at?: string | null;
  retry_policy?: RetryPolicy | null;
  /** Call At */
  call_at?: string | null;
  personal_callback?: PersonalCallback | null;
  last_call_outcome?: OutcomeType | null;
  /** Last Call Outcome At */
  last_call_outcome_at?: string | null;
  campaign_data?: DMLeadCampaignData | null;
}

/** DashboardPayload */
export interface DashboardPayload {
  /** User Ids */
  user_ids?: string[] | null;
  /** Sverker Usernames */
  sverker_usernames?: string[] | null;
  /**
   * Start Date
   * @format date-time
   */
  start_date: string;
  /**
   * End Date
   * @format date-time
   */
  end_date: string;
}

/** DaySlotDoc */
export interface DaySlotDoc {
  /**
   * Day
   * @min 0
   * @max 6
   */
  day: number;
  product: Product;
  country: Country;
  /**
   * Starts At
   * @format date-time
   */
  starts_at: string;
  /** Ends At */
  ends_at?: string | null;
  /** Slots */
  slots: ScheduleSlot[];
  /** Id */
  id: string;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** User Id */
  user_id: string;
  /** Request Id */
  request_id: string;
}

/** DaySlotDocPayload */
export interface DaySlotDocPayload {
  /**
   * Day
   * @min 0
   * @max 6
   */
  day: number;
  product: Product;
  country: Country;
  /**
   * Starts At
   * @format date-time
   */
  starts_at: string;
  /** Ends At */
  ends_at?: string | null;
  /** Slots */
  slots: ScheduleSlot[];
}

/** DeactivatePayload */
export interface DeactivatePayload {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  review: DeactivationReview;
}

/** DeactivateUserPayload */
export interface DeactivateUserPayload {
  /** User Id */
  user_id: string;
}

/** DeactivationReview */
export interface DeactivationReview {
  reason: AppsmgrBlancoModelsApplicationDeactivationReason;
  review: ApplicationReview;
}

/** DeactivationReviewsPayload */
export interface DeactivationReviewsPayload {
  country: Country;
}

/** DeactivationReviewsResponse */
export interface DeactivationReviewsResponse {
  /** Reviews */
  reviews: DeactivationReview[];
}

/** Debt */
export interface Debt {
  /** Debt */
  debt?: DebtItem[] | null;
}

/** DebtItem */
export interface DebtItem {
  /** Date */
  date?: string | null;
  /** Type */
  type?: string | null;
  /** Source */
  source?: string | null;
  /** Status */
  status?: string | null;
  /** Saksnr */
  saksnr?: string | null;
  /** Amount */
  amount?: number | null;
  /** Oppgjort */
  oppgjort?: string | null;
  /** Creditor */
  creditor?: string | null;
  /** Art */
  art?: string | null;
  /** Amount Percentage */
  amount_percentage?: number | null;
  /** Currency */
  currency?: string | null;
  /** Code */
  code?: string | null;
}

/** DefaultCalendarView */
export enum DefaultCalendarView {
  DayGridMonth = "dayGridMonth",
  AppViewWeek = "appViewWeek",
  AppViewDay = "appViewDay",
}

/** DialCampaignData */
export interface DialCampaignData {
  /** Name */
  name: string;
  /** Description */
  description?: string | null;
  /** Source */
  source:
    | ({
        type: "BIG_QUERY";
      } & BigQueryCampaignSource)
    | ({
        type: "PROCESS";
      } & ProcessCampaignSource)
    | ({
        type: "SHEET";
      } & SheetCampaignSource);
  /** File Id */
  file_id?: string | null;
  exclusion_rules: ExclusionRules;
  /** Daily Extraction Limit */
  daily_extraction_limit?: number | null;
  /** Comment Template */
  comment_template?: string | null;
  retry_policy: RetryPolicy;
  /** Auto Dial */
  auto_dial?: boolean | null;
  /** Personal Callbacks */
  personal_callbacks?: boolean | null;
  /** No Contact Outcomes */
  no_contact_outcomes?: string[] | null;
  /** Failure Outcomes */
  failure_outcomes?: string[] | null;
  /** Discard Outcomes */
  discard_outcomes?: string[] | null;
  /**
   * Show Call History
   * @default true
   */
  show_call_history?: boolean | null;
  type: CampaignType;
  country: Country;
  /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
  product: ExtendedProduct;
  department?: ZDepartment | null;
  /**
   * Contact Type
   * @default "phone"
   */
  contact_type?: "phone";
}

/** Direction */
export enum Direction {
  INCOMING = "INCOMING",
  OUTGOING = "OUTGOING",
}

/** Discount */
export interface Discount {
  /** Occupational Pension In Banks */
  occupational_pension_in_banks?: number[] | null;
}

/** DocRequest */
export interface DocRequest {
  /** Customer Id */
  customer_id?: string | null;
  /** Type */
  type: DocumentType | string;
  /** Status */
  status: "RECEIVED" | "REQUESTED" | "APPROVED" | "DECLINED" | "WITHDRAWN";
  /** Document Id */
  document_id?: string | null;
  /** Comment */
  comment?: string | null;
}

/** Document */
export interface Document {
  /** Document Id */
  document_id: string;
  /** Customer Id */
  customer_id: string;
}

/** DocumentAssignation */
export interface DocumentAssignation {
  /** Customer Id */
  customer_id: string;
  /** Document Id */
  document_id: string;
}

/** DocumentCreate */
export interface DocumentCreate {
  /**
   * Name
   * Name of the document
   */
  name: string;
  /**
   * Mime Type
   * MIME type of the document
   */
  mime_type: string;
  /**
   * Document Type
   * The type of document you're uploading, will be used as key on the customer
   */
  document_type: string;
  /**
   * Customer Id
   * ID of the customer to attach the document to
   * @format uuid
   */
  customer_id: string;
  /**
   * Data
   * Base64 encoded data of the document
   */
  data: string;
}

/** DocumentMeta */
export interface DocumentMeta {
  /**
   * Name
   * Name of the document
   */
  name: string;
  /**
   * Mime Type
   * MIME type of the document
   */
  mime_type: string;
  /**
   * Document Type
   * The type of document you're uploading, will be used as key on the customer
   */
  document_type: string;
  /**
   * Document Id
   * ID of the document
   * @format uuid
   */
  document_id: string;
  /**
   * Customer Id
   * ID of the customer to attach the document to
   * @format uuid
   */
  customer_id: string;
  /**
   * Timestamp
   * Document creation time
   * @format date-time
   */
  timestamp: string;
}

/** DocumentToBank */
export interface DocumentToBank {
  /** Documents */
  documents: Document[];
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Bank Id */
  bank_id: number;
}

/** DocumentType */
export enum DocumentType {
  AMORTIZATION_PLAN = "AMORTIZATION_PLAN",
  PETITION_FOR_DIVORCE = "PETITION_FOR_DIVORCE",
  CERTIFICATE_OF_EMPLOYMENT = "CERTIFICATE_OF_EMPLOYMENT",
  DIVISION_OF_PROPERTY_AGREEMENT = "DIVISION_OF_PROPERTY_AGREEMENT",
  VERIFY_ESCROW_AGREEMENT = "VERIFY_ESCROW_AGREEMENT",
  FINANCIAL_FORECASTING_FOR_TENANT_OWNERSHIP_ASSOCIATION = "FINANCIAL_FORECASTING_FOR_TENANT_OWNERSHIP_ASSOCIATION",
  POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
  PHYSICAL_MORTGAGE_DEEDS = "PHYSICAL_MORTGAGE_DEEDS",
  INTERMEDIATION_AGREEMENT_INCLUDING_STARTING_PRICE = "INTERMEDIATION_AGREEMENT_INCLUDING_STARTING_PRICE",
  DEED_OF_GIFT = "DEED_OF_GIFT",
  PURCHASE_AGREEMENT = "PURCHASE_AGREEMENT",
  CONTRACTORS_AGREEMENT = "CONTRACTORS_AGREEMENT",
  APARTMENT_CERTIFICATE = "APARTMENT_CERTIFICATE",
  COLLATERAL_OBJECT_DESCRIPTION = "COLLATERAL_OBJECT_DESCRIPTION",
  PENSION_PROJECTION = "PENSION_PROJECTION",
  PENSION_SPECIFICATION = "PENSION_SPECIFICATION",
  AUDITORS_CERTIFICATE = "AUDITORS_CERTIFICATE",
  LATESTAPPROVEDINCOMESTATEMENTANDNE3APPENDIX = "LATEST_APPROVED_INCOME_STATEMENT_AND_NE3_APPENDIX",
  LATEST_APPROVED_INCOME_STATEMENT_AND_NE_APPENDIX = "LATEST_APPROVED_INCOME_STATEMENT_AND_NE_APPENDIX",
  FINAL_INVOICE = "FINAL_INVOICE",
  SALES_AGREEMENT = "SALES_AGREEMENT",
  INFORMATION_TO_CONTACT_PERSON = "INFORMATION_TO_CONTACT_PERSON",
  TENURE_AGREEMENT = "TENURE_AGREEMENT",
  VALUATION = "VALUATION",
  VALUATION_OF_COMPLETED_PROPERTY = "VALUATION_OF_COMPLETED_PROPERTY",
  VALUATION_FROM_INDEPENDENT_BROKER = "VALUATION_FROM_INDEPENDENT_BROKER",
  TRANSFER_AGREEMENT = "TRANSFER_AGREEMENT",
  VALUATION_CERTIFICATE = "VALUATION_CERTIFICATE",
  CASH_DEPOSIT_DOCUMENTATION = "CASH_DEPOSIT_DOCUMENTATION",
  PRELIMINARY_SETTLEMENT_NOTE = "PRELIMINARY_SETTLEMENT_NOTE",
  ORIGINAL_BILL_OF_PURCHASE = "ORIGINAL_BILL_OF_PURCHASE",
  TITLE_DEED = "TITLE_DEED",
  FINAL_SERVEYING_PROTOCOL = "FINAL_SERVEYING_PROTOCOL",
  FINAL_CLEARANCE = "FINAL_CLEARANCE",
  INVOICES = "INVOICES",
  STATEMENT_OF_ACCOUNT = "STATEMENT_OF_ACCOUNT",
  PAYSLIP = "PAYSLIP",
  ANNUAL_REPORT_HOUSING_SOCIETY = "ANNUAL_REPORT_HOUSING_SOCIETY",
  ANNUAL_REPORT_SELF_EMPLOYED = "ANNUAL_REPORT_SELF_EMPLOYED",
  SPOUSE_CONSENT = "SPOUSE_CONSENT",
  OTHER_INCOME_CERTIFICATES = "OTHER_INCOME_CERTIFICATES",
  ESTATE_INVENTORY = "ESTATE_INVENTORY",
  LOAN_TERMS_OPTIONS = "LOAN_TERMS_OPTIONS",
  INSTRUMENT_OF_DEBT = "INSTRUMENT_OF_DEBT",
  KNOW_YOUR_CUSTOMER_QUESTIONS = "KNOW_YOUR_CUSTOMER_QUESTIONS",
  COPY_OF_IDENTIFICATION_CARD = "COPY_OF_IDENTIFICATION_CARD",
  INCOME_TAX_RETURN = "INCOME_TAX_RETURN",
  ENERGY_DECLARATION = "ENERGY_DECLARATION",
  OTHER = "OTHER",
}

/** DuplicateFormPreferencePayload */
export interface DuplicateFormPreferencePayload {
  /** Form Id */
  form_id: string;
  product: Product;
  country: Country;
  /** Copy From Preference Id */
  copy_from_preference_id: string;
}

/** EditUser */
export interface EditUser {
  /** First Name */
  first_name: string;
  /** Last Name */
  last_name: string;
  /**
   * Username
   * @format email
   */
  username: string;
  /** Banks */
  banks: IntegrationId[];
}

/** EducationType */
export enum EducationType {
  ElementarySchool = "elementarySchool",
  HighSchool = "highSchool",
  Value1YearUniversity = "1yearUniversity",
  Value2YearsUniversity = "2yearsUniversity",
  Value3YearsUniversity = "3yearsUniversity",
  MoreThan3YearsUniversity = "moreThan3yearsUniversity",
}

/** Elk46CallResponse */
export interface Elk46CallResponse {
  /** Connect */
  connect: string;
}

/** Email */
export interface Email {
  country?: Country | null;
  /**
   * Email
   * @format email
   */
  email: string;
}

/** EmailGetCustomerPayload */
export interface EmailGetCustomerPayload {
  /** Reason */
  reason: string;
  /**
   * Email
   * @format email
   */
  email: string;
}

/** EmailTemplate */
export interface EmailTemplate {
  event: TemplateEvent;
  /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
  product: ExtendedProduct;
  country: Country;
  /** @default "EMAIL" */
  message_type?: MessageType;
  /**
   * Timestamp
   * @format date-time
   * @default "2024-11-19T09:51:36.708620Z"
   */
  timestamp?: string;
  /** Comment */
  comment?: string | null;
  /**
   * Deleted
   * @default false
   */
  deleted?: boolean | null;
  /** Name */
  name?: string | null;
  /** Mandrill Name */
  mandrill_name: string;
  /** Subject */
  subject: string;
  /**
   * Use Comment Subject
   * @default false
   */
  use_comment_subject?: boolean | null;
  /** From Name */
  from_name?: string | null;
  /** From Email */
  from_email?: string | null;
  /** Reply To */
  reply_to?: string | null;
  /** Attachment Ids */
  attachment_ids?: string[] | null;
}

/** Employee */
export interface Employee {
  /** Email */
  email?: string | null;
  /** First Name */
  first_name?: string | null;
  /** Last Name */
  last_name?: string | null;
  /** Zoordinates */
  zoordinates: Zoordinates[];
  /** Phone Number */
  phone_number?: string | null;
  /** Telavox Ids */
  telavox_ids?: string[] | null;
  /** Sverker Usernames */
  sverker_usernames?: SverkerUsername[] | null;
  /** Roles */
  roles: RoleType[];
  /** User Id */
  user_id: string;
  /** Avatar */
  avatar: string;
  /**
   * Deactivated
   * @default false
   */
  deactivated?: boolean;
  /** Last Login */
  last_login?: string | null;
  /** Created At */
  created_at?: string | null;
}

/** EmployerNO */
export interface EmployerNO {
  /** Name */
  name: string;
  type: EmploymentTypeNO;
  /**
   * Start Date
   * @format date
   */
  start_date: string;
  /** End Date */
  end_date?: string | null;
  /** Phone */
  phone?: string | null;
  /** Retirement Age */
  retirement_age?: number | null;
  /** Organization Number */
  organization_number?: string | null;
  /** Profession */
  profession?: string | null;
}

/** EmployerSE */
export interface EmployerSE {
  /** Name */
  name: string;
  type: EmploymentTypeSE;
  /**
   * Start Date
   * @format date
   */
  start_date: string;
  /** End Date */
  end_date?: string | null;
  /** Phone */
  phone?: string | null;
  /** Retirement Age */
  retirement_age?: number | null;
  /** Organization Number */
  organization_number?: string | null;
  /** Profession */
  profession?: string | null;
}

/** EmploymentType */
export enum EmploymentType {
  Full = "full",
  Trial = "trial",
  Retired = "retired",
  Hourly = "hourly",
  EarlyRetired = "earlyRetired",
  Project = "project",
  SelfEmployed = "selfEmployed",
  CompanyOwner = "companyOwner",
  NoWork = "noWork",
  TemporarilyDischarged = "temporarilyDischarged",
  Student = "student",
  StandIn = "standIn",
  NorwegianSickLeave = "norwegianSickLeave",
  Disabled = "disabled",
  Other = "other",
}

/** EmploymentTypeBase */
export type EmploymentTypeBase = any;

/** EmploymentTypeNO */
export enum EmploymentTypeNO {
  Full = "full",
  SelfEmployed = "selfEmployed",
  Retired = "retired",
  TemporarilyDischarged = "temporarilyDischarged",
  Other = "other",
  Disabled = "disabled",
  Trial = "trial",
  Hourly = "hourly",
  Student = "student",
  NoWork = "noWork",
  StandIn = "standIn",
  Project = "project",
  NorwegianSickLeave = "norwegianSickLeave",
}

/** EmploymentTypeSE */
export enum EmploymentTypeSE {
  Full = "full",
  Trial = "trial",
  Retired = "retired",
  Hourly = "hourly",
  EarlyRetired = "earlyRetired",
  Project = "project",
  SelfEmployed = "selfEmployed",
  CompanyOwner = "companyOwner",
  NoWork = "noWork",
}

/** EmptyModel */
export type EmptyModel = object;

/** EnqueuedJobStatus */
export enum EnqueuedJobStatus {
  Success = "success",
  Failure = "failure",
  Pending = "pending",
  InProgress = "in_progress",
}

/** EnqueuedSubmissionJobResult */
export interface EnqueuedSubmissionJobResult {
  /** Submission Id */
  submission_id: string;
  status: EnqueuedJobStatus;
  checklist?: Checklist | null;
}

/** EnumDescription */
export interface EnumDescription {
  /**
   * Value
   * The value of the enum
   */
  value: string | number;
  /**
   * En Label
   * The English label of the enum
   */
  en_label: string;
  /**
   * Sv Label
   * The Swedish label of the enum
   */
  sv_label: string;
  /**
   * Nb Label
   * The Norwegian label of the enum
   */
  nb_label: string;
}

/** EstateTypeType */
export interface EstateTypeType {
  /** Description */
  Description?: string | null;
  /** Key */
  Key?: string | null;
}

/** EvCheckPayload */
export interface EvCheckPayload {
  /** Pnis */
  pnis: string[];
  /** Reason */
  reason: string;
}

/** EvEstate */
export interface EvEstate {
  /** Municipality */
  municipality?: string | null;
  /** Municipality Number */
  municipality_number?: number | null;
  /** Cadastralunitnumber */
  cadastralUnitNumber?: number | null;
  /** Unitnumber */
  unitNumber?: number | null;
  /** Leaseholdunitnumber */
  leaseholdUnitNumber?: number | null;
  /** Sectionnumber */
  sectionNumber?: number | null;
  /** Apartmentreference */
  apartmentReference?: string | null;
  /** Organizationnumber */
  organizationNumber?: string | null;
  /** Estimatedvalue */
  estimatedValue?: number | null;
  /** Commondebt */
  commonDebt?: number | null;
  /** Estatetype */
  estateType?: string | null;
  address?: EvEstateAddress | null;
}

/** EvEstateAddress */
export interface EvEstateAddress {
  /** Streetname */
  streetName?: string | null;
  /** Streetnumber */
  streetNumber?: number | null;
  /** Streetletter */
  streetLetter?: string | null;
  /** Postcodearea */
  postCodeArea?: string | null;
  /** Postcode */
  postCode?: string | null;
}

/** EvPartialCommon */
export interface EvPartialCommon {
  /** Area Market Score */
  area_market_score?: number | null;
  /** Municipality Score */
  municipality_score?: number | null;
  /** Net Value Estimate */
  net_value_estimate?: number | null;
  /** Last Known Common Debt */
  last_known_common_debt?: number | null;
  /** Cadastral Unit Number */
  cadastral_unit_number?: number | null;
  /** Unit Number */
  unit_number?: number | null;
  /** Leasehold Unit Number */
  leasehold_unit_number?: number | null;
  /** Section Number */
  section_number?: number | null;
}

/** EvProperty */
export interface EvProperty {
  info: Info;
  /** Mortgages */
  mortgages?: Mortgage[] | null;
  value?: Value | null;
}

/** EventData */
export interface EventData {
  trigger?: CancellationTrigger | null;
  /** Mailid */
  mailId?: string | null;
  /** Rejectreason */
  rejectReason?: string | null;
  /** Successful */
  successful?: boolean | null;
  /** Signupurl */
  signupUrl?: string | null;
  /** Amount */
  amount?: number | null;
  /**
   * Winbacked
   * If the insurance has been winbacked
   */
  winbacked?: boolean | null;
  /**
   * Response
   * If the customer responded for a winbackable insurance
   */
  response?: boolean | null;
  /**
   * Comment
   * The comment for the winbackable insurance
   */
  comment?: string | null;
  /** Newmandateurl */
  newMandateUrl?: string | null;
  /** Handled */
  handled?: boolean | null;
  [key: string]: any;
}

/** EventsResponse */
export interface EventsResponse {
  /** Integrations */
  integrations: BankCommunication[];
}

/** ExclusionRules */
export interface ExclusionRules {
  /**
   * Global Extraction Policy Days
   * days to exclude lead since last extraction
   * @default 30
   */
  global_extraction_policy_days?: number | null;
  /**
   * Local Extraction Policy Days
   * days to exclude lead for same campaign extraction
   * @default 30
   */
  local_extraction_policy_days?: number | null;
  /**
   * Global Contact Policy Days
   * days since customer was contacted in any campaign
   * @default 90
   */
  global_contact_policy_days?: number | null;
  /**
   * Local Contact Policy Days
   * days since customer was contacted in same campaign
   * @default 90
   */
  local_contact_policy_days?: number | null;
  /**
   * Local Contact Policy Hours
   * hours since customer was contacted in same campaign
   */
  local_contact_policy_hours?: number | null;
  /**
   * Global Contact Policy Hours
   * hours since customer was contacted in any campaign
   */
  global_contact_policy_hours?: number | null;
  /** Exclude Blocked Numbers */
  exclude_blocked_numbers?: boolean | null;
  /** Exclude Blocked Customers */
  exclude_blocked_customers?: boolean | null;
  /** Exclude Blocked Pnis */
  exclude_blocked_pnis?: boolean | null;
  reservation_register_policy?: PolicyPreference | null;
  marketing_consent_policy?: PolicyPreference | null;
}

/**
 * ExtendedProduct
 * Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed
 */
export enum ExtendedProduct {
  Blanco = "blanco",
  Mortgage = "mortgage",
  CreditCard = "credit_card",
  LoanInsurance = "loan_insurance",
}

/** ExternalBlockCheckNO */
export interface ExternalBlockCheckNO {
  /** Email */
  email?: boolean | null;
  /** Phone */
  phone?: boolean | null;
  /** Humanitarian */
  humanitarian?: boolean | null;
}

/** ExternalProperty */
export interface ExternalProperty {
  /** Estimatedvalue */
  estimatedValue?: number | null;
  /** Marketvalueuc */
  marketValueUC?: number | null;
  /** Marketvaluebooli */
  marketValueBooli?: number | null;
  propertyType?: AppsmgrMortgageSeSpecCommonDefinitionsPropertyType | null;
  /**
   * Otherowner
   * If applicant is the only owner of the property. Required when propertyType is CONDOMINIUM and applicant is applying alone but has marital status MARRIED or REGISTERED_PARTNERSHIP
   */
  otherOwner?: boolean | null;
  /**
   * Housecadastraldesignation
   * Cadastral Designation. Only for propertyType HOUSE and VACATION_HOME, otherwise null
   */
  houseCadastralDesignation?: string | null;
  /** Housingmonthlycost */
  housingMonthlyCost?: number | null;
  /** Squaremeters */
  squareMeters?: number | null;
  /** Rooms */
  rooms?: number | null;
  /**
   * Newproduction
   * If the property is a new production
   */
  newProduction?: boolean | null;
  /**
   * Firstowner
   * If the applicant is the first owner of new production. Required if 'newProduction' is true.
   */
  firstOwner?: boolean | null;
  address: OptionalPropertyAddress;
  condominium?: CondominiumItem | null;
  /** External Id */
  external_id?: string | null;
}

/** ExternallyVerifiedCredit */
export interface ExternallyVerifiedCredit {
  /** Balance */
  balance?: number | null;
  /** Credittype */
  creditType?: string | null;
  /** Creditlimit */
  creditLimit?: number | null;
  /** @default "NOK" */
  currency?: Currency | null;
  /** Dataold */
  dataOld?: boolean | null;
  /** Financialinstitutionid */
  financialInstitutionId?: string | null;
  /** Financialinstitutionname */
  financialInstitutionName?: string | null;
  /** Gjeldsregisteret Type */
  gjeldsregisteret_type?: string | null;
  /** Hascoborrower */
  hasCoBorrower?: boolean | null;
  /** Installmentchargeperiod */
  installmentChargePeriod?: string | null;
  /** Installmentcharges */
  installmentCharges?: number | null;
  /** Noninterestbearingbalance */
  nonInterestBearingBalance?: number | null;
  /** Interestbearingbalance */
  interestBearingBalance?: number | null;
  /** Iscoborrower */
  isCoBorrower?: boolean | null;
  /** Nominalinterestrate */
  nominalInterestRate?: number | null;
  /** Originalbalance */
  originalBalance?: number | null;
  /** Processedtime */
  processedTime?: string | null;
  /** Receivedtime */
  receivedTime?: string | null;
  /** Terms */
  terms?: number | null;
}

/** ExternallyVerifiedCreditWithRole */
export interface ExternallyVerifiedCreditWithRole {
  /** Balance */
  balance?: number | null;
  /** Credittype */
  creditType?: string | null;
  /** Creditlimit */
  creditLimit?: number | null;
  /** @default "NOK" */
  currency?: Currency | null;
  /** Dataold */
  dataOld?: boolean | null;
  /** Financialinstitutionid */
  financialInstitutionId?: string | null;
  /** Financialinstitutionname */
  financialInstitutionName?: string | null;
  /** Gjeldsregisteret Type */
  gjeldsregisteret_type?: string | null;
  /** Hascoborrower */
  hasCoBorrower?: boolean | null;
  /** Installmentchargeperiod */
  installmentChargePeriod?: string | null;
  /** Installmentcharges */
  installmentCharges?: number | null;
  /** Noninterestbearingbalance */
  nonInterestBearingBalance?: number | null;
  /** Interestbearingbalance */
  interestBearingBalance?: number | null;
  /** Iscoborrower */
  isCoBorrower?: boolean | null;
  /** Nominalinterestrate */
  nominalInterestRate?: number | null;
  /** Originalbalance */
  originalBalance?: number | null;
  /** Processedtime */
  processedTime?: string | null;
  /** Receivedtime */
  receivedTime?: string | null;
  /** Terms */
  terms?: number | null;
  role: ApplicantRole;
}

/** FailedVariable */
export interface FailedVariable {
  /** Name */
  name: string;
  /** Amount */
  amount: number;
}

/** FailureOutcomes */
export enum FailureOutcomes {
  IrrelevantLoans = "irrelevant_loans",
  OnlyMortgage = "only_mortgage",
  DebtFree = "debt_free",
  Click = "click",
  DoesNotWantToListen = "does_not_want_to_listen",
  WrongNumber = "wrong_number",
  NotInterested = "not_interested",
  LanguageBarrier = "language_barrier",
  FutureCallback = "future_callback",
  NotQualified = "not_qualified",
  Unemployed = "unemployed",
  CreditCheck = "credit_check",
  DebtRestructuring = "debt_restructuring",
  SwedishEnforcementAuthority = "swedish_enforcement_authority",
  PaymentRemarks = "payment_remarks",
  Deceased = "deceased",
  DoNotCall = "do_not_call",
}

/** FieldMapping */
export interface FieldMapping {
  /** Lead Field */
  lead_field: string;
  /** Jotform Field */
  jotform_field: string;
  /** Options Map */
  options_map?: any[][] | null;
}

/** FieldMappingExt */
export interface FieldMappingExt {
  /** Lead Field */
  lead_field: string;
  /** Jotform Field */
  jotform_field: string;
  /** Options Map */
  options_map?: any[][] | null;
  /** Error */
  error?: string | null;
}

/** Filter */
export interface Filter {
  product?: ExtendedProduct | null;
  country?: Country | null;
  department?: ZDepartment | null;
  /** Active */
  active?: boolean | null;
  /** Creator Id */
  creator_id?: string | null;
  /** Campaign Ids */
  campaign_ids?: string[] | null;
  /** Extraction Id */
  extraction_id?: string | null;
  /** Exported Extraction Id */
  exported_extraction_id?: string | null;
  /** Reservation Register Policy */
  reservation_register_policy?: PolicyPreference[] | null;
  campaign_type?: CampaignType | null;
  /** Campaign Types */
  campaign_types?: CampaignType[] | null;
  contact_type?: ContactType | null;
  /** Process */
  process?: ProcessCampaignType[] | null;
}

/** FilteringReason */
export enum FilteringReason {
  BLOCK_LIST = "BLOCK_LIST",
  ALREADY_CONSUMED = "ALREADY_CONSUMED",
  EXTRACTION_LIMIT = "EXTRACTION_LIMIT",
}

/** FilteringResult */
export interface FilteringResult {
  /** Reason */
  reason: string;
  /** Number Of Leads Before Filtering */
  number_of_leads_before_filtering: number;
  /** Number Of Leads After Filtering */
  number_of_leads_after_filtering: number;
  filtering_reason: FilteringReason;
}

/** Form */
export interface Form {
  /** Name */
  name: string;
  /** Form Id */
  form_id: string;
  /** Preferences */
  preferences: string[];
}

/** FormApplicationSubmissionResult */
export interface FormApplicationSubmissionResult {
  /** Ok */
  ok: boolean;
  /** Submission Id */
  submission_id: string;
}

/** FormCredit */
export interface FormCredit {
  /**
   * Amount
   * @exclusiveMin 0
   */
  amount: number;
  /**
   * Bankname
   * Bank ID
   */
  bankName: number;
  /**
   * Loantype
   * Loan type ID
   */
  loanType: number;
  /**
   * Monthlycost
   * @min 0
   */
  monthlyCost: number;
  /** Rate */
  rate?: number | null;
  /** Owner */
  owner?: string | null;
}

/** FormMapping */
export interface FormMapping {
  /** Lead Fields */
  lead_fields: LeadField[];
  /** Jotform Fields */
  jotform_fields: JotformField[];
  /** Mapping */
  mapping: FieldMappingExt[];
}

/** FormPreference */
export interface FormPreference {
  /** Form Preference Id */
  form_preference_id: string;
  /** Jotform Id */
  jotform_id: string;
  product: Product;
  country: Country;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** Deactivation Date */
  deactivation_date?: string | null;
  /**
   * Mapping
   * @default []
   */
  mapping?: FieldMapping[];
}

/** FormPreferenceExt */
export interface FormPreferenceExt {
  /** Form Preference Id */
  form_preference_id: string;
  /** Jotform Id */
  jotform_id: string;
  product: Product;
  country: Country;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** Deactivation Date */
  deactivation_date?: string | null;
  /**
   * Mapping
   * @default []
   */
  mapping?: FieldMappingExt[];
  /**
   * Valid
   * @default false
   */
  valid?: boolean;
  /** Unmapped */
  unmapped?: string[] | null;
  /**
   * Form Name
   * @default ""
   */
  form_name?: string;
  /**
   * Has Webhook
   * @default false
   */
  has_webhook?: boolean;
}

/** FormPreferencePayload */
export interface FormPreferencePayload {
  /** Form Id */
  form_id: string;
  product: Product;
  country: Country;
  /** Mapping */
  mapping: FieldMapping[];
}

/** FormWebhookPayload */
export interface FormWebhookPayload {
  /** Preference Id */
  preference_id: string;
}

/** Gender */
export enum Gender {
  Male = "male",
  Female = "female",
  Other = "other",
}

/** GeneralMessage */
export interface GeneralMessage {
  /** Type */
  type: "GENERAL_MESSAGE_RECEIVED";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  status: NotificationEvent;
  data: GeneralMessageData;
  /** Notification Id */
  notification_id: string;
  /** User Id */
  user_id: string | null;
}

/** GeneralMessageData */
export interface GeneralMessageData {
  /** Message */
  message: string;
}

/** GetApplicationMessagesPayload */
export interface GetApplicationMessagesPayload {
  /**
   * Limit
   * @exclusiveMin 0
   * @exclusiveMax 101
   * @default 50
   */
  limit?: number;
  /**
   * Page
   * @min 0
   * @default 0
   */
  page?: number;
  /**
   * Application Id
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id?: string | null;
}

/** GetApplicationPayload */
export interface GetApplicationPayload {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
}

/** GetApplications */
export interface GetApplications {
  /**
   * Page
   * @min 0
   */
  page: number;
  /**
   * Limit
   * @min 0
   */
  limit: number;
  product?: Product | null;
  country?: Country | null;
  /** Bank Statuses */
  bank_statuses?: BankStatus[] | null;
  /** Loan Purposes */
  loan_purposes?: MortgageLoanType[] | null;
}

/** GetApplicationsByApplicantIdentifierPayload */
export interface GetApplicationsByApplicantIdentifierPayload {
  /**
   * Text
   * @default ""
   */
  text?: string;
}

/** GetApplicationsByCustomerIdentifierPayload */
export interface GetApplicationsByCustomerIdentifierPayload {
  country?: Country | null;
  product?: Product | null;
  /** Pni */
  pni?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Email */
  email?: string | null;
  pagination?: Pagination | null;
}

/** GetApplicationsByCustomerIdentifierPayloadV2 */
export interface GetApplicationsByCustomerIdentifierPayloadV2 {
  country?: Country | null;
  product?: Product | null;
  /** Customer Id */
  customer_id: string;
  /** Phone Number */
  phone_number?: string | null;
  /** Email */
  email?: string | null;
  pagination?: Pagination | null;
}

/** GetBanksPayload */
export interface GetBanksPayload {
  /** Bank Id */
  bank_id?: number | null;
  country?: Country | null;
}

/** GetLeads */
export interface GetLeads {
  /**
   * Page
   * @default 0
   */
  page?: number | null;
  /**
   * Limit
   * @default 25
   */
  limit?: number | null;
  product: Product;
  country: Country;
  /**
   * Include Handled
   * @default false
   */
  include_handled?: boolean | null;
  /**
   * Include Failed
   * @default false
   */
  include_failed?: boolean | null;
  /**
   * Unassigned Only
   * @default false
   */
  unassigned_only?: boolean | null;
}

/** GetMeetings */
export interface GetMeetings {
  /** Gt */
  gt?: string | null;
  /** Lt */
  lt?: string | null;
  /** Page */
  page?: number | null;
  /** Limit */
  limit?: number | null;
  product?: Product | null;
  country?: Country | null;
  /**
   * With Apps
   * If true, will return apps and leads
   * @default false
   */
  with_apps?: boolean | null;
  /**
   * With Customer Name
   * If true, will return customer name
   * @default false
   */
  with_customer_name?: boolean | null;
  /**
   * Application Id
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id?: string | null;
  /** Application Ids */
  application_ids?: string[] | null;
  /** Handler Id */
  handler_id?: string | null;
  /** Customer Id */
  customer_id?: string | null;
  /** With Handler Name */
  with_handler_name?: boolean | null;
}

/** GetMessages */
export interface GetMessages {
  /** Customer Id */
  customer_id?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Pni */
  pni?: string | null;
}

/** GetMessagesPayload */
export interface GetMessagesPayload {
  /**
   * Limit
   * @exclusiveMin 0
   * @exclusiveMax 101
   * @default 50
   */
  limit?: number;
  /**
   * Page
   * @min 0
   * @default 0
   */
  page?: number;
}

/** GetMyApplicationsPayload */
export interface GetMyApplicationsPayload {
  country?: Country | null;
  product?: Product | null;
  /** Statuses */
  statuses?: AppsmgrModelsStatusStatus[] | null;
  /** Substatuses */
  substatuses?: Substatus[] | null;
  /** Loan Purposes */
  loan_purposes?: MortgageLoanType[] | null;
  /**
   * Unassigned Only
   * @default false
   */
  unassigned_only?: boolean | null;
}

/** GetMyLeads */
export interface GetMyLeads {
  /**
   * Page
   * @default 0
   */
  page?: number | null;
  /**
   * Limit
   * @default 25
   */
  limit?: number | null;
  product: Product;
  country: Country;
  /**
   * Include Handled
   * @default false
   */
  include_handled?: boolean | null;
}

/** GetMyMeetings */
export interface GetMyMeetings {
  /** Gt */
  gt?: string | null;
  /** Lt */
  lt?: string | null;
  /** Page */
  page?: number | null;
  /** Limit */
  limit?: number | null;
  product?: Product | null;
  country?: Country | null;
  /**
   * With Apps
   * If true, will return apps and leads
   * @default false
   */
  with_apps?: boolean | null;
  /**
   * With Customer Name
   * If true, will return customer name
   * @default false
   */
  with_customer_name?: boolean | null;
}

/** GetNameByPNIPayload */
export interface GetNameByPNIPayload {
  /** Pni */
  pni: string;
}

/** GetRecentApplicationsPayload */
export interface GetRecentApplicationsPayload {
  country?: Country | null;
  product?: Product | null;
  /** Statuses */
  statuses?: AppsmgrModelsStatusStatus[] | null;
  /** Substatuses */
  substatuses?: Substatus[] | null;
  /** Loan Purposes */
  loan_purposes?: MortgageLoanType[] | null;
  /**
   * Unassigned Only
   * @default false
   */
  unassigned_only?: boolean | null;
}

/** GetRecentPayload */
export interface GetRecentPayload {
  product: Product;
  country: Country;
  state?: AppState | null;
}

/** GetTaxPayload */
export interface GetTaxPayload {
  /** Monthly Salary */
  monthly_salary: number;
  /** Key */
  key: string;
  country: Country;
}

/** GetTaxPayloadResponse */
export interface GetTaxPayloadResponse {
  /** Monthly Tax */
  monthly_tax: number;
}

/** GetTemplatePayload */
export interface GetTemplatePayload {
  message_type: MessageType;
  template_event: TemplateEvent;
  country: Country;
  /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
  product: ExtendedProduct;
}

/** GetUserMessagesPayload */
export interface GetUserMessagesPayload {
  /**
   * Limit
   * @exclusiveMin 0
   * @exclusiveMax 101
   * @default 50
   */
  limit?: number;
  /**
   * Page
   * @min 0
   * @default 0
   */
  page?: number;
  /** Customer Id */
  customer_id: string;
}

/** GetUsersPayload */
export interface GetUsersPayload {
  /**
   * Limit
   * @default 50
   */
  limit?: number | null;
  /**
   * Page
   * @default 0
   */
  page?: number | null;
  /** Bank Id */
  bank_id?: number | null;
  /**
   * Without Deactivated
   * @default false
   */
  without_deactivated?: boolean | null;
}

/** GoalPayload */
export interface GoalPayload {
  /**
   * Year
   * What year the goal is set for
   */
  year: number;
  /**
   * Month
   * Month from 1-12
   */
  month: number;
  /** User Ids */
  user_ids?: string[] | null;
}

/** GoalType */
export enum GoalType {
  BLANCO = "BLANCO",
  MORTGAGE = "MORTGAGE",
  INITIAL_BLANCO = "INITIAL_BLANCO",
  BLANCO_MB = "BLANCO_MB",
  BUDGET_HL = "BUDGET_HL",
  BUDGET_KA = "BUDGET_KA",
  BUDGET_SK = "BUDGET_SK",
  BUDGET_SP = "BUDGET_SP",
}

/**
 * HTTPStatus
 * HTTP status codes and reason phrases
 *
 * Status codes from the following RFCs are all observed:
 *
 *     * RFC 7231: Hypertext Transfer Protocol (HTTP/1.1), obsoletes 2616
 *     * RFC 6585: Additional HTTP Status Codes
 *     * RFC 3229: Delta encoding in HTTP
 *     * RFC 4918: HTTP Extensions for WebDAV, obsoletes 2518
 *     * RFC 5842: Binding Extensions to WebDAV
 *     * RFC 7238: Permanent Redirect
 *     * RFC 2295: Transparent Content Negotiation in HTTP
 *     * RFC 2774: An HTTP Extension Framework
 *     * RFC 7725: An HTTP Status Code to Report Legal Obstacles
 *     * RFC 7540: Hypertext Transfer Protocol Version 2 (HTTP/2)
 *     * RFC 2324: Hyper Text Coffee Pot Control Protocol (HTCPCP/1.0)
 *     * RFC 8297: An HTTP Status Code for Indicating Hints
 *     * RFC 8470: Using Early Data in HTTP
 */
export enum HTTPStatus {
  Value100 = 100,
  Value101 = 101,
  Value102 = 102,
  Value103 = 103,
  Value200 = 200,
  Value201 = 201,
  Value202 = 202,
  Value203 = 203,
  Value204 = 204,
  Value205 = 205,
  Value206 = 206,
  Value207 = 207,
  Value208 = 208,
  Value226 = 226,
  Value300 = 300,
  Value301 = 301,
  Value302 = 302,
  Value303 = 303,
  Value304 = 304,
  Value305 = 305,
  Value307 = 307,
  Value308 = 308,
  Value400 = 400,
  Value401 = 401,
  Value402 = 402,
  Value403 = 403,
  Value404 = 404,
  Value405 = 405,
  Value406 = 406,
  Value407 = 407,
  Value408 = 408,
  Value409 = 409,
  Value410 = 410,
  Value411 = 411,
  Value412 = 412,
  Value413 = 413,
  Value414 = 414,
  Value415 = 415,
  Value416 = 416,
  Value417 = 417,
  Value418 = 418,
  Value421 = 421,
  Value422 = 422,
  Value423 = 423,
  Value424 = 424,
  Value425 = 425,
  Value426 = 426,
  Value428 = 428,
  Value429 = 429,
  Value431 = 431,
  Value451 = 451,
  Value500 = 500,
  Value501 = 501,
  Value502 = 502,
  Value503 = 503,
  Value504 = 504,
  Value505 = 505,
  Value506 = 506,
  Value507 = 507,
  Value508 = 508,
  Value510 = 510,
  Value511 = 511,
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** HandledSubmission */
export interface HandledSubmission {
  /** Submission Id */
  submission_id: string;
  /** Handled */
  handled?: boolean | null;
  /**
   * Reviews
   * @default []
   */
  reviews?: Reviewer[];
}

/** HeimdallCounterPair */
export interface HeimdallCounterPair {
  /** Allowed Count */
  allowed_count: number;
  /** Current Count */
  current_count: number;
}

/** HeimdallReportingPayload */
export interface HeimdallReportingPayload {
  tracking_type: HeimdallTrackingType;
  /** Ids */
  ids: string[];
}

/** HeimdallReportingResponse */
export interface HeimdallReportingResponse {
  /** Rate Limit Exceeded */
  rate_limit_exceeded: boolean;
}

/** HeimdallResponse */
export interface HeimdallResponse {
  /** User Id */
  user_id: string;
  /** Date */
  date: string;
  apps: HeimdallCounterPair;
  leads: HeimdallCounterPair;
  customers: HeimdallCounterPair;
  insurances: HeimdallCounterPair;
}

/** HeimdallTrackingType */
export enum HeimdallTrackingType {
  Apps = "apps",
  Customers = "customers",
  Insurances = "insurances",
  Leads = "leads",
}

/** House */
export interface House {
  /** Property Id */
  property_id: string;
  /** Value */
  value?: number | null;
  /** Booli Value */
  booli_value?: number | null;
  /** External Id */
  external_id?: string | null;
  /** Property Type */
  property_type: "HOUSE";
  country: Country;
  /** Street Address */
  street_address?: string | null;
  /** Post Code */
  post_code?: string | null;
  /** City */
  city?: string | null;
  /** Other Owner */
  other_owner?: boolean | null;
  /** Monthly Cost */
  monthly_cost?: number | null;
  /** Square Meter Living Area */
  square_meter_living_area?: number | null;
  /** Municipality */
  municipality?: string | null;
  /** Municipality Code */
  municipality_code?: string | null;
  /** Estimated Value */
  estimated_value?: number | null;
  /** Latitude */
  latitude?: number | null;
  /** Longitude */
  longitude?: number | null;
  ev_partial?: EvPartialCommon | null;
  /** Cadastral Designation */
  cadastral_designation?: string | null;
  [key: string]: any;
}

/** HousingCooperatativeShareIdentificationType */
export interface HousingCooperatativeShareIdentificationType {
  /** Organizationnumber */
  OrganizationNumber?: string | null;
  /** Sharenumber */
  ShareNumber?: string | null;
}

/** HousingTypeNO */
export enum HousingTypeNO {
  RENTED = "RENTED",
  OWN_HOUSE = "OWN_HOUSE",
  PARENTS = "PARENTS",
  CONDOMINIUM = "CONDOMINIUM",
  OWN_APARTMENT = "OWN_APARTMENT",
}

/** HousingTypeSE */
export enum HousingTypeSE {
  RENTED = "RENTED",
  CONDOMINIUM = "CONDOMINIUM",
  LIVE_IN = "LIVE_IN",
  OWN_HOUSE = "OWN_HOUSE",
}

/** IDCheckGetCustomerPayload */
export interface IDCheckGetCustomerPayload {
  /** Reason */
  reason: string;
  country?: Country | null;
  /** Pni */
  pni: string;
}

/** IDCheckedPerson */
export interface IDCheckedPerson {
  country: Country;
  /** Pni */
  pni: string;
  /** First Name */
  first_name?: string | null;
  /** Last Name */
  last_name?: string | null;
  /** Street Address */
  street_address?: string | null;
  /** Post Code */
  post_code?: string | null;
  /** City */
  city?: string | null;
  marital_status?: MaritalStatus | null;
  /** Is Blocked */
  is_blocked?: boolean | null;
  /** Age */
  age?: number | null;
  block_list?: BlockList | null;
}

/** IDKollenAuthStatusResponse */
export enum IDKollenAuthStatusResponse {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

/** IDKollenCollectResponse */
export interface IDKollenCollectResponse {
  /** Id */
  id?: string | null;
  status: IDKollenAuthStatusResponse;
  /**
   * Refid
   * @default ""
   */
  refId?: string;
  /** Ssn */
  ssn?: string | null;
  /** Givenname */
  givenName?: string | null;
  /** Surname */
  surname?: string | null;
  /** Error */
  error?: string | null;
  /** Certstartdate */
  certStartDate?: string | null;
  /** Signresult */
  signResult?: object | null;
}

/** IdentityTypeType */
export interface IdentityTypeType {
  /** Description */
  Description?: string | null;
  /** Key */
  Key?: string | null;
}

/** ImpersonateCustomerPayload */
export interface ImpersonateCustomerPayload {
  /** Pni */
  pni?: string | null;
  /** Customer Id */
  customer_id?: string | null;
}

/** ImportStatus */
export enum ImportStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

/** Income */
export interface Income {
  /**
   * Amount
   * Monthly amount
   * @min 0
   * @default 0
   */
  amount?: number;
  /**
   * Yearly Amount
   * Yearly amount
   * @min 0
   * @default 0
   */
  yearly_amount?: number;
  currency?: Currency | null;
  type: IncomeType;
  /** Name */
  name: string;
}

/** IncomeType */
export enum IncomeType {
  Rent = "rent",
  Other = "other",
  Employment = "employment",
  Partner = "partner",
}

/** IncomingInsuranceEvent */
export enum IncomingInsuranceEvent {
  MANDATE_CANCELLED = "MANDATE_CANCELLED",
}

/** IncomingSMS */
export interface IncomingSMS {
  /** Message Id */
  message_id: string;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** Sender */
  sender: string;
  /** Message */
  message: string;
  /** Customer Ids */
  customer_ids: string[];
  /** Application Id */
  application_id?: string | null;
  /** @default "SMS" */
  message_type?: MessageType;
  /** @default "INCOMING" */
  direction?: Direction;
  /** Phone Number */
  phone_number?: string | null;
}

/** Info */
export interface Info {
  Address: Address;
  Cadastre: Cadastre;
  /** Ev Addressid */
  EV_AddressID?: string | null;
  /** Ev Estateid */
  EV_EstateID?: string | null;
  HousingCooperatativeShareIdentification?: HousingCooperatativeShareIdentificationType | null;
}

/** Insurance */
export interface Insurance {
  /** Insuranceid */
  insuranceId: string;
  /** Encryptedinsuranceid */
  encryptedInsuranceId?: string | null;
  status: InsuranceStatus;
  substatus: InsuranceSubstatus;
  insuranceType: InsuranceType;
  insuranceGroup?: InsuranceGroup | null;
  mandate?: MandateStatus;
  /** Premiumamount */
  premiumAmount: number;
  /** Insuredamount */
  insuredAmount: number;
  /**
   * Customerid
   * @format uuid
   */
  customerId: string;
  /** Sellerid */
  sellerId?: string | null;
  /** Seller Name */
  seller_name?: string | null;
  /**
   * Datecreated
   * @format date-time
   */
  dateCreated: string;
  lastCharge?: LastCharge;
  /** Events */
  events?: AppsmgrInsuranceInsuranceEvent[];
  /** Signupdate */
  signupDate?: string | null;
  /**
   * Totalcharged
   * @default 0
   */
  totalCharged?: number;
  /** Targetinitalchargedate */
  targetInitalChargeDate?: string | null;
  /** Gocardlesssubscriptionid */
  goCardlessSubscriptionId?: string | null;
  /**
   * Failedconsecutivemonthlypayments
   * @default 0
   */
  failedConsecutiveMonthlyPayments?: number;
  /** Cancellationdate */
  cancellationDate?: string | null;
  currency: Currency;
  /** Cta Tags */
  cta_tags?: CTATags[];
  /**
   * Handled
   * If the insurance has been handled uin the winbackable state
   * @default false
   */
  handled?: boolean;
  /** Last Comment */
  last_comment?: string | null;
  /** Customer */
  customer?:
    | (
        | ({
            country: "NO";
          } & ApplicantNO)
        | ({
            country: "SE";
          } & ApplicantSE)
      )
    | null;
}

/**
 * InsuranceCustomer
 * Model for Loan Insurance for what a customer can see
 * missing things like events, etc
 */
export interface InsuranceCustomer {
  currency: Currency;
  /**
   * Date Created
   * @format date-time
   */
  date_created: string;
  /** Signup Date */
  signup_date?: string | null;
  /** Signup Url */
  signup_url?: string | null;
  /** Insurance Id */
  insurance_id: string;
  /** Encrypted Insurance Id */
  encrypted_insurance_id?: string | null;
  insurance_type: InsuranceType;
  /** Insured Amount */
  insured_amount: number;
  /** Premium Amount */
  premium_amount: number;
  status: InsuranceStatus;
  substatus: InsuranceSubstatus;
  last_charge?: LastCharge | null;
  mandate?: MandateStatus | null;
  insurance_group: InsuranceGroup;
}

/** InsuranceEventType */
export enum InsuranceEventType {
  INSURANCE_CREATED = "INSURANCE_CREATED",
  INSURANCE_SIGNUP_URL = "INSURANCE_SIGNUP_URL",
  INSURANCE_UPDATED = "INSURANCE_UPDATED",
  INSURANCE_CANCELLED = "INSURANCE_CANCELLED",
  INSURANCE_PENDING_CANCELLATION = "INSURANCE_PENDING_CANCELLATION",
  CUSTOMER_ACCEPTED_TERMS = "CUSTOMER_ACCEPTED_TERMS",
  CUSTOMER_REQUESTED_SIGNUP_DATA = "CUSTOMER_REQUESTED_SIGNUP_DATA",
  GO_CARDLESS_MANDATE_CREATED = "GO_CARDLESS_MANDATE_CREATED",
  GO_CARDLESS_MANDATE_SUBMITTED = "GO_CARDLESS_MANDATE_SUBMITTED",
  GO_CARDLESS_ACCOUNT_INFO = "GO_CARDLESS_ACCOUNT_INFO",
  GO_CARDLESS_MANDATE_ACQUIRED = "GO_CARDLESS_MANDATE_ACQUIRED",
  GO_CARDLESS_MANDATE_CANCELLED = "GO_CARDLESS_MANDATE_CANCELLED",
  GO_CARDLESS_SUBSCRIPTION_CREATED = "GO_CARDLESS_SUBSCRIPTION_CREATED",
  GO_CARDLESS_SUBSCRIPTION_SUBMITTED = "GO_CARDLESS_SUBSCRIPTION_SUBMITTED",
  GO_CARDLESS_SUBSCRIPTION_CANCELLED = "GO_CARDLESS_SUBSCRIPTION_CANCELLED",
  GO_CARDLESS_PAYMENT_CREATED = "GO_CARDLESS_PAYMENT_CREATED",
  GO_CARDLESS_PAYMENT_CONFIRMED = "GO_CARDLESS_PAYMENT_CONFIRMED",
  GO_CARDLESS_PAYMENT_FAILED = "GO_CARDLESS_PAYMENT_FAILED",
  GO_CARDLESS_PAYMENT_CANCELLED = "GO_CARDLESS_PAYMENT_CANCELLED",
  GO_CARDLESS_PAYMENT_RESUBMISSION_REQUESTED = "GO_CARDLESS_PAYMENT_RESUBMISSION_REQUESTED",
  GO_CARDLESS_REFUND_CREATED = "GO_CARDLESS_REFUND_CREATED",
  GO_CARDLESS_REFUND_PAID = "GO_CARDLESS_REFUND_PAID",
  TRUSTLY_INITIATED = "TRUSTLY_INITIATED",
  TRUSTLY_ACCOUNT_INFO = "TRUSTLY_ACCOUNT_INFO",
  TRUSTLY_MANDATE_ACQUIRED = "TRUSTLY_MANDATE_ACQUIRED",
  TRUSTLY_MANDATE_CANCELLED = "TRUSTLY_MANDATE_CANCELLED",
  GO_CARDLESS_MANDATE_FAILED = "GO_CARDLESS_MANDATE_FAILED",
  TRUSTLY_MANDATE_SIGNUP_CANCELLED = "TRUSTLY_MANDATE_SIGNUP_CANCELLED",
  TRUSTLY_UNKNOWN_EVENT = "TRUSTLY_UNKNOWN_EVENT",
  SCRIVE_SIGNATURE = "SCRIVE_SIGNATURE",
  TRUSTLY_CHARGE_INITIATED = "TRUSTLY_CHARGE_INITIATED",
  TRUSTLY_PENDING_CHARGE = "TRUSTLY_PENDING_CHARGE",
  TRUSTLY_ACCOUNT_CHARGED = "TRUSTLY_ACCOUNT_CHARGED",
  TRUSTLY_CHARGE_CANCELLED = "TRUSTLY_CHARGE_CANCELLED",
  TRUSTLY_CHARGE_REFUNDED = "TRUSTLY_CHARGE_REFUNDED",
  TRUSTLY_CHARGE_REFUND_FAILED = "TRUSTLY_CHARGE_REFUND_FAILED",
  TRUSTLY_REFUND_INITIATED = "TRUSTLY_REFUND_INITIATED",
  TRUSTLY_REFUND_INITIATION_FAILED = "TRUSTLY_REFUND_INITIATION_FAILED",
  TRUSTLY_CANCEL_ORDER_INITIATED = "TRUSTLY_CANCEL_ORDER_INITIATED",
  TRUSTLY_CANCEL_ORDER_FAILED = "TRUSTLY_CANCEL_ORDER_FAILED",
  INVITATION_EMAIL_SENT = "INVITATION_EMAIL_SENT",
  INVITATION_EMAIL_SENT_AGAIN = "INVITATION_EMAIL_SENT_AGAIN",
  INVITATION_EMAIL_REMINDER_SENT = "INVITATION_EMAIL_REMINDER_SENT",
  SIGNUP_CONFIRMATION_EMAIL_SENT = "SIGNUP_CONFIRMATION_EMAIL_SENT",
  SIGNUP_CONFIRMATION_EMAIL_SENT_AGAIN = "SIGNUP_CONFIRMATION_EMAIL_SENT_AGAIN",
  FAILED_TO_CHARGE_EMAIL_SENT = "FAILED_TO_CHARGE_EMAIL_SENT",
  FAILED_TO_CHARGE_EMAIL_SENT_AGAIN = "FAILED_TO_CHARGE_EMAIL_SENT_AGAIN",
  MANDATE_CANCELLED_EMAIL_SENT = "MANDATE_CANCELLED_EMAIL_SENT",
  MANDATE_CANCELLED_EMAIL_SENT_AGAIN = "MANDATE_CANCELLED_EMAIL_SENT_AGAIN",
  NEW_MANDATE_ACCOUNT_INFO_RECEIVED_EMAIL_SENT = "NEW_MANDATE_ACCOUNT_INFO_RECEIVED_EMAIL_SENT",
  NEW_MANDATE_ACCOUNT_INFO_RECEIVED_EMAIL_SENT_AGAIN = "NEW_MANDATE_ACCOUNT_INFO_RECEIVED_EMAIL_SENT_AGAIN",
  CANCEL_EMAIL_UNPAID_PREMIUM_SENT = "CANCEL_EMAIL_UNPAID_PREMIUM_SENT",
  CANCEL_EMAIL_UNPAID_PREMIUM_SENT_AGAIN = "CANCEL_EMAIL_UNPAID_PREMIUM_SENT_AGAIN",
  CANCEL_EMAIL_CUSTOMER_AGE_SENT = "CANCEL_EMAIL_CUSTOMER_AGE_SENT",
  CANCEL_EMAIL_CUSTOMER_AGE_SENT_AGAIN = "CANCEL_EMAIL_CUSTOMER_AGE_SENT_AGAIN",
  CANCEL_EMAIL_MANDATE_CANCELLED_SENT = "CANCEL_EMAIL_MANDATE_CANCELLED_SENT",
  CANCEL_EMAIL_MANDATE_CANCELLED_SENT_AGAIN = "CANCEL_EMAIL_MANDATE_CANCELLED_SENT_AGAIN",
  CANCEL_EMAIL_GENERIC_SENT = "CANCEL_EMAIL_GENERIC_SENT",
  CANCEL_EMAIL_GENERIC_SENT_AGAIN = "CANCEL_EMAIL_GENERIC_SENT_AGAIN",
  NEW_MANDATE_ACQUIRED_EMAIL_SENT = "NEW_MANDATE_ACQUIRED_EMAIL_SENT",
  NEW_MANDATE_ACQUIRED_EMAIL_SENT_AGAIN = "NEW_MANDATE_ACQUIRED_EMAIL_SENT_AGAIN",
  REQUEST_NEW_MANDATE_EMAIL_SENT = "REQUEST_NEW_MANDATE_EMAIL_SENT",
  REQUEST_NEW_MANDATE_LINK_CREATED = "REQUEST_NEW_MANDATE_LINK_CREATED",
  WINBACK_FEEDBACK = "WINBACK_FEEDBACK",
  VIPPS_INITIATED = "VIPPS_INITIATED",
  VIPPS_MANDATE_ACQUIRED = "VIPPS_MANDATE_ACQUIRED",
  VIPPS_MANDATE_EXPIRED = "VIPPS_MANDATE_EXPIRED",
  VIPPS_MANDATE_FAILED = "VIPPS_MANDATE_FAILED",
  VIPPS_MANDATE_CANCELLED = "VIPPS_MANDATE_CANCELLED",
  VIPPS_CHARGE_INITIATED = "VIPPS_CHARGE_INITIATED",
  VIPPS_CHARGE_PENDING = "VIPPS_CHARGE_PENDING",
  VIPPS_CHARGE_CANCELLED = "VIPPS_CHARGE_CANCELLED",
  VIPPS_ACCOUNT_CHARGED = "VIPPS_ACCOUNT_CHARGED",
  VIPPS_CHARGE_FAILED = "VIPPS_CHARGE_FAILED",
  VIPPS_CHARGE_REFUNDED = "VIPPS_CHARGE_REFUNDED",
}

/** InsuranceGroup */
export enum InsuranceGroup {
  SAFETY_INSURANCE = "SAFETY_INSURANCE",
  ACCIDENT_INSURANCE = "ACCIDENT_INSURANCE",
}

/** InsuranceNotification */
export interface InsuranceNotification {
  type: IncomingInsuranceEvent;
  /** Insurance Id */
  insurance_id: string;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  country: Country;
  /** Customer Id */
  customer_id: string;
}

/** InsuranceStatus */
export enum InsuranceStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

/** InsuranceSubstatus */
export enum InsuranceSubstatus {
  WAITING_FOR_CUSTOMER_ACCEPTANCE = "WAITING_FOR_CUSTOMER_ACCEPTANCE",
  WAITING_FOR_BANK = "WAITING_FOR_BANK",
  INACTIVE_MANDATE = "INACTIVE_MANDATE",
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  PENDING_CANCELLATION = "PENDING_CANCELLATION",
  DISCARDED = "DISCARDED",
}

/** InsuranceTerms */
export interface InsuranceTerms {
  /**
   * Active Max Age
   * The age at which the insurance ceases to be valid
   */
  active_max_age: number;
  /** Can Create New */
  can_create_new: boolean;
  /** Can Request New Mandate */
  can_request_new_mandate: boolean;
  /** Can Update Insurance */
  can_update_insurance: boolean;
  /** Cancellation Withdrawal Period Days */
  cancellation_withdrawal_period_days: number;
  /** Countries Of Residence */
  countries_of_residence: Country[];
  country: Country;
  currency: Currency;
  /** Customer Can Self Sign Up */
  customer_can_self_sign_up: boolean;
  /** Employment Types */
  employment_types: string[];
  insurance_type: InsuranceType;
  insurance_group: InsuranceGroup;
  /** Insurer Code */
  insurer_code: string;
  /** Max Age */
  max_age: number;
  /**
   * Max Amount
   * The maximum amount that can be insured
   */
  max_amount?: number | null;
  /** Max Insured Rate Vs Monthly Gross Income */
  max_insured_rate_vs_monthly_gross_income: number;
  /** Min Age */
  min_age: number;
  /**
   * Min Amount
   * The minimum amount that can be insured
   */
  min_amount?: number | null;
  /** Min Months Full Time Employment Time */
  min_months_full_time_employment_time?: number | null;
  /** Min Months Employment Time */
  min_months_employment_time: number;
  /** Min Hours Of Work Per Week */
  min_hours_of_work_per_week: number;
  /** Min Months Employment Time For Self Employed */
  min_months_employment_time_for_self_employed?: number | null;
  /** Min Hours Of Work Per Week For Self Employed */
  min_hours_of_work_per_week_for_self_employed?: number | null;
  /** Payment Provider */
  payment_provider: string;
  /**
   * Premium Rate
   * The rate of the premium insured amount * premium rate = premium amount
   */
  premium_rate?: number | null;
  /**
   * Usps
   * @default []
   */
  usps?: string[];
  /**
   * Terms
   * @default []
   */
  terms?: string[];
  /**
   * Static Premium
   * The premium amount for a static premium insurance e.g accident insurance
   */
  static_premium?: number | null;
  /**
   * Insured Amount
   * The amount that is insured for insurances with a fixed premium
   */
  insured_amount?: number | null;
  /**
   * Product Name
   * The name of the insurance product
   */
  product_name: string;
}

/** InsuranceType */
export enum InsuranceType {
  AXA_ZS_SE = "AXA_ZS_SE",
  AXA_ZS_NO = "AXA_ZS_NO",
  Z8 = "Z8",
  Z6 = "Z6",
  Z1 = "Z1",
  AXAO1SE = "AXA_O1_SE",
}

/** Integration */
export interface Integration {
  /** Bank Id */
  bank_id: number;
  country: Country;
  product: Product;
  /** Name */
  name: string;
  /**
   * Actions
   * Allowed actions to be performed on this integration
   */
  actions: IntegrationAction[];
}

/** IntegrationAction */
export enum IntegrationAction {
  INVITE = "INVITE",
  AUTO_INVITE = "AUTO_INVITE",
  COMMENTS = "COMMENTS",
  DOCUMENTS = "DOCUMENTS",
  ACCEPT = "ACCEPT",
  CANCEL = "CANCEL",
}

/** IntegrationId */
export interface IntegrationId {
  /** Bank Id */
  bank_id: number;
  country: Country;
  product: Product;
}

/** IntegrationQuery */
export interface IntegrationQuery {
  country?: Country | null;
  /** Bank Id */
  bank_id?: number | null;
  product?: Product | null;
}

/** IntentionOfExcessAmount */
export enum IntentionOfExcessAmount {
  BUYING_PROPERTY = "BUYING_PROPERTY",
  REPAYMENT_OTHER_LOANS = "REPAYMENT_OTHER_LOANS",
  RENOVATION_OF_PROPERTY = "RENOVATION_OF_PROPERTY",
  BUYING_CAPITAL_GOODS = "BUYING_CAPITAL_GOODS",
  OTHER_CONSUMPTION = "OTHER_CONSUMPTION",
}

/** InterimLead */
export interface InterimLead {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  data: LeadData;
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
  /** File Id */
  file_id?: string | null;
  /** Job Id */
  job_id?: string | null;
  /**
   * Lead Type
   * @default "COLD"
   */
  lead_type?: "COLD";
  /** Imported At */
  imported_at?: string | null;
  /** Extracted At */
  extracted_at?: string | null;
  /** Extraction Id */
  extraction_id?: string | null;
}

/** InterimLeadJob */
export interface InterimLeadJob {
  /**
   * Job Id
   * @format uuid
   */
  job_id: string;
  /**
   * Is Finished
   * @default false
   */
  is_finished?: boolean;
  /**
   * Is Failed
   * @default false
   */
  is_failed?: boolean;
}

/** InterimLeadsInsertion */
export interface InterimLeadsInsertion {
  /**
   * Not Normalized Count
   * @default 0
   */
  not_normalized_count?: number;
  /**
   * Duplicated Count
   * @default 0
   */
  duplicated_count?: number;
  /**
   * Inserted Count
   * @default 0
   */
  inserted_count?: number;
}

/** InternalCommentData */
export interface InternalCommentData {
  /** Application Id */
  application_id: string;
  /** From User Id */
  from_user_id?: string | null;
}

/** InternalCommentWithName */
export interface InternalCommentWithName {
  /** User Id */
  user_id: string;
  /** Comment */
  comment: string;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** Comment Id */
  comment_id: string;
  /**
   * Action
   * @default "INTERNAL_COMMENT_ADDED"
   */
  action?: "INTERNAL_COMMENT_ADDED";
  /** User Name */
  user_name: string;
  /** User Avatar */
  user_avatar: string;
}

/** InvitePayloadBase */
export interface InvitePayloadBase {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Bank Id */
  bank_id: number;
  /** Comment */
  comment?: string | null;
  /**
   * Force
   * @default false
   */
  force?: boolean;
}

/** JobType */
export enum JobType {
  Scheduled = "scheduled",
  Failed = "failed",
  Started = "started",
  Finished = "finished",
}

/** JoinedCampaignExt */
export interface JoinedCampaignExt {
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
  /**
   * Joined At
   * @format date-time
   */
  joined_at: string;
  /** Playing */
  playing: boolean;
  /**
   * Color
   * @exclusiveMin 0
   * @max 16
   */
  color: number;
  stats: CampaignStats;
  /** Campaign Data */
  campaign_data: MailCampaignData | DialCampaignData;
}

/** JotformCCApplication */
export interface JotformCCApplication {
  country: Country;
  data: OptionalCreditCardApplicationDataCreate;
  /** Model used only for mapping jotform data to Applicant model, allowing some more fields to be optional */
  applicant: OptionalCreateApplicantNO;
}

/** JotformField */
export interface JotformField {
  /** Name */
  name: string;
  /** Options */
  options?: string[] | null;
}

/** JotformLeadSource */
export interface JotformLeadSource {
  /**
   * Type
   * @default "JOTFORM"
   */
  type?: "JOTFORM";
  /** Form Id */
  form_id: string;
  /** Submission Id */
  submission_id: string;
  /** Error */
  error?: string | null;
}

/** KDLTCallbackPayload */
export interface KDLTCallbackPayload {
  event: KDLTEvent;
  /** Customer Id */
  customer_id: string;
  /** Event Id */
  event_id: string;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
}

/** KDLTEvent */
export enum KDLTEvent {
  CUSTOMER_CREATED = "CUSTOMER_CREATED",
  CUSTOMER_UPDATED = "CUSTOMER_UPDATED",
  UC_ID_CHECK = "UC_ID_CHECK",
  UC_CREDIT_CHECK = "UC_CREDIT_CHECK",
  EXPERIAN_ID_CHECK = "EXPERIAN_ID_CHECK",
  EXPERIAN_CREDIT_CHECK = "EXPERIAN_CREDIT_CHECK",
  CM1PEPCHECK = "CM1_PEP_CHECK",
  GJELDSREGISTERET_CREDITS = "GJELDSREGISTERET_CREDITS",
  CUSTOMER_DELETED = "CUSTOMER_DELETED",
  CUSTOMER_ACCESSED = "CUSTOMER_ACCESSED",
  PEP_SANCTION_WHITELISTING = "PEP_SANCTION_WHITELISTING",
  DOCUMENT_ADDED = "DOCUMENT_ADDED",
  PROPERTY_EV_CHECK = "PROPERTY_EV_CHECK",
  MANUALLY_BLOCKED = "MANUALLY_BLOCKED",
}

/** LastCharge */
export interface LastCharge {
  /** Amount */
  amount?: number | null;
  /** Date */
  date?: string | null;
  /** Orderid */
  orderId?: string | null;
}

/** LastQuery */
export interface LastQuery {
  /** Issuer Business Type */
  issuer_business_type?: string | null;
  /** Issuer Name */
  issuer_name?: string | null;
  /** Query Date */
  query_date?: string | null;
  /** Uc Internal Information */
  uc_internal_information?: string | null;
  /** Year */
  year?: string | null;
}

/** Lead */
export interface Lead {
  product: Product;
  country: Country;
  /** Lead Id */
  lead_id: string;
  /** Creator */
  creator?: string | null;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Handled */
  handled: boolean;
  /**
   * Reprocessable
   * @default false
   */
  reprocessable?: boolean | null;
  /** Create Source */
  create_source?: JotformLeadSource | ApiLeadSource | AffiliateApiLeadSource | null;
  /**
   * Create Sources
   * @default []
   */
  create_sources?: (JotformLeadSource | ApiLeadSource | AffiliateApiLeadSource)[];
  /** Converted */
  converted?: string[] | null;
  data?: LeadCreationData | null;
  source?: ApplicationSource | null;
  deactivation_reason?: AppsmgrMortgageModelsApplicationDeactivationReason | null;
}

/** LeadAssigned */
export interface LeadAssigned {
  /** Type */
  type: "LEAD_ASSIGNED";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  status: NotificationEvent;
  data: LeadAssignedData;
  /** Notification Id */
  notification_id: string;
  /** User Id */
  user_id: string | null;
}

/** LeadAssignedData */
export interface LeadAssignedData {
  /** Lead Id */
  lead_id: string;
}

/** LeadComment */
export interface LeadComment {
  /** Type */
  type: "LEAD_COMMENT_RECEIVED";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  status: NotificationEvent;
  data: LeadCommentData;
  /** Notification Id */
  notification_id: string;
  /** User Id */
  user_id: string | null;
}

/** LeadCommentData */
export interface LeadCommentData {
  /** Lead Id */
  lead_id: string;
}

/** LeadCreationData */
export interface LeadCreationData {
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  /** Applicants */
  applicants?: BareApplicantWithRole[] | null;
  /** Property Id */
  property_id?: string | null;
  discount?: Discount | null;
  /** Loans */
  loans?: Loan[] | null;
  /** Other Mortgages */
  other_mortgages?: MortgageLoan[] | null;
  status?: AppsmgrModelsStatusStatus | null;
  substatus?: Substatus | null;
  bank_status?: BankStatus | null;
  /** Children In The Household */
  children_in_the_household?: number | null;
  /** Children In The Household Fulltime */
  children_in_the_household_fulltime?: number | null;
  /** Other Properties To Keep */
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  brokerage?: Brokerage | null;
  /** Applicant */
  applicant?:
    | (
        | ({
            country: "NO";
          } & LimitedApplicantNO)
        | ({
            country: "SE";
          } & LimitedApplicantSE)
      )
    | null;
  /** Co Applicant */
  co_applicant?:
    | (
        | ({
            country: "NO";
          } & LimitedApplicantNO)
        | ({
            country: "SE";
          } & LimitedApplicantSE)
      )
    | null;
  property?: ExternalProperty | null;
  /** Comment */
  comment?: string | null;
  /** Ip Address */
  ip_address?: string | null;
  [key: string]: any;
}

/** LeadData */
export interface LeadData {
  /** Phone */
  phone?: string | null;
  /** Other Phones */
  other_phones?: string[] | null;
  /** Pni */
  pni?: string | null;
  process_type?: ProcessCampaignType | null;
  process_subtype?: InsuranceSubstatus | null;
  /** Priority */
  priority?: number | null;
  /** Name */
  name?: string | null;
  /** Customer Id */
  customer_id?: string | null;
  /** Other Properties */
  other_properties?: object | null;
  /** Comment Vars */
  comment_vars?: object | null;
  /** Tags */
  tags?: object | null;
  /**
   * Application Id
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id?: string | null;
  /** Comment */
  comment?: string | null;
  /** Customer Cluster */
  customer_cluster?: string | null;
  /**
   * Extraction Count
   * Number of times this customer/phone number was extracted.
   */
  extraction_count?: number | null;
  /** Sorting Order */
  sorting_order?: number | null;
  /** Mail */
  mail?: string | null;
  /** @default "phone" */
  contact_type?: ContactType;
  calling_order?: CallingOrder | null;
  raw_calling_order?: CallingOrder | null;
  /** Data Template Vars */
  data_template_vars?: object | null;
}

/** LeadField */
export interface LeadField {
  /** Name */
  name: string;
  /** Options */
  options?: string[] | null;
}

/** LeadHandledPayload */
export interface LeadHandledPayload {
  /** Lead Id */
  lead_id: string;
  deactivation_reason: AppsmgrMortgageModelsApplicationDeactivationReason;
}

/** LeadStatus */
export enum LeadStatus {
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
  FAILED = "FAILED",
  DEACTIVATED = "DEACTIVATED",
  DISCARD = "DISCARD",
}

/** LeadUpdatePayload */
export interface LeadUpdatePayload {
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  /** Applicants */
  applicants?: BareApplicantWithRole[] | null;
  /** Property Id */
  property_id?: string | null;
  discount?: Discount | null;
  /** Loans */
  loans?: Loan[] | null;
  /** Other Mortgages */
  other_mortgages?: MortgageLoan[] | null;
  status?: AppsmgrModelsStatusStatus | null;
  substatus?: Substatus | null;
  bank_status?: BankStatus | null;
  /** Children In The Household */
  children_in_the_household?: number | null;
  /** Children In The Household Fulltime */
  children_in_the_household_fulltime?: number | null;
  /** Other Properties To Keep */
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  brokerage?: Brokerage | null;
  /** Applicant */
  applicant?:
    | (
        | ({
            country: "NO";
          } & LimitedApplicantNO)
        | ({
            country: "SE";
          } & LimitedApplicantSE)
      )
    | null;
  /** Co Applicant */
  co_applicant?:
    | (
        | ({
            country: "NO";
          } & LimitedApplicantNO)
        | ({
            country: "SE";
          } & LimitedApplicantSE)
      )
    | null;
  property?: ExternalProperty | null;
  /** Comment */
  comment?: string | null;
}

/** LeftToLiveFields */
export enum LeftToLiveFields {
  ExistingMortgage = "existing_mortgage",
  BuyingInfo = "buying_info",
  BooliValuation = "booli_valuation",
  GuarantorEmployer = "guarantor_employer",
  CoApplicantEmployer = "co_applicant_employer",
  MainApplicantEmployer = "main_applicant_employer",
  LoanPurpose = "loan_purpose",
  MortgageLoanAmount = "mortgage_loan_amount",
  NewMortgageLoan = "new_mortgage_loan",
  Property = "property",
  Applicant = "applicant",
  Municipality = "municipality",
  ExistingLoanAmount = "existing_loan_amount",
  ExistingLoanRefinance = "existing_loan_refinance",
  ExistingLoanResponsibility = "existing_loan_responsibility",
  ExistingLoanType = "existing_loan_type",
}

/** LeftToLiveOutcome */
export interface LeftToLiveOutcome {
  /**
   * Message
   * TODO
   */
  message?: string | null;
  /**
   * Success
   * TODO
   */
  success?: boolean | null;
  /**
   * Loans
   * TODO
   */
  loans?: LeftToLiveOutcomeLoan[] | null;
  /**
   * Lefttoliveoriginalresult
   * TODO
   */
  leftToLiveOriginalResult?: number | null;
  /**
   * Lefttolivevalue
   * TODO
   */
  leftToLiveValue?: number | null;
  /**
   * Lefttolivebudget
   * TODO
   */
  leftToLiveBudget?: number | null;
  /**
   * Lefttoliveresult
   * TODO
   */
  leftToLiveResult?: number | null;
  /**
   * Requestedloanamount
   * TODO
   */
  requestedLoanAmount?: number | null;
  /**
   * Loanamountadjusted
   * TODO
   */
  loanAmountAdjusted?: boolean | null;
  /**
   * Offeredloanamount
   * TODO
   */
  offeredLoanAmount?: number | null;
  /**
   * Debttoincomeratio
   * TODO
   */
  debtToIncomeRatio?: number | null;
}

/** LeftToLiveOutcomeLoan */
export interface LeftToLiveOutcomeLoan {
  /**
   * Monthlypayment
   * TODO
   */
  monthlyPayment?: number | null;
  /**
   * Interestrate
   * TODO
   */
  interestRate?: number | null;
  /**
   * Loanamount
   * TODO
   */
  loanAmount?: number | null;
  /** TODO */
  loanType?: LoanTypeEnum | null;
  /**
   * Monthlyamortization
   * TODO
   */
  monthlyAmortization?: number | null;
}

/** LeftToLivePayload */
export interface LeftToLivePayload {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Bank Id */
  bank_id: number;
  /** Force */
  force?: boolean | null;
}

/** LeftToLiveResponse */
export interface LeftToLiveResponse {
  outcome?: LeftToLiveOutcome | null;
  pre_offer?: MortgageRates | null;
  /** Missing Required Fields */
  missing_required_fields?: LeftToLiveFields[] | null;
  /** Missing Optional Fields */
  missing_optional_fields?: LeftToLiveFields[] | null;
}

/** LegalDocumentTypeType */
export interface LegalDocumentTypeType {
  /** Description */
  Description?: string | null;
  /** Key */
  Key?: string | null;
}

/** LienTypeType */
export interface LienTypeType {
  /** Description */
  Description?: string | null;
  /** Key */
  Key?: string | null;
}

/** LimitedApplicantNO */
export interface LimitedApplicantNO {
  /** Zid */
  zid?: string | null;
  /** Maklarpartner */
  maklarpartner?: string | null;
  /** Session Zid */
  session_zid?: string | null;
  /** Gid */
  gid?: string | null;
  /**
   * Country
   * @default "NO"
   */
  country?: "NO";
  /** Pni */
  pni?: string | null;
  /** First Name */
  first_name?: string | null;
  /** Last Name */
  last_name?: string | null;
  /** Email */
  email?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Additional Phone Numbers */
  additional_phone_numbers?: string[] | null;
  /** Monthly Property Cost */
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;
  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  /** Post Code */
  post_code?: string | null;
  /** City */
  city?: string | null;
  /** Incomes */
  incomes?: Income[] | null;
  /** Net Monthly Income */
  net_monthly_income?: number | null;
  account?: Account | null;
  /** Street Address */
  street_address?: string | null;
  /**
   * Paying Child Support
   * Number of children to pay child support for
   */
  paying_child_support?: number | null;
  /**
   * Receiving Child Support
   * Number of children to receive child support for
   */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  /** Consents Popup Dismissed Timestamp */
  consents_popup_dismissed_timestamp?: string | null;
  /** Partner Pni */
  partner_pni?: string | null;
  /** Partner Name */
  partner_name?: string | null;
  /** Municipality Code */
  municipality_code?: string | null;
  /** Municipality */
  municipality?: string | null;
  /** Is Pep */
  is_pep?: boolean | null;
  /** Age */
  age?: number | null;
  /** Other Phones */
  other_phones?: AppsmgrModelsPhonePhoneNumber[] | null;
  housing_type?: HousingTypeNO | null;
  employer?: OptionalEmployerNO | null;
  /** Customer Id */
  customer_id?: string | null;
}

/** LimitedApplicantSE */
export interface LimitedApplicantSE {
  /** Zid */
  zid?: string | null;
  /** Maklarpartner */
  maklarpartner?: string | null;
  /** Session Zid */
  session_zid?: string | null;
  /** Gid */
  gid?: string | null;
  /**
   * Country
   * @default "SE"
   */
  country?: "SE";
  /** Pni */
  pni?: string | null;
  /** First Name */
  first_name?: string | null;
  /** Last Name */
  last_name?: string | null;
  /** Email */
  email?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Additional Phone Numbers */
  additional_phone_numbers?: string[] | null;
  /** Monthly Property Cost */
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;
  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  /** Post Code */
  post_code?: string | null;
  /** City */
  city?: string | null;
  /** Incomes */
  incomes?: Income[] | null;
  /** Net Monthly Income */
  net_monthly_income?: number | null;
  account?: Account | null;
  /** Street Address */
  street_address?: string | null;
  /**
   * Paying Child Support
   * Number of children to pay child support for
   */
  paying_child_support?: number | null;
  /**
   * Receiving Child Support
   * Number of children to receive child support for
   */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  /** Consents Popup Dismissed Timestamp */
  consents_popup_dismissed_timestamp?: string | null;
  /** Partner Pni */
  partner_pni?: string | null;
  /** Partner Name */
  partner_name?: string | null;
  /** Municipality Code */
  municipality_code?: string | null;
  /** Municipality */
  municipality?: string | null;
  /** Is Pep */
  is_pep?: boolean | null;
  /** Age */
  age?: number | null;
  /** Other Phones */
  other_phones?: AppsmgrModelsPhonePhoneNumber[] | null;
  housing_type?: HousingTypeSE | null;
  employer?: OptionalEmployerSE | null;
  /** Customer Id */
  customer_id?: string | null;
}

/** Loan */
export interface Loan {
  currency: Currency;
  /**
   * Loan Id
   * @format uuid4
   */
  loan_id: string;
  /** Amount */
  amount?: number | null;
  /** Bank Id */
  bank_id?: number | null;
  /** Refinance */
  refinance?: boolean | null;
  /** Refinanceamount */
  refinanceAmount?: number | null;
  loan_type?: LoanType | null;
  /** Monthly Payment */
  monthly_payment?: number | null;
  /** Rate */
  rate?: number | null;
  /** Monthly Amortisation */
  monthly_amortisation?: number | null;
  responsibility?: null;
  /** Credit Limit */
  credit_limit?: number | null;
  /** Monthly Administrative Fee */
  monthly_administrative_fee?: number | null;
}

/** LoanAndExternallyVerifiedCredit */
export interface LoanAndExternallyVerifiedCredit {
  /** Externally Verified Credits */
  externally_verified_credits: ExternallyVerifiedCreditWithRole[];
  /** Loans */
  loans: Loan[];
}

/** LoanPurpose */
export enum LoanPurpose {
  Refinance = "refinance",
  HomeRemodelling = "homeRemodelling",
  HealthcareExpenses = "healthcareExpenses",
  DivorceProceeding = "divorceProceeding",
  HomeDownpayment = "homeDownpayment",
  Investment = "investment",
  Education = "education",
  Travel = "travel",
  Car = "car",
  Other = "other",
  DriversLicense = "driversLicense",
  Wedding = "wedding",
  Relocation = "relocation",
  Interior = "interior",
  Consumption = "consumption",
  DebtCollection = "debtCollection",
  LoanOnBehalfOfOther = "loan-on-behalf-of-other",
  InvestmentAbroad = "investment-abroad",
}

/** LoanPurposeNO */
export enum LoanPurposeNO {
  Refinance = "refinance",
  HomeRemodelling = "homeRemodelling",
  HealthcareExpenses = "healthcareExpenses",
  DivorceProceeding = "divorceProceeding",
  HomeDownpayment = "homeDownpayment",
  Investment = "investment",
  Education = "education",
  Travel = "travel",
  Car = "car",
  Other = "other",
  DriversLicense = "driversLicense",
  Wedding = "wedding",
  Relocation = "relocation",
  Interior = "interior",
  Consumption = "consumption",
  DebtCollection = "debtCollection",
}

/** LoanPurposeSE */
export enum LoanPurposeSE {
  Refinance = "refinance",
  HomeRemodelling = "homeRemodelling",
  HealthcareExpenses = "healthcareExpenses",
  DivorceProceeding = "divorceProceeding",
  HomeDownpayment = "homeDownpayment",
  Investment = "investment",
  Education = "education",
  Travel = "travel",
  Car = "car",
  Other = "other",
  DriversLicense = "driversLicense",
  Wedding = "wedding",
  Relocation = "relocation",
  Interior = "interior",
  Consumption = "consumption",
  DebtCollection = "debtCollection",
  LoanOnBehalfOfOther = "loan-on-behalf-of-other",
  InvestmentAbroad = "investment-abroad",
}

/** LoanType */
export enum LoanType {
  CREDIT_CARD = "CREDIT_CARD",
  UNSECURED_LOAN = "UNSECURED_LOAN",
  CAR_LOAN = "CAR_LOAN",
  CHECK_CREDIT = "CHECK_CREDIT",
  OTHER = "OTHER",
  STUDENT_LOAN = "STUDENT_LOAN",
  INSTALLMENT = "INSTALLMENT",
  TOP_MORTGAGE_LOAN = "TOP_MORTGAGE_LOAN",
  MEMBER_LOAN = "MEMBER_LOAN",
  MORTGAGE = "MORTGAGE",
}

/** LoanTypeEnum */
export enum LoanTypeEnum {
  UNSECURED_LOAN = "UNSECURED_LOAN",
  MORTGAGE = "MORTGAGE",
}

/** Login */
export interface Login {
  /**
   * Username
   * @format email
   */
  username: string;
  /**
   * Password
   * @format password
   */
  password: string;
}

/** LoginPayload */
export interface LoginPayload {
  /** Pni */
  pni?: string | null;
  /** Callback Url */
  callback_url?: string | null;
  /** Callback Fail Url */
  callback_fail_url?: string | null;
  /** @default "SE" */
  country?: Country;
  /** @default "blanco" */
  product?: Product;
}

/** LoginResp */
export interface LoginResp {
  /** Session Id */
  session_id: string;
}

/** MailBatchStats */
export interface MailBatchStats {
  /**
   * Total Opens
   * @default 0
   */
  total_opens?: number;
  /**
   * Total Clicks
   * @default 0
   */
  total_clicks?: number;
  /**
   * Unique Opens
   * @default 0
   */
  unique_opens?: number;
  /**
   * Unique Clicks
   * @default 0
   */
  unique_clicks?: number;
  /**
   * Sent
   * @default 0
   */
  sent?: number;
  /**
   * Hard Bounce
   * @default 0
   */
  hard_bounce?: number;
  /**
   * Soft Bounce
   * @default 0
   */
  soft_bounce?: number;
  /**
   * Spam
   * @default 0
   */
  spam?: number;
  /**
   * Unsubscribed
   * @default 0
   */
  unsubscribed?: number;
  /**
   * Redirects
   * @default 0
   */
  redirects?: number;
  /**
   * Unique Redirects
   * @default 0
   */
  unique_redirects?: number;
  /**
   * Batch Id
   * @format uuid
   */
  batch_id: string;
  /** Mail Ids */
  mail_ids: string[];
  /**
   * Batch Sent
   * @format date-time
   */
  batch_sent: string;
}

/** MailCampaignData */
export interface MailCampaignData {
  /** Name */
  name: string;
  /** Description */
  description?: string | null;
  /** Source */
  source:
    | ({
        type: "BIG_QUERY";
      } & BigQueryCampaignSource)
    | ({
        type: "PROCESS";
      } & ProcessCampaignSource)
    | ({
        type: "SHEET";
      } & SheetCampaignSource);
  /** File Id */
  file_id?: string | null;
  exclusion_rules: ExclusionRules;
  /** Daily Extraction Limit */
  daily_extraction_limit?: number | null;
  /** Comment Template */
  comment_template?: string | null;
  mail_policy?: MailSendPolicy | null;
  template?: EmailTemplate | null;
  /** Template Vars */
  template_vars?: string[] | null;
  type: CampaignType;
  country: Country;
  /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
  product: ExtendedProduct;
  department?: ZDepartment | null;
  /**
   * Contact Type
   * @default "mail"
   */
  contact_type?: "mail";
  /**
   * Mailings
   * @default []
   */
  mailings?: MailingData[];
  /** Last Bq Sync */
  last_bq_sync?: string | null;
  /** Last Mailing At */
  last_mailing_at?: string | null;
  /** Template Vars Checked At */
  template_vars_checked_at?: string | null;
  /** Template Links Vars */
  template_links_vars?: string[] | null;
}

/** MailSendPolicy */
export interface MailSendPolicy {
  /** Weekdays To Send */
  weekdays_to_send?: number[] | null;
  /** Spread Send Times */
  spread_send_times?: string[] | null;
  /**
   * Batch Size
   * @min 0
   * @default 30
   */
  batch_size?: number;
}

/** MailingData */
export interface MailingData {
  /**
   * Mailing Id
   * @format uuid
   */
  mailing_id: string;
  template: EmailTemplate;
  /** Template Vars */
  template_vars?: string[] | null;
  /**
   * Leads Count
   * @min 0
   */
  leads_count: number;
  /** Mail Times */
  mail_times: string[];
  /** Template Vars Checked At */
  template_vars_checked_at?: string | null;
  /** Template Links Vars */
  template_links_vars?: string[] | null;
}

/** MandateStatus */
export interface MandateStatus {
  /**
   * Acquired
   * @default false
   */
  acquired?: boolean;
  /** Accountid */
  accountId?: string | null;
  /**
   * Canceled
   * @default false
   */
  canceled?: boolean;
  /** Bank */
  bank?: string | null;
  /** Lastbankaccountdigits */
  lastBankAccountDigits?: string | null;
}

/** MandrillTemplate */
export interface MandrillTemplate {
  /** Slug */
  slug: string;
  /** Name */
  name: string;
  /** Labels */
  labels: string[];
  /** Code */
  code?: string | null;
  /** Subject */
  subject?: string | null;
  /** From Email */
  from_email?: string | null;
  /** From Name */
  from_name?: string | null;
  /** Text */
  text?: string | null;
  /** Publish Name */
  publish_name: string;
  /** Publish Code */
  publish_code?: string | null;
  /** Publish Subject */
  publish_subject?: string | null;
  /** Publish From Email */
  publish_from_email?: string | null;
  /** Publish From Name */
  publish_from_name?: string | null;
  /** Publish Text */
  publish_text?: string | null;
  /** Published At */
  published_at?: string | null;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Is Broken Template */
  is_broken_template: boolean;
}

/** MaritalStatus */
export enum MaritalStatus {
  Unmarried = "unmarried",
  Married = "married",
  Divorced = "divorced",
  WidowWidower = "widow/widower",
  Deceased = "deceased",
  ChildUnder18Years = "child under 18 years",
  PreviouslyMarried = "previously married",
  RegisteredPartnership = "registered partnership",
  Partner = "partner",
}

/** MeetingWithApp */
export interface MeetingWithApp {
  /**
   * Meeting Start
   * @format date-time
   */
  meeting_start: string;
  /**
   * Meeting End
   * @format date-time
   */
  meeting_end: string;
  /** Handler Id */
  handler_id?: string | null;
  /** Meeting Notes */
  meeting_notes?: string | null;
  /** Meeting Subject */
  meeting_subject?: string | null;
  /** Customer Id */
  customer_id?: string | null;
  /**
   * Application Id
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Meeting Id */
  meeting_id: string;
  /**
   * Last Updated
   * @format date-time
   */
  last_updated: string;
  /**
   * Date Created
   * @format date-time
   */
  date_created: string;
  product: Product;
  country: Country;
  /** Customer Name */
  customer_name?: string | null;
  /** Handler Name */
  handler_name?: string | null;
  /** Application */
  application?: AppBase | Lead | null;
}

/** MeetingWithHandlerName */
export interface MeetingWithHandlerName {
  /**
   * Meeting Start
   * @format date-time
   */
  meeting_start: string;
  /**
   * Meeting End
   * @format date-time
   */
  meeting_end: string;
  /** Handler Id */
  handler_id?: string | null;
  /** Meeting Notes */
  meeting_notes?: string | null;
  /** Meeting Subject */
  meeting_subject?: string | null;
  /** Customer Id */
  customer_id?: string | null;
  /**
   * Application Id
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Meeting Id */
  meeting_id: string;
  /**
   * Last Updated
   * @format date-time
   */
  last_updated: string;
  /**
   * Date Created
   * @format date-time
   */
  date_created: string;
  product: Product;
  country: Country;
  /** Customer Name */
  customer_name?: string | null;
  /** Handler Name */
  handler_name?: string | null;
}

/** MergeVar */
export interface MergeVar {
  /**
   * Rcpt
   * @format email
   */
  rcpt: string;
  /** Vars */
  vars: MergeVarContent[];
  /** Lead Id */
  lead_id?: string | null;
}

/** MergeVarContent */
export interface MergeVarContent {
  /** Name */
  name: string;
  /** Content */
  content?: string | number | boolean | null;
}

/** MessageType */
export enum MessageType {
  SMS = "SMS",
  EMAIL = "EMAIL",
}

/** MinimalApplicant */
export interface MinimalApplicant {
  role: ApplicantRole;
  /** Pni */
  pni: string;
}

/** Mortgage */
export interface Mortgage {
  /** Document */
  Document?: string | null;
  /** Documentnumber */
  DocumentNumber?: string | null;
  /** Documentyear */
  DocumentYear?: string | null;
  /** Identityname */
  IdentityName?: string | null;
  /** Identitynumber */
  IdentityNumber?: string | null;
  IdentityType?: IdentityTypeType | null;
  /** Isregisteredelectronically */
  IsRegisteredElectronically?: boolean | null;
  /** Isuncertain */
  IsUncertain?: boolean | null;
  /** Legaldocumentnumber */
  LegalDocumentNumber?: string | null;
  LegalDocumentType?: LegalDocumentTypeType | null;
  LienType?: LienTypeType | null;
  /** Mortgageinfoastext */
  MortgageInfoAsText?: string | null;
  /** Numestatesmortgageapplyto */
  NumEstatesMortgageApplyTo?: string | null;
  /** Officenumber */
  OfficeNumber?: string | null;
  /** Priority */
  Priority?: string | null;
  /** Registereddate */
  RegisteredDate?: string | null;
  /** Share */
  Share?: number | null;
  UncertainReason?: UncertainReasonType | null;
  /** Value */
  Value?: number | null;
}

/** MortgageBankEventsResponse */
export interface MortgageBankEventsResponse {
  /** Bank Events */
  bank_events: BankEventsMortgageOfferExt[];
}

/** MortgageDeactivatePayload */
export interface MortgageDeactivatePayload {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  reason: AppsmgrMortgageModelsApplicationDeactivationReason;
}

/** MortgageDeactivationReviewsResponse */
export interface MortgageDeactivationReviewsResponse {
  /** Reasons */
  reasons: AppsmgrMortgageModelsApplicationDeactivationReason[];
}

/** MortgageFormPayload */
export interface MortgageFormPayload {
  /** Givenname */
  givenName: string;
  /** Lastname */
  lastName: string;
  /** Phonenumber */
  phoneNumber: string;
  /**
   * Email
   * @format email
   */
  email: string;
  /** Bankname */
  bankName: string;
  /** Loanamount */
  loanAmount: number;
}

/** MortgageGoal */
export interface MortgageGoal {
  type_event: GoalType;
  /** User Id */
  user_id?: string[] | null;
  country: Country;
  /**
   * Year
   * What year the goal is set for
   */
  year: number;
  /**
   * Month
   * Month from 1-12
   */
  month: number;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
}

/** MortgageLeads */
export interface MortgageLeads {
  /** Amount */
  amount: number;
}

/** MortgageLoan */
export interface MortgageLoan {
  /** Bank Id */
  bank_id?: number | null;
  /** Monthly Cost */
  monthly_cost?: number | null;
  /** Loan Amount */
  loan_amount?: number | null;
  /** Refinance */
  refinance?: boolean | null;
  /** Responsibility */
  responsibility?: ResponsibilityOutput | null;
  /** Rate */
  rate?: number | null;
}

/** MortgageLoanType */
export enum MortgageLoanType {
  REFINANCE = "REFINANCE",
  PRE_APPROVAL = "PRE_APPROVAL",
  BUY = "BUY",
  BUY_AND_SELL = "BUY_AND_SELL",
}

/** MortgageOfferExt */
export interface MortgageOfferExt {
  /**
   * Offeredamount
   * @exclusiveMin 0
   */
  offeredAmount: number;
  currency: Currency;
  /** Bindingperiodmonth */
  bindingPeriodMonth: number;
  /** Interestrate */
  interestRate: number;
  /** Discount */
  discount: number;
  amortizationRule: AmortizationRule;
  /** Monthlyamortization */
  monthlyAmortization: number;
  /** Invoicefee */
  invoiceFee?: number | null;
  /** Setupfee */
  setupFee?: number | null;
  /** Autogirofee */
  autogiroFee?: number | null;
  /** Amortizationlength */
  amortizationLength?: number | null;
  /** Maxofferedamount */
  maxOfferedAmount?: number | null;
  /** Mustrefinance */
  mustRefinance?: number | null;
  /** Effectiveinterestrate */
  effectiveInterestRate?: number | null;
  /** Monthlypayment */
  monthlyPayment?: number | null;
  /** Arrangementfee */
  arrangementFee?: number | null;
  /** Termfee */
  termFee?: number | null;
  /** Termmonths */
  termMonths?: number | null;
  /** Downpaymentloanamount */
  downPaymentLoanAmount?: number | null;
  /** Downpaymentloaninterestrate */
  downPaymentLoanInterestRate?: number | null;
  /** Bridgeloanamount */
  bridgeLoanAmount?: number | null;
  /** Bridgeloaninterestrate */
  bridgeLoanInterestRate?: number | null;
  /** Firstmonthlypayment */
  firstMonthlyPayment: number;
}

/** MortgagePropertyType */
export enum MortgagePropertyType {
  HOUSE = "HOUSE",
  CONDOMINIUM = "CONDOMINIUM",
  VACATION_HOME = "VACATION_HOME",
}

/** MortgageRates */
export interface MortgageRates {
  /**
   * Loanamount
   * TODO
   */
  loanAmount?: number | null;
  /**
   * Marketvalue
   * TODO
   */
  marketValue?: number | null;
  /**
   * Bindingperiodmonth
   * TODO
   */
  bindingPeriodMonth?: number | null;
  /**
   * Interestrateafterdiscount
   * TODO
   */
  interestRateAfterDiscount?: number | null;
  /**
   * Interestrate
   * TODO
   */
  interestRate?: number | null;
  /**
   * Monthlyamortization
   * TODO
   */
  monthlyAmortization?: number | null;
}

/** Municipality */
export interface Municipality {
  /** Id */
  id: string;
  /**
   * Key
   * Used to get the correct table
   */
  key: string;
  /** Description */
  description: string;
  /** Municipality Code */
  municipality_code: string;
  country: Country;
}

/** MyDisbursedLoans */
export interface MyDisbursedLoans {
  /** Amount */
  amount: number;
  /** Sum */
  sum: number;
  /** Average Sum */
  average_sum: number;
  currency: Currency;
  /** Average Rate */
  average_rate?: number | null;
  /** Average Year */
  average_year?: number | null;
  /** Average Disbursed Time */
  average_disbursed_time?: number | null;
}

/** MyStats */
export interface MyStats {
  accepted?: AcceptedLoans | null;
  disbursed?: MyDisbursedLoans | null;
  insurance?: AllInsurances | null;
  mortgage_leads?: MortgageLeads | null;
}

/** MyStatsMb */
export interface MyStatsMb {
  /** Applications */
  applications: number;
  /** Applications Per Day */
  applications_per_day: number;
  /** Sent Loans */
  sent_loans: number;
  /** Signed */
  signed: number;
  /** Disbursed */
  disbursed: number;
  /** Co Applicants */
  co_applicants: number;
  /** Disbursed Amount */
  disbursed_amount: number;
  /** Signed Amount */
  signed_amount: number;
  /** Sent Amount */
  sent_amount: number;
  /** Co Applicant Percentage */
  co_applicant_percentage: number;
  /** Disbursement Rate */
  disbursement_rate: number;
  /** Average Team Applications */
  average_team_applications: number;
  /** Average Team Disbursed */
  average_team_disbursed: number;
  currency: Currency;
}

/** NOApplicationForm */
export interface NOApplicationForm {
  /**
   * Example-Loan-Payback-Time
   * @min 0
   * @default 0
   */
  "example-loan-payback-time"?: number;
  /**
   * Loan-Amount-Text-Field
   * @min 0
   * @default 0
   */
  "loan-amount-text-field"?: number;
  /**
   * Total-Loan-Amount
   * @min 0
   * @default 0
   */
  "total-loan-amount"?: number;
  /**
   * Slider
   * @min 0
   * @default 0
   */
  slider?: number;
  /**
   * Refinance-Question
   * @default false
   */
  "refinance-question"?: boolean;
  /**
   * Refinance-Amount
   * @default 0
   */
  "refinance-amount"?: number;
  /** Original-Loan-Purpose */
  "original-loan-purpose"?: string | null;
  "marital-status"?: MaritalStatus | null;
  /**
   * Number-Of-Children
   * @default 0
   */
  "number-of-children"?: number | null;
  /**
   * Rent-Part
   * @default "0"
   */
  "rent-part"?: string | null;
  /** @default "other" */
  "employment-type"?: EmploymentType;
  /** Employer */
  employer?: string | null;
  /** Employed-Year */
  "employed-year"?: string | null;
  /** Employed-Month */
  "employed-month"?: string | null;
  /** Employed-To-Year */
  "employed-to-year"?: string | null;
  /** Employed-To-Month */
  "employed-to-month"?: string | null;
  /** Company-Industry */
  "company-industry"?: string | null;
  /** Email */
  email?: string | null;
  /**
   * Ssn
   * @default ""
   */
  ssn?: string;
  /**
   * Email-Marketing
   * @default false
   */
  "email-marketing"?: boolean;
  /**
   * Sms-Marketing
   * @default false
   */
  "sms-marketing"?: boolean;
  /**
   * Customer-Club-Marketing
   * @default false
   */
  "customer-club-marketing"?: boolean;
  /**
   * Applicant-Pep
   * @default false
   */
  "applicant-pep"?: boolean;
  /**
   * Ms-Enabled
   * @default false
   */
  "ms-enabled"?: boolean;
  "ms-marital-status"?: MaritalStatus | null;
  /** Ms-Number-Of-Children */
  "ms-number-of-children"?: string | null;
  /** Ms-Rent-Part */
  "ms-rent-part"?: number | null;
  "ms-employment-type"?: EmploymentType | null;
  /** Ms-Employer */
  "ms-employer"?: string | null;
  /** Ms-Employed-Year */
  "ms-employed-year"?: string | null;
  /** Ms-Employed-Month */
  "ms-employed-month"?: string | null;
  /** Ms-Employed-To-Year */
  "ms-employed-to-year"?: string | null;
  /** Ms-Employed-To-Month */
  "ms-employed-to-month"?: string | null;
  /** Ms-Company-Industry */
  "ms-company-industry"?: string | null;
  /** Ms-Email */
  "ms-email"?: string | null;
  /** Ms-Ssn */
  "ms-ssn"?: string | null;
  /**
   * Ms-Applicant-Pep
   * @default false
   */
  "ms-applicant-pep"?: boolean | null;
  /**
   * Is-New-Loan-Form
   * @default false
   */
  "is-new-loan-form"?: boolean | null;
  /**
   * Credits
   * @default []
   */
  credits?: FormCredit[] | null;
  /** @default "other" */
  "loan-purpose"?: LoanPurposeNO;
  /**
   * Norsk-Statsborger
   * @default false
   */
  "norsk-statsborger"?: boolean | null;
  /** Lived-In-Country-Since-Year */
  "lived-in-country-since-year"?: string | null;
  /** Lived-In-Country-Since-Month */
  "lived-in-country-since-month"?: string | null;
  "housing-status"?: AppsmgrBlancoNoSpecPrivateUnsecuredLoanApplicationCreatedHousingType | null;
  "housing-type"?: HousingTypeNO | null;
  /** Phone-Number */
  "phone-number"?: string | null;
  /**
   * Salary-Yearly
   * @min 0
   * @default 0
   */
  "salary-yearly"?: number;
  /**
   * Other-Income
   * @min 0
   * @default 0
   */
  "other-income"?: number;
  /** Partners-Income-Amount */
  "partners-income-amount"?: number | null;
  /** Ms-Norsk-Statsborger */
  "ms-norsk-statsborger"?: boolean | null;
  /** Ms-Lived-In-Country-Since-Year */
  "ms-lived-in-country-since-year"?: string | null;
  /** Ms-Lived-In-Country-Since-Month */
  "ms-lived-in-country-since-month"?: string | null;
  "ms-housing-status"?: AppsmgrBlancoNoSpecPrivateUnsecuredLoanApplicationCreatedHousingType | null;
  "ms-housing-type"?: HousingTypeNO | null;
  /** Ms-Phone-Number */
  "ms-phone-number"?: string | null;
  /**
   * Ms-Salary-Yearly
   * @min 0
   * @default 0
   */
  "ms-salary-yearly"?: number;
  /**
   * Ms-Other-Income
   * @min 0
   * @default 0
   */
  "ms-other-income"?: number;
}

/** Name */
export interface Name {
  /** Name */
  name: string;
}

/** NewAppOption */
export interface NewAppOption {
  /** Id */
  id: string;
  /** Options */
  options: string[];
}

/** NewMortgageLoan */
export interface NewMortgageLoan {
  /** Mortgage Loan Amount */
  mortgage_loan_amount?: number | null;
  /** Down Payment */
  down_payment?: number | null;
  /** Down Payment Loan Amount */
  down_payment_loan_amount?: number | null;
  /** Bridge Loan Amount */
  bridge_loan_amount?: number | null;
  /** Disbursement Date */
  disbursement_date?: string | null;
  applying_with?: ApplyingWith | null;
  loan_purpose?: MortgageLoanType | null;
  intention_of_excess_amount?: IntentionOfExcessAmount | null;
  /** Monthly Cost Of Excess Amount */
  monthly_cost_of_excess_amount?: number | null;
  /** Max Amount */
  max_amount?: boolean | null;
  situation?: Situation | null;
  /** Private Loan Amount */
  private_loan_amount?: number | null;
}

/** NewPartialProperty */
export interface NewPartialProperty {
  property_type: MortgagePropertyType;
  /** Country */
  country?: string | null;
  /** Monthly Cost */
  monthly_cost?: number | null;
  /** Square Meter Living Area */
  square_meter_living_area?: number | null;
  /** Estimated Value */
  estimated_value?: number | null;
  /** Municipality */
  municipality?: string | null;
  /** Municipality Code */
  municipality_code?: string | null;
}

/** NoContactOutcomes */
export enum NoContactOutcomes {
  NotConnected = "not_connected",
  Busy = "busy",
  AnsweringMachine = "answering_machine",
  NoAnswer = "no_answer",
}

/** Note */
export interface Note {
  /** Cause Description */
  cause_description?: string | null;
  /** Year */
  year?: string | null;
}

/** Notification */
export interface Notification {
  type: AppsmgrModelsNotificationsNotificationType;
  /** State Labels */
  state_labels?: AppStateLabel[] | null;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  app_id: string;
  /**
   * When
   * @format date-time
   */
  when: string;
  product: Product;
  country: Country;
  /** Bank Id */
  bank_id: number;
  /** Customer Ids */
  customer_ids?: string[] | null;
  /** Pni */
  pni?: string | null;
  /** Online */
  online?: boolean | null;
}

/** NotificationEvent */
export enum NotificationEvent {
  CREATED = "CREATED",
  RECEIVED = "RECEIVED",
  READ = "READ",
  DELETED = "DELETED",
}

/** OTPValidation */
export interface OTPValidation {
  /**
   * Session Id
   * @format password
   * @minLength 32
   * @maxLength 32
   */
  session_id: string;
  /**
   * Otp
   * @format password
   */
  otp: string;
}

/** OTPValidationResp */
export interface OTPValidationResp {
  /** Token */
  token: string;
}

/** Offer */
export interface Offer {
  /** Id */
  id: string;
  /** Bank Id */
  bank_id: number;
  /** Status Id */
  status_id: number;
  /**
   * Rate
   * @default 0
   */
  rate?: number;
  /**
   * Sum
   * @default 0
   */
  sum?: number | null;
  /** Status Name */
  status_name: string;
  /** External Offer Id */
  external_offer_id?: string | null;
  /** Amortization Plan */
  amortization_plan: string;
  /**
   * Sum To Solve
   * @default 0
   */
  sum_to_solve?: number | null;
  /** Monthly Payment */
  monthly_payment: number;
  /**
   * Admin Fee
   * @default 0
   */
  admin_fee?: number | null;
  /**
   * Setup Fee
   * @default 0
   */
  setup_fee?: number | null;
  /**
   * Apr
   * @default 0
   */
  apr?: number | null;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Need More Info
   * @default false
   */
  need_more_info?: boolean | null;
  /**
   * Payback Time
   * Payback time in months
   */
  payback_time: number;
  /**
   * Direct Debit Fee
   * @default 0
   */
  direct_debit_fee?: number;
  /**
   * Is Accepted
   * @default false
   */
  is_accepted?: boolean | null;
  [key: string]: any;
}

/** OfferReceived */
export interface OfferReceived {
  /** Type */
  type: "OFFER_RECEIVED";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  status: NotificationEvent;
  data: OfferReceivedData;
  /** Notification Id */
  notification_id: string;
  /** User Id */
  user_id: string | null;
}

/** OfferReceivedData */
export interface OfferReceivedData {
  /** Application Id */
  application_id: string;
}

/** OfferStatus */
export enum OfferStatus {
  Approved = "approved",
  Created = "created",
  Edited = "edited",
  Rejected = "rejected",
  Ongoing = "ongoing",
}

/** OnlineFormEventData */
export interface OnlineFormEventData {
  event_type: OnlineFormEventType;
  event_subtype?: OnlineFormEventSubType | null;
  /** Event Meta */
  event_meta?: object | null;
  /** Application Id */
  application_id?: string | null;
  /** Form Id */
  form_id?: string | null;
  /** Session Id */
  session_id?: string | null;
  /** Device Id */
  device_id?: string | null;
  /** Device Meta */
  device_meta?: object | null;
  /** User Agent */
  user_agent?: string | null;
  /** Event Description */
  event_description?: string | null;
}

/** OnlineFormEventSubType */
export enum OnlineFormEventSubType {
  VALIDATION = "VALIDATION",
  BUTTON = "BUTTON",
  START = "START",
  FORWARD = "FORWARD",
  BACKWARD = "BACKWARD",
  SUCCESS = "SUCCESS",
}

/** OnlineFormEventType */
export enum OnlineFormEventType {
  ERROR = "ERROR",
  CLICK = "CLICK",
  NAVIGATION = "NAVIGATION",
  SUBMISSION = "SUBMISSION",
  UPDATE = "UPDATE",
}

/** OnlineFormsEvent */
export interface OnlineFormsEvent {
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** Filling Id */
  filling_id?: string | null;
  /** Submission Id */
  submission_id?: string | null;
  /** Domain */
  domain?: string | null;
  /** Uri */
  uri?: string | null;
  /** Ip */
  ip?: string | null;
  /** Zid */
  zid?: string | null;
  /** Git Sha */
  git_sha?: string | null;
  data?: OnlineFormEventData | null;
}

/** OnlineFormsTestingPNIResponse */
export interface OnlineFormsTestingPNIResponse {
  /** Se */
  se: string[];
  /** No */
  no: string[];
}

/** Option */
export interface Option {
  /** Id */
  id: string;
  /** Option */
  option: string;
}

/** OptionalAffiliateApplicant */
export interface OptionalAffiliateApplicant {
  /**
   * Pni
   * Personal national identifier fo the customer
   */
  pni: string;
  role: AffiliateApplicantRole;
  /** Occupation */
  occupation:
    | ({
        type: "COMPANY_OWNER";
      } & AffiliateEmployer)
    | ({
        type: "EARLY_RETIRED";
      } & AffiliateNotEmployed)
    | ({
        type: "FULL";
      } & AffiliateEmployer)
    | ({
        type: "HOURLY";
      } & AffiliateEmployer)
    | ({
        type: "NO_WORK";
      } & AffiliateNotEmployed)
    | ({
        type: "PROJECT";
      } & AffiliateEmployer)
    | ({
        type: "RETIRED";
      } & AffiliateNotEmployed)
    | ({
        type: "SELF_EMPLOYED";
      } & AffiliateEmployer)
    | ({
        type: "TRIAL";
      } & AffiliateEmployer);
  /** Incomes */
  incomes: AffiliateIncome[];
  /** Phone Number */
  phone_number: string;
  /**
   * Email
   * @format email
   */
  email: string;
  /** How does the person live */
  currently_living_in?: AffiliateHousingType | null;
  /** Marital status of a person */
  marital_status: AffiliateMaritalStatus;
}

/** OptionalAffiliateApplicantBlanco */
export interface OptionalAffiliateApplicantBlanco {
  /**
   * Pni
   * Personal national identifier fo the customer
   */
  pni: string;
  role: AffiliateApplicantRole;
  /** Occupation */
  occupation:
    | ({
        type: "COMPANY_OWNER";
      } & AffiliateEmployer)
    | ({
        type: "EARLY_RETIRED";
      } & AffiliateNotEmployed)
    | ({
        type: "FULL";
      } & AffiliateEmployer)
    | ({
        type: "HOURLY";
      } & AffiliateEmployer)
    | ({
        type: "NO_WORK";
      } & AffiliateNotEmployed)
    | ({
        type: "PROJECT";
      } & AffiliateEmployer)
    | ({
        type: "RETIRED";
      } & AffiliateNotEmployed)
    | ({
        type: "SELF_EMPLOYED";
      } & AffiliateEmployer)
    | ({
        type: "TRIAL";
      } & AffiliateEmployer);
  /** Incomes */
  incomes: AffiliateIncome[];
  /** Phone Number */
  phone_number: string;
  /**
   * Email
   * @format email
   */
  email: string;
  /** How does the person live */
  currently_living_in: AffiliateHousingType;
  /** Marital status of a person */
  marital_status: AffiliateMaritalStatus;
  /**
   * Currently Living In Cost
   * How much does the person pay for their housing, full SEK
   * @min 0
   */
  currently_living_in_cost: number;
}

/** OptionalApplicantNO */
export interface OptionalApplicantNO {
  /**
   * Country
   * @default "NO"
   */
  country?: "NO";
  /** Pni */
  pni?: string | null;
  /** First Name */
  first_name?: string | null;
  /** Last Name */
  last_name?: string | null;
  /** Email */
  email?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Additional Phone Numbers */
  additional_phone_numbers?: string[] | null;
  /** Monthly Property Cost */
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;
  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  /** Post Code */
  post_code?: string | null;
  /** City */
  city?: string | null;
  /** Incomes */
  incomes?: Income[] | null;
  /** Net Monthly Income */
  net_monthly_income?: number | null;
  account?: Account | null;
  /** Street Address */
  street_address?: string | null;
  /**
   * Paying Child Support
   * Number of children to pay child support for
   */
  paying_child_support?: number | null;
  /**
   * Receiving Child Support
   * Number of children to receive child support for
   */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  /** Consents Popup Dismissed Timestamp */
  consents_popup_dismissed_timestamp?: string | null;
  /** Partner Pni */
  partner_pni?: string | null;
  /** Partner Name */
  partner_name?: string | null;
  /** Municipality Code */
  municipality_code?: string | null;
  /** Municipality */
  municipality?: string | null;
  /** Is Pep */
  is_pep?: boolean | null;
  /** Age */
  age?: number | null;
  /** Other Phones */
  other_phones?: AppsmgrModelsPhonePhoneNumber[] | null;
  /** Sverker Id */
  sverker_id?: number | null;
  /** Children */
  children?: number | null;
  /** Citizen */
  citizen?: number | null;
  education?: EducationType | null;
  /** Monthly Partner Income */
  monthly_partner_income?: number | null;
  /** Immigrated At */
  immigrated_at?: string | null;
  /** Fullname */
  fullname?: string | null;
  /** Documents */
  documents?: ApplicantDocument[] | null;
  /** Verified Credits */
  verified_credits?: boolean | null;
  /** Housing Since */
  housing_since?: string | null;
  /** Deceased */
  deceased?: boolean | null;
  /** Emigrated */
  emigrated?: boolean | null;
  /** Is Sanction */
  is_sanction?: boolean | null;
  /** Is Rca */
  is_rca?: boolean | null;
  /** Date Created */
  date_created?: string | null;
  /** Date Updated */
  date_updated?: string | null;
  /** Last Credit Check */
  last_credit_check?: string | null;
  /** Last Externally Verified Credits */
  last_externally_verified_credits?: string | null;
  /** Last Property Valuation */
  last_property_valuation?: string | null;
  /** Pep Quality */
  pep_quality?: boolean | null;
  /**
   * Cluster
   * A cluster is a group of customers displayed with a color
   */
  cluster?: string | null;
  /**
   * Is Blocked
   * Indicates if the customer is blocked
   * @default false
   */
  is_blocked?: boolean;
  housing_type?: HousingTypeNO | null;
  employer?: OptionalEmployerNO | null;
  /** Ev Estate */
  ev_estate?: EvEstate[] | null;
  /** Ev Estates */
  ev_estates?: EvProperty[] | null;
  /** Citizenship Iso Codes */
  citizenship_iso_codes?: string[] | null;
  /** Externally Verified Credits */
  externally_verified_credits?: ExternallyVerifiedCredit[] | null;
  /** Customer Id */
  customer_id?: string | null;
  credit_check?: CreditCheckNO | null;
  external_block_check?: ExternalBlockCheckNO | null;
}

/** OptionalApplicantSE */
export interface OptionalApplicantSE {
  /**
   * Country
   * @default "SE"
   */
  country?: "SE";
  /** Pni */
  pni?: string | null;
  /** First Name */
  first_name?: string | null;
  /** Last Name */
  last_name?: string | null;
  /** Email */
  email?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Additional Phone Numbers */
  additional_phone_numbers?: string[] | null;
  /** Monthly Property Cost */
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;
  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  /** Post Code */
  post_code?: string | null;
  /** City */
  city?: string | null;
  /** Incomes */
  incomes?: Income[] | null;
  /** Net Monthly Income */
  net_monthly_income?: number | null;
  account?: Account | null;
  /** Street Address */
  street_address?: string | null;
  /**
   * Paying Child Support
   * Number of children to pay child support for
   */
  paying_child_support?: number | null;
  /**
   * Receiving Child Support
   * Number of children to receive child support for
   */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  /** Consents Popup Dismissed Timestamp */
  consents_popup_dismissed_timestamp?: string | null;
  /** Partner Pni */
  partner_pni?: string | null;
  /** Partner Name */
  partner_name?: string | null;
  /** Municipality Code */
  municipality_code?: string | null;
  /** Municipality */
  municipality?: string | null;
  /** Is Pep */
  is_pep?: boolean | null;
  /** Age */
  age?: number | null;
  /** Other Phones */
  other_phones?: AppsmgrModelsPhonePhoneNumber[] | null;
  /** Sverker Id */
  sverker_id?: number | null;
  /** Children */
  children?: number | null;
  /** Citizen */
  citizen?: number | null;
  education?: EducationType | null;
  /** Monthly Partner Income */
  monthly_partner_income?: number | null;
  /** Immigrated At */
  immigrated_at?: string | null;
  /** Fullname */
  fullname?: string | null;
  /** Documents */
  documents?: ApplicantDocument[] | null;
  /** Verified Credits */
  verified_credits?: boolean | null;
  /** Housing Since */
  housing_since?: string | null;
  /** Deceased */
  deceased?: boolean | null;
  /** Emigrated */
  emigrated?: boolean | null;
  /** Is Sanction */
  is_sanction?: boolean | null;
  /** Is Rca */
  is_rca?: boolean | null;
  /** Date Created */
  date_created?: string | null;
  /** Date Updated */
  date_updated?: string | null;
  /** Last Credit Check */
  last_credit_check?: string | null;
  /** Last Externally Verified Credits */
  last_externally_verified_credits?: string | null;
  /** Last Property Valuation */
  last_property_valuation?: string | null;
  /** Pep Quality */
  pep_quality?: any;
  /**
   * Cluster
   * A cluster is a group of customers displayed with a color
   */
  cluster?: string | null;
  /**
   * Is Blocked
   * Indicates if the customer is blocked
   * @default false
   */
  is_blocked?: boolean;
  housing_type?: HousingTypeSE | null;
  employer?: OptionalEmployerSE | null;
  /** Census Registration Year */
  census_registration_year?: string | null;
  /** Credit Regulation Information */
  credit_regulation_information?: CreditRegulationInformation[] | null;
  /** Credit Regulations */
  credit_regulations?: CreditRegulation[] | null;
  /** Case Collection */
  case_collection?: CaseCollectionItem[] | null;
  /** Current Section Of Case */
  current_section_of_case?: CurrentSectionOfCaseItem[] | null;
  /** Current Frame */
  current_frame?: CurrentFrameItem[] | null;
  /** Html */
  html?: string | null;
  /** Last Queries */
  last_queries?: LastQuery[] | null;
  /** Notes */
  notes?: Note[] | null;
  /** Tax */
  tax?: TaxItem[] | null;
  /** Tax Thousands */
  tax_thousands?: TaxThousand[] | null;
  /** Fraudster */
  fraudster?: boolean | null;
  /** Investigation Barrier */
  investigation_barrier?: boolean | null;
  /** Uc Interall */
  uc_interall?: string | null;
  /** Date Of Birth */
  date_of_birth?: string | null;
  /** Prenup Text */
  prenup_text?: string | null;
  /** Uc Internal 2 */
  uc_internal_2?: null;
  /** Parish */
  parish?: string | null;
  /** Parish Code */
  parish_code?: string | null;
  /** Blocked */
  blocked?: boolean | null;
  /** Protected Address */
  protected_address?: boolean | null;
  /** Protected Identity */
  protected_identity?: boolean | null;
  /** Marital Status Date */
  marital_status_date?: string | null;
  /** Minor */
  minor?: string | null;
  /** Lost Id Documents */
  lost_id_documents?: boolean | null;
  /** County */
  county?: string | null;
  /** County Code */
  county_code?: string | null;
  /** Customer Id */
  customer_id?: string | null;
  credit_check?: CreditCheckSE | null;
}

/**
 * OptionalCreateApplicantNO
 * Model used only for mapping jotform data to Applicant model, allowing some more fields to be optional
 */
export interface OptionalCreateApplicantNO {
  /**
   * Country
   * @default "NO"
   */
  country?: "NO";
  /** Pni */
  pni: string | null;
  /** First Name */
  first_name?: string | null;
  /** Last Name */
  last_name?: string | null;
  /** Email */
  email?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Additional Phone Numbers */
  additional_phone_numbers?: string[] | null;
  /** Monthly Property Cost */
  monthly_property_cost?: number | null;
  marital_status?: MaritalStatus | null;
  /** Official marital status from ID check; should not be modified; marital_status has higher priority */
  registered_marital_status?: MaritalStatus | null;
  /** Post Code */
  post_code?: string | null;
  /** City */
  city?: string | null;
  /** Incomes */
  incomes?: Income[] | null;
  /** Net Monthly Income */
  net_monthly_income?: number | null;
  account?: Account | null;
  /** Street Address */
  street_address?: string | null;
  /**
   * Paying Child Support
   * Number of children to pay child support for
   */
  paying_child_support?: number | null;
  /**
   * Receiving Child Support
   * Number of children to receive child support for
   */
  receiving_child_support?: number | null;
  consents?: Consents | null;
  /** Consents Popup Dismissed Timestamp */
  consents_popup_dismissed_timestamp?: string | null;
  /** Partner Pni */
  partner_pni?: string | null;
  /** Partner Name */
  partner_name?: string | null;
  /** Municipality Code */
  municipality_code?: string | null;
  /** Municipality */
  municipality?: string | null;
  /** Is Pep */
  is_pep?: boolean | null;
  /** Age */
  age?: number | null;
  /** Other Phones */
  other_phones?: AppsmgrModelsPhonePhoneNumber[] | null;
  /** Sverker Id */
  sverker_id?: number | null;
  /** Children */
  children?: number | null;
  /** Citizen */
  citizen?: number | null;
  education?: EducationType | null;
  /** Monthly Partner Income */
  monthly_partner_income?: number | null;
  /** Immigrated At */
  immigrated_at?: string | null;
  /** Fullname */
  fullname?: string | null;
  /** Documents */
  documents?: ApplicantDocument[] | null;
  /** Verified Credits */
  verified_credits?: boolean | null;
  /** Housing Since */
  housing_since?: string | null;
  /** Deceased */
  deceased?: boolean | null;
  /** Emigrated */
  emigrated?: boolean | null;
  /** Is Sanction */
  is_sanction?: boolean | null;
  /** Is Rca */
  is_rca?: boolean | null;
  /** Date Created */
  date_created?: string | null;
  /** Date Updated */
  date_updated?: string | null;
  /** Last Credit Check */
  last_credit_check?: string | null;
  /** Last Externally Verified Credits */
  last_externally_verified_credits?: string | null;
  /** Last Property Valuation */
  last_property_valuation?: string | null;
  /** Pep Quality */
  pep_quality?: boolean | null;
  /**
   * Cluster
   * A cluster is a group of customers displayed with a color
   */
  cluster?: string | null;
  /**
   * Is Blocked
   * Indicates if the customer is blocked
   * @default false
   */
  is_blocked?: boolean;
  housing_type?: HousingTypeNO | null;
  employer?: OptionalEmployerNO | null;
}

/** OptionalCreditCardApplicationDataCreate */
export interface OptionalCreditCardApplicationDataCreate {
  /** Desired Credit Limit */
  desired_credit_limit?: number | null;
  /** Car */
  car?: boolean | null;
  /** Student Loans Amount */
  student_loans_amount?: number | null;
  /** Student Loans Monthly Payment */
  student_loans_monthly_payment?: number | null;
  /** Car Boat Mc Loans Amount */
  car_boat_mc_loans_amount?: number | null;
  /** Car Boat Mc Loans Monthly Payment */
  car_boat_mc_loans_monthly_payment?: number | null;
  /** Total Consumer Loans Amount */
  total_consumer_loans_amount?: number | null;
  /** Total Consumer Loans Monthly Payment */
  total_consumer_loans_monthly_payment?: number | null;
  /** Mortgage Loans Amount */
  mortgage_loans_amount?: number | null;
  /** Mortgage Loans Monthly Payment */
  mortgage_loans_monthly_payment?: number | null;
  /** Credit Card Debt Amount */
  credit_card_debt_amount?: number | null;
  /** Credit Card Debt Monthly Payment */
  credit_card_debt_monthly_payment?: number | null;
  /** Children In The Household Fulltime */
  children_in_the_household_fulltime?: number | null;
}

/** OptionalEmployerNO */
export interface OptionalEmployerNO {
  /** Name */
  name?: string | null;
  type?: EmploymentTypeNO | null;
  /** Start Date */
  start_date?: string | null;
  /** End Date */
  end_date?: string | null;
  /** Phone */
  phone?: string | null;
  /** Retirement Age */
  retirement_age?: number | null;
  /** Organization Number */
  organization_number?: string | null;
  /** Profession */
  profession?: string | null;
}

/** OptionalEmployerSE */
export interface OptionalEmployerSE {
  /** Name */
  name?: string | null;
  type?: EmploymentTypeSE | null;
  /** Start Date */
  start_date?: string | null;
  /** End Date */
  end_date?: string | null;
  /** Phone */
  phone?: string | null;
  /** Retirement Age */
  retirement_age?: number | null;
  /** Organization Number */
  organization_number?: string | null;
  /** Profession */
  profession?: string | null;
}

/** OptionalPropertyAddress */
export interface OptionalPropertyAddress {
  /**
   * Streetaddress
   * Street address of property
   */
  streetAddress?: string | null;
  /**
   * City
   * City of the property
   */
  city?: string | null;
  /**
   * Postalcode
   * Postal code of the property
   */
  postalCode?: string | null;
  /** Municipality */
  municipality?: string | null;
}

/** OptionalQuickApplicant */
export interface OptionalQuickApplicant {
  /**
   * Pni
   * Personal national identifier fo the customer
   */
  pni?: string | null;
  role?: ApplicantRole | null;
  employer?: OptionalQuickApplicantEmployer | null;
  /** Incomes */
  incomes?: (Income | null)[] | null;
  /** Phone Number */
  phone_number?: string | null;
  /** Email */
  email?: string | null;
  /**
   * Housing Type
   * How does the person live
   */
  housing_type?: HousingTypeSE | HousingTypeNO | null;
  /**
   * Currently Living In Cost
   * How much does the person pay for their housing, full SEK
   */
  currently_living_in_cost?: number | null;
  /** Marital status of a person */
  marital_status?: MaritalStatus | null;
  education?: EducationType | null;
  /**
   * Immigrated At
   * NORWAY only - when immigrated to Norway
   */
  immigrated_at?: string | null;
  /**
   * Citizenship
   * NORWAY only - is applicant Norway citizen or not
   */
  citizenship?: boolean | null;
}

/** OptionalQuickApplicantEmployer */
export interface OptionalQuickApplicantEmployer {
  /** Name */
  name?: string | null;
  /** Type */
  type?: EmploymentType | null;
  /** Start Date */
  start_date?: string | null;
  /** End Date */
  end_date?: string | null;
  /** Phone */
  phone?: string | null;
  /** Retirement Age */
  retirement_age?: number | null;
  /** Organization Number */
  organization_number?: string | null;
  /** Profession */
  profession?: string | null;
}

/** OptionalQuickChildren */
export interface OptionalQuickChildren {
  /**
   * Childrenbelow18
   * Number of children under the age of 18
   */
  childrenBelow18?: number | null;
  /** Childrenbelow18Fulltime */
  childrenBelow18FullTime?: number | null;
  /** Receivingchildsupport */
  receivingChildSupport?: number | null;
  /** Payingchildsupport */
  payingChildSupport?: number | null;
}

/** OptionalQuickCreateBlancoApplication */
export interface OptionalQuickCreateBlancoApplication {
  /** Product */
  product: "blanco";
  new_loan?: BlancoLoan | null;
  /** Sverker user when creating a legacy blanco app */
  sverker_user?: SverkerUsername | null;
  aml?: BlancoAMLPatchPayload | null;
  /**
   * Desired Amount
   * Desired loan amount
   */
  desired_amount?: number | null;
  country: Country;
  /** Meeting Start */
  meeting_start?: string | null;
  /** Meeting End */
  meeting_end?: string | null;
  /** Meeting Subject */
  meeting_subject?: string | null;
  /** Should Remind About Meeting */
  should_remind_about_meeting?: boolean | null;
  /** Applicants */
  applicants: OptionalQuickApplicant[];
  /** Loans */
  loans?: Loan[] | null;
  children?: OptionalQuickChildren | null;
  /** Internal Comment */
  internal_comment?: string | null;
  /** Comments */
  comments?: string[] | null;
  /** New App Options */
  new_app_options?: Option[] | null;
  /** New App Checklist */
  new_app_checklist?: string[] | null;
  source?: ApplicationSource | null;
  /** Desired Payback Time */
  desired_payback_time?: number | null;
  consents?: Consents | null;
}

/** OptionalQuickCreateMortgageApplication */
export interface OptionalQuickCreateMortgageApplication {
  /** Product */
  product: "mortgage";
  new_loan?: BlancoLoan | null;
  /**
   * Desired Amount
   * Desired loan amount
   */
  desired_amount?: number | null;
  country: Country;
  /** Meeting Start */
  meeting_start?: string | null;
  /** Meeting End */
  meeting_end?: string | null;
  /** Meeting Subject */
  meeting_subject?: string | null;
  /** Should Remind About Meeting */
  should_remind_about_meeting?: boolean | null;
  /** Applicants */
  applicants: OptionalQuickApplicant[];
  /** Loans */
  loans?: Loan[] | null;
  children?: OptionalQuickChildren | null;
  /** Internal Comment */
  internal_comment?: string | null;
  /** Comments */
  comments?: string[] | null;
  /** New App Options */
  new_app_options?: Option[] | null;
  /** New App Checklist */
  new_app_checklist?: string[] | null;
  source?: ApplicationSource | null;
  /** Desired Payback Time */
  desired_payback_time?: number | null;
  consents?: Consents | null;
}

/** Order */
export interface Order {
  /**
   * Created At
   * @default "desc"
   */
  created_at?: "asc" | "desc" | null;
  /**
   * Updated At
   * @default "desc"
   */
  updated_at?: "asc" | "desc" | null;
}

/** OtherProperty */
export interface OtherProperty {
  /** Monthly Cost */
  monthly_cost: number;
  /** Mortgage Amount */
  mortgage_amount?: number | null;
  property_type: PropertyTypeOutput;
  /** Estimated Value */
  estimated_value?: number | null;
  /** Property Ownership Share */
  property_ownership_share?: number | null;
  /** Loan Ownership Share */
  loan_ownership_share?: number | null;
  /** Interest Rate */
  interest_rate?: number | null;
  /** Monthly Amortization */
  monthly_amortization?: number | null;
  /** Lender */
  lender?: string | null;
}

/** OutcomeCount */
export interface OutcomeCount {
  /** Users */
  users: UserOutcomeCount[];
  total: UserOutcomeCount;
}

/** OutcomeType */
export enum OutcomeType {
  SUCCESS = "SUCCESS",
  CALL_BACK = "CALL_BACK",
  NO_CONTACT = "NO_CONTACT",
  FAILURE = "FAILURE",
  DISCARD = "DISCARD",
}

/** Outcomes */
export interface Outcomes {
  /** No Contact */
  no_contact: string[];
  /** Failure */
  failure: string[];
  /** Discard */
  discard: string[];
}

/** PNI */
export interface PNI {
  country?: Country | null;
  /** Pni */
  pni: string;
}

/** Page[CampaignExtraction] */
export interface PageCampaignExtraction {
  /** Items */
  items: CampaignExtraction[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** Page[Campaign] */
export interface PageCampaign {
  /** Items */
  items: ModelsDialMaker2CampaignAggregateCampaign[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** Page[CreditCardApplicationExtended] */
export interface PageCreditCardApplicationExtended {
  /** Items */
  items: CreditCardApplicationExtended[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** Page[DMLead] */
export interface PageDMLead {
  /** Items */
  items: DMLead[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** Page[Sheet] */
export interface PageSheet {
  /** Items */
  items: Sheet[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** Pagination */
export interface Pagination {
  /**
   * Page
   * @default 0
   */
  page?: number | null;
  /**
   * Limit
   * @default 50
   */
  limit?: number | null;
}

/** PartialCondominium */
export interface PartialCondominium {
  /** Property Type */
  property_type: "CONDOMINIUM";
  country: Country;
  /** Street Address */
  street_address?: string | null;
  /** Post Code */
  post_code?: string | null;
  /** City */
  city?: string | null;
  /** Other Owner */
  other_owner?: boolean | null;
  /** Monthly Cost */
  monthly_cost?: number | null;
  /** Square Meter Living Area */
  square_meter_living_area?: number | null;
  /** Municipality */
  municipality?: string | null;
  /** Municipality Code */
  municipality_code?: string | null;
  /** Estimated Value */
  estimated_value?: number | null;
  /** External Id */
  external_id?: string | null;
  /** Latitude */
  latitude?: number | null;
  /** Longitude */
  longitude?: number | null;
  ev_partial?: EvPartialCommon | null;
  /** Apartment Number */
  apartment_number?: string | null;
  /** Elevator */
  elevator?: boolean | null;
  /** Organization Number */
  organization_number?: string | null;
  /** Organization Name */
  organization_name?: string | null;
  /** Floor */
  floor?: number | null;
  balcony_type?: BalconyType | null;
  /** Rooms */
  rooms?: number | null;
}

/** PartialHouse */
export interface PartialHouse {
  /** Property Type */
  property_type: "HOUSE";
  country: Country;
  /** Street Address */
  street_address?: string | null;
  /** Post Code */
  post_code?: string | null;
  /** City */
  city?: string | null;
  /** Other Owner */
  other_owner?: boolean | null;
  /** Monthly Cost */
  monthly_cost?: number | null;
  /** Square Meter Living Area */
  square_meter_living_area?: number | null;
  /** Municipality */
  municipality?: string | null;
  /** Municipality Code */
  municipality_code?: string | null;
  /** Estimated Value */
  estimated_value?: number | null;
  /** External Id */
  external_id?: string | null;
  /** Latitude */
  latitude?: number | null;
  /** Longitude */
  longitude?: number | null;
  ev_partial?: EvPartialCommon | null;
  /** Cadastral Designation */
  cadastral_designation?: string | null;
}

/** PartialProperty */
export interface PartialProperty {
  /** Property Id */
  property_id: string;
  property_type: MortgagePropertyType;
  /** Country */
  country?: string | null;
  /** Monthly Cost */
  monthly_cost?: number | null;
  /** Square Meter Living Area */
  square_meter_living_area?: number | null;
  /** Estimated Value */
  estimated_value?: number | null;
  /** Municipality */
  municipality?: string | null;
  /** Municipality Code */
  municipality_code?: string | null;
}

/** PartialVacationHome */
export interface PartialVacationHome {
  /** Property Type */
  property_type: "VACATION_HOME";
  country: Country;
  /** Street Address */
  street_address?: string | null;
  /** Post Code */
  post_code?: string | null;
  /** City */
  city?: string | null;
  /** Other Owner */
  other_owner?: boolean | null;
  /** Monthly Cost */
  monthly_cost?: number | null;
  /** Square Meter Living Area */
  square_meter_living_area?: number | null;
  /** Municipality */
  municipality?: string | null;
  /** Municipality Code */
  municipality_code?: string | null;
  /** Estimated Value */
  estimated_value?: number | null;
  /** External Id */
  external_id?: string | null;
  /** Latitude */
  latitude?: number | null;
  /** Longitude */
  longitude?: number | null;
  ev_partial?: EvPartialCommon | null;
  /** Cadastral Designation */
  cadastral_designation?: string | null;
}

/**
 * Person
 * These fields only appear in pipeline
 */
export interface Person {
  /** Zid */
  zid: string;
  /** Session Zid */
  session_zid?: string | null;
  /** Pni */
  pni?: string | File | null;
  /** Customer Id */
  customer_id?: string | null;
  /** Gid */
  gid?: string | null;
  /** Affiliate Id */
  affiliate_id?: string | null;
  product?: Product | null;
  country?: Country | null;
  /** Landing Params */
  landing_params?: object | null;
  /** Landing Url */
  landing_url?: string | null;
  /** Cookie Consent */
  cookie_consent?: object | null;
  status: PersonStatus;
  /**
   * Last Activity
   * @format date-time
   */
  last_activity: string;
  /**
   * Initialized At
   * @format date-time
   */
  initialized_at: string;
  /** Last Application Created At */
  last_application_created_at?: string | null;
  /** Last Disbursed At */
  last_disbursed_at?: string | null;
}

/** PersonStatus */
export enum PersonStatus {
  INITIALIZED = "INITIALIZED",
  WEBSITE_VISIT = "WEBSITE_VISIT",
  LEAD_CREATED = "LEAD_CREATED",
  APPLICATION_CREATED = "APPLICATION_CREATED",
  INVITE = "INVITE",
  OFFER_ACCEPTED = "OFFER_ACCEPTED",
  OFFER = "OFFER",
  DISBURSED = "DISBURSED",
  DISCARDED = "DISCARDED",
}

/** PersonalCallback */
export interface PersonalCallback {
  /**
   * First At
   * @format date-time
   */
  first_at: string;
  /**
   * Last At
   * @format date-time
   */
  last_at: string;
  /**
   * Count
   * @exclusiveMin 0
   */
  count: number;
}

/** PhoneGetCustomerPayload */
export interface PhoneGetCustomerPayload {
  /** Reason */
  reason: string;
  /** Phone Number */
  phone_number: string;
}

/** PhoneNumberType */
export enum PhoneNumberType {
  Home = "home",
  Day = "day",
  Work = "work",
  Other = "other",
}

/** PniGetCustomerPayload */
export interface PniGetCustomerPayload {
  /** Reason */
  reason: string;
  country?: Country | null;
  /** Pni */
  pni: string;
}

/** PolicyPreference */
export enum PolicyPreference {
  EXCLUDE = "EXCLUDE",
  INCLUDE = "INCLUDE",
  DONTCARE = "DONTCARE",
}

/** PollResp */
export interface PollResp {
  /** Verified */
  verified: boolean;
  /** Timestamp */
  timestamp?: string | null;
}

/** PrefsResponse */
export interface PrefsResponse {
  /**
   * Statuses
   * @default ["LEAD","DRAFT","ACTIVE","DEACTIVATED"]
   */
  statuses?: AppsmgrModelsStatusStatus[];
  /**
   * Substatuses
   * @default ["DRAFT","UNKNOWN","OFFERED","ACCEPTED","DISBURSED","ACTIVE","DEACTIVATED"]
   */
  substatuses?: Substatus[];
  /**
   * Countries
   * @default ["SE","NO"]
   */
  countries?: Country[];
  /**
   * Property Types
   * @default ["HOUSE","CONDOMINIUM","VACATION_HOME"]
   */
  property_types?: MortgagePropertyType[];
  /** New App Checklist */
  new_app_checklist: Record<string, Record<string, string[]>>;
  /** New App Options */
  new_app_options: Record<string, Record<string, NewAppOption[]>>;
  /**
   * Loan Types
   * @default ["CREDIT_CARD","UNSECURED_LOAN","CAR_LOAN","CHECK_CREDIT","OTHER","STUDENT_LOAN","INSTALLMENT","TOP_MORTGAGE_LOAN","MEMBER_LOAN","MORTGAGE"]
   */
  loan_types?: LoanType[];
}

/** PreviousApplication */
export interface PreviousApplication {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Status */
  status?: Substatus | AppState | null;
  role?: ApplicantRole | null;
  /** Created At */
  created_at?: string | null;
  /** Completed At */
  completed_at?: string | null;
}

/** PreviousApplicationsList */
export interface PreviousApplicationsList {
  /** Mortgage */
  mortgage: PreviousApplication[];
  /** Blanco */
  blanco: PreviousApplication[];
  /** Credit Card */
  credit_card: PreviousApplication[];
}

/** ProcessCampaignSource */
export interface ProcessCampaignSource {
  /**
   * Type
   * @default "PROCESS"
   */
  type?: "PROCESS";
  country: Country;
  /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
  product: ExtendedProduct;
  process: ProcessCampaignType;
  /**
   * Priority
   * @default 100
   */
  priority?: number;
  substatus?: InsuranceSubstatus | null;
}

/** ProcessCampaignType */
export enum ProcessCampaignType {
  QUEUED_FOR_AUTO_INVITE = "QUEUED_FOR_AUTO_INVITE",
  INVITES_SENT = "INVITES_SENT",
  NO_INVITES_SENT = "NO_INVITES_SENT",
  OFFERED = "OFFERED",
  OFFERED_BUT_NO_IMPROVEMENT_TO_CURRENT_LOAN_SITUATION = "OFFERED_BUT_NO_IMPROVEMENT_TO_CURRENT_LOAN_SITUATION",
  NO_OFFERS = "NO_OFFERS",
  NO_OFFERS_BUT_CAN_BE_FIXED = "NO_OFFERS_BUT_CAN_BE_FIXED",
  NOACCEPTAFTER5DAYS = "NO_ACCEPT_AFTER_5_DAYS",
  ACCEPTED_BAD_OFFER = "ACCEPTED_BAD_OFFER",
  ACCEPTED_GOOD_OFFER = "ACCEPTED_GOOD_OFFER",
  REJECTED = "REJECTED",
  REJECTED_HAS_FIXABLE_REMARKS = "REJECTED_HAS_FIXABLE_REMARKS",
  LATE_REJECTED = "LATE_REJECTED",
  DISBURSED = "DISBURSED",
  DISBURSEDNOCONFIRMATIONFROMCREDITORAFTER5DAYS = "DISBURSED_NO_CONFIRMATION_FROM_CREDITOR_AFTER_5_DAYS",
  DEACTIVATED = "DEACTIVATED",
  ABOUT_TO_BE_CANCELED = "ABOUT_TO_BE_CANCELED",
  CANCELED = "CANCELED",
}

/** Product */
export enum Product {
  Blanco = "blanco",
  Mortgage = "mortgage",
  CreditCard = "credit_card",
}

/** PropertyBeingSold */
export interface PropertyBeingSold {
  /** Selling Contract Signed */
  selling_contract_signed?: boolean | null;
  /** Property Price */
  property_price?: number | null;
  /** Existing Mortgage Amount */
  existing_mortgage_amount?: number | null;
  /** Real Estate Agent Fee */
  real_estate_agent_fee?: number | null;
}

/** PropertyInfo */
export interface PropertyInfo {
  property_type: MortgagePropertyType;
  /** Id */
  id: string;
  /** Apartments */
  apartments?: Apartment[] | null;
  /** Street Address */
  street_address: string;
  /** Post Code */
  post_code: string;
  /** City */
  city: string;
  country: Country;
  /** Municipality Code */
  municipality_code: string;
  /** Municipality */
  municipality: string;
  /** Organization Name */
  organization_name?: string | null;
  /** Organization Number */
  organization_number?: string | null;
  /** External Id */
  external_id?: string | null;
}

/** PropertySearchResult */
export interface PropertySearchResult {
  /** Id */
  id: string;
  /** Description */
  description: string;
}

/** PropertyType */
export enum PropertyTypeOutput {
  HOUSE = "HOUSE",
  CONDOMINIUM = "CONDOMINIUM",
  VACATION_HOME = "VACATION_HOME",
  RENTING = "RENTING",
}

/** PropertyValuation */
export interface PropertyValuation {
  property_type: MortgagePropertyType;
  /** Property Id */
  property_id: string;
  /** Living Area */
  living_area?: number | null;
  /** Apartment Number */
  apartment_number?: string | null;
  /** External Id */
  external_id?: string | null;
  /** Municipality */
  municipality: string;
  /** Municipality Code */
  municipality_code: string | null;
  /** Square Meter Living Area */
  square_meter_living_area: number;
  /** Latitude */
  latitude?: number | null;
  /** Longitude */
  longitude?: number | null;
  /** Value */
  value?: number | null;
  /** Booli Value */
  booli_value?: number | null;
  [key: string]: any;
}

/** PublicApplicant */
export interface PublicApplicant {
  /** First Name */
  first_name?: string | null;
  /** Last Name */
  last_name?: string | null;
  /** Email */
  email?: string | null;
  /** Phone Number */
  phone_number?: string | null;
  country: Country;
}

/** PublisherData */
export interface PublisherData {
  /** Publisher Id */
  publisher_id: string;
  callback_trigger: PersonStatus;
  touch: Touch;
  /** Callback Time Limit In Days */
  callback_time_limit_in_days: number;
  callback_trigger_blanco?: PersonStatus | null;
  callback_trigger_mortgage?: PersonStatus | null;
  callback_trigger_credit_card?: PersonStatus | null;
}

/** QRCodeContent */
export interface QRCodeContent {
  /** Qr Code Content */
  qr_code_content: string;
  /** Verified */
  verified: boolean;
}

/** QuickApplicant */
export interface QuickApplicant {
  /**
   * Pni
   * Personal national identifier fo the customer
   */
  pni: string;
  role: ApplicantRole;
  employer: QuickApplicantEmployer;
  /** Incomes */
  incomes: (Income | null)[];
  /** Phone Number */
  phone_number: string;
  /**
   * Email
   * @format email
   */
  email: string;
  /**
   * Housing Type
   * How does the person live
   */
  housing_type: HousingTypeSE | HousingTypeNO;
  /**
   * Currently Living In Cost
   * How much does the person pay for their housing, full SEK
   * @min 0
   */
  currently_living_in_cost: number;
  /** Marital status of a person */
  marital_status: MaritalStatus;
  education?: EducationType | null;
  /**
   * Immigrated At
   * NORWAY only - when immigrated to Norway
   */
  immigrated_at?: string | null;
  /**
   * Citizenship
   * NORWAY only - is applicant Norway citizen or not
   */
  citizenship?: boolean | null;
  /** Net Monthly Income */
  net_monthly_income?: number | null;
}

/** QuickApplicantEmployer */
export interface QuickApplicantEmployer {
  /** Name */
  name: string;
  /** Type */
  type?: EmploymentType | null;
  /** Start Date */
  start_date?: string | null;
  /** End Date */
  end_date?: string | null;
  /** Phone */
  phone?: string | null;
  /** Retirement Age */
  retirement_age?: number | null;
  /** Organization Number */
  organization_number?: string | null;
  /** Profession */
  profession?: string | null;
}

/** QuickChildren */
export interface QuickChildren {
  /**
   * Childrenbelow18
   * @min 0
   * @max 10
   */
  childrenBelow18: number;
  /** Childrenbelow18Fulltime */
  childrenBelow18FullTime?: number | null;
  /** Receivingchildsupport */
  receivingChildSupport?: number | null;
  /** Payingchildsupport */
  payingChildSupport?: number | null;
}

/** QuickCreateBlancoApplication */
export interface QuickCreateBlancoApplication {
  /** Product */
  product: "blanco";
  new_loan?: BlancoLoan | null;
  /** Sverker user when creating a legacy blanco app */
  sverker_user?: SverkerUsername | null;
  aml?: BlancoAMLPatchPayload | null;
  /**
   * Desired Amount
   * Desired loan amount
   */
  desired_amount?: number | null;
  country: Country;
  /** Meeting Start */
  meeting_start?: string | null;
  /** Meeting End */
  meeting_end?: string | null;
  /** Meeting Subject */
  meeting_subject?: string | null;
  /** Should Remind About Meeting */
  should_remind_about_meeting?: boolean | null;
  /** Applicants */
  applicants: QuickApplicant[];
  /** Loans */
  loans: Loan[];
  children: QuickChildren;
  /** Internal Comment */
  internal_comment?: string | null;
  /** Comments */
  comments?: string[] | null;
  /** New App Options */
  new_app_options: Option[];
  /** New App Checklist */
  new_app_checklist: string[];
  source: ApplicationSource;
  /** Desired Payback Time */
  desired_payback_time?: number | null;
  consents?: Consents | null;
}

/** QuickCreateMortgageApplication */
export interface QuickCreateMortgageApplication {
  /** Product */
  product: "mortgage";
  new_loan?: BlancoLoan | null;
  /**
   * Desired Amount
   * Desired loan amount
   */
  desired_amount?: number | null;
  country: Country;
  /** Meeting Start */
  meeting_start?: string | null;
  /** Meeting End */
  meeting_end?: string | null;
  /** Meeting Subject */
  meeting_subject?: string | null;
  /** Should Remind About Meeting */
  should_remind_about_meeting?: boolean | null;
  /** Applicants */
  applicants: QuickApplicant[];
  /** Loans */
  loans: Loan[];
  children: QuickChildren;
  /** Internal Comment */
  internal_comment?: string | null;
  /** Comments */
  comments?: string[] | null;
  /** New App Options */
  new_app_options: Option[];
  /** New App Checklist */
  new_app_checklist: string[];
  source: ApplicationSource;
  /** Desired Payback Time */
  desired_payback_time?: number | null;
  consents?: Consents | null;
}

/** RatePart */
export interface RatePart {
  rate_type: any;
  /** Amount */
  amount?: number | null;
  /** Rate */
  rate?: number | null;
  /** Monthly Payment */
  monthly_payment?: number | null;
  /** Until */
  until?: string | null;
  /** Loannumber */
  loanNumber?: string | null;
  /** Conditionrolloverdate */
  conditionRolloverDate?: string | null;
  /** Monthlyamortisation */
  monthlyAmortisation?: number | null;
}

/** RateType */
export enum RateType {
  Fixed = "fixed",
  Flexible = "flexible",
  Mixed = "mixed",
}

/** ReactivatePayload */
export interface ReactivatePayload {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Comment */
  comment: string;
}

/**
 * RefinanceInfo
 * In SE we can only refinance one existing mortgage so this object is the loan to be refinanced,
 * not used in NO
 */
export interface RefinanceInfo {
  /** Current Bank Id */
  current_bank_id: number;
  /** Amount */
  amount?: number | null;
  /** Rate */
  rate?: number | null;
  /** Monthly Payment */
  monthly_payment?: number | null;
  /** Rate Parts */
  rate_parts?: RatePart[] | null;
  rate_type?: null;
  /** Ownershipshareofloan */
  ownershipShareOfLoan?: number | null;
  /** Mortgagebank */
  mortgageBank?: string | null;
  /** Amortisationrulebeforemarch2018 */
  amortisationRuleBeforeMarch2018?: boolean | null;
}

/** RelationshipStatus */
export enum RelationshipStatus {
  Spouse = "spouse",
  Child = "child",
  Parent = "parent",
  Partner = "partner",
}

/** RenderTemplatePayload */
export interface RenderTemplatePayload {
  /** Mandrill Name */
  mandrill_name: string;
  /** Template Content */
  template_content: MergeVarContent[];
  /** Merge Vars */
  merge_vars: MergeVarContent[];
}

/** ReprocessResult */
export interface ReprocessResult {
  /** Lead Id */
  lead_id: string;
  status: ReprocessStatus;
  /** Error */
  error?: string | null;
  /** Details */
  details?: string | null;
}

/** ReprocessStatus */
export enum ReprocessStatus {
  Success = "success",
  Failure = "failure",
  Pending = "pending",
}

/** ReprocessSubmissionPayload */
export interface ReprocessSubmissionPayload {
  /** Lead Ids */
  lead_ids: string[];
}

/** RequestNewOTP */
export interface RequestNewOTP {
  /** Token */
  token: string;
}

/** ResetPasswordPayload */
export interface ResetPasswordPayload {
  /** User Id */
  user_id: string;
}

/** Responsibility */
export enum ResponsibilityOutput {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  CO_APPLICANT = "CO_APPLICANT",
  SHARED = "SHARED",
}

/** ResubmitPayload */
export interface ResubmitPayload {
  /** Submission Id */
  submission_id: string;
  country: Country;
  /** Parsed Form */
  parsed_form:
    | (
        | ({
            product: "blanco";
          } & QuickCreateBlancoApplication)
        | ({
            product: "mortgage";
          } & QuickCreateMortgageApplication)
      )
    | CreateCreditCardApplicationPayloadWithApplicant;
}

/** RetryPolicy */
export interface RetryPolicy {
  /**
   * Retry Count
   * @default 5
   */
  retry_count?: number;
  /**
   * Retry Interval Minutes
   * @default 60
   */
  retry_interval_minutes?: number;
}

/** ReviewSubmissions */
export interface ReviewSubmissions {
  /**
   * Submission Ids
   * List of submission ids
   */
  submission_ids: string[];
  /**
   * Handled
   * Mark submissions as handled or not
   * @default true
   */
  handled?: boolean;
}

/** Reviewer */
export interface Reviewer {
  /** User Id */
  user_id: string;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
}

/** RoleType */
export enum RoleType {
  EmployeeAdmin = "employee:admin",
  PartnerAdmin = "partner:admin",
  MortgageAdmin = "mortgage:admin",
  LegalAdmin = "legal:admin",
  ZensumSakerReadSalesStats = "zensum-saker:read:sales-stats",
  ZakerInsuranceCreate = "zaker:insurance:create",
  Admin = "admin",
  ImpersonateCustomer = "impersonate-customer",
  REMOTE_ACCESS = "REMOTE_ACCESS",
  READ_BLANCO_SALES_STATS = "READ_BLANCO_SALES_STATS",
  READ_MORTGAGE_SALES_STATS = "READ_MORTGAGE_SALES_STATS",
  InsuranceAdmin = "insurance:admin",
  OnlineAdmin = "online:admin",
  MbAdmin = "mb:admin",
  KdltAdmin = "kdlt:admin",
  DataAdmin = "data:admin",
  TemplateAdmin = "template:admin",
  AffiliateAdmin = "affiliate:admin",
  Developer = "developer",
  TmCampaignAdmin = "tm-campaign:admin",
  TmCampaignRead = "tm-campaign:read",
  DialmakerAdmin = "dialmaker:admin",
  TeamLead = "team-lead",
  MailmakerAdmin = "mailmaker:admin",
}

/** RootModel[Union[EmailTemplate, SmsTemplate]] */
export type RootModelUnionEmailTemplateSmsTemplate = EmailTemplate | SmsTemplate;

/** RootModel[Union[MyStats, MyStatsMb]] */
export type RootModelUnionMyStatsMyStatsMb = MyStats | MyStatsMb;

/** RootModel[Union[SummarizedView, MyStatsMb]] */
export type RootModelUnionSummarizedViewMyStatsMb = SummarizedView | MyStatsMb;

/** RqJobStatusResponse */
export interface RqJobStatusResponse {
  /** Job Id */
  job_id?: string | null;
  /** Queue */
  queue?: string | null;
  /** Created At */
  created_at?: string | null;
  /** Description */
  description?: string | null;
  /** Exception Info */
  exception_info?: string | null;
  /** Retries Left */
  retries_left?: number | null;
  /** Retry Intervals */
  retry_intervals?: number[] | null;
  /** Retry At */
  retry_at?: string[] | null;
  /** Status */
  status?: string | null;
  /** Meta */
  meta?: object | null;
  /** Kwargs */
  kwargs?: object | null;
}

/** SEApplicationForm */
export interface SEApplicationForm {
  /**
   * Example-Loan-Payback-Time
   * @min 0
   * @default 0
   */
  "example-loan-payback-time"?: number;
  /**
   * Loan-Amount-Text-Field
   * @min 0
   * @default 0
   */
  "loan-amount-text-field"?: number;
  /**
   * Total-Loan-Amount
   * @min 0
   * @default 0
   */
  "total-loan-amount"?: number;
  /**
   * Slider
   * @min 0
   * @default 0
   */
  slider?: number;
  /**
   * Refinance-Question
   * @default false
   */
  "refinance-question"?: boolean;
  /**
   * Refinance-Amount
   * @default 0
   */
  "refinance-amount"?: number;
  /** Original-Loan-Purpose */
  "original-loan-purpose"?: string | null;
  "marital-status"?: MaritalStatus | null;
  /**
   * Number-Of-Children
   * @default 0
   */
  "number-of-children"?: number | null;
  /**
   * Rent-Part
   * @default "0"
   */
  "rent-part"?: string | null;
  /** @default "other" */
  "employment-type"?: EmploymentType;
  /** Employer */
  employer?: string | null;
  /** Employed-Year */
  "employed-year"?: string | null;
  /** Employed-Month */
  "employed-month"?: string | null;
  /** Employed-To-Year */
  "employed-to-year"?: string | null;
  /** Employed-To-Month */
  "employed-to-month"?: string | null;
  /** Company-Industry */
  "company-industry"?: string | null;
  /** Email */
  email?: string | null;
  /**
   * Ssn
   * @default ""
   */
  ssn?: string;
  /**
   * Email-Marketing
   * @default false
   */
  "email-marketing"?: boolean;
  /**
   * Sms-Marketing
   * @default false
   */
  "sms-marketing"?: boolean;
  /**
   * Customer-Club-Marketing
   * @default false
   */
  "customer-club-marketing"?: boolean;
  /**
   * Applicant-Pep
   * @default false
   */
  "applicant-pep"?: boolean;
  /**
   * Ms-Enabled
   * @default false
   */
  "ms-enabled"?: boolean;
  "ms-marital-status"?: MaritalStatus | null;
  /** Ms-Number-Of-Children */
  "ms-number-of-children"?: string | null;
  /** Ms-Rent-Part */
  "ms-rent-part"?: number | null;
  "ms-employment-type"?: EmploymentType | null;
  /** Ms-Employer */
  "ms-employer"?: string | null;
  /** Ms-Employed-Year */
  "ms-employed-year"?: string | null;
  /** Ms-Employed-Month */
  "ms-employed-month"?: string | null;
  /** Ms-Employed-To-Year */
  "ms-employed-to-year"?: string | null;
  /** Ms-Employed-To-Month */
  "ms-employed-to-month"?: string | null;
  /** Ms-Company-Industry */
  "ms-company-industry"?: string | null;
  /** Ms-Email */
  "ms-email"?: string | null;
  /** Ms-Ssn */
  "ms-ssn"?: string | null;
  /**
   * Ms-Applicant-Pep
   * @default false
   */
  "ms-applicant-pep"?: boolean | null;
  /**
   * Is-New-Loan-Form
   * @default false
   */
  "is-new-loan-form"?: boolean | null;
  /**
   * Credits
   * @default []
   */
  credits?: FormCredit[] | null;
  /** @default "other" */
  "loan-purpose"?: LoanPurpose;
  "housing-status"?: AppsmgrBlancoSeSpecPrivateUnsecuredLoanApplicationCreatedHousingType | null;
  "housing-type"?: HousingTypeSE | null;
  /** Phone-Number */
  "phone-number"?: string | null;
  /**
   * Salary
   * @min 0
   * @default 0
   */
  salary?: number;
  "ms-housing-status"?: AppsmgrBlancoSeSpecPrivateUnsecuredLoanApplicationCreatedHousingType | null;
  "ms-housing-type"?: HousingTypeSE | null;
  /** Ms-Phone-Number */
  "ms-phone-number"?: string | null;
  /** Ms-Salary */
  "ms-salary"?: number | null;
}

/** SMSReceived */
export interface SMSReceived {
  /** Type */
  type: "SMS_RECEIVED";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  status: NotificationEvent;
  data: SMSReceivedData;
  /** Notification Id */
  notification_id: string;
  /** User Id */
  user_id: string | null;
}

/** SMSReceivedData */
export interface SMSReceivedData {
  /** Application Id */
  application_id?: string | null;
  /** Customer Ids */
  customer_ids: string[];
}

/** SampleVars */
export interface SampleVars {
  /**
   * Customer Id
   * @format uuid
   */
  customer_id: string;
  /** Template Vars */
  template_vars: MergeVarContent[];
}

/** SavedAttachment */
export interface SavedAttachment {
  /** File Id */
  file_id: string;
  /** Name */
  name?: string | null;
}

/** SavedMessage */
export interface SavedMessage {
  message_type: MessageType;
  /** Message Id */
  message_id: string;
  /** Customer Id */
  customer_id?: string | null;
  /**
   * Application Id
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id?: string | null;
  /** Request Id */
  request_id?: string | null;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  direction: Direction;
  /** User Id */
  user_id?: string | null;
  country?: Country | null;
  product?: ExtendedProduct | null;
  event?: TemplateEvent | null;
  /** Send At */
  send_at?: string | null;
  /** Message */
  message?: string | null;
  /** Sender */
  sender?: string | null;
  /** Template Name */
  template_name?: string | null;
  /** Template Vars */
  template_vars?: object | null;
  /** Customer Ids */
  customer_ids?: string[] | null;
}

/** SavedMessageWithName */
export interface SavedMessageWithName {
  message_type: MessageType;
  /** Message Id */
  message_id: string;
  /** Customer Id */
  customer_id?: string | null;
  /**
   * Application Id
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id?: string | null;
  /** Request Id */
  request_id?: string | null;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  direction: Direction;
  /** User Id */
  user_id?: string | null;
  country?: Country | null;
  product?: ExtendedProduct | null;
  event?: TemplateEvent | null;
  /** Send At */
  send_at?: string | null;
  /** Message */
  message?: string | null;
  /** Sender */
  sender?: string | null;
  /** Template Name */
  template_name?: string | null;
  /** Template Vars */
  template_vars?: object | null;
  /** Customer Ids */
  customer_ids?: string[] | null;
  /** Sender Name */
  sender_name: string;
}

/** ScheduleSlot */
export interface ScheduleSlot {
  /**
   * Start
   * @format time
   */
  start: string;
  /**
   * End
   * @format time
   */
  end: string;
  /**
   * Available Slots Per Hour
   * @min 0
   */
  available_slots_per_hour: number;
}

/** SearchLeadsPayload */
export interface SearchLeadsPayload {
  /** Campaign Ids */
  campaign_ids?: string[] | null;
  country?: Country | null;
  product?: ExtendedProduct | null;
  department?: ZDepartment | null;
  /** User Ids */
  user_ids?: string[] | null;
  /** Start */
  start?: string | null;
  /** End */
  end?: string | null;
  /** Outcomes */
  outcomes?: OutcomeType[] | null;
  /** Failure Sub Outcomes */
  failure_sub_outcomes?: FailureOutcomes[] | null;
  /** No Contact Sub Outcomes */
  no_contact_sub_outcomes?: NoContactOutcomes[] | null;
  status?: LeadStatus | null;
  /**
   * Order
   * @default "desc"
   */
  order?: "asc" | "desc";
  /** Search */
  search?: string | null;
  /**
   * Exclude Unextracted
   * @default true
   */
  exclude_unextracted?: boolean;
  /**
   * Exclude Null Campaign Id
   * @default true
   */
  exclude_null_campaign_id?: boolean;
}

/** SearchType */
export enum SearchType {
  APPLICATION_ID = "APPLICATION_ID",
  PORTAL_APP_ID = "PORTAL_APP_ID",
  PERSONAL_DATA = "PERSONAL_DATA",
  EXTERNAL_ID = "EXTERNAL_ID",
}

/** SellingInfo */
export interface SellingInfo {
  /** Property Being Sold */
  property_being_sold: PropertyBeingSold[];
}

/** SendRawPayload */
export interface SendRawPayload {
  /**
   * Customer Id
   * When MessageType.SMS, pass either phone_number or customer_id
   */
  customer_id?: string | null;
  /**
   * Phone Number
   * Only for MessageType.SMS, pass either phone_number or customer_id
   */
  phone_number?: string | null;
  /**
   * Application Id Or Lead Id
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id_or_lead_id?: string | null;
  /** Message */
  message: string;
  data?: RoutersMessagePayloadData | null;
  /** Subject */
  subject?: string | null;
  /** Only for MessageType.SMS without customer_id */
  country?: Country | null;
}

/** SendTemplatePayload */
export interface SendTemplatePayload {
  /**
   * Customer Id
   * When MessageType.SMS, pass either phone_number or customer_id
   */
  customer_id?: string | null;
  /**
   * Phone Number
   * Only for MessageType.SMS, pass either phone_number or customer_id
   */
  phone_number?: string | null;
  /**
   * Application Id
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id?: string | null;
  data?: RoutersMessagePayloadData | null;
  /** Only for MessageType.SMS without customer_id */
  country?: Country | null;
  /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
  product: ExtendedProduct;
}

/** SentTemplateResponse */
export interface SentTemplateResponse {
  /** Email */
  email: string;
  /** Status */
  status: string;
  /** Reject Reason */
  reject_reason?: string | null;
  /** Queued Reason */
  queued_reason?: string | null;
  /**  Id */
  _id: string;
  merge_vars?: MergeVar | null;
}

/** SetPassword */
export interface SetPassword {
  /**
   * Otp
   * @format password
   */
  otp: string;
  /** Token */
  token: string;
  /**
   * Password
   * @format password
   */
  password: string;
}

/** SetPasswordResp */
export interface SetPasswordResp {
  /** Success */
  success: "true";
}

/** ShareNameConsent */
export enum ShareNameConsent {
  NO_CONSENT = "NO_CONSENT",
  INITIALS_ONLY = "INITIALS_ONLY",
  FULL_NAMES = "FULL_NAMES",
}

/** Sheet */
export interface Sheet {
  /**
   * Uploaded At
   * @format date-time
   */
  uploaded_at: string;
  type: SheetType;
  /**
   * Name
   * @default ""
   */
  name?: string;
  /**
   * Sheet Id
   * @format uuid
   */
  sheet_id: string;
  /** Separator */
  separator?: string | null;
  /**
   * Mapping
   * @default {}
   */
  mapping?: Record<string, string>;
  uploaded_sheet_preview?: SheetPreviewBase | null;
  /** @default "NOT_STARTED" */
  import_status?: ImportStatus;
  import_details?: InterimLeadsInsertion | null;
  /** Import Error Msg */
  import_error_msg?: string | null;
  /** Imported At */
  imported_at?: string | null;
}

/** SheetCampaignSource */
export interface SheetCampaignSource {
  /**
   * Type
   * @default "SHEET"
   */
  type?: "SHEET";
  /** Sheets */
  sheets: Sheet[];
}

/** SheetPreview */
export interface SheetPreview {
  /** Headers */
  headers: string[];
  /** Model Fields */
  model_fields: string[];
  /** Preview */
  preview: Record<string, string | Record<string, string | null> | null>[];
  /**
   * Sheet Id
   * Sheet ID which is needed in extraction endpoint.
   * @format uuid
   */
  sheet_id: string;
  /** File Name */
  file_name: string;
  file_type: SheetType;
  /**
   * Uploaded At
   * Date when file was uploaded.
   * @format date-time
   */
  uploaded_at: string;
}

/** SheetPreviewBase */
export interface SheetPreviewBase {
  /** Headers */
  headers: string[];
  /** Model Fields */
  model_fields: string[];
  /** Preview */
  preview: Record<string, string | Record<string, string | null> | null>[];
}

/** SheetType */
export enum SheetType {
  XLSX = "XLSX",
  CSV = "CSV",
}

/** Situation */
export enum Situation {
  SOLD = "SOLD",
  WILL_SELL = "WILL_SELL",
  ONLY_BUY = "ONLY_BUY",
}

/** Slots */
export interface Slots {
  /**
   * Start Date
   * @format date-time
   */
  start_date: string;
}

/** SmsTemplate */
export interface SmsTemplate {
  event: TemplateEvent;
  /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
  product: ExtendedProduct;
  country: Country;
  /** @default "SMS" */
  message_type?: MessageType;
  /**
   * Timestamp
   * @format date-time
   * @default "2024-11-19T09:51:36.708620Z"
   */
  timestamp?: string;
  /** Comment */
  comment?: string | null;
  /**
   * Deleted
   * @default false
   */
  deleted?: boolean | null;
  /** Name */
  name?: string | null;
  /** Body */
  body: string;
}

/** StatusType */
export interface StatusType {
  /** Description */
  Description?: string | null;
  /** Key */
  Key?: string | null;
}

/** SubmissionReview */
export interface SubmissionReview {
  /** Submission Id */
  submission_id: string;
  /** Handled */
  handled?: boolean | null;
  /**
   * Reviews
   * @default []
   */
  reviews?: Reviewer[];
  /**
   * Success
   * @default false
   */
  success?: boolean;
  /**
   * Ongoing
   * @default false
   */
  ongoing?: boolean;
  /**
   * Submission Timestamp
   * @format date-time
   */
  submission_timestamp: string;
  /** Raw Data */
  raw_data: object;
  country: Country;
  product: Product;
  /** Request Id */
  request_id: string;
  /** @default {} */
  checklist?: Checklist;
  /**
   * Application Id
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id?: string | null;
  /** Parsed Form */
  parsed_form?: NOApplicationForm | SEApplicationForm | null;
  /** Quick Application */
  quick_application?:
    | (
        | ({
            product: "blanco";
          } & OptionalQuickCreateBlancoApplication)
        | ({
            product: "mortgage";
          } & OptionalQuickCreateMortgageApplication)
      )
    | null;
  parsed_application?: JotformCCApplication | null;
  /** Gid */
  gid?: string | null;
  /** Zid */
  zid?: string | null;
  /** Session Zid */
  session_zid?: string | null;
  /** Gid List */
  gid_list?: string | null;
  /** Affiliate Id */
  affiliate_id?: string | null;
  /** Http Request Metadata */
  http_request_metadata?: object | null;
  /** Cc Converted Blanco App Id */
  cc_converted_blanco_app_id?: string | null;
  cc_conversion_status?: ConvertToBlancoStatus | null;
}

/** SubmissionReviewResponse */
export interface SubmissionReviewResponse {
  /** Submissions */
  submissions: BasicSubmissionReview[];
  /** Count */
  count: number;
}

/** SubmissionStatus */
export enum SubmissionStatus {
  Error = "error",
  AppCreated = "app_created",
  AppNotAllowed = "app_not_allowed",
}

/** Substatus */
export enum Substatus {
  DRAFT = "DRAFT",
  UNKNOWN = "UNKNOWN",
  OFFERED = "OFFERED",
  ACCEPTED = "ACCEPTED",
  DISBURSED = "DISBURSED",
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
}

/** SummarizedView */
export interface SummarizedView {
  accepted?: AcceptedLoans | null;
  disbursed?: AllDisbursedLoans | null;
  insurance?: AllInsurances | null;
  mortgage_leads?: MortgageLeads | null;
}

/** SverkerClearanceID */
export enum SverkerClearanceID {
  Value1 = "1",
  Value2 = "2",
  Value3 = "3",
  Value5 = "5",
  Value6 = "6",
  Value9 = "9",
  Value10 = "10",
}

/** SverkerSearchApplicationResponse */
export interface SverkerSearchApplicationResponse {
  /** Id */
  id: number;
  /** Created At */
  created_at: string;
}

/** SverkerSearchResponse */
export interface SverkerSearchResponse {
  /** Applications */
  applications: SverkerSearchApplicationResponse[];
}

/** SverkerUsername */
export interface SverkerUsername {
  clearance_id?: SverkerClearanceID | null;
  /** Username */
  username: string;
}

/** TMCampaign */
export interface TMCampaign {
  /** Query */
  query: string;
  country: Country;
  product: Product;
  department: ZDepartment;
  /** Description */
  description: string;
  /**
   * Ignore Days Since Last Extraction
   * Number of days since last extraction to ignore customer_id
   * @default 30
   */
  ignore_days_since_last_extraction?: number;
  /** Campaign Id */
  campaign_id: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /** Created By */
  created_by: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Updated By */
  updated_by: string;
  /** Active */
  active: boolean;
}

/** TMCampaignList */
export interface TMCampaignList {
  /** Total */
  total: number;
  /** Data */
  data: TMCampaign[];
}

/** TMLead */
export interface TMLead {
  /** Customer Id */
  customer_id: string;
  /** Tags */
  tags?: object | null;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  app_id: string;
}

/** TMLeadsExtraction */
export interface TMLeadsExtraction {
  /** Leads */
  leads: TMLead[];
  /** Filtering Reasons */
  filtering_reasons: FilteringResult[];
  /** Campaign Id */
  campaign_id: string;
  /** Job Id */
  job_id: string;
  /**
   * Extraction Date
   * @format date-time
   */
  extraction_date: string;
  /** User Id */
  user_id?: string | null;
  /** Request Id */
  request_id: string;
}

/** TMLeadsJob */
export interface TMLeadsJob {
  /** Job Id */
  job_id: string;
  /**
   * Is Finished
   * @default false
   */
  is_finished?: boolean;
  /**
   * Is Failed
   * @default false
   */
  is_failed?: boolean;
}

/** TakeCreditCheck */
export interface TakeCreditCheck {
  /** Customer Id */
  customer_id: string;
  /** Reason */
  reason: string;
}

/** Tax */
export interface Tax {
  /** Id */
  id: string;
  /**
   * Key
   * Used to get the correct table
   */
  key: string;
  /** Description */
  description: string;
  /** Municipality Code */
  municipality_code: string;
  country: Country;
}

/** TaxDeduction */
export interface TaxDeduction {
  /** Lower */
  lower: number;
  /** Upper Yearly Limit */
  upper_yearly_limit: number;
  /** Upper */
  upper: number;
}

/** TaxItem */
export interface TaxItem {
  /** Assessed Income */
  assessed_income?: string | null;
  /** Assessed Wages */
  assessed_wages?: string | null;
  /** Capital Gains */
  capital_gains?: string | null;
  /** Final Tax */
  final_tax?: string | null;
  /** Income Year */
  income_year?: string | null;
  /** Remaining Tax */
  remaining_tax?: string | null;
  /** Total Income */
  total_income?: string | null;
  /** Year */
  year?: string | null;
}

/** TaxThousand */
export interface TaxThousand {
  /** Assessed Income Thousand */
  assessed_income_thousand?: string | null;
  /** Assessed Wages Thousand */
  assessed_wages_thousand?: string | null;
  /** Capital Gains Thousand */
  capital_gains_thousand?: string | null;
  /** Final Tax Thousand */
  final_tax_thousand?: string | null;
  /** Income Year */
  income_year?: string | null;
  /** Remaining Tax Thousand */
  remaining_tax_thousand?: string | null;
  /** Taxed Income */
  taxed_income?: string | null;
  /** Year */
  year?: string | null;
}

/** TelavoxDirection */
export enum TelavoxDirection {
  In = "in",
  Out = "out",
  Unknown = "unknown",
}

/** TelavoxExtension */
export interface TelavoxExtension {
  /**
   * Extension
   * The extension number
   */
  extension: string;
  /**
   * Name
   * The name of the user
   */
  name: string;
  /**
   * Email
   * The email of the user
   */
  email: string;
  /**
   * Mobile
   * The mobile number of the user
   */
  mobile?: string | null;
  /** The profile of the user */
  profile?: TelavoxExtensionProfile | null;
  /**
   * Calls
   * The current call of the user
   */
  calls?: TelavoxExtensionCall[] | null;
  /**
   * Extensione164
   * The extension number in E164 format
   */
  extensionE164?: string | null;
  /**
   * Mobilee164
   * The mobile number in E164 format
   */
  mobileE164?: string | null;
  /**
   * Keywords
   * Keywords for the user
   */
  keywords?: string[] | null;
}

/** TelavoxExtensionCall */
export interface TelavoxExtensionCall {
  /**
   * Callerid
   * The dialed number
   */
  callerid: string;
  direction: TelavoxDirection;
  linestatus: TelavoxLineStatus;
}

/** TelavoxExtensionProfile */
export interface TelavoxExtensionProfile {
  /**
   * Available
   * Is the user available for calls
   */
  available: boolean;
  /**
   * Name
   * Name of current status for telavox user
   */
  name: string;
  /**
   * Until
   * Time that the user is available until
   */
  until?: string | null;
  /**
   * Message
   * Message for current status set by user
   */
  message?: string | null;
}

/** TelavoxLineStatus */
export enum TelavoxLineStatus {
  Up = "up",
  Down = "down",
  Ringing = "ringing",
}

/** TelavoxMismatch */
export interface TelavoxMismatch {
  /** No Id Set */
  no_id_set: TelavoxNoUserWithId[];
  /** No Name Match */
  no_name_match: TelavoxZrmMismatch[];
}

/** TelavoxNoUserWithId */
export interface TelavoxNoUserWithId {
  /** Telavox User */
  telavox_user: string;
  /** Telavox Id */
  telavox_id: string;
}

/** TelavoxZrmMismatch */
export interface TelavoxZrmMismatch {
  /** Full Name */
  full_name: string;
  /** Telavox Full Name */
  telavox_full_name: string;
  /** User Id */
  user_id: string;
}

/** TemplateDryRunResult */
export interface TemplateDryRunResult {
  /** Tested Leads */
  tested_leads: number;
  /** Populated Leads */
  populated_leads: number;
  errors: TemplateVarsPopulateErrors;
}

/** TemplateEvent */
export enum TemplateEvent {
  ApplicationCreated = "application_created",
  ApplicationCreatedReoccurringCustomer = "application_created_reoccurring_customer",
  NoBids = "no_bids",
  FullRejection = "full_rejection",
  FailedAutoInvite = "failed_auto_invite",
  FullLeadCreated = "full_lead_created",
  Missing2Payments = "missing_2_payments",
  MandateCancelled = "mandate_cancelled",
  LateDenial = "late_denial",
  BlancoAppCreation = "blanco_app_creation",
  FirstOffer = "first_offer",
  ReminderToAccept1 = "reminder_to_accept_1",
  ReminderToAccept2 = "reminder_to_accept_2",
  ReminderToAccept3 = "reminder_to_accept_3",
  NoAccept = "no_accept",
  FirstInvite = "first_invite",
  ExternalMortgageInterestConfirmation = "external_mortgage_interest_confirmation",
  MainApplicantConfirmation = "main_applicant_confirmation",
  MainApplicantConfirmationPamind = "main_applicant_confirmation_pamind",
  BookingConfirmation = "booking_confirmation",
  TooManyApplications = "too_many_applications",
  Gr = "gr",
  CoApplicantConfirmation = "co_applicant_confirmation",
  BookingReminder1H = "booking_reminder_1h",
  BookingReminder15Min = "booking_reminder_15_min",
  FirstOfferOnline = "first_offer_online",
  SecondOfferOnline = "second_offer_online",
  Disbursement = "disbursement",
  ExternalSigningLink = "external_signing_link",
  SevenDaysBeforeCreditCheckExpires = "seven_days_before_credit_check_expires",
  Test = "test",
  Marketing = "marketing",
}

/** TemplateVariables */
export interface TemplateVariables {
  /** Template Vars */
  template_vars: string[];
  /** Link Vars */
  link_vars: string[];
  /**
   * Template Sample Vars
   * @default []
   */
  template_sample_vars?: SampleVars[];
}

/** TemplateVarsPopulateErrors */
export interface TemplateVarsPopulateErrors {
  /**
   * No Email
   * @default 0
   */
  no_email?: number;
  /**
   * Failed Vars
   * @default []
   */
  failed_vars?: FailedVariable[];
  /**
   * No Links
   * @default 0
   */
  no_links?: number;
  /**
   * Failed Links
   * @default []
   */
  failed_links?: FailedVariable[];
  /**
   * No Comment Subject
   * @default 0
   */
  no_comment_subject?: number;
}

/** TestTemplatePayload */
export interface TestTemplatePayload {
  /** To */
  to: string[];
  /** Mandrill Name */
  mandrill_name: string;
  /** Global Merge Vars */
  global_merge_vars: MergeVarContent[];
  /** Merge Vars */
  merge_vars: MergeVar[];
  /**
   * From Email
   * @format email
   */
  from_email: string;
  /** From Name */
  from_name: string;
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
  /** Customer Id */
  customer_id?: string | null;
  /**
   * Test With Real Data
   * @default false
   */
  test_with_real_data?: boolean;
}

/** TokenResp */
export interface TokenResp {
  /** Token */
  token: string;
}

/** Touch */
export enum Touch {
  FIRST = "FIRST",
  LAST = "LAST",
}

/** URL */
export interface URL {
  /** Url Name */
  url_name: string;
  /** Key */
  key: string;
  /** Target Url */
  target_url: string;
  /** Is Active */
  is_active: boolean;
  /** Clicks */
  clicks: number;
  /** Path */
  path?: string | null;
  utm_tags?: UtmTags | null;
  /**
   * Click Events
   * @default []
   */
  click_events?: ClickEvent[];
}

/**
 * UnauthenticatedInsurance
 * Model for Insurance details for what a customer can see
 */
export interface UnauthenticatedInsurance {
  /**
   * Model for Loan Insurance for what a customer can see
   * missing things like events, etc
   */
  insurance: InsuranceCustomer;
  customer: PublicApplicant;
  rules: InsuranceTerms;
}

/** UncertainReasonType */
export interface UncertainReasonType {
  /** Description */
  Description?: string | null;
  /** Key */
  Key?: string | null;
}

/** UpdateApplicationPayloadExt */
export interface UpdateApplicationPayloadExt {
  loan?: NewMortgageLoan | null;
  refinance_info?: RefinanceInfo | null;
  buying_info?: BuyingInfo | null;
  selling_info?: SellingInfo | null;
  /** Applicants */
  applicants?: BareApplicantWithRole[] | null;
  /** Property Id */
  property_id?: string | null;
  discount?: Discount | null;
  /** Loans */
  loans?: Loan[] | null;
  /** Other Mortgages */
  other_mortgages?: MortgageLoan[] | null;
  status?: AppsmgrModelsStatusStatus | null;
  substatus?: Substatus | null;
  bank_status?: BankStatus | null;
  /** Children In The Household */
  children_in_the_household?: number | null;
  /** Children In The Household Fulltime */
  children_in_the_household_fulltime?: number | null;
  /** Other Properties To Keep */
  other_properties_to_keep?: OtherProperty[] | null;
  co_relationship_to_main_applicant?: RelationshipStatus | null;
  guarantor_relationship_to_main_applicant?: RelationshipStatus | null;
  cars?: CarsInput | null;
  brokerage?: Brokerage | null;
  /** New App Options */
  new_app_options?: Option[] | null;
  /** New App Checklist */
  new_app_checklist?: string[] | null;
  /** Internal Comment */
  internal_comment?: string | null;
  source?: ApplicationSource | null;
  aml?: AMLInsertion | null;
}

/** UpdateCampaignData */
export interface UpdateCampaignData {
  /** Name */
  name: string;
  /** Description */
  description?: string | null;
  /** Source */
  source:
    | ({
        type: "BIG_QUERY";
      } & BigQueryCampaignSource)
    | ({
        type: "PROCESS";
      } & ProcessCampaignSource)
    | ({
        type: "SHEET";
      } & SheetCampaignSource);
  /** File Id */
  file_id?: string | null;
  exclusion_rules: ExclusionRules;
  /** Daily Extraction Limit */
  daily_extraction_limit?: number | null;
  /** Comment Template */
  comment_template?: string | null;
  mail_policy?: MailSendPolicy | null;
  template?: EmailTemplate | null;
  /** Template Vars */
  template_vars?: string[] | null;
  retry_policy?: RetryPolicy | null;
  /** Auto Dial */
  auto_dial?: boolean | null;
  /** Personal Callbacks */
  personal_callbacks?: boolean | null;
  /** No Contact Outcomes */
  no_contact_outcomes?: string[] | null;
  /** Failure Outcomes */
  failure_outcomes?: string[] | null;
  /** Discard Outcomes */
  discard_outcomes?: string[] | null;
  /**
   * Show Call History
   * @default true
   */
  show_call_history?: boolean | null;
}

/** UpdateCreditCardApplicationPayload */
export interface UpdateCreditCardApplicationPayload {
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  data: OptionalCreditCardApplicationDataCreate;
}

/** UpdateCustomerConsentsPayload */
export interface UpdateCustomerConsentsPayload {
  /** Reason */
  reason: string;
  /** Customer Id */
  customer_id: string;
  consents: Consents;
}

/** UpdateCustomerPayload */
export interface UpdateCustomerPayload {
  /** Reason */
  reason: string;
  /** Customer Id */
  customer_id: string;
  data: CustomerData;
}

/** UpdateFormPreferencePayload */
export interface UpdateFormPreferencePayload {
  /** Form Id */
  form_id: string;
  product: Product;
  country: Country;
  /** Mapping */
  mapping: FieldMapping[];
  /** Preference Id */
  preference_id: string;
}

/** UpdateLeadData */
export interface UpdateLeadData {
  /** Phone */
  phone?: string | null;
  /** Other Phones */
  other_phones?: string[] | null;
  /** Pni */
  pni?: string | null;
  process_type?: ProcessCampaignType | null;
  process_subtype?: InsuranceSubstatus | null;
  /** Priority */
  priority?: number | null;
}

/** UpdateMeetingPayload */
export interface UpdateMeetingPayload {
  /** Meeting Start */
  meeting_start?: string | null;
  /** Meeting End */
  meeting_end?: string | null;
  /** Handler Id */
  handler_id?: string | null;
  /** Meeting Notes */
  meeting_notes?: string | null;
  /** Meeting Subject */
  meeting_subject?: string | null;
  /** Customer Id */
  customer_id?: string | null;
}

/** UpsertCampaignStatus */
export enum UpsertCampaignStatus {
  Created = "created",
  AlreadyExists = "already exists",
  Error = "error",
}

/** UserCampaignsExt */
export interface UserCampaignsExt {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /** Version */
  version: number;
  /** User Id */
  user_id: string;
  /** Joined Campaigns */
  joined_campaigns: JoinedCampaignExt[];
}

/** UserOutcomeCount */
export interface UserOutcomeCount {
  /** User Id */
  user_id?: string | null;
  /** Campaign Id */
  campaign_id?: string | null;
  /**
   * Unique
   * Number of unique customers
   * @default 0
   */
  unique?: number | null;
  /**
   * All
   * Number of all outcomes excluding personal_callbacks
   * @default 0
   */
  all?: number;
  /**
   * First
   * Number of outcomes with first outcome set by user
   * @default 0
   */
  first?: number;
  /**
   * Success
   * Number of successful outcomes
   * @default 0
   */
  success?: number;
}

/** UserSettings */
export interface UserSettings {
  /**
   * Compact
   * @default false
   */
  compact?: boolean | null;
  /**
   * Direction
   * @default "ltr"
   */
  direction?: "ltr" | "rtl" | null;
  /**
   * Responsivefontsizes
   * @default true
   */
  responsiveFontSizes?: boolean | null;
  /**
   * Roundedcorners
   * @default true
   */
  roundedCorners?: boolean | null;
  /** Theme */
  theme?: string | null;
  /** Zoordinates */
  zoordinates: string;
  /**
   * Debug
   * @default false
   */
  debug?: boolean | null;
  /** @default "NO_CONSENT" */
  shareNameConsent?: ShareNameConsent;
  sverkerUsername?: SverkerUsername | null;
  /** @default "dayGridMonth" */
  default_calender_view?: DefaultCalendarView | null;
  /** Telavox Api Key */
  telavox_api_key?: string | null;
}

/** UtmTags */
export interface UtmTags {
  /** Utm Source */
  utm_source: string;
  /** Utm Medium */
  utm_medium: string;
  /** Utm Campaign */
  utm_campaign: string;
}

/** VacationHome */
export interface VacationHome {
  /** Property Id */
  property_id: string;
  /** Value */
  value?: number | null;
  /** Booli Value */
  booli_value?: number | null;
  /** External Id */
  external_id?: string | null;
  /** Property Type */
  property_type: "VACATION_HOME";
  country: Country;
  /** Street Address */
  street_address?: string | null;
  /** Post Code */
  post_code?: string | null;
  /** City */
  city?: string | null;
  /** Other Owner */
  other_owner?: boolean | null;
  /** Monthly Cost */
  monthly_cost?: number | null;
  /** Square Meter Living Area */
  square_meter_living_area?: number | null;
  /** Municipality */
  municipality?: string | null;
  /** Municipality Code */
  municipality_code?: string | null;
  /** Estimated Value */
  estimated_value?: number | null;
  /** Latitude */
  latitude?: number | null;
  /** Longitude */
  longitude?: number | null;
  ev_partial?: EvPartialCommon | null;
  /** Cadastral Designation */
  cadastral_designation?: string | null;
  [key: string]: any;
}

/** ValidatePayload */
export interface ValidatePayload {
  /** Form Id */
  form_id: string;
  product: Product;
  country: Country;
  /** Mapping */
  mapping: FieldMapping[];
  /**
   * Add Defaults
   * @default false
   */
  add_defaults?: boolean;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** ValidatorServiceResponseModel */
export interface ValidatorServiceResponseModel {
  /** Valid */
  valid: boolean;
  /** Formatted */
  formatted?: any;
}

/** ValuationPayload */
export interface ValuationPayload {
  /** Search Id */
  search_id: string;
  /** Apartment Number */
  apartment_number?: string | null;
  /** Living Area */
  living_area?: number | null;
}

/** ValuationPayloadV2 */
export interface ValuationPayloadV2 {
  /** External Id */
  external_id: string;
  /** Property Id */
  property_id: string;
  /** Apartment Number */
  apartment_number?: string | null;
  /** Living Area */
  living_area?: number | null;
}

/** Value */
export interface Value {
  /** Accuracylevel */
  AccuracyLevel?: string | null;
  /** Areamarketscore */
  AreaMarketScore?: number | null;
  /** Areavacationhomemarketscore */
  AreaVacationHomeMarketScore?: string | null;
  /** Customerapprovedvalue */
  CustomerApprovedValue?: string | null;
  /** Customermaxvalue */
  CustomerMaxValue?: string | null;
  EstateType?: EstateTypeType | null;
  /** Estimate */
  Estimate?: number | null;
  /** Estimatehaircut */
  EstimateHaircut?: string | null;
  /** Grossvalueestimate */
  GrossValueEstimate?: string | null;
  /** Haircutpercentage */
  HaircutPercentage?: string | null;
  /** Lastknowncommondebt */
  LastKnownCommondebt?: number | null;
  /** Lastknowncommondebtdate */
  LastKnownCommondebtDate?: string | null;
  /** Municipalityscore */
  MunicipalityScore?: number | null;
  /** Netvalueestimate */
  NetValueEstimate?: number | null;
  Status?: StatusType | null;
  /** Storedestimatedocumentationid */
  StoredEstimateDocumentationId?: string | null;
}

/** ValueListItem */
export interface ValueListItem {
  /** Label Sv */
  label_sv?: string | null;
  /** Label Nb */
  label_nb?: string | null;
  /** Label En */
  label_en?: string | null;
  /** Value Sv */
  value_sv?: string | null;
  /** Value En */
  value_en?: string | null;
  /** Value Nb */
  value_nb?: string | null;
}

/** WebsiteVisitPayload */
export interface WebsiteVisitPayload {
  /** Landing Params */
  landing_params?: string | null;
  /** Landing Url */
  landing_url?: string | null;
  /** Cookie Consent */
  cookie_consent?: string | null;
}

/** WorkingDays */
export interface WorkingDays {
  /** Working Days */
  working_days: number;
  /** Worked Days */
  worked_days: number;
}

/** ZCountry */
export enum ZCountry {
  SE = "SE",
  NO = "NO",
  FI = "FI",
}

/** ZDepartment */
export enum ZDepartment {
  HL = "HL",
  KA = "KA",
  SK = "SK",
  SP = "SP",
  KH = "KH",
  ON = "ON",
}

/** ZEmploymentType */
export enum ZEmploymentType {
  FU = "FU",
  PA = "PA",
  UN = "UN",
}

/** ZMSConfig */
export interface ZMSConfig {
  config: Config;
  country: Country;
  /** User Id */
  user_id: string;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
}

/** ZOffice */
export enum ZOffice {
  ST = "ST",
  UP = "UP",
}

/** ZProduct */
export enum ZProduct {
  MO = "MO",
  UL = "UL",
  CC = "CC",
  LI = "LI",
}

/** Zoordinates */
export interface Zoordinates {
  Product: ZProduct;
  Country: ZCountry;
  Office: ZOffice;
  EmploymentType: ZEmploymentType;
  Department: ZDepartment;
}

/** ZrmAssignmentEvent */
export interface ZrmAssignmentEvent {
  /**
   * Event Type
   * @default "ASSIGNMENT"
   */
  event_type?: "ASSIGNMENT";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** User Id */
  user_id?: string | null;
  zoordinates?: Zoordinates | null;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Handler Id */
  handler_id: string;
  sverker_data?: ZrmSverkerData | null;
}

/** ZrmBookingEvent */
export interface ZrmBookingEvent {
  /**
   * Event Type
   * @default "BOOKING"
   */
  event_type?: "BOOKING";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** User Id */
  user_id?: string | null;
  zoordinates?: Zoordinates | null;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Handler Id */
  handler_id?: string | null;
  sverker_data?: ZrmSverkerData | null;
  /**
   * Meeting Id
   * @format uuid
   */
  meeting_id: string;
  /**
   * Meeting Start
   * @format date-time
   */
  meeting_start: string;
  /**
   * Meeting End
   * @format date-time
   */
  meeting_end: string;
  /**
   * Customer Id
   * @format uuid
   */
  customer_id: string;
  /**
   * Meeting Subject
   * @default "Migrated from Sverker"
   */
  meeting_subject?: string;
}

/** ZrmCreationEvent */
export interface ZrmCreationEvent {
  /**
   * Event Type
   * @default "CREATION"
   */
  event_type?: "CREATION";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** User Id */
  user_id?: string | null;
  zoordinates?: Zoordinates | null;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Handler Id */
  handler_id?: string | null;
  sverker_data?: ZrmSverkerData | null;
  /**
   * Customer Id
   * @format uuid
   */
  customer_id: string;
}

/** ZrmInternalCommentEvent */
export interface ZrmInternalCommentEvent {
  /**
   * Event Type
   * @default "INTERNAL_COMMENT"
   */
  event_type?: "INTERNAL_COMMENT";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** User Id */
  user_id?: string | null;
  zoordinates?: Zoordinates | null;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Handler Id */
  handler_id?: string | null;
  sverker_data?: ZrmSverkerData | null;
  /**
   * Event Id
   * @format uuid
   */
  event_id: string;
  /** Comment */
  comment: string;
  /** Customer Id */
  customer_id?: string | null;
}

/** ZrmMigrationStats */
export interface ZrmMigrationStats {
  /**
   * Creation
   * @default false
   */
  creation?: boolean;
  /**
   * Meetings
   * @default 0
   */
  meetings?: number;
  /**
   * Assignments
   * @default 0
   */
  assignments?: number;
  /**
   * Comments
   * @default 0
   */
  comments?: number;
}

/** ZrmMigratorEvents */
export interface ZrmMigratorEvents {
  creation: ZrmCreationEvent;
  /** Assignments */
  assignments: ZrmAssignmentEvent[];
  /** Bookings */
  bookings: ZrmBookingEvent[];
  /** Comments */
  comments: ZrmInternalCommentEvent[];
}

/** ZrmSverkerData */
export interface ZrmSverkerData {
  /** Version */
  version?: number | null;
  /** Row Id */
  row_id?: number | null;
  /** Source */
  source?: string | null;
  /** User Id */
  user_id?: string | null;
  /** Creator Id */
  creator_id?: string | null;
  /** Advisor Id */
  advisor_id?: string | null;
}

/** DeactivationReason */
export enum AppsmgrBlancoModelsApplicationDeactivationReason {
  Other = "other",
  Moved = "moved",
  FailedCreditCheck = "failedCreditCheck",
  HighRent = "highRent",
  MiddleRent = "middleRent",
  LowRent = "lowRent",
  NoUC = "noUC",
  CallInSix = "callInSix",
  PossibleCreditCard = "possibleCreditCard",
  SentSB = "sentSB",
  Dontcall = "dontcall",
  HighRentBid = "highRentBid",
  HighRentNoBid = "highRentNoBid",
  NoReach = "noReach",
  Duplicate = "duplicate",
  MemberOrCarLoan = "memberOrCarLoan",
  Double = "double",
  UcAlreadyProtected = "ucAlreadyProtected",
  UcNoInterest = "ucNoInterest",
  UcNoTOC = "ucNoTOC",
  UcTooExpensive = "ucTooExpensive",
  UcTookLoanProtection = "ucTookLoanProtection",
  UcTryAgainLater = "ucTryAgainLater",
  PpmNoTime = "ppmNoTime",
  PpmNoInterest = "ppmNoInterest",
  PpmAlreadyHappy = "ppmAlreadyHappy",
}

/** HousingType */
export enum AppsmgrBlancoNoSpecPrivateUnsecuredLoanApplicationCreatedHousingType {
  COOPERATIVE = "COOPERATIVE",
  LODGER = "LODGER",
  OTHER = "OTHER",
  OWN_APARTMENT = "OWN_APARTMENT",
  OWN_HOUSE = "OWN_HOUSE",
  PARENTS = "PARENTS",
  RENTED = "RENTED",
}

/** HousingType */
export enum AppsmgrBlancoSeSpecPrivateUnsecuredLoanApplicationCreatedHousingType {
  RENTED = "RENTED",
  OWN_APARTMENT = "OWN_APARTMENT",
  OWN_HOUSE = "OWN_HOUSE",
  LIVE_IN = "LIVE_IN",
}

/** Event */
export interface AppsmgrInsuranceInsuranceEvent {
  /** Eventid */
  eventId: string;
  event: InsuranceEventType;
  data?: EventData;
  /**
   * Date
   * @format date-time
   */
  date: string;
  /** Orderid */
  orderId?: string | null;
  /** Userid */
  userId?: string | null;
  /**
   * Type
   * The type of the event e.g charges, mandate, etc
   */
  type: string;
  /**
   * Response
   * If the customer responded for a winbackable insurance
   */
  response?: boolean | null;
  /**
   * Comment
   * The comment for the winbackable insurance
   */
  comment?: string | null;
}

/** PropertyType */
export enum AppsmgrModelsApplicationPropertyType {
  HOUSE = "HOUSE",
  CONDOMINIUM = "CONDOMINIUM",
  VACATION_HOME = "VACATION_HOME",
  RENTING = "RENTING",
}

/** NotificationType */
export enum AppsmgrModelsNotificationsNotificationType {
  OFFER = "OFFER",
  REJECTION = "REJECTION",
  OFFER_ACCEPTED = "OFFER_ACCEPTED",
  INVITE = "INVITE",
  BANK_COMMENT = "BANK_COMMENT",
  BROKER_COMMENT = "BROKER_COMMENT",
  SIGN_STATUS = "SIGN_STATUS",
  FIRST_INVITE = "FIRST_INVITE",
  DISBURSED = "DISBURSED",
  DEACTIVATED = "DEACTIVATED",
  APPLICATION_CREATED = "APPLICATION_CREATED",
  BOOKING_CHANGED = "BOOKING_CHANGED",
}

/** PhoneNumber */
export interface AppsmgrModelsPhonePhoneNumber {
  /** Number */
  number: string;
  type: PhoneNumberType;
}

/** Status */
export enum AppsmgrModelsStatusStatus {
  LEAD = "LEAD",
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
}

/** DeactivationReason */
export enum AppsmgrMortgageModelsApplicationDeactivationReason {
  CUSTOMER_NOT_REACHABLE = "CUSTOMER_NOT_REACHABLE",
  CUSTOMER_NOT_INTERESTED = "CUSTOMER_NOT_INTERESTED",
  NO_IMPROVEMENT = "NO_IMPROVEMENT",
  NEGATIVE_CALCULATION = "NEGATIVE_CALCULATION",
  LOAN_TO_VALUE_TOO_HIGH = "LOAN_TO_VALUE_TOO_HIGH",
  INSECURE_EMPLOYMENT = "INSECURE_EMPLOYMENT",
  CREDIT_RATING_TOO_LOW = "CREDIT_RATING_TOO_LOW",
  TOO_SMALL_BRF = "TOO_SMALL_BRF",
  HOUS_IN_POOR_CONTITION = "HOUS_IN_POOR_CONTITION",
  RED_MUNICIPALITY = "RED_MUNICIPALITY",
  FIXEDINTEREST1YEAR = "FIXED_INTEREST_1_YEAR",
  FIXEDINTEREST3YEAR = "FIXED_INTEREST_3_YEAR",
  FIXEDINTEREST5YEAR = "FIXED_INTEREST_5_YEAR",
  AGRICULTURAL_PROPERTY = "AGRICULTURAL_PROPERTY",
  MIDDLE_OF_SALES = "MIDDLE_OF_SALES",
  TOO_SMALL_CASH_CONTRIBUTION = "TOO_SMALL_CASH_CONTRIBUTION",
  INCOME_REQUIREMENTS_NOT_MET = "INCOME_REQUIREMENTS_NOT_MET",
  NOT_COMMUNICABLE = "NOT_COMMUNICABLE",
  ONGOING_COMPETITOR = "ONGOING_COMPETITOR",
  ONGOING_DIVORCE = "ONGOING_DIVORCE",
  CONSTRUCTION_CREDIT = "CONSTRUCTION_CREDIT",
  BLANCO_APPLICATION = "BLANCO_APPLICATION",
  DUPLICATE = "DUPLICATE",
  OTHER = "OTHER",
}

/** Responsibility */
export enum AppsmgrMortgageNoSpecCommonDefinitionsResponsibility {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  CO_APPLICANT = "CO_APPLICANT",
  SHARED = "SHARED",
}

/** PropertyType */
export enum AppsmgrMortgageSeSpecCommonDefinitionsPropertyType {
  CONDOMINIUM = "CONDOMINIUM",
  HOUSE = "HOUSE",
  VACATION_HOME = "VACATION_HOME",
}

/** Responsibility */
export enum AppsmgrMortgageSeSpecCommonDefinitionsResponsibility {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  CO_APPLICANT = "CO_APPLICANT",
  SHARED = "SHARED",
  GUARANTOR = "GUARANTOR",
}

/** AffiliateStatus */
export enum ModelsAffiliateAffiliateAffiliateStatus {
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
}

/** Campaign */
export interface ModelsAffiliateAffiliateCampaign {
  url_target: Product;
  /** Valid To */
  valid_to?: string | null;
  /** Url */
  url?: string | null;
  /** Campaign Id */
  campaign_id: string;
}

/** AffiliateStatus */
export enum ModelsAffiliatePartnerApplicationAffiliateStatus {
  APP_CREATED = "APP_CREATED",
  OFFER_ACCEPTED = "OFFER_ACCEPTED",
  OFFER_SIGNED = "OFFER_SIGNED",
  APP_DISBURSED = "APP_DISBURSED",
  APP_DEACTIVATED = "APP_DEACTIVATED",
  LEAD_CREATED = "LEAD_CREATED",
  LEAD_DEACTIVATED = "LEAD_DEACTIVATED",
  APP_NOT_CREATED = "APP_NOT_CREATED",
  SUBMITTED = "SUBMITTED",
}

/** Data */
export interface ModelsApplicationMappingApplicationMappingData {
  /** Handler Id */
  handler_id?: string | null;
  /** Creator Id */
  creator_id?: string | null;
  /** Property Id */
  property_id?: string | null;
  /** New App Checklist */
  new_app_checklist?: string[] | null;
  /** New App Options */
  new_app_options?: Option[] | null;
  /** Internal Comment */
  internal_comment?: string | null;
  document?: DocumentAssignation | null;
  /** Customer Id */
  customer_id?: string | null;
  /** External Id */
  external_id?: string | null;
  source?: ApplicationSource | null;
  /**
   * Duplicate Of
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  duplicate_of?: string | null;
  /** Lead Id */
  lead_id?: string | null;
  aml?: AMLInsertion | null;
  aml_review?: AMLReviewPayload | null;
  [key: string]: any;
}

/** Event */
export interface ModelsApplicationMappingApplicationMappingEvent {
  action: Action;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** User Id */
  user_id?: string | null;
  data: ModelsApplicationMappingApplicationMappingData;
  /**
   * Application label
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Event Id */
  event_id: string;
  /** Request Id */
  request_id: string;
  zoordinates?: Zoordinates | null;
}

/** InternalComment */
export interface ModelsApplicationMappingCommentsInternalComment {
  /** User Id */
  user_id: string;
  /** Comment */
  comment: string;
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /** Comment Id */
  comment_id: string;
  /**
   * Action
   * @default "INTERNAL_COMMENT_ADDED"
   */
  action?: "INTERNAL_COMMENT_ADDED";
}

/** LeadExtraction */
export interface ModelsDialMaker2LeadDataModelsLeadExtraction {
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  /**
   * Extraction Id
   * @format uuid
   */
  extraction_id: string;
  /**
   * Campaign Id
   * @format uuid
   */
  campaign_id: string;
  /**
   * Count
   * Number of times this customer/phone number was extracted.
   * @exclusiveMin 0
   * @default 1
   */
  count?: number;
}

/** Campaign */
export interface ModelsDialMaker2CampaignAggregateCampaign {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /** Version */
  version: number;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Updated At
   * @format date-time
   */
  updated_at: string;
  /** Created By */
  created_by: string;
  /** Updated By */
  updated_by?: string | null;
  /** Active */
  active: boolean;
  /** Data */
  data: DialCampaignData | MailCampaignData;
  /** Extractions */
  extractions: CampaignExtraction[];
  /** Exports */
  exports: CampaignExport[];
}

/** Meeting */
export interface ModelsMeetingModelsMeeting {
  /**
   * Meeting Start
   * @format date-time
   */
  meeting_start: string;
  /**
   * Meeting End
   * @format date-time
   */
  meeting_end: string;
  /** Handler Id */
  handler_id?: string | null;
  /** Meeting Notes */
  meeting_notes?: string | null;
  /** Meeting Subject */
  meeting_subject?: string | null;
  /** Customer Id */
  customer_id?: string | null;
  /**
   * Application Id
   * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
   */
  application_id: string;
  /** Meeting Id */
  meeting_id: string;
  /**
   * Last Updated
   * @format date-time
   */
  last_updated: string;
  /**
   * Date Created
   * @format date-time
   */
  date_created: string;
  product: Product;
  country: Country;
  /** Customer Name */
  customer_name?: string | null;
}

/** InternalComment */
export interface ModelsNotificationsNotificationInternalComment {
  /** Type */
  type: "INTERNAL_COMMENT_RECEIVED";
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string;
  status: NotificationEvent;
  data: InternalCommentData;
  /** Notification Id */
  notification_id: string;
  /** User Id */
  user_id: string | null;
}

/** NotificationType */
export enum ModelsNotificationsNotificationTypesNotificationType {
  SMS_RECEIVED = "SMS_RECEIVED",
  OFFER_RECEIVED = "OFFER_RECEIVED",
  BANK_MESSAGE_RECEIVED = "BANK_MESSAGE_RECEIVED",
  INTERNAL_COMMENT_RECEIVED = "INTERNAL_COMMENT_RECEIVED",
  GENERAL_MESSAGE_RECEIVED = "GENERAL_MESSAGE_RECEIVED",
  APPLICATION_ASSIGNED = "APPLICATION_ASSIGNED",
  LEAD_ASSIGNED = "LEAD_ASSIGNED",
  LEAD_COMMENT_RECEIVED = "LEAD_COMMENT_RECEIVED",
  APPLICATION_DISBURSED = "APPLICATION_DISBURSED",
}

/** Status */
export interface ModelsOnlineLoanApplicationStatus {
  /** Success */
  success: boolean;
  /** Error */
  error?: string | null;
}

/** PhoneNumber */
export interface ModelsValidatorServicePayloadPhoneNumber {
  country?: Country | null;
  /** Phone Number */
  phone_number: string;
}

/** SearchPayload */
export interface RoutersApplicationApplicationPayloadSearchPayload {
  country?: Country | null;
  product?: Product | null;
  /** String */
  string: string;
  /**
   * Search Types
   * @default []
   */
  search_types?: SearchType[];
}

/** SearchPayload */
export interface RoutersBankPayloadsSearchPayload {
  country?: Country | null;
  product?: Product | null;
  /** String */
  string: string;
}

/** BankIDResp */
export interface RoutersBankIdModelsBankIDResp {
  /** Ticket Id */
  ticket_id: string;
}

/** BankIDResp */
export interface RoutersBankIdLoginPayloadBankIDResp {
  /** Autostart Token */
  autostart_token?: string | null;
  /** Ticket Id */
  ticket_id: string;
  /** Polling Endpoint */
  polling_endpoint?: string | null;
  /** Login Url */
  login_url?: string | null;
  /** Qr Code Content */
  qr_code_content?: string | null;
  /** Autostart Uri */
  autostart_uri?: string | null;
  /** Desktop Autostart Uri */
  desktop_autostart_uri?: string | null;
}

/** SignedUrl */
export interface RoutersDialMaker2PayloadsSignedUrl {
  /** Signed Url */
  signed_url: string;
}

/** LeadExtraction */
export interface RoutersDialMakerPayloadsLeadExtraction {
  /** Leads To Extract */
  leads_to_extract: number;
}

/** SignedUrl */
export interface RoutersDialMakerPayloadsSignedUrl {
  /** Signed Url */
  signed_url: string;
  /** File Name */
  file_name: string;
}

/** SearchPayload */
export interface RoutersEmployeeModelsSearchPayload {
  /** String */
  string: string;
}

/** SearchPayload */
export interface RoutersLeadPayloadSearchPayload {
  /** String */
  string: string;
}

/** Data */
export interface RoutersMessagePayloadData {
  /** Meeting Id */
  meeting_id?: string | null;
}

/** SearchPayload */
export interface RoutersPropertyPayloadSearchPayload {
  country: Country;
  /** Search String */
  search_string: string;
}

/** Meeting */
export interface ServicesSverkerResponsesMeeting {
  /**
   * Id
   * @format date-time
   */
  id: string;
  /** Time */
  time: string;
  /** Booked */
  booked: boolean;
  /** Format */
  format: string;
}

export namespace Application {
  /**
   * No description
   * @tags Application
   * @name QuickCreateApplicationApplicationQuickCreatePost
   * @summary Quick Create Application
   * @request POST:/application/quick-create
   * @secure
   */
  export namespace QuickCreateApplicationApplicationQuickCreatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody =
      | ({
          product: "mortgage";
        } & QuickCreateMortgageApplication)
      | ({
          product: "blanco";
        } & QuickCreateBlancoApplication);
    export type RequestHeaders = {};
    export type ResponseBody = ApplicationCreatedResponse;
  }

  /**
   * No description
   * @tags Application
   * @name CreateApplicationApplicationCreatePost
   * @summary Create Application
   * @request POST:/application/create
   * @secure
   */
  export namespace CreateApplicationApplicationCreatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateApplicationPayloadExt;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicationCreatedResponseExt;
  }

  /**
   * No description
   * @tags Application
   * @name UpdateApplicationApplicationUpdateApplicationIdPost
   * @summary Update Application
   * @request POST:/application/update/{application_id}
   * @secure
   */
  export namespace UpdateApplicationApplicationUpdateApplicationIdPost {
    export type RequestParams = {
      /**
       * Application Id
       * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
       */
      applicationId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateApplicationPayloadExt;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicationWithNamesInComments;
  }

  /**
   * No description
   * @tags Application
   * @name GetApplicationApplicationGetPost
   * @summary Get Application
   * @request POST:/application/get
   * @secure
   */
  export namespace GetApplicationApplicationGetPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetApplicationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicationWithNamesInComments;
  }

  /**
   * No description
   * @tags Application
   * @name GetApplicationsApplicationGetsPost
   * @summary Get Applications
   * @request POST:/application/gets
   * @deprecated
   * @secure
   */
  export namespace GetApplicationsApplicationGetsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetApplicationsByCustomerIdentifierPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Application[];
  }

  /**
   * No description
   * @tags Application
   * @name GetApplicationsV2ApplicationV2GetsPost
   * @summary Get Applications V2
   * @request POST:/application/v2/gets
   * @secure
   */
  export namespace GetApplicationsV2ApplicationV2GetsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetApplicationsByCustomerIdentifierPayloadV2;
    export type RequestHeaders = {};
    export type ResponseBody = Application[];
  }

  /**
   * No description
   * @tags Application
   * @name GetApplicationsAssignedToMeApplicationGetMinePost
   * @summary Get Applications Assigned To Me
   * @request POST:/application/get/mine
   * @secure
   */
  export namespace GetApplicationsAssignedToMeApplicationGetMinePost {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Page
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * Limit
       * @min 0
       * @max 50
       * @default 50
       */
      limit?: number;
    };
    export type RequestBody = GetMyApplicationsPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Application[];
  }

  /**
   * No description
   * @tags Application
   * @name GetRecentApplicationsApplicationGetRecentPost
   * @summary Get Recent Applications
   * @request POST:/application/get/recent
   * @secure
   */
  export namespace GetRecentApplicationsApplicationGetRecentPost {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Page
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * Limit
       * @min 0
       * @max 50
       * @default 50
       */
      limit?: number;
    };
    export type RequestBody = GetRecentApplicationsPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Application[];
  }

  /**
   * No description
   * @tags Application
   * @name SearchApplicationsApplicationSearchPost
   * @summary Search Applications
   * @request POST:/application/search
   * @secure
   */
  export namespace SearchApplicationsApplicationSearchPost {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Page
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * Limit
       * @min 0
       * @max 50
       * @default 50
       */
      limit?: number;
    };
    export type RequestBody = RoutersApplicationApplicationPayloadSearchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Application[];
  }

  /**
   * No description
   * @tags Application
   * @name SearchApplications2ApplicationSearch2Post
   * @summary Search Applications2
   * @request POST:/application/search2
   * @secure
   */
  export namespace SearchApplications2ApplicationSearch2Post {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Page
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * Limit
       * @min 0
       * @max 50
       * @default 50
       */
      limit?: number;
    };
    export type RequestBody = RoutersApplicationApplicationPayloadSearchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = AppsSearchResult;
  }

  /**
   * No description
   * @tags Application
   * @name GetLeftToLiveApplicationLeftToLivePost
   * @summary Get Left To Live
   * @request POST:/application/left_to_live
   * @secure
   */
  export namespace GetLeftToLiveApplicationLeftToLivePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = LeftToLivePayload;
    export type RequestHeaders = {};
    export type ResponseBody = LeftToLiveResponse;
  }

  /**
   * No description
   * @tags Application
   * @name DuplicateApplicationApplicationDuplicatePost
   * @summary Duplicate Application
   * @request POST:/application/duplicate
   * @secure
   */
  export namespace DuplicateApplicationApplicationDuplicatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ApplicationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicationPayload;
  }

  /**
   * No description
   * @tags Application
   * @name DeactivateApplicationApplicationDeactivateProductPost
   * @summary Deactivate Application
   * @request POST:/application/deactivate/{product}
   * @secure
   */
  export namespace DeactivateApplicationApplicationDeactivateProductPost {
    export type RequestParams = {
      product: Product;
    };
    export type RequestQuery = {};
    export type RequestBody = MortgageDeactivatePayload | DeactivatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Application
   * @name ReactivateApplicationReactivatePost
   * @summary Reactivate
   * @request POST:/application/reactivate
   * @secure
   */
  export namespace ReactivateApplicationReactivatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ReactivatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags CreditCard Application
   * @name GetApplicationApplicationCcNoGetPost
   * @summary Get Application
   * @request POST:/application/cc/no/get
   * @secure
   */
  export namespace GetApplicationApplicationCcNoGetPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetApplicationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = CreditCardApplicationWithNamesInComments;
  }

  /**
   * No description
   * @tags CreditCard Application
   * @name CreateApplicationApplicationCcNoCreatePost
   * @summary Create Application
   * @request POST:/application/cc/no/create
   * @secure
   */
  export namespace CreateApplicationApplicationCcNoCreatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateCreditCardApplicationPayloadExt;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicationCreatedResponseExt;
  }

  /**
   * No description
   * @tags CreditCard Application
   * @name UpdateApplicationApplicationCcNoUpdatePost
   * @summary Update Application
   * @request POST:/application/cc/no/update
   * @secure
   */
  export namespace UpdateApplicationApplicationCcNoUpdatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateCreditCardApplicationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmptyModel;
  }

  /**
   * No description
   * @tags CreditCard Application
   * @name UpdateAndGetApplicationApplicationCcNoUpdateAndGetPost
   * @summary Update And Get Application
   * @request POST:/application/cc/no/update-and-get
   * @secure
   */
  export namespace UpdateAndGetApplicationApplicationCcNoUpdateAndGetPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateCreditCardApplicationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = CreditCardApplicationExtended;
  }

  /**
   * No description
   * @tags CreditCard Application
   * @name CreateApplicationApplicantApplicationCcNoCreateWithApplicantPost
   * @summary Create Application Applicant
   * @request POST:/application/cc/no/create-with-applicant
   * @secure
   */
  export namespace CreateApplicationApplicantApplicationCcNoCreateWithApplicantPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateCreditCardApplicationApplicantPayloadExt;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicationCreatedResponseExt;
  }

  /**
   * No description
   * @tags CreditCard Application
   * @name GetRecentApplicationsApplicationCcNoGetRecentPost
   * @summary Get Recent Applications
   * @request POST:/application/cc/no/get_recent
   * @secure
   */
  export namespace GetRecentApplicationsApplicationCcNoGetRecentPost {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Page
       * @min 1
       * @default 1
       */
      page?: number;
      /**
       * Size
       * @min 1
       * @max 100000
       * @default 50
       */
      size?: number;
    };
    export type RequestBody = GetRecentPayload;
    export type RequestHeaders = {};
    export type ResponseBody = PageCreditCardApplicationExtended;
  }

  /**
   * No description
   * @tags CreditCard Application
   * @name GetApplicationBankEventsApplicationCcNoBankEventsPost
   * @summary Get Application Bank Events
   * @request POST:/application/cc/no/bank-events
   * @secure
   */
  export namespace GetApplicationBankEventsApplicationCcNoBankEventsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ApplicationQuery;
    export type RequestHeaders = {};
    export type ResponseBody = CreditCardBankEventsResponse;
  }

  /**
   * No description
   * @tags CreditCard Application
   * @name SearchApplicationsApplicationCcNoSearchApplicationsPost
   * @summary Search Applications
   * @request POST:/application/cc/no/search_applications
   * @secure
   */
  export namespace SearchApplicationsApplicationCcNoSearchApplicationsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetApplicationsByApplicantIdentifierPayload;
    export type RequestHeaders = {};
    export type ResponseBody = CreditCardApplicationExtended[];
  }

  /**
   * No description
   * @tags CreditCard Application
   * @name AcceptOfferApplicationCcNoAcceptOfferPost
   * @summary Accept Offer
   * @request POST:/application/cc/no/accept_offer
   * @secure
   */
  export namespace AcceptOfferApplicationCcNoAcceptOfferPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AcceptOffer;
    export type RequestHeaders = {};
    export type ResponseBody = CreditorOfferCreditCardOfferCreditCardTerms;
  }

  /**
   * No description
   * @tags Mortgage SE Application
   * @name GetApplicationBankEventsApplicationMoSeBankEventsPost
   * @summary Get Application Bank Events
   * @request POST:/application/mo/se/bank-events
   * @secure
   */
  export namespace GetApplicationBankEventsApplicationMoSeBankEventsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ApplicationQuery;
    export type RequestHeaders = {};
    export type ResponseBody = MortgageBankEventsResponse;
  }

  /**
   * No description
   * @tags Bank Communication
   * @name InviteApplicationApplicationInvitePost
   * @summary Invite Application
   * @request POST:/application/invite
   * @secure
   */
  export namespace InviteApplicationApplicationInvitePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = InvitePayloadBase;
    export type RequestHeaders = {};
    export type ResponseBody = EmptyModel;
  }

  /**
   * No description
   * @tags Bank Communication
   * @name InviteApplicationIdApplicationInviteApplicationIdPost
   * @summary Invite Application Id
   * @request POST:/application/invite/{application_id}
   * @secure
   */
  export namespace InviteApplicationIdApplicationInviteApplicationIdPost {
    export type RequestParams = {
      /**
       * Application Id
       * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
       */
      applicationId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = InvitePayloadBase;
    export type RequestHeaders = {};
    export type ResponseBody = EmptyModel;
  }

  /**
   * No description
   * @tags Bank Communication
   * @name GetAppEventsApplicationGetAppEventsApplicationIdGet
   * @summary Get App Events
   * @request GET:/application/get_app_events/{application_id}
   * @secure
   */
  export namespace GetAppEventsApplicationGetAppEventsApplicationIdGet {
    export type RequestParams = {
      /**
       * Application Id
       * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
       */
      applicationId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EventsResponse;
  }

  /**
   * No description
   * @tags Bank Communication
   * @name GetAppEventsOldApplicationGetAppEventsPost
   * @summary Get App Events Old
   * @request POST:/application/get_app_events
   * @secure
   */
  export namespace GetAppEventsOldApplicationGetAppEventsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Bank Communication
   * @name SendCommentToIntegrationApplicationCommentToBankPost
   * @summary Send Comment To Integration
   * @request POST:/application/comment_to_bank
   * @secure
   */
  export namespace SendCommentToIntegrationApplicationCommentToBankPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BaseComment;
    export type RequestHeaders = {};
    export type ResponseBody = EventsResponse;
  }

  /**
   * No description
   * @tags Bank Communication
   * @name AcceptOfferApplicationAcceptOfferPost
   * @summary Accept Offer
   * @request POST:/application/accept_offer
   * @secure
   */
  export namespace AcceptOfferApplicationAcceptOfferPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AcceptOffer;
    export type RequestHeaders = {};
    export type ResponseBody = EventsResponse;
  }

  /**
   * No description
   * @tags Bank Communication
   * @name CancelApplicationCancelPost
   * @summary Cancel
   * @request POST:/application/cancel
   * @secure
   */
  export namespace CancelApplicationCancelPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CancelOfferPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EventsResponse;
  }

  /**
   * No description
   * @tags Bank Communication
   * @name SendDocumentToIntegrationApplicationDocumentToBankPost
   * @summary Send Document To Integration
   * @request POST:/application/document_to_bank
   * @secure
   */
  export namespace SendDocumentToIntegrationApplicationDocumentToBankPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DocumentToBank;
    export type RequestHeaders = {};
    export type ResponseBody = EventsResponse;
  }

  /**
   * No description
   * @tags Application Meta
   * @name AssignApplicationToHandlerApplicationAssignApplicationIdPost
   * @summary Assign Application To Handler
   * @request POST:/application/assign/{application_id}
   * @secure
   */
  export namespace AssignApplicationToHandlerApplicationAssignApplicationIdPost {
    export type RequestParams = {
      /**
       * Application Id
       * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
       */
      applicationId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = AssignApplicationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags Application Meta
   * @name ListInternalCommentsApplicationCommentApplicationIdGet
   * @summary List Internal Comments
   * @request GET:/application/comment/{application_id}
   * @secure
   */
  export namespace ListInternalCommentsApplicationCommentApplicationIdGet {
    export type RequestParams = {
      /**
       * Application Id
       * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
       */
      applicationId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = (BankIDSignedWithNames | InternalCommentWithName)[];
  }

  /**
   * No description
   * @tags Application Meta
   * @name AddInternalCommentApplicationCommentApplicationIdPost
   * @summary Add Internal Comment
   * @request POST:/application/comment/{application_id}
   * @secure
   */
  export namespace AddInternalCommentApplicationCommentApplicationIdPost {
    export type RequestParams = {
      /**
       * Application Id
       * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
       */
      applicationId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CommentPayload;
    export type RequestHeaders = {};
    export type ResponseBody = (BankIDSignedWithNames | InternalCommentWithName)[];
  }

  /**
   * No description
   * @tags Application Meta
   * @name GetPossibleStatusesApplicationPrefsGet
   * @summary Get Possible Statuses
   * @request GET:/application/prefs
   * @secure
   */
  export namespace GetPossibleStatusesApplicationPrefsGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PrefsResponse;
  }

  /**
   * No description
   * @tags Application Meta
   * @name GetPossibleLoanTypesApplicationLoanTypesCountryGet
   * @summary Get Possible Loan Types
   * @request GET:/application/loan-types/{country}
   * @secure
   */
  export namespace GetPossibleLoanTypesApplicationLoanTypesCountryGet {
    export type RequestParams = {
      country: Country;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string[];
  }

  /**
   * No description
   * @tags Application Meta
   * @name SearchBlancoSverkerApplicationBlancoSearchCountryGet
   * @summary Search Blanco Sverker
   * @request GET:/application/blanco/search/{country}
   * @secure
   */
  export namespace SearchBlancoSverkerApplicationBlancoSearchCountryGet {
    export type RequestParams = {
      country: Country;
    };
    export type RequestQuery = {
      /** S */
      s: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SverkerSearchResponse;
  }

  /**
   * No description
   * @tags AML
   * @name AmlReviewApplicationAmlReviewPost
   * @summary Aml Review
   * @request POST:/application/aml/review
   * @secure
   */
  export namespace AmlReviewApplicationAmlReviewPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AMLReviewPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ApplicationWithNamesInComments;
  }

  /**
   * No description
   * @tags AML
   * @name GetBlancoAmlApplicationBlancoAmlApplicationIdGet
   * @summary Get Blanco Aml
   * @request GET:/application/blanco/aml/{application_id}
   * @secure
   */
  export namespace GetBlancoAmlApplicationBlancoAmlApplicationIdGet {
    export type RequestParams = {
      /** Application Id */
      applicationId: number;
    };
    export type RequestQuery = {
      country: Country;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AMLBlancoResponse;
  }

  /**
   * No description
   * @tags AML
   * @name PatchBlancoAmlApplicationBlancoAmlApplicationIdPatch
   * @summary Patch Blanco Aml
   * @request PATCH:/application/blanco/aml/{application_id}
   * @secure
   */
  export namespace PatchBlancoAmlApplicationBlancoAmlApplicationIdPatch {
    export type RequestParams = {
      /** Application Id */
      applicationId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = BlancoAMLPatchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = AMLBlancoResponse;
  }

  /**
   * No description
   * @tags AML
   * @name ReviewBlancoAmlApplicationBlancoAmlReviewApplicationIdPatch
   * @summary Review Blanco Aml
   * @request PATCH:/application/blanco/aml/review/{application_id}
   * @secure
   */
  export namespace ReviewBlancoAmlApplicationBlancoAmlReviewApplicationIdPatch {
    export type RequestParams = {
      /** Application Id */
      applicationId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = BlancoAMLReviewPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags AML
   * @name MortgageDeactivationReviewsApplicationDeactivationReviewsMortgagePost
   * @summary Mortgage Deactivation Reviews
   * @request POST:/application/deactivation_reviews/mortgage
   * @secure
   */
  export namespace MortgageDeactivationReviewsApplicationDeactivationReviewsMortgagePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DeactivationReviewsPayload;
    export type RequestHeaders = {};
    export type ResponseBody = MortgageDeactivationReviewsResponse;
  }

  /**
   * No description
   * @tags AML
   * @name BlancoDeactivationReviewsApplicationDeactivationReviewsBlancoPost
   * @summary Blanco Deactivation Reviews
   * @request POST:/application/deactivation_reviews/blanco
   * @secure
   */
  export namespace BlancoDeactivationReviewsApplicationDeactivationReviewsBlancoPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DeactivationReviewsPayload;
    export type RequestHeaders = {};
    export type ResponseBody = DeactivationReviewsResponse;
  }

  /**
   * No description
   * @tags Migrator
   * @name MigrateSverkerApplicationApplicationMigratorAppLabelPost
   * @summary Migrate Sverker Application
   * @request POST:/application/migrator/{app_label}
   * @secure
   */
  export namespace MigrateSverkerApplicationApplicationMigratorAppLabelPost {
    export type RequestParams = {
      /**
       * App Label
       * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
       */
      appLabel: string;
    };
    export type RequestQuery = {};
    export type RequestBody = ZrmMigratorEvents;
    export type RequestHeaders = {};
    export type ResponseBody = ZrmMigrationStats;
  }
}

export namespace Customer {
  /**
   * No description
   * @tags Customer
   * @name GetCustomerCustomerGetPost
   * @summary Get Customer
   * @request POST:/customer/get
   * @secure
   */
  export namespace GetCustomerCustomerGetPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody =
      | PniGetCustomerPayload
      | EmailGetCustomerPayload
      | PhoneGetCustomerPayload
      | CustomerIDGetCustomerPayload
      | CustomerIDsGetCustomerPayload;
    export type RequestHeaders = {};
    export type ResponseBody =
      | ({
          country: "SE";
        } & ApplicantSE)
      | ({
          country: "NO";
        } & ApplicantNO);
  }

  /**
   * No description
   * @tags Customer
   * @name GetCustomersCustomerGetsPost
   * @summary Get Customers
   * @request POST:/customer/gets
   * @secure
   */
  export namespace GetCustomersCustomerGetsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CustomerIDsGetCustomerPayload;
    export type RequestHeaders = {};
    export type ResponseBody = (
      | ({
          country: "SE";
        } & ApplicantSE)
      | ({
          country: "NO";
        } & ApplicantNO)
    )[];
  }

  /**
   * No description
   * @tags Customer
   * @name CreateCustomerCustomerCreatePost
   * @summary Create Customer
   * @request POST:/customer/create
   * @secure
   */
  export namespace CreateCustomerCustomerCreatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateCustomerPayload;
    export type RequestHeaders = {};
    export type ResponseBody =
      | ({
          country: "SE";
        } & ApplicantSE)
      | ({
          country: "NO";
        } & ApplicantNO);
  }

  /**
   * No description
   * @tags Customer
   * @name UpdateCustomerCustomerUpdatePost
   * @summary Update Customer
   * @request POST:/customer/update
   * @secure
   */
  export namespace UpdateCustomerCustomerUpdatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateCustomerPayload;
    export type RequestHeaders = {};
    export type ResponseBody =
      | ({
          country: "SE";
        } & ApplicantSE)
      | ({
          country: "NO";
        } & ApplicantNO);
  }

  /**
   * No description
   * @tags Customer
   * @name UpdateConsentsCustomerUpdateConsentsPost
   * @summary Update Consents
   * @request POST:/customer/update_consents
   * @secure
   */
  export namespace UpdateConsentsCustomerUpdateConsentsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateCustomerConsentsPayload;
    export type RequestHeaders = {};
    export type ResponseBody =
      | ({
          country: "SE";
        } & ApplicantSE)
      | ({
          country: "NO";
        } & ApplicantNO);
  }

  /**
   * No description
   * @tags Customer
   * @name IdCheckOrGetCustomerCustomerIdCheckPost
   * @summary Id Check Or Get Customer
   * @request POST:/customer/id-check
   * @secure
   */
  export namespace IdCheckOrGetCustomerCustomerIdCheckPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = IDCheckGetCustomerPayload;
    export type RequestHeaders = {};
    export type ResponseBody =
      | (
          | ({
              country: "SE";
            } & ApplicantSE)
          | ({
              country: "NO";
            } & ApplicantNO)
        )
      | IDCheckedPerson
      | null;
  }

  /**
   * No description
   * @tags Customer
   * @name TakeCreditCheckCustomerCreditCheckPost
   * @summary Take Credit Check
   * @request POST:/customer/credit-check
   * @secure
   */
  export namespace TakeCreditCheckCustomerCreditCheckPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TakeCreditCheck;
    export type RequestHeaders = {};
    export type ResponseBody =
      | ({
          country: "SE";
        } & ApplicantSE)
      | ({
          country: "NO";
        } & ApplicantNO);
  }

  /**
   * No description
   * @tags Customer
   * @name GetNameByPniCustomerNameByPniPost
   * @summary Get Name By Pni
   * @request POST:/customer/name/by-pni
   * @secure
   */
  export namespace GetNameByPniCustomerNameByPniPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetNameByPNIPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Name | null;
  }

  /**
   * No description
   * @tags Customer
   * @name GetNameCustomerNameCustomerIdGet
   * @summary Get Name
   * @request GET:/customer/name/{customer_id}
   * @secure
   */
  export namespace GetNameCustomerNameCustomerIdGet {
    export type RequestParams = {
      /** Customer Id */
      customerId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Name | null;
  }

  /**
   * No description
   * @tags Customer, Application Meta
   * @name EmploymentTypesCustomerEmploymentTypesCountryGet
   * @summary Employment Types
   * @request GET:/customer/employment-types/{country}
   * @secure
   */
  export namespace EmploymentTypesCustomerEmploymentTypesCountryGet {
    export type RequestParams = {
      country: Country;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string[];
  }

  /**
   * No description
   * @tags Customer, Application Meta
   * @name HousingTypesCustomerHousingTypesCountryGet
   * @summary Housing Types
   * @request GET:/customer/housing-types/{country}
   * @secure
   */
  export namespace HousingTypesCustomerHousingTypesCountryGet {
    export type RequestParams = {
      country: Country;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string[];
  }

  /**
   * No description
   * @tags Customer
   * @name GetExternallyVerifiedCreditsCustomerExternallyVerifiedCreditsPost
   * @summary Get Externally Verified Credits
   * @request POST:/customer/externally-verified-credits
   * @secure
   */
  export namespace GetExternallyVerifiedCreditsCustomerExternallyVerifiedCreditsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = MinimalApplicant[];
    export type RequestHeaders = {};
    export type ResponseBody = LoanAndExternallyVerifiedCredit;
  }
}

export namespace Meeting {
  /**
   * No description
   * @tags Meeting
   * @name GetMeetingMeetingGetMeetingIdGet
   * @summary Get Meeting
   * @request GET:/meeting/get/{meeting_id}
   * @secure
   */
  export namespace GetMeetingMeetingGetMeetingIdGet {
    export type RequestParams = {
      /** Meeting Id */
      meetingId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsMeetingModelsMeeting;
  }

  /**
   * No description
   * @tags Meeting
   * @name GetMeetingsMeetingGetPost
   * @summary Get Meetings
   * @request POST:/meeting/get
   * @secure
   */
  export namespace GetMeetingsMeetingGetPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetMeetings;
    export type RequestHeaders = {};
    export type ResponseBody = MeetingWithApp[] | MeetingWithHandlerName[] | ModelsMeetingModelsMeeting[];
  }

  /**
   * No description
   * @tags Meeting
   * @name GetMyMeetingsMeetingMinePost
   * @summary Get My Meetings
   * @request POST:/meeting/mine
   * @secure
   */
  export namespace GetMyMeetingsMeetingMinePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetMyMeetings;
    export type RequestHeaders = {};
    export type ResponseBody = (MeetingWithApp | MeetingWithHandlerName | ModelsMeetingModelsMeeting)[];
  }

  /**
   * No description
   * @tags Meeting
   * @name CreateMeetingMeetingCreatePost
   * @summary Create Meeting
   * @request POST:/meeting/create
   * @secure
   */
  export namespace CreateMeetingMeetingCreatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateMeetingPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsMeetingModelsMeeting;
  }

  /**
   * No description
   * @tags Meeting
   * @name UpdateMeetingMeetingUpdateMeetingIdPost
   * @summary Update Meeting
   * @request POST:/meeting/update/{meeting_id}
   * @secure
   */
  export namespace UpdateMeetingMeetingUpdateMeetingIdPost {
    export type RequestParams = {
      /** Meeting Id */
      meetingId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateMeetingPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsMeetingModelsMeeting;
  }

  /**
   * No description
   * @tags Meeting
   * @name CancelMeetingsMeetingCancelMeetingIdDelete
   * @summary Cancel Meetings
   * @request DELETE:/meeting/cancel/{meeting_id}
   * @secure
   */
  export namespace CancelMeetingsMeetingCancelMeetingIdDelete {
    export type RequestParams = {
      /** Meeting Id */
      meetingId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Meeting
   * @name GetBookableTimesMeetingAvailableDayGet
   * @summary Get Bookable Times
   * @request GET:/meeting/available/{day}
   * @secure
   */
  export namespace GetBookableTimesMeetingAvailableDayGet {
    export type RequestParams = {
      /**
       * Day
       * @format date
       */
      day: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {
      /** X-Zoordinates */
      "x-zoordinates": string;
    };
    export type ResponseBody = BookableSlot[];
  }
}

export namespace Document {
  /**
   * No description
   * @tags Document
   * @name GetDocumentMetaDocumentGetMetaCustomerIdDocumentIdGet
   * @summary Get Document Meta
   * @request GET:/document/get/meta/{customer_id}/{document_id}
   * @secure
   */
  export namespace GetDocumentMetaDocumentGetMetaCustomerIdDocumentIdGet {
    export type RequestParams = {
      /**
       * Customer Id
       * @format uuid
       */
      customerId: string;
      /**
       * Document Id
       * @format uuid
       */
      documentId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DocumentMeta;
  }

  /**
   * No description
   * @tags Document
   * @name GetDocumentDocumentGetCustomerIdDocumentIdGet
   * @summary Get Document
   * @request GET:/document/get/{customer_id}/{document_id}
   * @secure
   */
  export namespace GetDocumentDocumentGetCustomerIdDocumentIdGet {
    export type RequestParams = {
      /**
       * Customer Id
       * @format uuid
       */
      customerId: string;
      /**
       * Document Id
       * @format uuid
       */
      documentId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CustomerDocument;
  }

  /**
   * No description
   * @tags Document
   * @name CreateDocumentDocumentSavePost
   * @summary Create Document
   * @request POST:/document/save
   * @secure
   */
  export namespace CreateDocumentDocumentSavePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DocumentCreate;
    export type RequestHeaders = {};
    export type ResponseBody = CustomerDocument;
  }

  /**
   * No description
   * @tags Document
   * @name DocumentTypesDocumentTypesGet
   * @summary Document Types
   * @request GET:/document/types
   * @secure
   */
  export namespace DocumentTypesDocumentTypesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DocumentType[];
  }

  /**
   * No description
   * @tags Document
   * @name GetAttachedDocumentsOfApplicationDocumentAttachedApplicationIdGet
   * @summary Get Attached Documents Of Application
   * @request GET:/document/attached/{application_id}
   * @secure
   */
  export namespace GetAttachedDocumentsOfApplicationDocumentAttachedApplicationIdGet {
    export type RequestParams = {
      /**
       * Application Id
       * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
       */
      applicationId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DocumentAssignation[];
  }

  /**
   * No description
   * @tags Document
   * @name AttachDocumentToApplicationDocumentAttachApplicationIdPost
   * @summary Attach Document To Application
   * @request POST:/document/attach/{application_id}
   * @secure
   */
  export namespace AttachDocumentToApplicationDocumentAttachApplicationIdPost {
    export type RequestParams = {
      /**
       * Application Id
       * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
       */
      applicationId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = DocumentAssignation;
    export type RequestHeaders = {};
    export type ResponseBody = DocumentAssignation[];
  }
}

export namespace Banks {
  /**
   * No description
   * @tags Banks
   * @name GetAllBanksBanksAllGet
   * @summary Get All Banks
   * @request GET:/banks/all
   * @secure
   */
  export namespace GetAllBanksBanksAllGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Bank[];
  }

  /**
   * No description
   * @tags Banks
   * @name GetBanksBanksGetPost
   * @summary Get Banks
   * @request POST:/banks/get
   * @secure
   */
  export namespace GetBanksBanksGetPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetBanksPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Bank[];
  }

  /**
   * No description
   * @tags Banks
   * @name GetAllIntegrationsBanksAllIntegrationsGet
   * @summary Get All Integrations
   * @request GET:/banks/all_integrations
   * @secure
   */
  export namespace GetAllIntegrationsBanksAllIntegrationsGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Integration[];
  }

  /**
   * No description
   * @tags Banks
   * @name GetIntegrationsBanksGetIntegrationsPost
   * @summary Get Integrations
   * @request POST:/banks/get_integrations
   * @secure
   */
  export namespace GetIntegrationsBanksGetIntegrationsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = IntegrationQuery;
    export type RequestHeaders = {};
    export type ResponseBody = Integration[];
  }
}

export namespace Schedule {
  /**
   * No description
   * @tags Advisor Schedule
   * @name ScheduleDaySchedulePost
   * @summary Schedule Day
   * @request POST:/schedule/
   * @secure
   */
  export namespace ScheduleDaySchedulePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DaySlotDocPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Advisor Schedule
   * @name GetScheduleOfDayScheduleDayGet
   * @summary Get Schedule Of Day
   * @request GET:/schedule/{day}
   * @secure
   */
  export namespace GetScheduleOfDayScheduleDayGet {
    export type RequestParams = {
      /**
       * Day
       * @format date
       */
      day: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {
      /** X-Zoordinates */
      "x-zoordinates": string;
    };
    export type ResponseBody = DaySlotDoc;
  }

  /**
   * No description
   * @tags Advisor Schedule
   * @name GetScheduleOfDayByCountryAndProductScheduleDayProductCountryGet
   * @summary Get Schedule Of Day By Country And Product
   * @request GET:/schedule/{day}/{product}/{country}
   * @secure
   */
  export namespace GetScheduleOfDayByCountryAndProductScheduleDayProductCountryGet {
    export type RequestParams = {
      /**
       * Day
       * @format date
       */
      day: string;
      product: Product;
      country: Country;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DaySlotDoc;
  }
}

export namespace Employee {
  /**
   * No description
   * @tags Employee
   * @name GetAllEmployeesEmployeeAllGet
   * @summary Get All Employees
   * @request GET:/employee/all
   * @secure
   */
  export namespace GetAllEmployeesEmployeeAllGet {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Page */
      page?: number | null;
      /** Limit */
      limit?: number | null;
      /**
       * Without Deactivated
       * @default false
       */
      without_deactivated?: boolean | null;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Employee[];
  }

  /**
   * No description
   * @tags Employee
   * @name GetSverkerUsersEmployeeGetSverkerUsersGet
   * @summary Get Sverker Users
   * @request GET:/employee/get_sverker_users
   * @secure
   */
  export namespace GetSverkerUsersEmployeeGetSverkerUsersGet {
    export type RequestParams = {};
    export type RequestQuery = {
      /** User Name */
      user_name: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string[];
  }

  /**
   * No description
   * @tags Employee
   * @name GetEmployeesByZoordinatesEmployeeByZoordinatesPost
   * @summary Get Employees By Zoordinates
   * @request POST:/employee/by_zoordinates
   * @secure
   */
  export namespace GetEmployeesByZoordinatesEmployeeByZoordinatesPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AllOptionalZoordinates;
    export type RequestHeaders = {};
    export type ResponseBody = Employee[];
  }

  /**
   * No description
   * @tags Employee
   * @name GetMyInfoEmployeeMeGet
   * @summary Get My Info
   * @request GET:/employee/me
   * @secure
   */
  export namespace GetMyInfoEmployeeMeGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Employee;
  }

  /**
   * No description
   * @tags Employee
   * @name DeactivateUserByIdEmployeeActionsBlockOrUnblockUserIdPatch
   * @summary Deactivate User By Id
   * @request PATCH:/employee/actions/block-or-unblock/{user_id}
   * @secure
   */
  export namespace DeactivateUserByIdEmployeeActionsBlockOrUnblockUserIdPatch {
    export type RequestParams = {
      /** User Id */
      userId: string;
    };
    export type RequestQuery = {
      /**
       * Block
       * @default true
       */
      block?: boolean;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags Employee
   * @name GetAllZoordinatesEmployeeZoordinatesGet
   * @summary Get All Zoordinates
   * @request GET:/employee/zoordinates
   * @secure
   */
  export namespace GetAllZoordinatesEmployeeZoordinatesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Zoordinates[];
  }

  /**
   * No description
   * @tags Employee
   * @name GetAllRolesEmployeeRolesGet
   * @summary Get All Roles
   * @request GET:/employee/roles
   * @secure
   */
  export namespace GetAllRolesEmployeeRolesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = RoleType[];
  }

  /**
   * No description
   * @tags Employee
   * @name GetMySettingsEmployeeMySettingsGet
   * @summary Get My Settings
   * @request GET:/employee/my-settings
   * @secure
   */
  export namespace GetMySettingsEmployeeMySettingsGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserSettings;
  }

  /**
   * No description
   * @tags Employee
   * @name SaveMySettingsEmployeeMySettingsPost
   * @summary Save My Settings
   * @request POST:/employee/my-settings
   * @secure
   */
  export namespace SaveMySettingsEmployeeMySettingsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UserSettings;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags Employee
   * @name CreateEmployeeEmployeePost
   * @summary Create Employee
   * @request POST:/employee/
   * @secure
   */
  export namespace CreateEmployeeEmployeePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateAndUpdateEmployeeRequest;
    export type RequestHeaders = {};
    export type ResponseBody = Employee;
  }

  /**
   * No description
   * @tags Employee
   * @name UpdateEmployeeEmployeeUserIdPut
   * @summary Update Employee
   * @request PUT:/employee/{user_id}
   * @secure
   */
  export namespace UpdateEmployeeEmployeeUserIdPut {
    export type RequestParams = {
      /** User Id */
      userId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CreateAndUpdateEmployeeRequest;
    export type RequestHeaders = {};
    export type ResponseBody = Employee;
  }

  /**
   * No description
   * @tags Employee
   * @name GetByIdEmployeeUserIdGet
   * @summary Get By Id
   * @request GET:/employee/{user_id}
   * @secure
   */
  export namespace GetByIdEmployeeUserIdGet {
    export type RequestParams = {
      /** User Id */
      userId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Employee;
  }

  /**
   * No description
   * @tags Employee
   * @name SearchEmployeeSearchPost
   * @summary Search
   * @request POST:/employee/search
   * @secure
   */
  export namespace SearchEmployeeSearchPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = RoutersEmployeeModelsSearchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Employee[];
  }
}

export namespace Property {
  /**
   * No description
   * @tags Property
   * @name GetPropertyPropertyGetPropertyIdGet
   * @summary Get Property
   * @request GET:/property/get/{property_id}
   * @secure
   */
  export namespace GetPropertyPropertyGetPropertyIdGet {
    export type RequestParams = {
      /** Property Id */
      propertyId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = House | VacationHome | Condominium;
  }

  /**
   * No description
   * @tags Property
   * @name GetPartialPropertyPropertyPartialGetPropertyIdGet
   * @summary Get Partial Property
   * @request GET:/property/partial/get/{property_id}
   * @secure
   */
  export namespace GetPartialPropertyPropertyPartialGetPropertyIdGet {
    export type RequestParams = {
      /** Property Id */
      propertyId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PartialProperty;
  }

  /**
   * No description
   * @tags Property
   * @name CreatePropertyPropertyCreateApplicationIdPost
   * @summary Create Property
   * @request POST:/property/create/{application_id}
   * @secure
   */
  export namespace CreatePropertyPropertyCreateApplicationIdPost {
    export type RequestParams = {
      /**
       * Application Id
       * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
       */
      applicationId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = PartialHouse | PartialCondominium | PartialVacationHome;
    export type RequestHeaders = {};
    export type ResponseBody = House | VacationHome | Condominium;
  }

  /**
   * No description
   * @tags Property
   * @name CreatePartialPropertyPropertyPartialCreateApplicationIdPost
   * @summary Create Partial Property
   * @request POST:/property/partial/create/{application_id}
   * @secure
   */
  export namespace CreatePartialPropertyPropertyPartialCreateApplicationIdPost {
    export type RequestParams = {
      /**
       * Application Id
       * Identifier for an application. Includes product, country and integer application ID. Integer ID is unique within given product and country. ID part is usually padded with zeroes up to 6 digits.
       */
      applicationId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = NewPartialProperty;
    export type RequestHeaders = {};
    export type ResponseBody = PartialProperty;
  }

  /**
   * No description
   * @tags Property
   * @name UpdatePropertyPropertyUpdatePropertyIdPut
   * @summary Update Property
   * @request PUT:/property/update/{property_id}
   * @secure
   */
  export namespace UpdatePropertyPropertyUpdatePropertyIdPut {
    export type RequestParams = {
      /** Property Id */
      propertyId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = PartialHouse | PartialCondominium | PartialVacationHome;
    export type RequestHeaders = {};
    export type ResponseBody = House | VacationHome | Condominium;
  }

  /**
   * No description
   * @tags Property
   * @name UpdatePartialPropertyPropertyPartialUpdatePropertyIdPut
   * @summary Update Partial Property
   * @request PUT:/property/partial/update/{property_id}
   * @secure
   */
  export namespace UpdatePartialPropertyPropertyPartialUpdatePropertyIdPut {
    export type RequestParams = {
      /** Property Id */
      propertyId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = NewPartialProperty;
    export type RequestHeaders = {};
    export type ResponseBody = PartialProperty;
  }

  /**
   * No description
   * @tags Property Valuation
   * @name SearchPropertyValuationSearchPropertyIdPost
   * @summary Search
   * @request POST:/property/valuation/search/{property_id}
   * @secure
   */
  export namespace SearchPropertyValuationSearchPropertyIdPost {
    export type RequestParams = {
      /** Property Id */
      propertyId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = RoutersPropertyPayloadSearchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = PropertySearchResult[];
  }

  /**
   * No description
   * @tags Property Creation
   * @name SearchForAddressPropertyV2SearchPropertyTypePost
   * @summary Search For Address
   * @request POST:/property/v2/search/{property_type}
   * @secure
   */
  export namespace SearchForAddressPropertyV2SearchPropertyTypePost {
    export type RequestParams = {
      propertyType: MortgagePropertyType;
    };
    export type RequestQuery = {};
    export type RequestBody = RoutersPropertyPayloadSearchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = PropertySearchResult[];
  }

  /**
   * No description
   * @tags Property Creation
   * @name GetPropertyInfoForCreationPropertyV2InfoSearchIdGet
   * @summary Get Property Info For Creation
   * @request GET:/property/v2/info/{search_id}
   * @secure
   */
  export namespace GetPropertyInfoForCreationPropertyV2InfoSearchIdGet {
    export type RequestParams = {
      /** Search Id */
      searchId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PropertyInfo;
  }

  /**
   * No description
   * @tags Property Creation
   * @name GetPropertyInfoByExternalIdPropertyV2InfoByExternalIdExternalIdGet
   * @summary Get Property Info By External Id
   * @request GET:/property/v2/info-by-external-id/{external_id}
   * @secure
   */
  export namespace GetPropertyInfoByExternalIdPropertyV2InfoByExternalIdExternalIdGet {
    export type RequestParams = {
      /** External Id */
      externalId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PropertyInfo;
  }

  /**
   * No description
   * @tags Property Valuation
   * @name GetPropertyValuationV2PropertyValuationV2Post
   * @summary Get Property Valuation V2
   * @request POST:/property/valuation/v2
   * @secure
   */
  export namespace GetPropertyValuationV2PropertyValuationV2Post {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ValuationPayloadV2;
    export type RequestHeaders = {};
    export type ResponseBody = PropertyValuation;
  }

  /**
   * No description
   * @tags Property Valuation
   * @name GetPropertyInfoPropertyValuationInfoSearchIdGet
   * @summary Get Property Info
   * @request GET:/property/valuation/info/{search_id}
   * @secure
   */
  export namespace GetPropertyInfoPropertyValuationInfoSearchIdGet {
    export type RequestParams = {
      /** Search Id */
      searchId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PropertyInfo;
  }

  /**
   * No description
   * @tags Property Valuation
   * @name GetPropertyValuationPropertyValuationValuationPost
   * @summary Get Property Valuation
   * @request POST:/property/valuation/valuation
   * @secure
   */
  export namespace GetPropertyValuationPropertyValuationValuationPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ValuationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = PropertyValuation;
  }

  /**
   * No description
   * @tags Property Creation
   * @name BooliSearchForAddressPropertyV2BooliSearchPost
   * @summary Booli Search For Address
   * @request POST:/property/v2/booli/search
   * @deprecated
   * @secure
   */
  export namespace BooliSearchForAddressPropertyV2BooliSearchPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BooliSearchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = BooliPropertySearchResult[];
  }

  /**
   * No description
   * @tags Property Valuation
   * @name GetPropertyBooliValuationPropertyValuationBooliPost
   * @summary Get Property Booli Valuation
   * @request POST:/property/valuation/booli
   * @deprecated
   * @secure
   */
  export namespace GetPropertyBooliValuationPropertyValuationBooliPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BooliValuationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = PropertyValuation;
  }

  /**
   * No description
   * @name GetPropertyByPniPropertyEvcheckCachePost
   * @summary Get Property By Pni
   * @request POST:/property/evcheck/cache
   * @secure
   */
  export namespace GetPropertyByPniPropertyEvcheckCachePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = EvCheckPayload;
    export type RequestHeaders = {};
    export type ResponseBody = (PartialHouse | PartialCondominium)[];
  }
}

export namespace Message {
  /**
   * No description
   * @tags Message
   * @name RemoveTemplateMessageTemplateDelete
   * @summary Remove Template
   * @request DELETE:/message/template/
   * @secure
   */
  export namespace RemoveTemplateMessageTemplateDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetTemplatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Message
   * @name GetOneTemplateMessageTemplateGetPost
   * @summary Get One Template
   * @request POST:/message/template/get
   * @secure
   */
  export namespace GetOneTemplateMessageTemplateGetPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetTemplatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = RootModelUnionEmailTemplateSmsTemplate;
  }

  /**
   * No description
   * @tags Message
   * @name CreateTemplateMessageTemplateAddPost
   * @summary Create Template
   * @request POST:/message/template/add
   * @secure
   */
  export namespace CreateTemplateMessageTemplateAddPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = RootModelUnionEmailTemplateSmsTemplate;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Message
   * @name TemplateListMessageTemplateListMessageTypeGet
   * @summary Template List
   * @request GET:/message/template/list/{message_type}
   * @secure
   */
  export namespace TemplateListMessageTemplateListMessageTypeGet {
    export type RequestParams = {
      messageType: MessageType;
    };
    export type RequestQuery = {
      /** Cutoff Date */
      cutoff_date?: string | null;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = (EmailTemplate | SmsTemplate)[];
  }

  /**
   * No description
   * @tags Message
   * @name SendTemplateMessageMessageSendTemplateMessageTypeTemplateEventPost
   * @summary Send Template Message
   * @request POST:/message/send/template/{message_type}/{template_event}
   * @secure
   */
  export namespace SendTemplateMessageMessageSendTemplateMessageTypeTemplateEventPost {
    export type RequestParams = {
      messageType: MessageType;
      templateEvent: TemplateEvent;
    };
    export type RequestQuery = {};
    export type RequestBody = SendTemplatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Message
   * @name SendRawMessageMessageSendRawMessageTypePost
   * @summary Send Raw Message
   * @request POST:/message/send/raw/{message_type}
   * @secure
   */
  export namespace SendRawMessageMessageSendRawMessageTypePost {
    export type RequestParams = {
      messageType: MessageType;
    };
    export type RequestQuery = {};
    export type RequestBody = SendRawPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Message
   * @name GetUnknownSenderSmsMessageSmsUnknownSenderPost
   * @summary Get Unknown Sender Sms
   * @request POST:/message/sms/unknown-sender
   * @secure
   */
  export namespace GetUnknownSenderSmsMessageSmsUnknownSenderPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetMessagesPayload;
    export type RequestHeaders = {};
    export type ResponseBody = IncomingSMS[];
  }

  /**
   * No description
   * @tags Message
   * @name GetMessagesForUserMessageUserPost
   * @summary Get Messages For User
   * @request POST:/message/user
   * @secure
   */
  export namespace GetMessagesForUserMessageUserPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetUserMessagesPayload;
    export type RequestHeaders = {};
    export type ResponseBody = SavedMessage[];
  }

  /**
   * No description
   * @tags Message
   * @name GetMessagesForApplicationMessageApplicationPost
   * @summary Get Messages For Application
   * @request POST:/message/application
   * @secure
   */
  export namespace GetMessagesForApplicationMessageApplicationPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetApplicationMessagesPayload;
    export type RequestHeaders = {};
    export type ResponseBody = SavedMessage[];
  }

  /**
   * No description
   * @tags Message
   * @name GetMessagesByCustomerIdMessageByCustomerPost
   * @summary Get Messages By Customer Id
   * @request POST:/message/by-customer
   * @secure
   */
  export namespace GetMessagesByCustomerIdMessageByCustomerPost {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Page
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * Limit
       * @min 0
       * @max 50
       * @default 50
       */
      limit?: number;
    };
    export type RequestBody = GetMessages;
    export type RequestHeaders = {};
    export type ResponseBody = SavedMessageWithName[];
  }

  /**
   * No description
   * @tags Message
   * @name GetConversationsMessageConversationsGet
   * @summary Get Conversations
   * @request GET:/message/conversations
   * @secure
   */
  export namespace GetConversationsMessageConversationsGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Conversation[];
  }

  /**
   * No description
   * @tags Message
   * @name SaveConversationsMessageConversationsPost
   * @summary Save Conversations
   * @request POST:/message/conversations
   * @secure
   */
  export namespace SaveConversationsMessageConversationsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Conversation[];
    export type RequestHeaders = {};
    export type ResponseBody = Conversation[];
  }

  /**
   * No description
   * @tags Message
   * @name StreamConversationsMessageConversationsStreamGet
   * @summary Stream Conversations
   * @request GET:/message/conversations/stream
   * @secure
   */
  export namespace StreamConversationsMessageConversationsStreamGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Message
   * @name UploadAttachmentMessageAttachmentsUploadPost
   * @summary Upload Attachment
   * @request POST:/message/attachments/upload
   * @secure
   */
  export namespace UploadAttachmentMessageAttachmentsUploadPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyUploadAttachmentMessageAttachmentsUploadPost;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }

  /**
   * No description
   * @tags Message
   * @name GetAttachmentsMessageAttachmentsAllGet
   * @summary Get Attachments
   * @request GET:/message/attachments/all
   * @secure
   */
  export namespace GetAttachmentsMessageAttachmentsAllGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SavedAttachment[];
  }

  /**
   * No description
   * @tags ELK46 Callbacks
   * @name Elk46SmsWebhookMessageElk46ConfirmationPost
   * @summary Elk46 Sms Webhook
   * @request POST:/message/elk46/confirmation
   */
  export namespace Elk46SmsWebhookMessageElk46ConfirmationPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {
      /**
       * X-Forwarded-For
       * @format ipvanyaddress
       */
      "x-forwarded-for": string;
    };
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags ELK46 Callbacks
   * @name Elk46CallWebhookMessageElk46CallPost
   * @summary Elk46 Call Webhook
   * @request POST:/message/elk46/call
   */
  export namespace Elk46CallWebhookMessageElk46CallPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyElk46CallWebhookMessageElk46CallPost;
    export type RequestHeaders = {};
    export type ResponseBody = Elk46CallResponse;
  }
}

export namespace Mortgage {
  /**
   * No description
   * @tags Mortgage
   * @name GetKalpSettingsMortgageKalpCountryGet
   * @summary Get Kalp Settings
   * @request GET:/mortgage/kalp/{country}
   * @secure
   */
  export namespace GetKalpSettingsMortgageKalpCountryGet {
    export type RequestParams = {
      country: Country;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = (BankKALP | BankAPIDrivenKALP)[];
  }
}

export namespace Other {
  /**
   * No description
   * @tags Other
   * @name GetAllTaxesForCountryOtherTaxesCountryGet
   * @summary Get All Taxes For Country
   * @request GET:/other/taxes/{country}
   * @secure
   */
  export namespace GetAllTaxesForCountryOtherTaxesCountryGet {
    export type RequestParams = {
      country: Country;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Tax[];
  }

  /**
   * No description
   * @tags Other
   * @name TaxesForIdentifierOtherTaxesCalculationPost
   * @summary Taxes For Identifier
   * @request POST:/other/taxes/calculation
   * @secure
   */
  export namespace TaxesForIdentifierOtherTaxesCalculationPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetTaxPayload;
    export type RequestHeaders = {};
    export type ResponseBody = GetTaxPayloadResponse;
  }

  /**
   * No description
   * @tags Other
   * @name GetAllMunicipalitiesForCountryOtherMunicipalityCountryGet
   * @summary Get All Municipalities For Country
   * @request GET:/other/municipality/{country}
   * @secure
   */
  export namespace GetAllMunicipalitiesForCountryOtherMunicipalityCountryGet {
    export type RequestParams = {
      country: Country;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Municipality[];
  }

  /**
   * No description
   * @tags Other
   * @name DeactivationReasonsOtherDeactivationReasonsGet
   * @summary Deactivation Reasons
   * @request GET:/other/deactivation-reasons
   * @secure
   */
  export namespace DeactivationReasonsOtherDeactivationReasonsGet {
    export type RequestParams = {};
    export type RequestQuery = {
      product: Product;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string[];
  }

  /**
   * No description
   * @tags Other, websocket
   * @name GetTokenOtherWsTokenForWebsocketGet
   * @summary Get Token
   * @request GET:/other/ws/token-for-websocket
   * @secure
   */
  export namespace GetTokenOtherWsTokenForWebsocketGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TokenResp;
  }
}

export namespace BankId {
  /**
   * No description
   * @tags BankID
   * @name VerifyCustomerBankIdVerifyPost
   * @summary Verify Customer
   * @request POST:/bank-id/verify
   * @secure
   */
  export namespace VerifyCustomerBankIdVerifyPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BankIDPayload;
    export type RequestHeaders = {};
    export type ResponseBody = RoutersBankIdModelsBankIDResp;
  }

  /**
   * No description
   * @tags BankID
   * @name CancelVerifyCustomerBankIdCancelDelete
   * @summary Cancel Verify Customer
   * @request DELETE:/bank-id/cancel
   * @secure
   */
  export namespace CancelVerifyCustomerBankIdCancelDelete {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Ticket Id */
      ticket_id: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags BankID
   * @name IsAlreadyVerifiedBankIdVerifiedPost
   * @summary Is Already Verified
   * @request POST:/bank-id/verified
   * @secure
   */
  export namespace IsAlreadyVerifiedBankIdVerifiedPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BankIDPayload;
    export type RequestHeaders = {};
    export type ResponseBody = PollResp;
  }

  /**
   * No description
   * @tags BankID
   * @name GetTicketStatusBankIdVerifyTicketIdGet
   * @summary Get Ticket Status
   * @request GET:/bank-id/verify/{ticket_id}
   * @secure
   */
  export namespace GetTicketStatusBankIdVerifyTicketIdGet {
    export type RequestParams = {
      /** Ticket Id */
      ticketId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PollResp;
  }

  /**
   * No description
   * @tags BankID
   * @name IdKollenRedirectNoBankIdIdKollenCallbackGet
   * @summary Id Kollen Redirect No
   * @request GET:/bank-id/id-kollen/callback
   */
  export namespace IdKollenRedirectNoBankIdIdKollenCallbackGet {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Refid */
      refId?: string | null;
      /** Id */
      id?: string | null;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }

  /**
   * No description
   * @tags BankID
   * @name IdKollenCallbackSeBankIdIdKollenCallbackPost
   * @summary Id Kollen Callback Se
   * @request POST:/bank-id/id-kollen/callback
   */
  export namespace IdKollenCallbackSeBankIdIdKollenCallbackPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = IDKollenCollectResponse;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags BankID
   * @name QrCodeBankIdQrCodeGet
   * @summary Qr Code
   * @request GET:/bank-id/qr-code
   */
  export namespace QrCodeBankIdQrCodeGet {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Ticket Id */
      ticket_id: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = QRCodeContent;
  }

  /**
   * No description
   * @tags BankID
   * @name RedirectFromAppBankIdRedirectGet
   * @summary Redirect From App
   * @request GET:/bank-id/redirect
   */
  export namespace RedirectFromAppBankIdRedirectGet {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Ticket Id */
      ticket_id: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags BankID
   * @name LoginBankIdAuthPost
   * @summary Login
   * @request POST:/bank-id/auth
   */
  export namespace LoginBankIdAuthPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = LoginPayload;
    export type RequestHeaders = {};
    export type ResponseBody = RoutersBankIdLoginPayloadBankIDResp;
  }

  /**
   * No description
   * @tags BankID
   * @name PollTicketBankIdTicketIdGet
   * @summary Poll Ticket
   * @request GET:/bank-id/{ticket_id}
   */
  export namespace PollTicketBankIdTicketIdGet {
    export type RequestParams = {
      /** Ticket Id */
      ticketId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BankIDLoginResponse;
  }
}

export namespace Notification {
  /**
   * No description
   * @tags Notifications
   * @name UserNotificationsNotificationGet
   * @summary User Notifications
   * @request GET:/notification/
   * @secure
   */
  export namespace UserNotificationsNotificationGet {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Status */
      status?: NotificationEvent[] | null;
      /** Notification Type */
      notification_type?: ModelsNotificationsNotificationTypesNotificationType | null;
      /**
       * Page
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * Limit
       * @min 0
       * @max 50
       * @default 50
       */
      limit?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = (
      | ({
          type: "SMS_RECEIVED";
        } & SMSReceived)
      | ({
          type: "BANK_MESSAGE_RECEIVED";
        } & BankMessageReceived)
      | ({
          type: "OFFER_RECEIVED";
        } & OfferReceived)
      | ({
          type: "INTERNAL_COMMENT_RECEIVED";
        } & ModelsNotificationsNotificationInternalComment)
      | ({
          type: "APPLICATION_ASSIGNED";
        } & ApplicationAssigned)
      | ({
          type: "GENERAL_MESSAGE_RECEIVED";
        } & GeneralMessage)
      | ({
          type: "LEAD_ASSIGNED";
        } & LeadAssigned)
      | ({
          type: "LEAD_COMMENT_RECEIVED";
        } & LeadComment)
      | ({
          type: "APPLICATION_DISBURSED";
        } & ApplicationDisbursed)
    )[];
  }

  /**
   * No description
   * @tags Notifications
   * @name GetNotificationNotificationByIdNotificationIdGet
   * @summary Get Notification
   * @request GET:/notification/by-id/{notification_id}
   * @secure
   */
  export namespace GetNotificationNotificationByIdNotificationIdGet {
    export type RequestParams = {
      /** Notification Id */
      notificationId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody =
      | ({
          type: "SMS_RECEIVED";
        } & SMSReceived)
      | ({
          type: "BANK_MESSAGE_RECEIVED";
        } & BankMessageReceived)
      | ({
          type: "OFFER_RECEIVED";
        } & OfferReceived)
      | ({
          type: "INTERNAL_COMMENT_RECEIVED";
        } & ModelsNotificationsNotificationInternalComment)
      | ({
          type: "APPLICATION_ASSIGNED";
        } & ApplicationAssigned)
      | ({
          type: "GENERAL_MESSAGE_RECEIVED";
        } & GeneralMessage)
      | ({
          type: "LEAD_ASSIGNED";
        } & LeadAssigned)
      | ({
          type: "LEAD_COMMENT_RECEIVED";
        } & LeadComment)
      | ({
          type: "APPLICATION_DISBURSED";
        } & ApplicationDisbursed);
  }

  /**
   * No description
   * @tags Notifications
   * @name NotifyNotificationNotifyIncomingPost
   * @summary Notify
   * @request POST:/notification/notify/incoming
   * @secure
   */
  export namespace NotifyNotificationNotifyIncomingPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Notification;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags Notifications
   * @name NotifyInsuranceNotificationNotifyInsurancePost
   * @summary Notify Insurance
   * @request POST:/notification/notify/insurance
   * @secure
   */
  export namespace NotifyInsuranceNotificationNotifyInsurancePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = InsuranceNotification;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags Notifications
   * @name AcknowledgeNotificationNotificationActionPost
   * @summary Acknowledge Notification
   * @request POST:/notification/action
   * @secure
   */
  export namespace AcknowledgeNotificationNotificationActionPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ActionPayload;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags Notifications
   * @name CustomAuthUserNotificationsNotificationNotificationIdReceivedPatch
   * @summary Custom Auth User Notifications
   * @request PATCH:/notification/{notification_id}/received
   */
  export namespace CustomAuthUserNotificationsNotificationNotificationIdReceivedPatch {
    export type RequestParams = {
      /** Notification Id */
      notificationId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {
      /**
       * Token
       * @default "Authorization"
       */
      token?: string;
    };
    export type ResponseBody = void;
  }
}

export namespace Ponytail {
  /**
   * No description
   * @tags Ponytail
   * @name GetDisbursedStatsPonytailDisbursedAllPost
   * @summary Get Disbursed Stats
   * @request POST:/ponytail/disbursed/all
   * @secure
   */
  export namespace GetDisbursedStatsPonytailDisbursedAllPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DashboardPayload;
    export type RequestHeaders = {};
    export type ResponseBody = AllDisbursedLoans;
  }

  /**
   * No description
   * @tags Ponytail
   * @name GetMyDisbursedPonytailDisbursedMePost
   * @summary Get My Disbursed
   * @request POST:/ponytail/disbursed/me
   * @secure
   */
  export namespace GetMyDisbursedPonytailDisbursedMePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DashboardPayload;
    export type RequestHeaders = {};
    export type ResponseBody = MyDisbursedLoans;
  }

  /**
   * No description
   * @tags Ponytail
   * @name GetAcceptedPonytailAcceptedAllPost
   * @summary Get Accepted
   * @request POST:/ponytail/accepted/all
   * @secure
   */
  export namespace GetAcceptedPonytailAcceptedAllPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DashboardPayload;
    export type RequestHeaders = {};
    export type ResponseBody = AcceptedLoans;
  }

  /**
   * No description
   * @tags Ponytail
   * @name GetMyAcceptedPonytailAcceptedMePost
   * @summary Get My Accepted
   * @request POST:/ponytail/accepted/me
   * @secure
   */
  export namespace GetMyAcceptedPonytailAcceptedMePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DashboardPayload;
    export type RequestHeaders = {};
    export type ResponseBody = AcceptedLoans;
  }

  /**
   * No description
   * @tags Ponytail
   * @name GetAllInsurancePonytailInsuranceAllPost
   * @summary Get All Insurance
   * @request POST:/ponytail/insurance/all
   * @secure
   */
  export namespace GetAllInsurancePonytailInsuranceAllPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DashboardPayload;
    export type RequestHeaders = {};
    export type ResponseBody = AllInsurances;
  }

  /**
   * No description
   * @tags Ponytail
   * @name GetMyInsurancePonytailInsuranceMePost
   * @summary Get My Insurance
   * @request POST:/ponytail/insurance/me
   * @secure
   */
  export namespace GetMyInsurancePonytailInsuranceMePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DashboardPayload;
    export type RequestHeaders = {};
    export type ResponseBody = AllInsurances;
  }

  /**
   * No description
   * @tags Ponytail
   * @name GetAllMyStatsPonytailMyStatsPost
   * @summary Get All My Stats
   * @request POST:/ponytail/my-stats
   * @secure
   */
  export namespace GetAllMyStatsPonytailMyStatsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DashboardPayload;
    export type RequestHeaders = {};
    export type ResponseBody = RootModelUnionMyStatsMyStatsMb;
  }

  /**
   * No description
   * @tags Ponytail
   * @name SummarizedViewPonytailSummarizedPost
   * @summary Summarized View
   * @request POST:/ponytail/summarized
   * @secure
   */
  export namespace SummarizedViewPonytailSummarizedPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DashboardPayload;
    export type RequestHeaders = {};
    export type ResponseBody = RootModelUnionSummarizedViewMyStatsMb;
  }

  /**
   * No description
   * @tags Ponytail
   * @name GetWorkingDaysPonytailWorkingDaysPost
   * @summary Get Working Days
   * @request POST:/ponytail/working-days
   * @secure
   */
  export namespace GetWorkingDaysPonytailWorkingDaysPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GoalPayload;
    export type RequestHeaders = {};
    export type ResponseBody = WorkingDays;
  }

  /**
   * No description
   * @tags Ponytail
   * @name PostBlancoGoalPonytailGoalsCreateBlancoPost
   * @summary Post Blanco Goal
   * @request POST:/ponytail/goals/create/blanco
   * @secure
   */
  export namespace PostBlancoGoalPonytailGoalsCreateBlancoPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BlancoGoalPayload;
    export type RequestHeaders = {};
    export type ResponseBody = BlancoGoal;
  }

  /**
   * No description
   * @tags Ponytail
   * @name PostBlancoGoalMbPonytailGoalsCreateBlancoMbPost
   * @summary Post Blanco Goal Mb
   * @request POST:/ponytail/goals/create/blanco-mb
   * @secure
   */
  export namespace PostBlancoGoalMbPonytailGoalsCreateBlancoMbPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BlancoGoalMbPayload;
    export type RequestHeaders = {};
    export type ResponseBody = BlancoGoalMb;
  }

  /**
   * No description
   * @tags Ponytail
   * @name PostInitialBlancoGoalPonytailGoalsCreateInitialBlancoPost
   * @summary Post Initial Blanco Goal
   * @request POST:/ponytail/goals/create/initial-blanco
   * @secure
   */
  export namespace PostInitialBlancoGoalPonytailGoalsCreateInitialBlancoPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BlancoGoalPayload;
    export type RequestHeaders = {};
    export type ResponseBody = BlancoGoal;
  }

  /**
   * No description
   * @tags Ponytail
   * @name PostInitialBudgetPonytailGoalsCreateInitialBlancoMbPost
   * @summary Post Initial Budget
   * @request POST:/ponytail/goals/create/initial-blanco-mb
   * @secure
   */
  export namespace PostInitialBudgetPonytailGoalsCreateInitialBlancoMbPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BudgetPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Budget;
  }

  /**
   * No description
   * @tags Ponytail
   * @name GetMyGoalsPonytailGoalsPost
   * @summary Get My Goals
   * @request POST:/ponytail/goals
   * @secure
   */
  export namespace GetMyGoalsPonytailGoalsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GoalPayload;
    export type RequestHeaders = {};
    export type ResponseBody = BlancoGoalMb | BlancoGoal | MortgageGoal | null;
  }

  /**
   * No description
   * @tags Ponytail
   * @name GetInitialGoalPonytailGoalsInitialGoalPost
   * @summary Get Initial Goal
   * @request POST:/ponytail/goals/initial-goal
   * @secure
   */
  export namespace GetInitialGoalPonytailGoalsInitialGoalPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GoalPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Budget | BlancoGoal | MortgageGoal | null;
  }
}

export namespace Lead {
  /**
   * No description
   * @tags Lead
   * @name GetLeadLeadGetLeadIdGet
   * @summary Get Lead
   * @request GET:/lead/get/{lead_id}
   * @secure
   */
  export namespace GetLeadLeadGetLeadIdGet {
    export type RequestParams = {
      /** Lead Id */
      leadId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Lead;
  }

  /**
   * No description
   * @tags Lead
   * @name GetLeadsLeadListPost
   * @summary Get Leads
   * @request POST:/lead/list
   * @secure
   */
  export namespace GetLeadsLeadListPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetLeads;
    export type RequestHeaders = {};
    export type ResponseBody = Lead[];
  }

  /**
   * No description
   * @tags Lead
   * @name CreateLeadLeadCreatePost
   * @summary Create Lead
   * @request POST:/lead/create
   * @secure
   */
  export namespace CreateLeadLeadCreatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateLeadPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Lead;
  }

  /**
   * No description
   * @tags Lead
   * @name SearchLeadSearchPost
   * @summary Search
   * @request POST:/lead/search
   * @secure
   */
  export namespace SearchLeadSearchPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = RoutersLeadPayloadSearchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Lead[];
  }

  /**
   * No description
   * @tags Lead
   * @name ConvertAndCloseLeadConvertAndMarkHandledPost
   * @summary Convert And Close
   * @request POST:/lead/convert_and_mark_handled
   * @secure
   */
  export namespace ConvertAndCloseLeadConvertAndMarkHandledPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ConvertPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ConvertResult;
  }

  /**
   * No description
   * @tags Lead
   * @name CloseLeadMarkHandledPost
   * @summary Close
   * @request POST:/lead/mark_handled
   * @secure
   */
  export namespace CloseLeadMarkHandledPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = LeadHandledPayload;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags Lead
   * @name UnhandleLeadLeadUnhandlePost
   * @summary Unhandle Lead
   * @request POST:/lead/unhandle
   * @secure
   */
  export namespace UnhandleLeadLeadUnhandlePost {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Lead Id */
      lead_id: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Lead
   * @name UpdateLeadLeadUpdateLeadIdPost
   * @summary Update Lead
   * @request POST:/lead/update/{lead_id}
   * @secure
   */
  export namespace UpdateLeadLeadUpdateLeadIdPost {
    export type RequestParams = {
      /** Lead Id */
      leadId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = LeadUpdatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = Lead;
  }

  /**
   * No description
   * @tags Lead
   * @name ReprocessSubmissionLeadReprocessSubmissionLeadIdPost
   * @summary Reprocess Submission
   * @request POST:/lead/reprocess_submission/{lead_id}
   * @secure
   */
  export namespace ReprocessSubmissionLeadReprocessSubmissionLeadIdPost {
    export type RequestParams = {
      /** Lead Id */
      leadId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Lead
   * @name ReprocessSubmissionsLeadReprocessSubmissionsPost
   * @summary Reprocess Submissions
   * @request POST:/lead/reprocess_submissions
   * @secure
   */
  export namespace ReprocessSubmissionsLeadReprocessSubmissionsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ReprocessSubmissionPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ReprocessResult[];
  }

  /**
   * No description
   * @tags Lead
   * @name GetCommentsLeadCommentLeadIdGet
   * @summary Get Comments
   * @request GET:/lead/comment/{lead_id}
   * @secure
   */
  export namespace GetCommentsLeadCommentLeadIdGet {
    export type RequestParams = {
      /** Lead Id */
      leadId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InternalCommentWithName[];
  }

  /**
   * No description
   * @tags Lead
   * @name AddInternalCommentLeadCommentLeadIdPost
   * @summary Add Internal Comment
   * @request POST:/lead/comment/{lead_id}
   * @secure
   */
  export namespace AddInternalCommentLeadCommentLeadIdPost {
    export type RequestParams = {
      /** Lead Id */
      leadId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CommentPayload;
    export type RequestHeaders = {};
    export type ResponseBody = InternalCommentWithName[];
  }

  /**
   * No description
   * @tags Lead
   * @name AssignLeadToHandlerLeadAssignLeadIdPost
   * @summary Assign Lead To Handler
   * @request POST:/lead/assign/{lead_id}
   * @secure
   */
  export namespace AssignLeadToHandlerLeadAssignLeadIdPost {
    export type RequestParams = {
      /** Lead Id */
      leadId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = AssignApplicationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags Lead
   * @name GetAssignedHandlerLeadAssigneeLeadIdGet
   * @summary Get Assigned Handler
   * @request GET:/lead/assignee/{lead_id}
   * @secure
   */
  export namespace GetAssignedHandlerLeadAssigneeLeadIdGet {
    export type RequestParams = {
      /** Lead Id */
      leadId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Assignee;
  }

  /**
   * No description
   * @tags Lead
   * @name GetLeadsLeadListMinePost
   * @summary Get Leads
   * @request POST:/lead/list/mine
   * @secure
   */
  export namespace GetLeadsLeadListMinePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetMyLeads;
    export type RequestHeaders = {};
    export type ResponseBody = Lead[];
  }
}

export namespace Formprefs {
  /**
   * No description
   * @tags Jotform
   * @name UpdatePreferenceFormprefsPut
   * @summary Update Preference
   * @request PUT:/formprefs/
   * @secure
   */
  export namespace UpdatePreferenceFormprefsPut {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UpdateFormPreferencePayload;
    export type RequestHeaders = {};
    export type ResponseBody = FormPreferenceExt;
  }

  /**
   * No description
   * @tags Jotform
   * @name CreatePreferenceFormprefsPost
   * @summary Create Preference
   * @request POST:/formprefs/
   * @secure
   */
  export namespace CreatePreferenceFormprefsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = FormPreferencePayload;
    export type RequestHeaders = {};
    export type ResponseBody = FormPreferenceExt;
  }

  /**
   * No description
   * @tags Jotform
   * @name DuplicatePreferenceFormprefsDuplicatePost
   * @summary Duplicate Preference
   * @request POST:/formprefs/duplicate
   * @secure
   */
  export namespace DuplicatePreferenceFormprefsDuplicatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DuplicateFormPreferencePayload;
    export type RequestHeaders = {};
    export type ResponseBody = FormPreferenceExt;
  }

  /**
   * No description
   * @tags Jotform
   * @name ValidatePreferenceFormprefsValidatePost
   * @summary Validate Preference
   * @request POST:/formprefs/validate
   * @secure
   */
  export namespace ValidatePreferenceFormprefsValidatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ValidatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = FormPreferenceExt;
  }

  /**
   * No description
   * @tags Jotform
   * @name GetMappingFormprefsGetMappingPost
   * @summary Get Mapping
   * @request POST:/formprefs/get_mapping
   * @secure
   */
  export namespace GetMappingFormprefsGetMappingPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BaseFormPreferencePayload;
    export type RequestHeaders = {};
    export type ResponseBody = FormMapping;
  }

  /**
   * No description
   * @tags Jotform
   * @name ListPreferencesFormprefsListGet
   * @summary List Preferences
   * @request GET:/formprefs/list
   * @secure
   */
  export namespace ListPreferencesFormprefsListGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FormPreference[];
  }

  /**
   * No description
   * @tags Jotform
   * @name ListPreferencesExtFormprefsListExtGet
   * @summary List Preferences Ext
   * @request GET:/formprefs/list_ext
   * @secure
   */
  export namespace ListPreferencesExtFormprefsListExtGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FormPreferenceExt[];
  }

  /**
   * No description
   * @tags Jotform
   * @name ListFormsFormprefsFormsGet
   * @summary List Forms
   * @request GET:/formprefs/forms
   * @secure
   */
  export namespace ListFormsFormprefsFormsGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Form[];
  }

  /**
   * No description
   * @tags Jotform
   * @name CheckAllSubmissionsFormprefsCheckAllGet
   * @summary Check All Submissions
   * @request GET:/formprefs/check-all
   * @secure
   */
  export namespace CheckAllSubmissionsFormprefsCheckAllGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Jotform
   * @name GetAllSubmissionsFormprefsSubmissionsGet
   * @summary Get All Submissions
   * @request GET:/formprefs/submissions
   * @secure
   */
  export namespace GetAllSubmissionsFormprefsSubmissionsGet {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Form Id */
      form_id?: string | null;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Jotform
   * @name GetFormPreferenceFormprefsFormPreferenceIdGet
   * @summary Get Form Preference
   * @request GET:/formprefs/{form_preference_id}
   * @secure
   */
  export namespace GetFormPreferenceFormprefsFormPreferenceIdGet {
    export type RequestParams = {
      /** Form Preference Id */
      formPreferenceId: string;
    };
    export type RequestQuery = {
      /**
       * Add Defaults
       * @default false
       */
      add_defaults?: boolean;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FormPreferenceExt;
  }

  /**
   * No description
   * @tags Jotform
   * @name CreateWebhookFormprefsWebhookPost
   * @summary Create Webhook
   * @request POST:/formprefs/webhook
   * @secure
   */
  export namespace CreateWebhookFormprefsWebhookPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = FormWebhookPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Jotform
   * @name DeleteWebhookFormprefsWebhookDelete
   * @summary Delete Webhook
   * @request DELETE:/formprefs/webhook
   * @secure
   */
  export namespace DeleteWebhookFormprefsWebhookDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = FormWebhookPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace Heimdall {
  /**
   * No description
   * @tags Heimdall
   * @name GetAllHeimdallRecordsForDateHeimdallDateGet
   * @summary Get All Heimdall Records For Date
   * @request GET:/heimdall/{date}
   * @secure
   */
  export namespace GetAllHeimdallRecordsForDateHeimdallDateGet {
    export type RequestParams = {
      /**
       * Date
       * @format date
       */
      date: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = HeimdallResponse[];
  }

  /**
   * No description
   * @tags Heimdall
   * @name ReportAndVerifyUsageToHeimdallHeimdallUserIdPatch
   * @summary Report And Verify Usage To Heimdall
   * @request PATCH:/heimdall/{user_id}
   * @secure
   */
  export namespace ReportAndVerifyUsageToHeimdallHeimdallUserIdPatch {
    export type RequestParams = {
      /** User Id */
      userId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = HeimdallReportingPayload;
    export type RequestHeaders = {};
    export type ResponseBody = HeimdallReportingResponse;
  }

  /**
   * No description
   * @tags Heimdall
   * @name ResetHeimdallRateLimitHeimdallUserIdToResetResetPatch
   * @summary Reset Heimdall Rate Limit
   * @request PATCH:/heimdall/{user_id_to_reset}/reset
   * @secure
   */
  export namespace ResetHeimdallRateLimitHeimdallUserIdToResetResetPatch {
    export type RequestParams = {
      /** User Id To Reset */
      userIdToReset: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace LarsMagnus {
  /**
   * No description
   * @tags Lars Magnus
   * @name CheckTelavoxIdsLarsMagnusTelavoxCheckGet
   * @summary Check Telavox Ids
   * @request GET:/lars-magnus/telavox-check
   * @secure
   */
  export namespace CheckTelavoxIdsLarsMagnusTelavoxCheckGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TelavoxMismatch;
  }

  /**
   * No description
   * @tags Lars Magnus
   * @name CurrentTelavoxUserLarsMagnusTelavoxUserGet
   * @summary Current Telavox User
   * @request GET:/lars-magnus/telavox-user
   * @secure
   */
  export namespace CurrentTelavoxUserLarsMagnusTelavoxUserGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TelavoxExtension;
  }
}

export namespace MyPages {
  /**
   * No description
   * @tags My Pages
   * @name ImpersonateCustomerMyPagesImpersonatePost
   * @summary Impersonate Customer
   * @request POST:/my-pages/impersonate
   * @secure
   */
  export namespace ImpersonateCustomerMyPagesImpersonatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ImpersonateCustomerPayload;
    export type RequestHeaders = {};
    export type ResponseBody = BankIDLoginResponse;
  }

  /**
   * No description
   * @tags My Pages
   * @name ValidateClearingMyPagesOffersClearingValidateClearingGet
   * @summary Validate Clearing
   * @request GET:/my-pages/offers/clearing/validate/{clearing}
   */
  export namespace ValidateClearingMyPagesOffersClearingValidateClearingGet {
    export type RequestParams = {
      /** Clearing */
      clearing: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmptyModel;
  }

  /**
   * @description Gather request md and proceed with accepting terms for insurance used for customers that are not authenticated and cannot change the amount
   * @tags My Pages
   * @name ConfirmInsuranceTermsMyPagesInsurancesConfirmPost
   * @summary Confirm Insurance Terms
   * @request POST:/my-pages/insurances/confirm
   */
  export namespace ConfirmInsuranceTermsMyPagesInsurancesConfirmPost {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Encrypted Insurance Id */
      encrypted_insurance_id: string;
      /** Email */
      email: string;
      /** Phone Number */
      phone_number: string;
      /** Success Url */
      success_url: string;
      /** Fail Url */
      fail_url: string;
      /** Insured Amount */
      insured_amount?: number | null;
      /** Premium */
      premium?: number | null;
    };
    export type RequestBody = never;
    export type RequestHeaders = {
      /** User-Agent */
      "user-agent"?: string | null;
      /** Origin */
      origin?: string | null;
    };
    export type ResponseBody = AcceptTermsResponse;
  }

  /**
   * No description
   * @tags My Pages
   * @name GetInsuranceMyPagesInsuranceGet
   * @summary Get Insurance
   * @request GET:/my-pages/insurance
   */
  export namespace GetInsuranceMyPagesInsuranceGet {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Id */
      id: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UnauthenticatedInsurance;
  }

  /**
   * No description
   * @tags My Pages
   * @name GetLatestActiveApplicationsV2MyPagesV2ApplicationsLatestGet
   * @summary Get Latest Active Applications V2
   * @request GET:/my-pages/v2/applications/latest
   * @secure
   */
  export namespace GetLatestActiveApplicationsV2MyPagesV2ApplicationsLatestGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CustomerApplicationV2[];
  }

  /**
   * No description
   * @tags My Pages
   * @name GetLatestActiveApplicationMyPagesApplicationLatestGet
   * @summary Get Latest Active Application
   * @request GET:/my-pages/application/latest
   * @secure
   */
  export namespace GetLatestActiveApplicationMyPagesApplicationLatestGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CustomerApplication | null;
  }

  /**
   * No description
   * @tags My Pages
   * @name GetCreditCardOffersMyPagesCreditCardOffersGet
   * @summary Get Credit Card Offers
   * @request GET:/my-pages/credit_card/offers
   * @secure
   */
  export namespace GetCreditCardOffersMyPagesCreditCardOffersGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CreditorOfferCreditCardOfferCreditCardTerms[];
  }

  /**
   * No description
   * @tags My Pages
   * @name GetApplicantMyPagesApplicantMeGet
   * @summary Get Applicant
   * @request GET:/my-pages/applicant/me
   * @secure
   */
  export namespace GetApplicantMyPagesApplicantMeGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody =
      | ({
          country: "SE";
        } & ApplicantSE)
      | ({
          country: "NO";
        } & ApplicantNO);
  }

  /**
   * @description Legacy blanco endpoint (Sverker)
   * @tags My Pages
   * @name AcceptOfferLegacyMyPagesOfferAcceptPost
   * @summary Accept Offer Legacy
   * @request POST:/my-pages/offer/accept
   * @secure
   */
  export namespace AcceptOfferLegacyMyPagesOfferAcceptPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AcceptOfferPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * @description Legacy blanco endpoint (Sverker)
   * @tags My Pages
   * @name AcceptOfferLegacyV2MyPagesV2OfferAcceptPost
   * @summary Accept Offer Legacy V2
   * @request POST:/my-pages/v2/offer/accept
   * @secure
   */
  export namespace AcceptOfferLegacyV2MyPagesV2OfferAcceptPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AcceptOfferPayloadV2;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags My Pages
   * @name AcceptOfferMyPagesOfferAccept2Post
   * @summary Accept Offer
   * @request POST:/my-pages/offer/accept2
   * @secure
   */
  export namespace AcceptOfferMyPagesOfferAccept2Post {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AcceptOffer;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags My Pages
   * @name BanksToExcludeFromInsuranceOfferingForMyPagesInsurancesBanksExcludedGet
   * @summary Banks To Exclude From Insurance Offering For
   * @request GET:/my-pages/insurances/banks/excluded
   * @deprecated
   * @secure
   */
  export namespace BanksToExcludeFromInsuranceOfferingForMyPagesInsurancesBanksExcludedGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BanksToExcludeInsuranceOfferingFor;
  }

  /**
   * No description
   * @tags My Pages
   * @name InsurancesRulesMyPagesInsurancesRulesGet
   * @summary Insurances Rules
   * @request GET:/my-pages/insurances/rules
   * @secure
   */
  export namespace InsurancesRulesMyPagesInsurancesRulesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InsuranceTerms[];
  }

  /**
   * No description
   * @tags My Pages
   * @name LoanInsurancesRulesMyPagesInsurancesLoanInsuranceRulesGet
   * @summary Loan Insurances Rules
   * @request GET:/my-pages/insurances/loan-insurance-rules
   * @secure
   */
  export namespace LoanInsurancesRulesMyPagesInsurancesLoanInsuranceRulesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InsuranceTerms;
  }

  /**
   * No description
   * @tags My Pages
   * @name IdCheckMyPagesIdCheckGet
   * @summary Id Check
   * @request GET:/my-pages/id-check
   * @secure
   */
  export namespace IdCheckMyPagesIdCheckGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody =
      | (
          | ({
              country: "SE";
            } & ApplicantSE)
          | ({
              country: "NO";
            } & ApplicantNO)
        )
      | (
          | ({
              country: "SE";
            } & OptionalApplicantSE)
          | ({
              country: "NO";
            } & OptionalApplicantNO)
        )
      | IDCheckedPerson;
  }

  /**
   * No description
   * @tags My Pages
   * @name ActiveInsuranceByPniMyPagesInsurancesGet
   * @summary Active Insurance By Pni
   * @request GET:/my-pages/insurances
   * @secure
   */
  export namespace ActiveInsuranceByPniMyPagesInsurancesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InsuranceCustomer[];
  }

  /**
   * No description
   * @tags My Pages
   * @name CreateInsuranceV2MyPagesInsurancesV2Post
   * @summary Create Insurance V2
   * @request POST:/my-pages/insurances/v2
   * @secure
   */
  export namespace CreateInsuranceV2MyPagesInsurancesV2Post {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateInsuranceCustomer;
    export type RequestHeaders = {
      /** User-Agent */
      "user-agent"?: string | null;
      /** Origin */
      origin?: string | null;
    };
    export type ResponseBody = AcceptTermsResponseCustomer;
  }

  /**
   * No description
   * @tags My Pages
   * @name DebtRegisterConsentMyPagesDebtRegisterConsentPost
   * @summary Debt Register Consent
   * @request POST:/my-pages/debt-register/consent
   * @secure
   */
  export namespace DebtRegisterConsentMyPagesDebtRegisterConsentPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {
      /** User-Agent */
      "user-agent"?: string | null;
      /** Origin */
      origin?: string | null;
    };
    export type ResponseBody =
      | (
          | ({
              country: "SE";
            } & ApplicantSE)
          | ({
              country: "NO";
            } & ApplicantNO)
        )
      | (
          | ({
              country: "SE";
            } & OptionalApplicantSE)
          | ({
              country: "NO";
            } & OptionalApplicantNO)
        );
  }

  /**
   * No description
   * @tags My Pages
   * @name DebtRegisterCheckConsentMyPagesDebtRegisterCheckConsentPost
   * @summary Debt Register Check Consent
   * @request POST:/my-pages/debt-register/check-consent
   * @secure
   */
  export namespace DebtRegisterCheckConsentMyPagesDebtRegisterCheckConsentPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody =
      | (
          | ({
              country: "SE";
            } & ApplicantSE)
          | ({
              country: "NO";
            } & ApplicantNO)
        )
      | (
          | ({
              country: "SE";
            } & OptionalApplicantSE)
          | ({
              country: "NO";
            } & OptionalApplicantNO)
        );
  }

  /**
   * No description
   * @tags My Pages
   * @name DebtRegisterRenewMyPagesDebtRegisterRenewPost
   * @summary Debt Register Renew
   * @request POST:/my-pages/debt-register/renew
   * @secure
   */
  export namespace DebtRegisterRenewMyPagesDebtRegisterRenewPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody =
      | (
          | ({
              country: "SE";
            } & ApplicantSE)
          | ({
              country: "NO";
            } & ApplicantNO)
        )
      | (
          | ({
              country: "SE";
            } & OptionalApplicantSE)
          | ({
              country: "NO";
            } & OptionalApplicantNO)
        );
  }

  /**
   * No description
   * @tags My Pages
   * @name UpsertMarketingConsentMyPagesMarketingConsentsPost
   * @summary Upsert Marketing Consent
   * @request POST:/my-pages/marketing-consents
   * @secure
   */
  export namespace UpsertMarketingConsentMyPagesMarketingConsentsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ConsentsPayload;
    export type RequestHeaders = {
      /** User-Agent */
      "user-agent"?: string | null;
      /** Origin */
      origin?: string | null;
    };
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags My Pages
   * @name GetTrustlyUrlForNewMandateMyPagesInsurancesTrustlyUrlGet
   * @summary Get Trustly Url For New Mandate
   * @request GET:/my-pages/insurances/trustly-url
   * @secure
   */
  export namespace GetTrustlyUrlForNewMandateMyPagesInsurancesTrustlyUrlGet {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Id */
      id: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace Zms {
  /**
   * No description
   * @tags ZMS
   * @name SaveZmsConfigZmsCountryPost
   * @summary Save Zms Config
   * @request POST:/zms/{country}
   * @secure
   */
  export namespace SaveZmsConfigZmsCountryPost {
    export type RequestParams = {
      country: Country;
    };
    export type RequestQuery = {};
    export type RequestBody = Config;
    export type RequestHeaders = {};
    export type ResponseBody = ZMSConfig;
  }

  /**
   * No description
   * @tags ZMS
   * @name GetZmsConfigZmsCountryGet
   * @summary Get Zms Config
   * @request GET:/zms/{country}
   */
  export namespace GetZmsConfigZmsCountryGet {
    export type RequestParams = {
      country: Country;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ZMSConfig;
  }

  /**
   * No description
   * @tags ZMS
   * @name GetAllConfigsZmsGet
   * @summary Get All Configs
   * @request GET:/zms
   */
  export namespace GetAllConfigsZmsGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ZMSConfig[];
  }
}

export namespace Affiliates {
  /**
   * No description
   * @tags Affiliate
   * @name CreateAffiliateAffiliatesPost
   * @summary Create Affiliate
   * @request POST:/affiliates/
   * @secure
   */
  export namespace CreateAffiliateAffiliatesPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AffiliateData;
    export type RequestHeaders = {};
    export type ResponseBody = Affiliate;
  }

  /**
   * No description
   * @tags Affiliate
   * @name GetAffiliatesAffiliatesGet
   * @summary Get Affiliates
   * @request GET:/affiliates/
   * @secure
   */
  export namespace GetAffiliatesAffiliatesGet {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Page
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * Limit
       * @min 0
       * @max 50
       * @default 50
       */
      limit?: number;
      /** Status */
      status?: string | null;
      /**
       * Network
       * @default false
       */
      network?: boolean | null;
      /**
       * Publisher
       * @default false
       */
      publisher?: boolean | null;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Affiliate[];
  }

  /**
   * No description
   * @tags Affiliate
   * @name SearchAffiliatesAffiliatesSearchGet
   * @summary Search Affiliates
   * @request GET:/affiliates/search
   * @secure
   */
  export namespace SearchAffiliatesAffiliatesSearchGet {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Keyword */
      keyword: string;
      /**
       * Page
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * Limit
       * @min 0
       * @max 50
       * @default 50
       */
      limit?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Affiliate[];
  }

  /**
   * No description
   * @tags Affiliate
   * @name GetAffiliateAffiliatesAffiliateIdGet
   * @summary Get Affiliate
   * @request GET:/affiliates/{affiliate_id}
   * @secure
   */
  export namespace GetAffiliateAffiliatesAffiliateIdGet {
    export type RequestParams = {
      /** Affiliate Id */
      affiliateId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Affiliate;
  }

  /**
   * No description
   * @tags Affiliate
   * @name UpdateAffiliateAffiliatesAffiliateIdPut
   * @summary Update Affiliate
   * @request PUT:/affiliates/{affiliate_id}
   * @secure
   */
  export namespace UpdateAffiliateAffiliatesAffiliateIdPut {
    export type RequestParams = {
      /** Affiliate Id */
      affiliateId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = AffiliateData;
    export type RequestHeaders = {};
    export type ResponseBody = Affiliate;
  }

  /**
   * No description
   * @tags Affiliate
   * @name DeactivateAffiliateAffiliatesAffiliateIdDelete
   * @summary Deactivate Affiliate
   * @request DELETE:/affiliates/{affiliate_id}
   * @secure
   */
  export namespace DeactivateAffiliateAffiliatesAffiliateIdDelete {
    export type RequestParams = {
      /** Affiliate Id */
      affiliateId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }

  /**
   * No description
   * @tags Affiliate
   * @name CreateCampaignAffiliatesAffiliateIdCampaignsPost
   * @summary Create Campaign
   * @request POST:/affiliates/{affiliate_id}/campaigns
   * @secure
   */
  export namespace CreateCampaignAffiliatesAffiliateIdCampaignsPost {
    export type RequestParams = {
      /** Affiliate Id */
      affiliateId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CampaignData;
    export type RequestHeaders = {};
    export type ResponseBody = Affiliate;
  }

  /**
   * No description
   * @tags Affiliate
   * @name GetCampaignsAffiliatesAffiliateIdCampaignsGet
   * @summary Get Campaigns
   * @request GET:/affiliates/{affiliate_id}/campaigns
   * @secure
   */
  export namespace GetCampaignsAffiliatesAffiliateIdCampaignsGet {
    export type RequestParams = {
      /** Affiliate Id */
      affiliateId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsAffiliateAffiliateCampaign[];
  }

  /**
   * No description
   * @tags Affiliate
   * @name UpdateCampaignAffiliatesAffiliateIdCampaignsCampaignIdPut
   * @summary Update Campaign
   * @request PUT:/affiliates/{affiliate_id}/campaigns/{campaign_id}
   * @secure
   */
  export namespace UpdateCampaignAffiliatesAffiliateIdCampaignsCampaignIdPut {
    export type RequestParams = {
      /** Campaign Id */
      campaignId: string;
      /** Affiliate Id */
      affiliateId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CampaignData;
    export type RequestHeaders = {};
    export type ResponseBody = Affiliate;
  }

  /**
   * No description
   * @tags Affiliate
   * @name RemoveCampaignAffiliatesAffiliateIdCampaignsCampaignIdDelete
   * @summary Remove Campaign
   * @request DELETE:/affiliates/{affiliate_id}/campaigns/{campaign_id}
   * @secure
   */
  export namespace RemoveCampaignAffiliatesAffiliateIdCampaignsCampaignIdDelete {
    export type RequestParams = {
      /** Campaign Id */
      campaignId: string;
      /** Affiliate Id */
      affiliateId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Affiliate
   * @name GetPersonEventAffiliatesPersonsZidGet
   * @summary Get Person Event
   * @request GET:/affiliates/persons/{zid}
   * @secure
   */
  export namespace GetPersonEventAffiliatesPersonsZidGet {
    export type RequestParams = {
      /** Zid */
      zid: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Person;
  }

  /**
   * No description
   * @tags Affiliate
   * @name GetAffiliatePersonEventsAffiliatesAffiliateIdPersonsGet
   * @summary Get Affiliate Person Events
   * @request GET:/affiliates/{affiliate_id}/persons
   * @secure
   */
  export namespace GetAffiliatePersonEventsAffiliatesAffiliateIdPersonsGet {
    export type RequestParams = {
      /** Affiliate Id */
      affiliateId: string;
    };
    export type RequestQuery = {
      /**
       * Page
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * Limit
       * @min 0
       * @max 50
       * @default 50
       */
      limit?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Person[];
  }

  /**
   * No description
   * @tags Affiliate
   * @name GetAffiliateCallbacksAffiliatesAffiliateNetworkIdCallbacksPost
   * @summary Get Affiliate Callbacks
   * @request POST:/affiliates/{affiliate_network_id}/callbacks
   * @secure
   */
  export namespace GetAffiliateCallbacksAffiliatesAffiliateNetworkIdCallbacksPost {
    export type RequestParams = {
      /** Affiliate Network Id */
      affiliateNetworkId: string;
    };
    export type RequestQuery = {
      /**
       * Page
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * Limit
       * @min 0
       * @max 100
       * @default 50
       */
      limit?: number;
      /** Publisher */
      publisher?: string | null;
      /** Start Date */
      start_date?: string | null;
      /** End Date */
      end_date?: string | null;
    };
    export type RequestBody = AffiliateNetworkCallbackStatus[] | null;
    export type RequestHeaders = {};
    export type ResponseBody = AffiliateNetworkCallbackEvent[];
  }

  /**
   * No description
   * @tags Affiliate
   * @name GetAffiliatePublishersAffiliatesAffiliateIdPublishersGet
   * @summary Get Affiliate Publishers
   * @request GET:/affiliates/{affiliate_id}/publishers
   * @secure
   */
  export namespace GetAffiliatePublishersAffiliatesAffiliateIdPublishersGet {
    export type RequestParams = {
      /** Affiliate Id */
      affiliateId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Affiliate[];
  }

  /**
   * No description
   * @tags Affiliate
   * @name AdtractionNetworkCallbackAffiliatesDevAdtractionCallbackSiteTtGet
   * @summary Adtraction Network Callback
   * @request GET:/affiliates/dev/adtraction/callback/{site}/t/t
   * @secure
   */
  export namespace AdtractionNetworkCallbackAffiliatesDevAdtractionCallbackSiteTtGet {
    export type RequestParams = {
      /** Site */
      site: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = HTTPStatus;
  }

  /**
   * No description
   * @tags Affiliate
   * @name AdserviceNetworkCallbackAffiliatesDevAdserviceCallbackCgiBinApiConversionServiceCallbackGet
   * @summary Adservice Network Callback
   * @request GET:/affiliates/dev/adservice/callback/cgi-bin/API/ConversionService/Callback
   * @secure
   */
  export namespace AdserviceNetworkCallbackAffiliatesDevAdserviceCallbackCgiBinApiConversionServiceCallbackGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = HTTPStatus;
  }

  /**
   * No description
   * @tags Affiliate
   * @name SmartresponseNetworkCallbackAffiliatesDevSmartresponseCallbackPAshxGet
   * @summary Smartresponse Network Callback
   * @request GET:/affiliates/dev/smartresponse/callback/p.ashx
   * @secure
   */
  export namespace SmartresponseNetworkCallbackAffiliatesDevSmartresponseCallbackPAshxGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = HTTPStatus;
  }
}

export namespace Online {
  /**
   * @description Get all submissions for a country
   * @tags Online
   * @name GetAllSubmissionsOnlineSubmissionsGet
   * @summary Get All Submissions
   * @request GET:/online/submissions
   * @secure
   */
  export namespace GetAllSubmissionsOnlineSubmissionsGet {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Failed */
      failed?: boolean | null;
      /** Handled */
      handled?: boolean | null;
      /** Form Validation */
      form_validation?: boolean | null;
      /** Quick Application */
      quick_application?: boolean | null;
      /** Get Customers */
      get_customers?: boolean | null;
      /** Has Recent App Check */
      has_recent_app_check?: boolean | null;
      /** Created Application */
      created_application?: boolean | null;
      /** App Created Hours Ago */
      app_created_hours_ago?: number | null;
      /** Limit */
      limit?: number | null;
      /** Page */
      page?: number | null;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SubmissionReviewResponse;
  }

  /**
   * @description Get submission by id
   * @tags Online
   * @name GetASubmissionOnlineSubmissionsSubmissionIdGet
   * @summary Get A Submission
   * @request GET:/online/submissions/{submission_id}
   * @secure
   */
  export namespace GetASubmissionOnlineSubmissionsSubmissionIdGet {
    export type RequestParams = {
      /** Submission Id */
      submissionId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SubmissionReview;
  }

  /**
   * @description Retry processing of submissions in case of temporary error
   * @tags Online
   * @name PostReprocessSubmissionsOnlineSubmissionsReprocessPost
   * @summary Post Reprocess Submissions
   * @request POST:/online/submissions/reprocess
   * @secure
   */
  export namespace PostReprocessSubmissionsOnlineSubmissionsReprocessPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = string[];
    export type RequestHeaders = {};
    export type ResponseBody = EnqueuedSubmissionJobResult[];
  }

  /**
   * @description Retry processing of submission in case of temporary error
   * @tags Online
   * @name PostReprocessSubmissionOnlineSubmissionsSubmissionIdReprocessPost
   * @summary Post Reprocess Submission
   * @request POST:/online/submissions/{submission_id}/reprocess
   * @secure
   */
  export namespace PostReprocessSubmissionOnlineSubmissionsSubmissionIdReprocessPost {
    export type RequestParams = {
      /** Submission Id */
      submissionId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EnqueuedSubmissionJobResult;
  }

  /**
   * No description
   * @tags Online
   * @name PostResubmitSubmissionOnlineSubmissionsSubmissionIdResubmitPost
   * @summary Post Resubmit Submission
   * @request POST:/online/submissions/{submission_id}/resubmit
   * @secure
   */
  export namespace PostResubmitSubmissionOnlineSubmissionsSubmissionIdResubmitPost {
    export type RequestParams = {
      submissionId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = ResubmitPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EnqueuedSubmissionJobResult;
  }

  /**
   * No description
   * @tags Online
   * @name PostReviewSubmissionOnlineSubmissionsSubmissionIdReviewPost
   * @summary Post Review Submission
   * @request POST:/online/submissions/{submission_id}/review
   * @secure
   */
  export namespace PostReviewSubmissionOnlineSubmissionsSubmissionIdReviewPost {
    export type RequestParams = {
      /** Submission Id */
      submissionId: string;
    };
    export type RequestQuery = {
      /**
       * Handled
       * @default true
       */
      handled?: boolean;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = HandledSubmission;
  }

  /**
   * No description
   * @tags Online
   * @name PostReviewSubmissionsOnlineSubmissionsReviewPost
   * @summary Post Review Submissions
   * @request POST:/online/submissions/review
   * @secure
   */
  export namespace PostReviewSubmissionsOnlineSubmissionsReviewPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ReviewSubmissions;
    export type RequestHeaders = {};
    export type ResponseBody = HandledSubmission[];
  }

  /**
   * @description Converts a credit card submission to a blanco submission
   * @tags Online
   * @name PostConvertToBlancoOnlineSubmissionsSubmissionIdConvertToBlancoPost
   * @summary Post Convert To Blanco
   * @request POST:/online/submissions/{submission_id}/convert_to_blanco
   * @secure
   */
  export namespace PostConvertToBlancoOnlineSubmissionsSubmissionIdConvertToBlancoPost {
    export type RequestParams = {
      /** Submission Id */
      submissionId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EnqueuedSubmissionJobResult;
  }

  /**
   * No description
   * @tags Online
   * @name OnlineAppsMessagesOnlineSendBlancoSmsMessagesPost
   * @summary Online Apps Messages
   * @request POST:/online/send_blanco_sms_messages
   * @secure
   */
  export namespace OnlineAppsMessagesOnlineSendBlancoSmsMessagesPost {
    export type RequestParams = {};
    export type RequestQuery = {
      country: Country;
      product: Product;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Online
   * @name WhitelistedPniOnlineTestingPnisGet
   * @summary Whitelisted Pni
   * @request GET:/online/testing-pnis
   * @secure
   */
  export namespace WhitelistedPniOnlineTestingPnisGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OnlineFormsTestingPNIResponse;
  }

  /**
   * No description
   * @tags Online
   * @name GetBookingsOnlineBookingsCountryGet
   * @summary Get Bookings
   * @request GET:/online/bookings/{country}
   */
  export namespace GetBookingsOnlineBookingsCountryGet {
    export type RequestParams = {
      country: Country;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmptyModel;
  }

  /**
   * No description
   * @tags Online
   * @name BookMeetingOnlineBookingsCountryPost
   * @summary Book Meeting
   * @request POST:/online/bookings/{country}
   */
  export namespace BookMeetingOnlineBookingsCountryPost {
    export type RequestParams = {
      country: Country;
    };
    export type RequestQuery = {};
    export type RequestBody = BookingPayload;
    export type RequestHeaders = {};
    export type ResponseBody = ServicesSverkerResponsesMeeting;
  }

  /**
   * No description
   * @tags Online
   * @name ContactFormOnlineContactCountryPost
   * @summary Contact Form
   * @request POST:/online/contact/{country}
   */
  export namespace ContactFormOnlineContactCountryPost {
    export type RequestParams = {
      country: Country;
    };
    export type RequestQuery = {};
    export type RequestBody = ContactFormPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Online
   * @name MortgageFormOnlineMortgageCountryPost
   * @summary Mortgage Form
   * @request POST:/online/mortgage/{country}
   */
  export namespace MortgageFormOnlineMortgageCountryPost {
    export type RequestParams = {
      country: Country;
    };
    export type RequestQuery = {};
    export type RequestBody = MortgageFormPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Online
   * @name BlancoApplicationOnlineBlancoApplicationCountryPost
   * @summary Blanco Application
   * @request POST:/online/blanco-application/{country}
   */
  export namespace BlancoApplicationOnlineBlancoApplicationCountryPost {
    export type RequestParams = {
      country: Country;
    };
    export type RequestQuery = {};
    export type RequestBody = EmptyModel;
    export type RequestHeaders = {
      /** X-Forwarded-For */
      "x-forwarded-for"?: string | null;
      /** User-Agent */
      "user-agent"?: string | null;
      /** Origin */
      origin?: string | null;
      /** X-Zid */
      "x-zid"?: string | null;
      /** X-S-Zid */
      "x-s-zid"?: string | null;
      /** X-Gid */
      "x-gid"?: string | null;
      /** X-Google-Ids */
      "x-google-ids"?: string | null;
    };
    export type ResponseBody = FormApplicationSubmissionResult;
  }

  /**
   * No description
   * @tags Online
   * @name OnlineQuickCreateOnlineQuickCreatePost
   * @summary Online Quick Create
   * @request POST:/online/quick-create
   */
  export namespace OnlineQuickCreateOnlineQuickCreatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody =
      | (
          | ({
              product: "blanco";
            } & QuickCreateBlancoApplication)
          | ({
              product: "mortgage";
            } & QuickCreateMortgageApplication)
        )
      | object;
    export type RequestHeaders = {
      /** X-Zid */
      "x-zid"?: string | null;
      /** X-S-Zid */
      "x-s-zid"?: string | null;
      /** X-Gid */
      "x-gid"?: string | null;
      /** X-Google-Ids */
      "x-google-ids"?: string | null;
    };
    export type ResponseBody = FormApplicationSubmissionResult;
  }

  /**
   * @description Norway only
   * @tags Online
   * @name NorwegianMortgageApplicationOnlineNorwegianMortgageApplicationPost
   * @summary Norwegian Mortgage Application
   * @request POST:/online/norwegian-mortgage-application
   */
  export namespace NorwegianMortgageApplicationOnlineNorwegianMortgageApplicationPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = EmptyModel;
    export type RequestHeaders = {
      /** X-Forwarded-For */
      "x-forwarded-for"?: string | null;
      /** User-Agent */
      "user-agent"?: string | null;
      /** Origin */
      origin?: string | null;
      /** X-Zid */
      "x-zid"?: string | null;
      /** X-Gid */
      "x-gid"?: string | null;
      /** X-S-Zid */
      "x-s-zid"?: string | null;
      /** X-Google-Ids */
      "x-google-ids"?: string | null;
    };
    export type ResponseBody = FormApplicationSubmissionResult;
  }
}

export namespace Rq {
  /**
   * No description
   * @tags Monitor
   * @name GetListOfJobsRqJobsGet
   * @summary Get List Of Jobs
   * @request GET:/rq/jobs
   * @secure
   */
  export namespace GetListOfJobsRqJobsGet {
    export type RequestParams = {};
    export type RequestQuery = {
      job_type: JobType;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = RqJobStatusResponse[];
  }

  /**
   * No description
   * @tags Monitor
   * @name GetFailedJobsRqManualFailGet
   * @summary Get Failed Jobs
   * @request GET:/rq/manual-fail
   * @secure
   */
  export namespace GetFailedJobsRqManualFailGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Monitor
   * @name ClearFailedJobRqDeleteJobJobIdGet
   * @summary Clear Failed Job
   * @request GET:/rq/delete-job/{job_id}
   * @secure
   */
  export namespace ClearFailedJobRqDeleteJobJobIdGet {
    export type RequestParams = {
      /** Job Id */
      jobId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Monitor
   * @name ReScheduleRqReScheduleGet
   * @summary Re Schedule
   * @request GET:/rq/re-schedule
   * @secure
   */
  export namespace ReScheduleRqReScheduleGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string[];
  }

  /**
   * No description
   * @tags Monitor
   * @name GetSingleJobRqJobIdGet
   * @summary Get Single Job
   * @request GET:/rq/{job_id}
   * @secure
   */
  export namespace GetSingleJobRqJobIdGet {
    export type RequestParams = {
      /** Job Id */
      jobId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = RqJobStatusResponse | null;
  }
}

export namespace Kdlt {
  /**
   * No description
   * @tags kdlt
   * @name CallbackKdltCallbackPost
   * @summary Callback
   * @request POST:/kdlt/callback
   * @secure
   */
  export namespace CallbackKdltCallbackPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = KDLTCallbackPayload;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace DialMaker {
  /**
   * No description
   * @tags Dial Maker
   * @name GetLeadsForCampaignDialMakerTmLeadsCampaignIdPost
   * @summary Get Leads For Campaign
   * @request POST:/dial-maker/tm-leads/{campaign_id}
   * @secure
   */
  export namespace GetLeadsForCampaignDialMakerTmLeadsCampaignIdPost {
    export type RequestParams = {
      /** Campaign Id */
      campaignId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = RoutersDialMakerPayloadsLeadExtraction;
    export type RequestHeaders = {};
    export type ResponseBody = TMLeadsJob;
  }

  /**
   * No description
   * @tags Dial Maker
   * @name GetJobStatusOrResultDialMakerJobStatusJobIdGet
   * @summary Get Job Status Or Result
   * @request GET:/dial-maker/job-status/{job_id}
   * @secure
   */
  export namespace GetJobStatusOrResultDialMakerJobStatusJobIdGet {
    export type RequestParams = {
      /** Job Id */
      jobId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TMLeadsExtraction | TMLeadsJob;
  }

  /**
   * No description
   * @tags Dial Maker
   * @name GetDownloadUrlDialMakerJobResultJobIdDownloadUrlGet
   * @summary Get Download Url
   * @request GET:/dial-maker/job-result/{job_id}/download-url
   * @secure
   */
  export namespace GetDownloadUrlDialMakerJobResultJobIdDownloadUrlGet {
    export type RequestParams = {
      /** Job Id */
      jobId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = RoutersDialMakerPayloadsSignedUrl;
  }

  /**
   * No description
   * @tags Dial Maker
   * @name GetCampaignsDialMakerCampaignsGet
   * @summary Get Campaigns
   * @request GET:/dial-maker/campaigns
   * @secure
   */
  export namespace GetCampaignsDialMakerCampaignsGet {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Page */
      page: number;
      /** Limit */
      limit: number;
      /** Department */
      department?: ZDepartment | null;
      /** Country */
      country?: Country | null;
      /** Product */
      product?: Product | null;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TMCampaignList;
  }

  /**
   * No description
   * @tags Dial Maker
   * @name CreateCampaignDialMakerCampaignsPost
   * @summary Create Campaign
   * @request POST:/dial-maker/campaigns
   * @secure
   */
  export namespace CreateCampaignDialMakerCampaignsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateTMCampaign;
    export type RequestHeaders = {};
    export type ResponseBody = TMCampaign;
  }

  /**
   * No description
   * @tags Dial Maker
   * @name UpdateCampaignDialMakerCampaignsCampaignIdPut
   * @summary Update Campaign
   * @request PUT:/dial-maker/campaigns/{campaign_id}
   * @secure
   */
  export namespace UpdateCampaignDialMakerCampaignsCampaignIdPut {
    export type RequestParams = {
      /** Campaign Id */
      campaignId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CreateTMCampaign;
    export type RequestHeaders = {};
    export type ResponseBody = TMCampaign;
  }

  /**
   * No description
   * @tags Dial Maker
   * @name GetCampaignDialMakerCampaignsCampaignIdGet
   * @summary Get Campaign
   * @request GET:/dial-maker/campaigns/{campaign_id}
   * @secure
   */
  export namespace GetCampaignDialMakerCampaignsCampaignIdGet {
    export type RequestParams = {
      /** Campaign Id */
      campaignId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TMCampaign;
  }

  /**
   * No description
   * @tags Dial Maker
   * @name DeleteCampaignDialMakerCampaignsCampaignIdDelete
   * @summary Delete Campaign
   * @request DELETE:/dial-maker/campaigns/{campaign_id}
   * @secure
   */
  export namespace DeleteCampaignDialMakerCampaignsCampaignIdDelete {
    export type RequestParams = {
      /** Campaign Id */
      campaignId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = boolean;
  }

  /**
   * No description
   * @tags Dial Maker
   * @name GetCampaignExtractionsDialMakerCampaignsCampaignIdExtractionsGet
   * @summary Get Campaign Extractions
   * @request GET:/dial-maker/campaigns/{campaign_id}/extractions
   * @secure
   */
  export namespace GetCampaignExtractionsDialMakerCampaignsCampaignIdExtractionsGet {
    export type RequestParams = {
      /** Campaign Id */
      campaignId: string;
    };
    export type RequestQuery = {
      /** Page */
      page: number;
      /** Limit */
      limit: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TMLeadsExtraction[];
  }

  /**
   * No description
   * @tags Dial Maker
   * @name DownloadXlsxDialMakerDownloadSignatureGet
   * @summary Download Xlsx
   * @request GET:/dial-maker/download/{signature}
   */
  export namespace DownloadXlsxDialMakerDownloadSignatureGet {
    export type RequestParams = {
      /** Signature */
      signature: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Dialmaker {
  /**
   * No description
   * @tags Dial Maker 2
   * @name CreateCampaignDialmakerCampaignPost
   * @summary Create Campaign
   * @request POST:/dialmaker/campaign
   * @secure
   */
  export namespace CreateCampaignDialmakerCampaignPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateCampaignData;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name UpdateCampaignDialmakerCampaignCampaignIdPut
   * @summary Update Campaign
   * @request PUT:/dialmaker/campaign/{campaign_id}
   * @secure
   */
  export namespace UpdateCampaignDialmakerCampaignCampaignIdPut {
    export type RequestParams = {
      /**
       * Campaign Id
       * @format uuid
       */
      campaignId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateCampaignData;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetCampaignDialmakerCampaignCampaignIdGet
   * @summary Get Campaign
   * @request GET:/dialmaker/campaign/{campaign_id}
   * @secure
   */
  export namespace GetCampaignDialmakerCampaignCampaignIdGet {
    export type RequestParams = {
      /**
       * Campaign Id
       * @format uuid
       */
      campaignId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name UpdateCampaignAutoDialDialmakerCampaignCampaignIdAutoDialPut
   * @summary Update Campaign Auto Dial
   * @request PUT:/dialmaker/campaign/{campaign_id}/auto-dial
   * @secure
   */
  export namespace UpdateCampaignAutoDialDialmakerCampaignCampaignIdAutoDialPut {
    export type RequestParams = {
      /**
       * Campaign Id
       * @format uuid
       */
      campaignId: string;
    };
    export type RequestQuery = {
      /** Auto Dial */
      auto_dial: boolean;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetCampaignSourceDialmakerCampaignCampaignIdDataSourceGet
   * @summary Get Campaign Source
   * @request GET:/dialmaker/campaign/{campaign_id}/data/source
   * @secure
   */
  export namespace GetCampaignSourceDialmakerCampaignCampaignIdDataSourceGet {
    export type RequestParams = {
      /**
       * Campaign Id
       * @format uuid
       */
      campaignId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SheetCampaignSource | BigQueryCampaignSource | ProcessCampaignSource;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetCampaignExtractionsDialmakerCampaignCampaignIdExtractionsGet
   * @summary Get Campaign Extractions
   * @request GET:/dialmaker/campaign/{campaign_id}/extractions
   * @secure
   */
  export namespace GetCampaignExtractionsDialmakerCampaignCampaignIdExtractionsGet {
    export type RequestParams = {
      /**
       * Campaign Id
       * @format uuid
       */
      campaignId: string;
    };
    export type RequestQuery = {
      /**
       * Page
       * @min 1
       * @default 1
       */
      page?: number;
      /**
       * Size
       * @min 1
       * @max 100000
       * @default 50
       */
      size?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PageCampaignExtraction;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetCampaignSheetsDialmakerCampaignCampaignIdDataSheetsGet
   * @summary Get Campaign Sheets
   * @request GET:/dialmaker/campaign/{campaign_id}/data/sheets
   * @secure
   */
  export namespace GetCampaignSheetsDialmakerCampaignCampaignIdDataSheetsGet {
    export type RequestParams = {
      /**
       * Campaign Id
       * @format uuid
       */
      campaignId: string;
    };
    export type RequestQuery = {
      /**
       * Page
       * @min 1
       * @default 1
       */
      page?: number;
      /**
       * Size
       * @min 1
       * @max 100000
       * @default 50
       */
      size?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PageSheet;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name DeactivateCampaignDialmakerCampaignDeactivatePost
   * @summary Deactivate Campaign
   * @request POST:/dialmaker/campaign/deactivate
   * @secure
   */
  export namespace DeactivateCampaignDialmakerCampaignDeactivatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyDeactivateCampaignDialmakerCampaignDeactivatePost;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name ActivateCampaignDialmakerCampaignActivatePost
   * @summary Activate Campaign
   * @request POST:/dialmaker/campaign/activate
   * @secure
   */
  export namespace ActivateCampaignDialmakerCampaignActivatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyActivateCampaignDialmakerCampaignActivatePost;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name ExtractCampaignsDialmakerCampaignExtractPost
   * @summary Extract Campaigns
   * @request POST:/dialmaker/campaign/extract
   * @secure
   */
  export namespace ExtractCampaignsDialmakerCampaignExtractPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyExtractCampaignsDialmakerCampaignExtractPost;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name DeleteExtractionDialmakerCampaignExtractionDelete
   * @summary Delete Extraction
   * @request DELETE:/dialmaker/campaign/extraction
   * @secure
   */
  export namespace DeleteExtractionDialmakerCampaignExtractionDelete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyDeleteExtractionDialmakerCampaignExtractionDelete;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name FindCampaignsDialmakerCampaignFindPost
   * @summary Find Campaigns
   * @request POST:/dialmaker/campaign/find
   * @secure
   */
  export namespace FindCampaignsDialmakerCampaignFindPost {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Page
       * @min 1
       * @default 1
       */
      page?: number;
      /**
       * Size
       * @min 1
       * @max 100000
       * @default 50
       */
      size?: number;
    };
    export type RequestBody = BodyFindCampaignsDialmakerCampaignFindPost;
    export type RequestHeaders = {};
    export type ResponseBody = PageCampaign;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetLeadsDialmakerCampaignLeadsCampaignIdGet
   * @summary Get Leads
   * @request GET:/dialmaker/campaign/leads/{campaign_id}
   * @secure
   */
  export namespace GetLeadsDialmakerCampaignLeadsCampaignIdGet {
    export type RequestParams = {
      /**
       * Campaign Id
       * @format uuid
       */
      campaignId: string;
    };
    export type RequestQuery = {
      /**
       * Page
       * @min 1
       * @default 1
       */
      page?: number;
      /**
       * Size
       * @min 1
       * @max 100000
       * @default 50
       */
      size?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PageDMLead;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name ImportLeadsDialmakerCampaignImportPost
   * @summary Import Leads
   * @request POST:/dialmaker/campaign/import
   * @secure
   */
  export namespace ImportLeadsDialmakerCampaignImportPost {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Synchronous
       * Import leads synchronously
       * @default false
       */
      synchronous?: boolean;
    };
    export type RequestBody = BodyImportLeadsDialmakerCampaignImportPost;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign | string;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetJobStatusOrResultDialmakerCampaignJobStatusJobIdGet
   * @summary Get Job Status Or Result
   * @request GET:/dialmaker/campaign/job-status/{job_id}
   * @secure
   */
  export namespace GetJobStatusOrResultDialmakerCampaignJobStatusJobIdGet {
    export type RequestParams = {
      /**
       * Job Id
       * @format uuid
       */
      jobId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InterimLeadJob | InterimLead[];
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name UploadAttachmentDialmakerCampaignImageUploadPost
   * @summary Upload Attachment
   * @request POST:/dialmaker/campaign/image/upload
   * @secure
   */
  export namespace UploadAttachmentDialmakerCampaignImageUploadPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyUploadAttachmentDialmakerCampaignImageUploadPost;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name ExportAndExtractLeadsUrlDialmakerCampaignExportPost
   * @summary Export And Extract Leads Url
   * @request POST:/dialmaker/campaign/export
   * @secure
   */
  export namespace ExportAndExtractLeadsUrlDialmakerCampaignExportPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyExportAndExtractLeadsUrlDialmakerCampaignExportPost;
    export type RequestHeaders = {};
    export type ResponseBody = RoutersDialMaker2PayloadsSignedUrl;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name ExportAndExtractLeadsUrlDownloadDialmakerCampaignExtractionExportPost
   * @summary Export And Extract Leads Url Download
   * @request POST:/dialmaker/campaign/extraction/export
   * @secure
   */
  export namespace ExportAndExtractLeadsUrlDownloadDialmakerCampaignExtractionExportPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyExportAndExtractLeadsUrlDownloadDialmakerCampaignExtractionExportPost;
    export type RequestHeaders = {};
    export type ResponseBody = RoutersDialMaker2PayloadsSignedUrl;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetOngoingExtractionsDialmakerCampaignCampaignIdOngoingExtractionsPost
   * @summary Get Ongoing Extractions
   * @request POST:/dialmaker/campaign/{campaign_id}/ongoing_extractions
   * @secure
   */
  export namespace GetOngoingExtractionsDialmakerCampaignCampaignIdOngoingExtractionsPost {
    export type RequestParams = {
      /**
       * Campaign Id
       * @format uuid
       */
      campaignId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CampaignOngoingExtraction[];
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetOutcomesReportDialmakerCampaignSearchLeadsPost
   * @summary Get Outcomes Report
   * @request POST:/dialmaker/campaign/search-leads
   * @secure
   */
  export namespace GetOutcomesReportDialmakerCampaignSearchLeadsPost {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Page
       * @min 1
       * @default 1
       */
      page?: number;
      /**
       * Size
       * @min 1
       * @max 100000
       * @default 50
       */
      size?: number;
    };
    export type RequestBody = SearchLeadsPayload;
    export type RequestHeaders = {};
    export type ResponseBody = PageDMLead | null;
  }

  /**
   * @description This endpoint is used to get statistics: - Leads with 0 outcomes - Available leads to call - Already extracted count - Leads left to extract (cold campaigns only)
   * @tags Dial Maker 2
   * @name GetExtractionsStatsDialmakerCampaignCampaignIdExtractionsStatsGet
   * @summary Get Extractions Stats
   * @request GET:/dialmaker/campaign/{campaign_id}/extractions_stats
   * @secure
   */
  export namespace GetExtractionsStatsDialmakerCampaignCampaignIdExtractionsStatsGet {
    export type RequestParams = {
      /**
       * Campaign Id
       * @format uuid
       */
      campaignId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CampaignExtractionsStats;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetCampaignsByIdsDialmakerCampaignGetCampaignsByIdsPost
   * @summary Get Campaigns By Ids
   * @request POST:/dialmaker/campaign/get_campaigns_by_ids
   * @secure
   */
  export namespace GetCampaignsByIdsDialmakerCampaignGetCampaignsByIdsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = string[];
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign[];
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name SeedCampaignsDialmakerCampaignProcessSeedPost
   * @summary Seed Campaigns
   * @request POST:/dialmaker/campaign/process/seed
   * @secure
   */
  export namespace SeedCampaignsDialmakerCampaignProcessSeedPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, UpsertCampaignStatus>;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name FetchAndHandleInsurancesDialmakerCampaignProcessFetchAndHandlePost
   * @summary Fetch And Handle Insurances
   * @request POST:/dialmaker/campaign/process/fetch_and_handle
   * @secure
   */
  export namespace FetchAndHandleInsurancesDialmakerCampaignProcessFetchAndHandlePost {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
      product: ExtendedProduct;
      country: Country;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name SendMailToCampaignManuallyDialmakerCampaignCampaignIdMailSendManuallyPost
   * @summary Send Mail To Campaign Manually
   * @request POST:/dialmaker/campaign/{campaign_id}/mail/send_manually
   * @secure
   */
  export namespace SendMailToCampaignManuallyDialmakerCampaignCampaignIdMailSendManuallyPost {
    export type RequestParams = {
      /**
       * Campaign Id
       * @format uuid
       */
      campaignId: string;
    };
    export type RequestQuery = {
      /** Limit Amount Of Recipients */
      limit_amount_of_recipients?: number | null;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetMailStatisticsDialmakerCampaignMailStatisticsPost
   * @summary Get Mail Statistics
   * @request POST:/dialmaker/campaign/mail/statistics
   * @secure
   */
  export namespace GetMailStatisticsDialmakerCampaignMailStatisticsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyGetMailStatisticsDialmakerCampaignMailStatisticsPost;
    export type RequestHeaders = {};
    export type ResponseBody = CampaignMailStats[];
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name DryRunMailTemplateDialmakerCampaignCampaignIdMailTemplateSlugDryRunPost
   * @summary Dry Run Mail Template
   * @request POST:/dialmaker/campaign/{campaign_id}/mail/template/{slug}/dry-run
   * @secure
   */
  export namespace DryRunMailTemplateDialmakerCampaignCampaignIdMailTemplateSlugDryRunPost {
    export type RequestParams = {
      /**
       * Campaign Id
       * @format uuid
       */
      campaignId: string;
      /** Slug */
      slug: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TemplateDryRunResult;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name SendTestMailDialmakerCampaignMailSendTestPost
   * @summary Send Test Mail
   * @request POST:/dialmaker/campaign/mail/send_test
   * @secure
   */
  export namespace SendTestMailDialmakerCampaignMailSendTestPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TestTemplatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = SentTemplateResponse[];
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetMailLeadsOverviewDialmakerCampaignCampaignIdMailLeadsGet
   * @summary Get Mail Leads Overview
   * @request GET:/dialmaker/campaign/{campaign_id}/mail/leads
   * @secure
   */
  export namespace GetMailLeadsOverviewDialmakerCampaignCampaignIdMailLeadsGet {
    export type RequestParams = {
      /**
       * Campaign Id
       * @format uuid
       */
      campaignId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CampaignMailOverview;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetUserCampaignsDialmakerCampaignsGet
   * @summary Get User Campaigns
   * @request GET:/dialmaker/campaigns
   * @deprecated
   * @secure
   */
  export namespace GetUserCampaignsDialmakerCampaignsGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserCampaignsExt | null;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name AvailableCampaignsDialmakerCampaignsAvailablePost
   * @summary Available Campaigns
   * @request POST:/dialmaker/campaigns/available
   * @secure
   */
  export namespace AvailableCampaignsDialmakerCampaignsAvailablePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Available;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name JoinCampaignDialmakerCampaignsJoinPost
   * @summary Join Campaign
   * @request POST:/dialmaker/campaigns/join
   * @secure
   */
  export namespace JoinCampaignDialmakerCampaignsJoinPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyJoinCampaignDialmakerCampaignsJoinPost;
    export type RequestHeaders = {};
    export type ResponseBody = Available;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name LeaveCampaignDialmakerCampaignsLeavePost
   * @summary Leave Campaign
   * @request POST:/dialmaker/campaigns/leave
   * @secure
   */
  export namespace LeaveCampaignDialmakerCampaignsLeavePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyLeaveCampaignDialmakerCampaignsLeavePost;
    export type RequestHeaders = {};
    export type ResponseBody = Available;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name PlayCampaignDialmakerCampaignsPlayPost
   * @summary Play Campaign
   * @request POST:/dialmaker/campaigns/play
   * @secure
   */
  export namespace PlayCampaignDialmakerCampaignsPlayPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyPlayCampaignDialmakerCampaignsPlayPost;
    export type RequestHeaders = {};
    export type ResponseBody = Available;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name PauseCampaignDialmakerCampaignsPausePost
   * @summary Pause Campaign
   * @request POST:/dialmaker/campaigns/pause
   * @secure
   */
  export namespace PauseCampaignDialmakerCampaignsPausePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyPauseCampaignDialmakerCampaignsPausePost;
    export type RequestHeaders = {};
    export type ResponseBody = Available;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetCardDialmakerCardNextPost
   * @summary Get Card
   * @request POST:/dialmaker/card/next
   * @secure
   */
  export namespace GetCardDialmakerCardNextPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt | null;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name ViewDialmakerCardViewPost
   * @summary View
   * @request POST:/dialmaker/card/view
   * @secure
   */
  export namespace ViewDialmakerCardViewPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyViewDialmakerCardViewPost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name UpdateDialmakerCardUpdatePost
   * @summary Update
   * @request POST:/dialmaker/card/update
   * @secure
   */
  export namespace UpdateDialmakerCardUpdatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyUpdateDialmakerCardUpdatePost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLead;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name HangupDialmakerCardHangupPost
   * @summary Hangup
   * @request POST:/dialmaker/card/hangup
   * @secure
   */
  export namespace HangupDialmakerCardHangupPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name CallSuccessDialmakerCardOutcomeSuccessPost
   * @summary Call Success
   * @request POST:/dialmaker/card/outcome/success
   * @secure
   */
  export namespace CallSuccessDialmakerCardOutcomeSuccessPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyCallSuccessDialmakerCardOutcomeSuccessPost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt | null;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name CallBackDialmakerCardOutcomeCallBackPost
   * @summary Call Back
   * @request POST:/dialmaker/card/outcome/call_back
   * @secure
   */
  export namespace CallBackDialmakerCardOutcomeCallBackPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyCallBackDialmakerCardOutcomeCallBackPost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt | null;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name CallBackDialmakerCardOutcomeLaterPost
   * @summary Call Back
   * @request POST:/dialmaker/card/outcome/later
   * @secure
   */
  export namespace CallBackDialmakerCardOutcomeLaterPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyCallBackDialmakerCardOutcomeLaterPost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt | null;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name CallNoContactDialmakerCardOutcomeNoContactPost
   * @summary Call No Contact
   * @request POST:/dialmaker/card/outcome/no_contact
   * @secure
   */
  export namespace CallNoContactDialmakerCardOutcomeNoContactPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyCallNoContactDialmakerCardOutcomeNoContactPost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt | null;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name CallDiscardsDialmakerCardOutcomeDiscardPost
   * @summary Call Discards
   * @request POST:/dialmaker/card/outcome/discard
   * @secure
   */
  export namespace CallDiscardsDialmakerCardOutcomeDiscardPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyCallDiscardsDialmakerCardOutcomeDiscardPost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt | null;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name CallFailureDialmakerCardOutcomeFailurePost
   * @summary Call Failure
   * @request POST:/dialmaker/card/outcome/failure
   * @secure
   */
  export namespace CallFailureDialmakerCardOutcomeFailurePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyCallFailureDialmakerCardOutcomeFailurePost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt | null;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetOutcomesDialmakerCardOutcomesPost
   * @summary Get Outcomes
   * @request POST:/dialmaker/card/outcomes
   * @secure
   */
  export namespace GetOutcomesDialmakerCardOutcomesPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyGetOutcomesDialmakerCardOutcomesPost;
    export type RequestHeaders = {};
    export type ResponseBody = Outcomes;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name SearchDialmakerCardSearchPost
   * @summary Search
   * @request POST:/dialmaker/card/search
   * @secure
   */
  export namespace SearchDialmakerCardSearchPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodySearchDialmakerCardSearchPost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt[];
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name LockDialmakerCardLockPost
   * @summary Lock
   * @request POST:/dialmaker/card/lock
   * @secure
   */
  export namespace LockDialmakerCardLockPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyLockDialmakerCardLockPost;
    export type RequestHeaders = {};
    export type ResponseBody = DMLead;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name PersonalCallbacksDialmakerCardPersonalCallbacksPost
   * @summary Personal Callbacks
   * @request POST:/dialmaker/card/personal_callbacks
   * @secure
   */
  export namespace PersonalCallbacksDialmakerCardPersonalCallbacksPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DMLeadExt[];
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetSlotsDialmakerCardSlotsGet
   * @summary Get Slots
   * @request GET:/dialmaker/card/slots
   * @secure
   */
  export namespace GetSlotsDialmakerCardSlotsGet {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Date
       * @format date-time
       */
      date: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BookableSlot[];
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetPreviousAppsDialmakerCardPreviousAppsPost
   * @summary Get Previous Apps
   * @request POST:/dialmaker/card/previous_apps
   * @secure
   */
  export namespace GetPreviousAppsDialmakerCardPreviousAppsPost {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Customer Id
       * @format uuid
       */
      customer_id: string;
      country: Country;
      /** Pni */
      pni?: string | null;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PreviousApplicationsList;
  }

  /**
   * @description Get all insurances for a customer, sorted by creation date.
   * @tags Dial Maker 2
   * @name GetCustomerInsurancesDialmakerCardCustomerInsurancesPost
   * @summary Get Customer Insurances
   * @request POST:/dialmaker/card/customer_insurances
   * @secure
   */
  export namespace GetCustomerInsurancesDialmakerCardCustomerInsurancesPost {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Customer Id
       * @format uuid
       */
      customer_id: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Insurance[];
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name MineDialmakerCardOutcomesMineTodayPost
   * @summary Mine
   * @request POST:/dialmaker/card/outcomes/mine_today
   * @secure
   */
  export namespace MineDialmakerCardOutcomesMineTodayPost {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Start Date
       * @format date-time
       */
      start_date: string;
      /**
       * End Date
       * @format date-time
       */
      end_date: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OutcomeCount;
  }

  /**
   * @description Upload file and return a preview of sheet, together with the file id needed to import leads. Uploaded file whether it's csv or xlsx should have headers in the first row to be able to map the columns to the lead data model.
   * @tags Dial Maker 2
   * @name UploadDialmakerSheetCampaignIdUploadWithPreviewPost
   * @summary Upload
   * @request POST:/dialmaker/sheet/{campaign_id}/upload_with_preview
   * @secure
   */
  export namespace UploadDialmakerSheetCampaignIdUploadWithPreviewPost {
    export type RequestParams = {
      /**
       * Campaign Id
       * @format uuid
       */
      campaignId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = BodyUploadDialmakerSheetCampaignIdUploadWithPreviewPost;
    export type RequestHeaders = {};
    export type ResponseBody = SheetPreview;
  }

  /**
   * @description This endpoint is used to import leads from a file to a global leads collection with campaign_id and file_id metadata
   * @tags Dial Maker 2
   * @name ImportFromFileDialmakerSheetCampaignIdImportFromFileIdPost
   * @summary Import From File
   * @request POST:/dialmaker/sheet/{campaign_id}/import_from/{file_id}
   * @secure
   */
  export namespace ImportFromFileDialmakerSheetCampaignIdImportFromFileIdPost {
    export type RequestParams = {
      /**
       * Campaign Id
       * @format uuid
       */
      campaignId: string;
      /**
       * File Id
       * @format uuid
       */
      fileId: string;
    };
    export type RequestQuery = {
      /**
       * Synchronous
       * Import leads synchronously
       * @default false
       */
      synchronous?: boolean;
    };
    export type RequestBody = Record<string, string>;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign | string;
  }

  /**
   * @description This endpoint is used to delete a file from the database if it was not imported yet
   * @tags Dial Maker 2
   * @name DeleteCampaignSheetDialmakerSheetCampaignIdDeleteFileFileIdDelete
   * @summary Delete Campaign Sheet
   * @request DELETE:/dialmaker/sheet/{campaign_id}/delete_file/{file_id}
   * @secure
   */
  export namespace DeleteCampaignSheetDialmakerSheetCampaignIdDeleteFileFileIdDelete {
    export type RequestParams = {
      /**
       * Campaign Id
       * @format uuid
       */
      campaignId: string;
      /**
       * File Id
       * @format uuid
       */
      fileId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign | null;
  }

  /**
   * @description This endpoint is used to delete import of a given sheet_id with all interim leads
   * @tags Dial Maker 2
   * @name DeleteInterimLeadsDialmakerSheetCampaignIdImportSheetIdDelete
   * @summary Delete Interim Leads
   * @request DELETE:/dialmaker/sheet/{campaign_id}/import/{sheet_id}
   * @secure
   */
  export namespace DeleteInterimLeadsDialmakerSheetCampaignIdImportSheetIdDelete {
    export type RequestParams = {
      /**
       * Campaign Id
       * @format uuid
       */
      campaignId: string;
      /**
       * Sheet Id
       * @format uuid
       */
      sheetId: string;
    };
    export type RequestQuery = {
      /**
       * Force
       * Force delete import even if status is IN_PROGRESS
       * @default false
       */
      force?: boolean;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ModelsDialMaker2CampaignAggregateCampaign | null;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetOutcomesDialmakerMiscOutcomesGet
   * @summary Get Outcomes
   * @request GET:/dialmaker/misc/outcomes
   * @secure
   */
  export namespace GetOutcomesDialmakerMiscOutcomesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Outcomes;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name SaveOutcomesDialmakerMiscOutcomesPost
   * @summary Save Outcomes
   * @request POST:/dialmaker/misc/outcomes
   * @secure
   */
  export namespace SaveOutcomesDialmakerMiscOutcomesPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodySaveOutcomesDialmakerMiscOutcomesPost;
    export type RequestHeaders = {};
    export type ResponseBody = Outcomes;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name AdminDialmakerAdminOutcomesPost
   * @summary Admin
   * @request POST:/dialmaker/admin/outcomes
   * @secure
   */
  export namespace AdminDialmakerAdminOutcomesPost {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Start Date
       * @format date-time
       */
      start_date: string;
      /**
       * End Date
       * @format date-time
       */
      end_date: string;
    };
    export type RequestBody = BodyAdminDialmakerAdminOutcomesPost;
    export type RequestHeaders = {};
    export type ResponseBody = OutcomeCount;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name BlockFieldsDialmakerAdminBlockPost
   * @summary Block Fields
   * @request POST:/dialmaker/admin/block
   * @secure
   */
  export namespace BlockFieldsDialmakerAdminBlockPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BlockCustomerFieldManuallyPayload;
    export type RequestHeaders = {};
    export type ResponseBody = BlockCustomerFieldManuallyResponse;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name StatsDialmakerAdminOutcomesUsersPost
   * @summary Stats
   * @request POST:/dialmaker/admin/outcomes/users
   * @secure
   */
  export namespace StatsDialmakerAdminOutcomesUsersPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyStatsDialmakerAdminOutcomesUsersPost;
    export type RequestHeaders = {};
    export type ResponseBody = OutcomeCount;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetMailTemplatesDialmakerMailTemplatesAvailableGet
   * @summary Get Mail Templates
   * @request GET:/dialmaker/mail/templates/available
   * @secure
   */
  export namespace GetMailTemplatesDialmakerMailTemplatesAvailableGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = MandrillTemplate[];
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetVariablesTemplateVariablesDialmakerMailTemplateVariablesPost
   * @summary Get Variables Template Variables
   * @request POST:/dialmaker/mail/template/variables
   * @secure
   */
  export namespace GetVariablesTemplateVariablesDialmakerMailTemplateVariablesPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyGetVariablesTemplateVariablesDialmakerMailTemplateVariablesPost;
    export type RequestHeaders = {};
    export type ResponseBody = TemplateVariables;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name GetAvailableTemplateVariablesDialmakerMailTemplateAvailableVariablesGet
   * @summary Get Available Template Variables
   * @request GET:/dialmaker/mail/template/available/variables
   * @secure
   */
  export namespace GetAvailableTemplateVariablesDialmakerMailTemplateAvailableVariablesGet {
    export type RequestParams = {};
    export type RequestQuery = {
      country: Country;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string[];
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name TemplateRenderDialmakerMailTemplateRenderPost
   * @summary Template Render
   * @request POST:/dialmaker/mail/template/render
   * @secure
   */
  export namespace TemplateRenderDialmakerMailTemplateRenderPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = RenderTemplatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
}

export namespace UrlShortener {
  /**
   * No description
   * @tags URL Shortener
   * @name CreateUrlUrlShortenerUrlPost
   * @summary Create Url
   * @request POST:/url-shortener/url
   * @secure
   */
  export namespace CreateUrlUrlShortenerUrlPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyCreateUrlUrlShortenerUrlPost;
    export type RequestHeaders = {};
    export type ResponseBody = URL;
  }

  /**
   * No description
   * @tags URL Shortener
   * @name GetAllUrlsUrlShortenerUrlsGet
   * @summary Get All Urls
   * @request GET:/url-shortener/urls
   * @secure
   */
  export namespace GetAllUrlsUrlShortenerUrlsGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = URL[];
  }

  /**
   * No description
   * @tags URL Shortener
   * @name GetUrlInfoUrlShortenerUrlKeyGet
   * @summary Get Url Info
   * @request GET:/url-shortener/{url_key}
   * @secure
   */
  export namespace GetUrlInfoUrlShortenerUrlKeyGet {
    export type RequestParams = {
      /** Url Key */
      urlKey: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = URL;
  }

  /**
   * No description
   * @tags URL Shortener
   * @name GetShortUrlUrlShortenerUrlKeyShortenedCountryGet
   * @summary Get Short Url
   * @request GET:/url-shortener/{url_key}/shortened/{country}
   * @secure
   */
  export namespace GetShortUrlUrlShortenerUrlKeyShortenedCountryGet {
    export type RequestParams = {
      /** Url Key */
      urlKey: string;
      country: Country;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
}

export namespace Insurance {
  /**
   * No description
   * @tags Insurance
   * @name CreateInsuranceInsuranceInsurancePost
   * @summary Create Insurance
   * @request POST:/insurance/insurance
   * @secure
   */
  export namespace CreateInsuranceInsuranceInsurancePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateInsuranceCustomerV1;
    export type RequestHeaders = {};
    export type ResponseBody = Insurance;
  }
}

export namespace Bank {
  /**
   * No description
   * @tags Bank Login
   * @name LoginBankLoginPost
   * @summary Login
   * @request POST:/bank/login
   */
  export namespace LoginBankLoginPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Login;
    export type RequestHeaders = {};
    export type ResponseBody = LoginResp;
  }

  /**
   * No description
   * @tags Bank Login
   * @name ValidateOtpCodeBankLoginValidatePost
   * @summary Validate Otp Code
   * @request POST:/bank/login/validate
   */
  export namespace ValidateOtpCodeBankLoginValidatePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = OTPValidation;
    export type RequestHeaders = {};
    export type ResponseBody = OTPValidationResp;
  }

  /**
   * No description
   * @tags Bank Login
   * @name SetNewPasswordBankSetPasswordPost
   * @summary Set New Password
   * @request POST:/bank/set-password
   */
  export namespace SetNewPasswordBankSetPasswordPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = SetPassword;
    export type RequestHeaders = {};
    export type ResponseBody = SetPasswordResp;
  }

  /**
   * No description
   * @tags Bank Login
   * @name RequestNewOtpBankNewOtpPost
   * @summary Request New Otp
   * @request POST:/bank/new-otp
   */
  export namespace RequestNewOtpBankNewOtpPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = RequestNewOTP;
    export type RequestHeaders = {};
    export type ResponseBody = SetPasswordResp;
  }

  /**
   * No description
   * @tags Bank User Administration
   * @name CreateNewBankUserBankCreateUserPost
   * @summary Create New Bank User
   * @request POST:/bank/create-user
   * @secure
   */
  export namespace CreateNewBankUserBankCreateUserPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateUser;
    export type RequestHeaders = {};
    export type ResponseBody = CreateUserResp;
  }

  /**
   * No description
   * @tags Bank User Administration
   * @name GetBankUsersBankGetPost
   * @summary Get Bank Users
   * @request POST:/bank/get
   * @secure
   */
  export namespace GetBankUsersBankGetPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetUsersPayload;
    export type RequestHeaders = {};
    export type ResponseBody = BankUserWithBankNames[];
  }

  /**
   * No description
   * @tags Bank User Administration
   * @name GetBankUserBankUserIdGet
   * @summary Get Bank User
   * @request GET:/bank/{user_id}
   * @secure
   */
  export namespace GetBankUserBankUserIdGet {
    export type RequestParams = {
      /** User Id */
      userId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BankUserWithBankNames;
  }

  /**
   * No description
   * @tags Bank User Administration
   * @name EditBankUserBankUserIdPut
   * @summary Edit Bank User
   * @request PUT:/bank/{user_id}
   * @secure
   */
  export namespace EditBankUserBankUserIdPut {
    export type RequestParams = {
      /** User Id */
      userId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = EditUser;
    export type RequestHeaders = {};
    export type ResponseBody = BankUserWithBankNames;
  }

  /**
   * No description
   * @tags Bank User Administration
   * @name ResetBankUserPasswordBankResetPasswordPost
   * @summary Reset Bank User Password
   * @request POST:/bank/reset-password
   * @secure
   */
  export namespace ResetBankUserPasswordBankResetPasswordPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ResetPasswordPayload;
    export type RequestHeaders = {};
    export type ResponseBody = CreateUserResp;
  }

  /**
   * No description
   * @tags Bank User Administration
   * @name DeactivateBankUserBankDeactivatePut
   * @summary Deactivate Bank User
   * @request PUT:/bank/deactivate
   * @secure
   */
  export namespace DeactivateBankUserBankDeactivatePut {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = DeactivateUserPayload;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags Bank
   * @name GetApplicationsBankApplicationsPost
   * @summary Get Applications
   * @request POST:/bank/applications
   * @secure
   */
  export namespace GetApplicationsBankApplicationsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetApplications;
    export type RequestHeaders = {};
    export type ResponseBody = BankBaseApp[];
  }

  /**
   * No description
   * @tags Bank
   * @name GetApplicationBankApplicationApplicationIdGet
   * @summary Get Application
   * @request GET:/bank/application/{application_id}
   * @secure
   */
  export namespace GetApplicationBankApplicationApplicationIdGet {
    export type RequestParams = {
      /** Application Id */
      applicationId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = BankBaseApp;
  }

  /**
   * No description
   * @tags Bank
   * @name GetDocumentMetaBankDocumentMetaCustomerIdDocumentIdGet
   * @summary Get Document Meta
   * @request GET:/bank/document/meta/{customer_id}/{document_id}
   * @secure
   */
  export namespace GetDocumentMetaBankDocumentMetaCustomerIdDocumentIdGet {
    export type RequestParams = {
      /**
       * Customer Id
       * @format uuid4
       */
      customerId: string;
      /**
       * Document Id
       * @format uuid
       */
      documentId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DocumentMeta;
  }

  /**
   * No description
   * @tags Bank
   * @name GetDocumentBankGetCustomerIdDocumentIdGet
   * @summary Get Document
   * @request GET:/bank/get/{customer_id}/{document_id}
   * @secure
   */
  export namespace GetDocumentBankGetCustomerIdDocumentIdGet {
    export type RequestParams = {
      /**
       * Customer Id
       * @format uuid
       */
      customerId: string;
      /**
       * Document Id
       * @format uuid
       */
      documentId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CustomerDocument;
  }

  /**
   * No description
   * @tags Bank
   * @name GetAllBanksBankGetBanksPost
   * @summary Get All Banks
   * @request POST:/bank/get-banks
   * @secure
   */
  export namespace GetAllBanksBankGetBanksPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GetBanksPayload;
    export type RequestHeaders = {};
    export type ResponseBody = Bank[];
  }

  /**
   * No description
   * @tags Bank
   * @name GetAppEventsBankGetAppEventsApplicationIdGet
   * @summary Get App Events
   * @request GET:/bank/get_app_events/{application_id}
   * @secure
   */
  export namespace GetAppEventsBankGetAppEventsApplicationIdGet {
    export type RequestParams = {
      /** Application Id */
      applicationId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EventsResponse;
  }

  /**
   * No description
   * @tags Bank
   * @name AddCommentBankCommentPost
   * @summary Add Comment
   * @request POST:/bank/comment
   * @secure
   */
  export namespace AddCommentBankCommentPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BankComment;
    export type RequestHeaders = {};
    export type ResponseBody = EventsResponse;
  }

  /**
   * No description
   * @tags Bank
   * @name PostOfferBankOfferPost
   * @summary Post Offer
   * @request POST:/bank/offer
   * @secure
   */
  export namespace PostOfferBankOfferPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BankOfferPayload;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags Bank, Application
   * @name RejectBankRejectPost
   * @summary Reject
   * @request POST:/bank/reject
   * @secure
   */
  export namespace RejectBankRejectPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BankRejectPayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Bank, Application
   * @name DisburseBankDisbursePost
   * @summary Disburse
   * @request POST:/bank/disburse
   * @secure
   */
  export namespace DisburseBankDisbursePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BankDisbursePayload;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Bank
   * @name SearchApplicationsBankSearchPost
   * @summary Search Applications
   * @request POST:/bank/search
   * @secure
   */
  export namespace SearchApplicationsBankSearchPost {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Page
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * Limit
       * @min 0
       * @max 50
       * @default 50
       */
      limit?: number;
    };
    export type RequestBody = RoutersBankPayloadsSearchPayload;
    export type RequestHeaders = {};
    export type ResponseBody = BankBaseApp[];
  }
}

export namespace Jotform {
  /**
   * No description
   * @tags Jotform
   * @name SubmissionJotformWebhookFormIdPost
   * @summary Submission
   * @request POST:/jotform/webhook/{form_id}
   */
  export namespace SubmissionJotformWebhookFormIdPost {
    export type RequestParams = {
      /** Form Id */
      formId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = BodySubmissionJotformWebhookFormIdPost;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace Campaign {
  /**
   * No description
   * @tags Affiliate
   * @name CreateCampaignInitializedEventCampaignCampaignIdGet
   * @summary Create Campaign Initialized Event
   * @request GET:/campaign/{campaign_id}
   */
  export namespace CreateCampaignInitializedEventCampaignCampaignIdGet {
    export type RequestParams = {
      /** Campaign Id */
      campaignId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace Visit {
  /**
   * No description
   * @tags Affiliate
   * @name CreateVisitEventVisitCountryZidPost
   * @summary Create Visit Event
   * @request POST:/visit/{country}/{zid}
   */
  export namespace CreateVisitEventVisitCountryZidPost {
    export type RequestParams = {
      country: Country;
      /** Zid */
      zid: string;
    };
    export type RequestQuery = {};
    export type RequestBody = WebsiteVisitPayload;
    export type RequestHeaders = {
      /** X-S-Zid */
      "x-s-zid"?: string | null;
      /** X-Gid */
      "x-gid"?: string | null;
    };
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace Slack {
  /**
   * No description
   * @tags Slack
   * @name SlackCallbackSlackCallbackPost
   * @summary Slack Callback
   * @request POST:/slack/callback
   */
  export namespace SlackCallbackSlackCallbackPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodySlackCallbackSlackCallbackPost;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace DialMaker2 {
  /**
   * No description
   * @tags Dial Maker 2
   * @name GetImageDialMaker2ImageGet
   * @summary Get Image
   * @request GET:/dial-maker2/image
   */
  export namespace GetImageDialMaker2ImageGet {
    export type RequestParams = {};
    export type RequestQuery = {
      /** File Id */
      file_id: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }

  /**
   * No description
   * @tags Dial Maker 2
   * @name DownloadLeadsXlsxDialMaker2ExportDownloadSignatureGet
   * @summary Download Leads Xlsx
   * @request GET:/dial-maker2/export/download/{signature}
   */
  export namespace DownloadLeadsXlsxDialMaker2ExportDownloadSignatureGet {
    export type RequestParams = {
      /** Signature */
      signature: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace WellKnown {
  /**
   * No description
   * @tags Well known
   * @name GetJwksWellKnownJwksJsonGet
   * @summary Get Jwks
   * @request GET:/.well-known/jwks.json
   */
  export namespace GetJwksWellKnownJwksJsonGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmptyModel;
  }
}

export namespace Health {
  /**
   * No description
   * @tags Health
   * @name GetHealthHealthGet
   * @summary Get Health
   * @request GET:/health
   */
  export namespace GetHealthHealthGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace Validator {
  /**
   * No description
   * @tags Validator
   * @name ValidateValidatorPost
   * @summary Validate
   * @request POST:/validator/
   */
  export namespace ValidateValidatorPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Email | PNI | ModelsValidatorServicePayloadPhoneNumber | BankAccount;
    export type RequestHeaders = {};
    export type ResponseBody = ValidatorServiceResponseModel;
  }

  /**
   * No description
   * @tags Validator
   * @name ValidateValidatorPost2
   * @summary Validate
   * @request POST:/validator
   * @originalName validateValidatorPost
   * @duplicate
   */
  export namespace ValidateValidatorPost2 {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Email | PNI | ModelsValidatorServicePayloadPhoneNumber | BankAccount;
    export type RequestHeaders = {};
    export type ResponseBody = ValidatorServiceResponseModel;
  }

  /**
   * No description
   * @tags Validator
   * @name ValidateSafelyValidatorSafePost
   * @summary Validate Safely
   * @request POST:/validator/safe/
   */
  export namespace ValidateSafelyValidatorSafePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = EmptyModel;
    export type RequestHeaders = {};
    export type ResponseBody = ValidatorServiceResponseModel;
  }

  /**
   * No description
   * @tags Validator
   * @name ValidateSafelyValidatorSafePost2
   * @summary Validate Safely
   * @request POST:/validator/safe
   * @originalName validateSafelyValidatorSafePost
   * @duplicate
   */
  export namespace ValidateSafelyValidatorSafePost2 {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = EmptyModel;
    export type RequestHeaders = {};
    export type ResponseBody = ValidatorServiceResponseModel;
  }
}

export namespace Mandrill {
  /**
   * No description
   * @tags Mail Webhook
   * @name MessageOpenedMandrillWebhookMessagePost
   * @summary Message Opened
   * @request POST:/mandrill/webhook/message
   */
  export namespace MessageOpenedMandrillWebhookMessagePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyMessageOpenedMandrillWebhookMessagePost;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace Mail {
  /**
   * No description
   * @tags MailMaker
   * @name UnsubscribeFromMailMarketingMailPublicUnsubscribePost
   * @summary Unsubscribe From Mail Marketing
   * @request POST:/mail/public/unsubscribe
   */
  export namespace UnsubscribeFromMailMarketingMailPublicUnsubscribePost {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Mail Key */
      mail_key: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace P {
  /**
   * No description
   * @tags URL Shortener
   * @name ForwardToTargetUrlPUrlKeyPathGet
   * @summary Forward To Target Url
   * @request GET:/p/{url_key}/{path}
   */
  export namespace ForwardToTargetUrlPUrlKeyPathGet {
    export type RequestParams = {
      /** Url Key */
      urlKey: string;
      /** Path */
      path: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace FormAnalytics {
  /**
   * No description
   * @tags Online
   * @name StoreEventsFormAnalyticsStoreEventsPost
   * @summary Store Events
   * @request POST:/form-analytics/store-events
   */
  export namespace StoreEventsFormAnalyticsStoreEventsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyStoreEventsFormAnalyticsStoreEventsPost;
    export type RequestHeaders = {};
    export type ResponseBody = EmploymentTypeBase;
  }
}

export namespace Infra {
  /**
   * No description
   * @tags Other
   * @name Auth0EventsProxyInfraAuth0EventsPost
   * @summary Auth0 Events Proxy
   * @request POST:/infra/auth0/events
   * @secure
   */
  export namespace Auth0EventsProxyInfraAuth0EventsPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = object[];
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Callmaker {
  /**
   * No description
   * @tags Callmaker Callbacks
   * @name GetCallmakerDataCallmakerCallbackCallPost
   * @summary Get Callmaker Data
   * @request POST:/callmaker/callback/call
   * @secure
   */
  export namespace GetCallmakerDataCallmakerCallbackCallPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = EmptyModel;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags Callmaker Callbacks
   * @name GetProcessedDataCallmakerCallbackProcessPost
   * @summary Get Processed Data
   * @request POST:/callmaker/callback/process
   * @secure
   */
  export namespace GetProcessedDataCallmakerCallbackProcessPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = EmptyModel;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace V1 {
  /**
   * No description
   * @tags V1 Affiliate API
   * @name CreateBlancoApplicationV1AffiliateApplicationsBlancoPost
   * @summary Create Blanco Application
   * @request POST:/v1/affiliate/applications/blanco
   * @secure
   */
  export namespace CreateBlancoApplicationV1AffiliateApplicationsBlancoPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AffiliateCreateBlancoApplication;
    export type RequestHeaders = {};
    export type ResponseBody = AffiliateResponse;
  }

  /**
   * No description
   * @tags V1 Affiliate API
   * @name GetBookingsBlancoV1AffiliateBookingsBlancoGet
   * @summary Get Bookings Blanco
   * @request GET:/v1/affiliate/bookings/blanco
   * @secure
   */
  export namespace GetBookingsBlancoV1AffiliateBookingsBlancoGet {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Start Date
       * @format date
       */
      start_date: string;
      /**
       * End Date
       * @format date
       */
      end_date: string;
      country: Country;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AvailableBookings;
  }

  /**
   * No description
   * @tags V1 Affiliate API
   * @name CreateMortgageLeadV1AffiliateLeadsMortgagePost
   * @summary Create Mortgage Lead
   * @request POST:/v1/affiliate/leads/mortgage
   * @secure
   */
  export namespace CreateMortgageLeadV1AffiliateLeadsMortgagePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = AffiliateLeadCreationPayload;
    export type RequestHeaders = {};
    export type ResponseBody = AffiliateResponse;
  }

  /**
   * No description
   * @tags V1 Affiliate API
   * @name GetBookingsMortgageV1AffiliateBookingsMortgageGet
   * @summary Get Bookings Mortgage
   * @request GET:/v1/affiliate/bookings/mortgage
   * @secure
   */
  export namespace GetBookingsMortgageV1AffiliateBookingsMortgageGet {
    export type RequestParams = {};
    export type RequestQuery = {
      /**
       * Start Date
       * @format date
       */
      start_date: string;
      /**
       * End Date
       * @format date
       */
      end_date: string;
      country: Country;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AvailableBookings;
  }

  /**
   * @description Returns all allowed enum values
   * @tags V1 Affiliate API
   * @name GetOptionsV1AffiliateOptionsCountryProductGet
   * @summary Get Options
   * @request GET:/v1/affiliate/options/{country}/{product}
   * @secure
   */
  export namespace GetOptionsV1AffiliateOptionsCountryProductGet {
    export type RequestParams = {
      country: Country;
      product: Product;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AffiliateOptionsResponse;
  }

  /**
   * No description
   * @tags V1 Affiliate API
   * @name GetBlancoStatusV1AffiliateApplicationsBlancoSubmissionIdGet
   * @summary Get Blanco Status
   * @request GET:/v1/affiliate/applications/blanco/{submission_id}
   * @secure
   */
  export namespace GetBlancoStatusV1AffiliateApplicationsBlancoSubmissionIdGet {
    export type RequestParams = {
      /**
       * Submission Id
       * @format uuid
       */
      submissionId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AffiliateCallback;
  }

  /**
   * No description
   * @tags V1 Affiliate API
   * @name GetMortgageStatusV1AffiliateLeadsMortgageSubmissionIdGet
   * @summary Get Mortgage Status
   * @request GET:/v1/affiliate/leads/mortgage/{submission_id}
   * @secure
   */
  export namespace GetMortgageStatusV1AffiliateLeadsMortgageSubmissionIdGet {
    export type RequestParams = {
      /**
       * Submission Id
       * @format uuid
       */
      submissionId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AffiliateCallback;
  }
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  headers?: { [key: string]: string | undefined };
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends globalThis.Response {
  data: D;
  error: E;
  requestId: string;
}

export interface HttpErrorResponse<E> extends globalThis.Response {
  error: E;
  requestId: string;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "include",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  private generateUUID() {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = Math.random() * 16; //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    // We need to generate new RequestID for every request
    let requestId = requestParams.headers["X-Request-ID"];
    if (!requestId) {
      requestId = this.generateUUID();
      requestParams.headers["X-Request-ID"] = requestId;
    }

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;
      r.requestId = requestId;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data as HttpErrorResponse<E>;
      return data;
    });
  };
}

/**
 * @title The ZRM
 * @version 0.1.0
 *
 *
 * A __ZRM__ _(please say it out loud and you shall understand)_ for the products at Zensum.
 *
 * ---
 * ### Code
 *
 * You can find this code [here on Github](https://github.com/zensum/zrm) if you're a part of the Zensum organization.
 *
 * ---
 *
 * ### Features
 *  - handling/booking meetings
 *  - sending messages to customer
 *  - proxying requests for customer data to kunddatalagringstjänsten
 *  - proxying requests for applications data to applications manager
 *
 * ---
 *
 * ### Authorization
 *
 * In order to authorize you need a token with the audience `API_TEST_WEB` from the issuer `zensum-test.eu.auth0.com`.
 * Easiest way of getting a token is to look in Auth0 and get a token with a curl similar to this
 *
 * ```sh
 * curl --request POST \
 *      --url https://zensum-test.eu.auth0.com/oauth/token \
 *      --header 'content-type: application/json' \
 *      --data '{"client_id":"{CLIENT_ID}","client_secret":"{CLIENT_SECRET}", \
 *         "audience":"API_TEST_WEB","grant_type":"client_credentials"}'
 * ```
 * _Remember to replace {CLIENT_ID} and {CLIENT_SECRET}!_
 *
 * ---
 *
 * ### Zoordinates
 * Please add one of your allowed Zoordinates to the header `X-Zoordinates`.
 *
 * ---
 *
 * ### Request ID
 * Please add a value to the header `X-Request-ID` with a UUID4 value to be able to track
 * the request between services. 🙏🏼
 *
 * _Otherwise Heroku will take care of it and we'd loose control._
 *
 * ---
 *
 * ### Support
 * Look below 😅
 *
 * ---
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  application = {
    /**
     * No description
     *
     * @tags Application
     * @name QuickCreateApplicationApplicationQuickCreatePost
     * @summary Quick Create Application
     * @request POST:/application/quick-create
     * @secure
     */
    quickCreateApplicationApplicationQuickCreatePost: (
      data:
        | ({
            product: "mortgage";
          } & QuickCreateMortgageApplication)
        | ({
            product: "blanco";
          } & QuickCreateBlancoApplication),
      params: RequestParams = {},
    ) =>
      this.request<ApplicationCreatedResponse, HTTPValidationError>({
        path: `/application/quick-create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name CreateApplicationApplicationCreatePost
     * @summary Create Application
     * @request POST:/application/create
     * @secure
     */
    createApplicationApplicationCreatePost: (data: CreateApplicationPayloadExt, params: RequestParams = {}) =>
      this.request<ApplicationCreatedResponseExt, HTTPValidationError>({
        path: `/application/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name UpdateApplicationApplicationUpdateApplicationIdPost
     * @summary Update Application
     * @request POST:/application/update/{application_id}
     * @secure
     */
    updateApplicationApplicationUpdateApplicationIdPost: (
      applicationId: string,
      data: UpdateApplicationPayloadExt,
      params: RequestParams = {},
    ) =>
      this.request<ApplicationWithNamesInComments, HTTPValidationError>({
        path: `/application/update/${applicationId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name GetApplicationApplicationGetPost
     * @summary Get Application
     * @request POST:/application/get
     * @secure
     */
    getApplicationApplicationGetPost: (data: GetApplicationPayload, params: RequestParams = {}) =>
      this.request<ApplicationWithNamesInComments, HTTPValidationError>({
        path: `/application/get`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name GetApplicationsApplicationGetsPost
     * @summary Get Applications
     * @request POST:/application/gets
     * @deprecated
     * @secure
     */
    getApplicationsApplicationGetsPost: (
      data: GetApplicationsByCustomerIdentifierPayload,
      params: RequestParams = {},
    ) =>
      this.request<Application[], HTTPValidationError>({
        path: `/application/gets`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name GetApplicationsV2ApplicationV2GetsPost
     * @summary Get Applications V2
     * @request POST:/application/v2/gets
     * @secure
     */
    getApplicationsV2ApplicationV2GetsPost: (
      data: GetApplicationsByCustomerIdentifierPayloadV2,
      params: RequestParams = {},
    ) =>
      this.request<Application[], HTTPValidationError>({
        path: `/application/v2/gets`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name GetApplicationsAssignedToMeApplicationGetMinePost
     * @summary Get Applications Assigned To Me
     * @request POST:/application/get/mine
     * @secure
     */
    getApplicationsAssignedToMeApplicationGetMinePost: (
      data: GetMyApplicationsPayload,
      query?: {
        /**
         * Page
         * @min 0
         * @default 0
         */
        page?: number;
        /**
         * Limit
         * @min 0
         * @max 50
         * @default 50
         */
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Application[], HTTPValidationError>({
        path: `/application/get/mine`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name GetRecentApplicationsApplicationGetRecentPost
     * @summary Get Recent Applications
     * @request POST:/application/get/recent
     * @secure
     */
    getRecentApplicationsApplicationGetRecentPost: (
      data: GetRecentApplicationsPayload,
      query?: {
        /**
         * Page
         * @min 0
         * @default 0
         */
        page?: number;
        /**
         * Limit
         * @min 0
         * @max 50
         * @default 50
         */
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Application[], HTTPValidationError>({
        path: `/application/get/recent`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name SearchApplicationsApplicationSearchPost
     * @summary Search Applications
     * @request POST:/application/search
     * @secure
     */
    searchApplicationsApplicationSearchPost: (
      data: RoutersApplicationApplicationPayloadSearchPayload,
      query?: {
        /**
         * Page
         * @min 0
         * @default 0
         */
        page?: number;
        /**
         * Limit
         * @min 0
         * @max 50
         * @default 50
         */
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Application[], HTTPValidationError>({
        path: `/application/search`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name SearchApplications2ApplicationSearch2Post
     * @summary Search Applications2
     * @request POST:/application/search2
     * @secure
     */
    searchApplications2ApplicationSearch2Post: (
      data: RoutersApplicationApplicationPayloadSearchPayload,
      query?: {
        /**
         * Page
         * @min 0
         * @default 0
         */
        page?: number;
        /**
         * Limit
         * @min 0
         * @max 50
         * @default 50
         */
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<AppsSearchResult, HTTPValidationError>({
        path: `/application/search2`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name GetLeftToLiveApplicationLeftToLivePost
     * @summary Get Left To Live
     * @request POST:/application/left_to_live
     * @secure
     */
    getLeftToLiveApplicationLeftToLivePost: (data: LeftToLivePayload, params: RequestParams = {}) =>
      this.request<LeftToLiveResponse, HTTPValidationError>({
        path: `/application/left_to_live`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name DuplicateApplicationApplicationDuplicatePost
     * @summary Duplicate Application
     * @request POST:/application/duplicate
     * @secure
     */
    duplicateApplicationApplicationDuplicatePost: (data: ApplicationPayload, params: RequestParams = {}) =>
      this.request<ApplicationPayload, HTTPValidationError>({
        path: `/application/duplicate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name DeactivateApplicationApplicationDeactivateProductPost
     * @summary Deactivate Application
     * @request POST:/application/deactivate/{product}
     * @secure
     */
    deactivateApplicationApplicationDeactivateProductPost: (
      product: Product,
      data: MortgageDeactivatePayload | DeactivatePayload,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/application/deactivate/${product}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application
     * @name ReactivateApplicationReactivatePost
     * @summary Reactivate
     * @request POST:/application/reactivate
     * @secure
     */
    reactivateApplicationReactivatePost: (data: ReactivatePayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/application/reactivate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name GetApplicationApplicationCcNoGetPost
     * @summary Get Application
     * @request POST:/application/cc/no/get
     * @secure
     */
    getApplicationApplicationCcNoGetPost: (data: GetApplicationPayload, params: RequestParams = {}) =>
      this.request<CreditCardApplicationWithNamesInComments, HTTPValidationError>({
        path: `/application/cc/no/get`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name CreateApplicationApplicationCcNoCreatePost
     * @summary Create Application
     * @request POST:/application/cc/no/create
     * @secure
     */
    createApplicationApplicationCcNoCreatePost: (
      data: CreateCreditCardApplicationPayloadExt,
      params: RequestParams = {},
    ) =>
      this.request<ApplicationCreatedResponseExt, HTTPValidationError>({
        path: `/application/cc/no/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name UpdateApplicationApplicationCcNoUpdatePost
     * @summary Update Application
     * @request POST:/application/cc/no/update
     * @secure
     */
    updateApplicationApplicationCcNoUpdatePost: (
      data: UpdateCreditCardApplicationPayload,
      params: RequestParams = {},
    ) =>
      this.request<EmptyModel, HTTPValidationError>({
        path: `/application/cc/no/update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name UpdateAndGetApplicationApplicationCcNoUpdateAndGetPost
     * @summary Update And Get Application
     * @request POST:/application/cc/no/update-and-get
     * @secure
     */
    updateAndGetApplicationApplicationCcNoUpdateAndGetPost: (
      data: UpdateCreditCardApplicationPayload,
      params: RequestParams = {},
    ) =>
      this.request<CreditCardApplicationExtended, HTTPValidationError>({
        path: `/application/cc/no/update-and-get`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name CreateApplicationApplicantApplicationCcNoCreateWithApplicantPost
     * @summary Create Application Applicant
     * @request POST:/application/cc/no/create-with-applicant
     * @secure
     */
    createApplicationApplicantApplicationCcNoCreateWithApplicantPost: (
      data: CreateCreditCardApplicationApplicantPayloadExt,
      params: RequestParams = {},
    ) =>
      this.request<ApplicationCreatedResponseExt, HTTPValidationError>({
        path: `/application/cc/no/create-with-applicant`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name GetRecentApplicationsApplicationCcNoGetRecentPost
     * @summary Get Recent Applications
     * @request POST:/application/cc/no/get_recent
     * @secure
     */
    getRecentApplicationsApplicationCcNoGetRecentPost: (
      data: GetRecentPayload,
      query?: {
        /**
         * Page
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * @min 1
         * @max 100000
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageCreditCardApplicationExtended, HTTPValidationError>({
        path: `/application/cc/no/get_recent`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name GetApplicationBankEventsApplicationCcNoBankEventsPost
     * @summary Get Application Bank Events
     * @request POST:/application/cc/no/bank-events
     * @secure
     */
    getApplicationBankEventsApplicationCcNoBankEventsPost: (data: ApplicationQuery, params: RequestParams = {}) =>
      this.request<CreditCardBankEventsResponse, HTTPValidationError>({
        path: `/application/cc/no/bank-events`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name SearchApplicationsApplicationCcNoSearchApplicationsPost
     * @summary Search Applications
     * @request POST:/application/cc/no/search_applications
     * @secure
     */
    searchApplicationsApplicationCcNoSearchApplicationsPost: (
      data: GetApplicationsByApplicantIdentifierPayload,
      params: RequestParams = {},
    ) =>
      this.request<CreditCardApplicationExtended[], HTTPValidationError>({
        path: `/application/cc/no/search_applications`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditCard Application
     * @name AcceptOfferApplicationCcNoAcceptOfferPost
     * @summary Accept Offer
     * @request POST:/application/cc/no/accept_offer
     * @secure
     */
    acceptOfferApplicationCcNoAcceptOfferPost: (data: AcceptOffer, params: RequestParams = {}) =>
      this.request<CreditorOfferCreditCardOfferCreditCardTerms, HTTPValidationError>({
        path: `/application/cc/no/accept_offer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mortgage SE Application
     * @name GetApplicationBankEventsApplicationMoSeBankEventsPost
     * @summary Get Application Bank Events
     * @request POST:/application/mo/se/bank-events
     * @secure
     */
    getApplicationBankEventsApplicationMoSeBankEventsPost: (data: ApplicationQuery, params: RequestParams = {}) =>
      this.request<MortgageBankEventsResponse, HTTPValidationError>({
        path: `/application/mo/se/bank-events`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Communication
     * @name InviteApplicationApplicationInvitePost
     * @summary Invite Application
     * @request POST:/application/invite
     * @secure
     */
    inviteApplicationApplicationInvitePost: (data: InvitePayloadBase, params: RequestParams = {}) =>
      this.request<EmptyModel, HTTPValidationError>({
        path: `/application/invite`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Communication
     * @name InviteApplicationIdApplicationInviteApplicationIdPost
     * @summary Invite Application Id
     * @request POST:/application/invite/{application_id}
     * @secure
     */
    inviteApplicationIdApplicationInviteApplicationIdPost: (
      applicationId: string,
      data: InvitePayloadBase,
      params: RequestParams = {},
    ) =>
      this.request<EmptyModel, HTTPValidationError>({
        path: `/application/invite/${applicationId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Communication
     * @name GetAppEventsApplicationGetAppEventsApplicationIdGet
     * @summary Get App Events
     * @request GET:/application/get_app_events/{application_id}
     * @secure
     */
    getAppEventsApplicationGetAppEventsApplicationIdGet: (applicationId: string, params: RequestParams = {}) =>
      this.request<EventsResponse, HTTPValidationError>({
        path: `/application/get_app_events/${applicationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Communication
     * @name GetAppEventsOldApplicationGetAppEventsPost
     * @summary Get App Events Old
     * @request POST:/application/get_app_events
     * @secure
     */
    getAppEventsOldApplicationGetAppEventsPost: (params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, any>({
        path: `/application/get_app_events`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Communication
     * @name SendCommentToIntegrationApplicationCommentToBankPost
     * @summary Send Comment To Integration
     * @request POST:/application/comment_to_bank
     * @secure
     */
    sendCommentToIntegrationApplicationCommentToBankPost: (data: BaseComment, params: RequestParams = {}) =>
      this.request<EventsResponse, HTTPValidationError>({
        path: `/application/comment_to_bank`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Communication
     * @name AcceptOfferApplicationAcceptOfferPost
     * @summary Accept Offer
     * @request POST:/application/accept_offer
     * @secure
     */
    acceptOfferApplicationAcceptOfferPost: (data: AcceptOffer, params: RequestParams = {}) =>
      this.request<EventsResponse, HTTPValidationError>({
        path: `/application/accept_offer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Communication
     * @name CancelApplicationCancelPost
     * @summary Cancel
     * @request POST:/application/cancel
     * @secure
     */
    cancelApplicationCancelPost: (data: CancelOfferPayload, params: RequestParams = {}) =>
      this.request<EventsResponse, HTTPValidationError>({
        path: `/application/cancel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Communication
     * @name SendDocumentToIntegrationApplicationDocumentToBankPost
     * @summary Send Document To Integration
     * @request POST:/application/document_to_bank
     * @secure
     */
    sendDocumentToIntegrationApplicationDocumentToBankPost: (data: DocumentToBank, params: RequestParams = {}) =>
      this.request<EventsResponse, HTTPValidationError>({
        path: `/application/document_to_bank`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application Meta
     * @name AssignApplicationToHandlerApplicationAssignApplicationIdPost
     * @summary Assign Application To Handler
     * @request POST:/application/assign/{application_id}
     * @secure
     */
    assignApplicationToHandlerApplicationAssignApplicationIdPost: (
      applicationId: string,
      data: AssignApplicationPayload,
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/application/assign/${applicationId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application Meta
     * @name ListInternalCommentsApplicationCommentApplicationIdGet
     * @summary List Internal Comments
     * @request GET:/application/comment/{application_id}
     * @secure
     */
    listInternalCommentsApplicationCommentApplicationIdGet: (applicationId: string, params: RequestParams = {}) =>
      this.request<(BankIDSignedWithNames | InternalCommentWithName)[], HTTPValidationError>({
        path: `/application/comment/${applicationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application Meta
     * @name AddInternalCommentApplicationCommentApplicationIdPost
     * @summary Add Internal Comment
     * @request POST:/application/comment/{application_id}
     * @secure
     */
    addInternalCommentApplicationCommentApplicationIdPost: (
      applicationId: string,
      data: CommentPayload,
      params: RequestParams = {},
    ) =>
      this.request<(BankIDSignedWithNames | InternalCommentWithName)[], HTTPValidationError>({
        path: `/application/comment/${applicationId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application Meta
     * @name GetPossibleStatusesApplicationPrefsGet
     * @summary Get Possible Statuses
     * @request GET:/application/prefs
     * @secure
     */
    getPossibleStatusesApplicationPrefsGet: (params: RequestParams = {}) =>
      this.request<PrefsResponse, any>({
        path: `/application/prefs`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application Meta
     * @name GetPossibleLoanTypesApplicationLoanTypesCountryGet
     * @summary Get Possible Loan Types
     * @request GET:/application/loan-types/{country}
     * @secure
     */
    getPossibleLoanTypesApplicationLoanTypesCountryGet: (country: Country, params: RequestParams = {}) =>
      this.request<string[], HTTPValidationError>({
        path: `/application/loan-types/${country}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Application Meta
     * @name SearchBlancoSverkerApplicationBlancoSearchCountryGet
     * @summary Search Blanco Sverker
     * @request GET:/application/blanco/search/{country}
     * @secure
     */
    searchBlancoSverkerApplicationBlancoSearchCountryGet: (
      country: Country,
      query: {
        /** S */
        s: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SverkerSearchResponse, HTTPValidationError>({
        path: `/application/blanco/search/${country}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AML
     * @name AmlReviewApplicationAmlReviewPost
     * @summary Aml Review
     * @request POST:/application/aml/review
     * @secure
     */
    amlReviewApplicationAmlReviewPost: (data: AMLReviewPayload, params: RequestParams = {}) =>
      this.request<ApplicationWithNamesInComments, HTTPValidationError>({
        path: `/application/aml/review`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AML
     * @name GetBlancoAmlApplicationBlancoAmlApplicationIdGet
     * @summary Get Blanco Aml
     * @request GET:/application/blanco/aml/{application_id}
     * @secure
     */
    getBlancoAmlApplicationBlancoAmlApplicationIdGet: (
      applicationId: number,
      query: {
        country: Country;
      },
      params: RequestParams = {},
    ) =>
      this.request<AMLBlancoResponse, HTTPValidationError>({
        path: `/application/blanco/aml/${applicationId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AML
     * @name PatchBlancoAmlApplicationBlancoAmlApplicationIdPatch
     * @summary Patch Blanco Aml
     * @request PATCH:/application/blanco/aml/{application_id}
     * @secure
     */
    patchBlancoAmlApplicationBlancoAmlApplicationIdPatch: (
      applicationId: number,
      data: BlancoAMLPatchPayload,
      params: RequestParams = {},
    ) =>
      this.request<AMLBlancoResponse, HTTPValidationError>({
        path: `/application/blanco/aml/${applicationId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AML
     * @name ReviewBlancoAmlApplicationBlancoAmlReviewApplicationIdPatch
     * @summary Review Blanco Aml
     * @request PATCH:/application/blanco/aml/review/{application_id}
     * @secure
     */
    reviewBlancoAmlApplicationBlancoAmlReviewApplicationIdPatch: (
      applicationId: number,
      data: BlancoAMLReviewPayload,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/application/blanco/aml/review/${applicationId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AML
     * @name MortgageDeactivationReviewsApplicationDeactivationReviewsMortgagePost
     * @summary Mortgage Deactivation Reviews
     * @request POST:/application/deactivation_reviews/mortgage
     * @secure
     */
    mortgageDeactivationReviewsApplicationDeactivationReviewsMortgagePost: (
      data: DeactivationReviewsPayload,
      params: RequestParams = {},
    ) =>
      this.request<MortgageDeactivationReviewsResponse, HTTPValidationError>({
        path: `/application/deactivation_reviews/mortgage`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AML
     * @name BlancoDeactivationReviewsApplicationDeactivationReviewsBlancoPost
     * @summary Blanco Deactivation Reviews
     * @request POST:/application/deactivation_reviews/blanco
     * @secure
     */
    blancoDeactivationReviewsApplicationDeactivationReviewsBlancoPost: (
      data: DeactivationReviewsPayload,
      params: RequestParams = {},
    ) =>
      this.request<DeactivationReviewsResponse, HTTPValidationError>({
        path: `/application/deactivation_reviews/blanco`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrator
     * @name MigrateSverkerApplicationApplicationMigratorAppLabelPost
     * @summary Migrate Sverker Application
     * @request POST:/application/migrator/{app_label}
     * @secure
     */
    migrateSverkerApplicationApplicationMigratorAppLabelPost: (
      appLabel: string,
      data: ZrmMigratorEvents,
      params: RequestParams = {},
    ) =>
      this.request<ZrmMigrationStats, HTTPValidationError>({
        path: `/application/migrator/${appLabel}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  customer = {
    /**
     * No description
     *
     * @tags Customer
     * @name GetCustomerCustomerGetPost
     * @summary Get Customer
     * @request POST:/customer/get
     * @secure
     */
    getCustomerCustomerGetPost: (
      data:
        | PniGetCustomerPayload
        | EmailGetCustomerPayload
        | PhoneGetCustomerPayload
        | CustomerIDGetCustomerPayload
        | CustomerIDsGetCustomerPayload,
      params: RequestParams = {},
    ) =>
      this.request<
        | ({
            country: "SE";
          } & ApplicantSE)
        | ({
            country: "NO";
          } & ApplicantNO),
        HTTPValidationError
      >({
        path: `/customer/get`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name GetCustomersCustomerGetsPost
     * @summary Get Customers
     * @request POST:/customer/gets
     * @secure
     */
    getCustomersCustomerGetsPost: (data: CustomerIDsGetCustomerPayload, params: RequestParams = {}) =>
      this.request<
        (
          | ({
              country: "SE";
            } & ApplicantSE)
          | ({
              country: "NO";
            } & ApplicantNO)
        )[],
        HTTPValidationError
      >({
        path: `/customer/gets`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CreateCustomerCustomerCreatePost
     * @summary Create Customer
     * @request POST:/customer/create
     * @secure
     */
    createCustomerCustomerCreatePost: (data: CreateCustomerPayload, params: RequestParams = {}) =>
      this.request<
        | ({
            country: "SE";
          } & ApplicantSE)
        | ({
            country: "NO";
          } & ApplicantNO),
        HTTPValidationError
      >({
        path: `/customer/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name UpdateCustomerCustomerUpdatePost
     * @summary Update Customer
     * @request POST:/customer/update
     * @secure
     */
    updateCustomerCustomerUpdatePost: (data: UpdateCustomerPayload, params: RequestParams = {}) =>
      this.request<
        | ({
            country: "SE";
          } & ApplicantSE)
        | ({
            country: "NO";
          } & ApplicantNO),
        HTTPValidationError
      >({
        path: `/customer/update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name UpdateConsentsCustomerUpdateConsentsPost
     * @summary Update Consents
     * @request POST:/customer/update_consents
     * @secure
     */
    updateConsentsCustomerUpdateConsentsPost: (data: UpdateCustomerConsentsPayload, params: RequestParams = {}) =>
      this.request<
        | ({
            country: "SE";
          } & ApplicantSE)
        | ({
            country: "NO";
          } & ApplicantNO),
        HTTPValidationError
      >({
        path: `/customer/update_consents`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name IdCheckOrGetCustomerCustomerIdCheckPost
     * @summary Id Check Or Get Customer
     * @request POST:/customer/id-check
     * @secure
     */
    idCheckOrGetCustomerCustomerIdCheckPost: (data: IDCheckGetCustomerPayload, params: RequestParams = {}) =>
      this.request<
        | (
            | ({
                country: "SE";
              } & ApplicantSE)
            | ({
                country: "NO";
              } & ApplicantNO)
          )
        | IDCheckedPerson
        | null,
        HTTPValidationError
      >({
        path: `/customer/id-check`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name TakeCreditCheckCustomerCreditCheckPost
     * @summary Take Credit Check
     * @request POST:/customer/credit-check
     * @secure
     */
    takeCreditCheckCustomerCreditCheckPost: (data: TakeCreditCheck, params: RequestParams = {}) =>
      this.request<
        | ({
            country: "SE";
          } & ApplicantSE)
        | ({
            country: "NO";
          } & ApplicantNO),
        HTTPValidationError
      >({
        path: `/customer/credit-check`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name GetNameByPniCustomerNameByPniPost
     * @summary Get Name By Pni
     * @request POST:/customer/name/by-pni
     * @secure
     */
    getNameByPniCustomerNameByPniPost: (data: GetNameByPNIPayload, params: RequestParams = {}) =>
      this.request<Name | null, HTTPValidationError>({
        path: `/customer/name/by-pni`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name GetNameCustomerNameCustomerIdGet
     * @summary Get Name
     * @request GET:/customer/name/{customer_id}
     * @secure
     */
    getNameCustomerNameCustomerIdGet: (customerId: string, params: RequestParams = {}) =>
      this.request<Name | null, HTTPValidationError>({
        path: `/customer/name/${customerId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, Application Meta
     * @name EmploymentTypesCustomerEmploymentTypesCountryGet
     * @summary Employment Types
     * @request GET:/customer/employment-types/{country}
     * @secure
     */
    employmentTypesCustomerEmploymentTypesCountryGet: (country: Country, params: RequestParams = {}) =>
      this.request<string[], HTTPValidationError>({
        path: `/customer/employment-types/${country}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer, Application Meta
     * @name HousingTypesCustomerHousingTypesCountryGet
     * @summary Housing Types
     * @request GET:/customer/housing-types/{country}
     * @secure
     */
    housingTypesCustomerHousingTypesCountryGet: (country: Country, params: RequestParams = {}) =>
      this.request<string[], HTTPValidationError>({
        path: `/customer/housing-types/${country}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name GetExternallyVerifiedCreditsCustomerExternallyVerifiedCreditsPost
     * @summary Get Externally Verified Credits
     * @request POST:/customer/externally-verified-credits
     * @secure
     */
    getExternallyVerifiedCreditsCustomerExternallyVerifiedCreditsPost: (
      data: MinimalApplicant[],
      params: RequestParams = {},
    ) =>
      this.request<LoanAndExternallyVerifiedCredit, HTTPValidationError>({
        path: `/customer/externally-verified-credits`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  meeting = {
    /**
     * No description
     *
     * @tags Meeting
     * @name GetMeetingMeetingGetMeetingIdGet
     * @summary Get Meeting
     * @request GET:/meeting/get/{meeting_id}
     * @secure
     */
    getMeetingMeetingGetMeetingIdGet: (meetingId: string, params: RequestParams = {}) =>
      this.request<ModelsMeetingModelsMeeting, HTTPValidationError>({
        path: `/meeting/get/${meetingId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Meeting
     * @name GetMeetingsMeetingGetPost
     * @summary Get Meetings
     * @request POST:/meeting/get
     * @secure
     */
    getMeetingsMeetingGetPost: (data: GetMeetings, params: RequestParams = {}) =>
      this.request<MeetingWithApp[] | MeetingWithHandlerName[] | ModelsMeetingModelsMeeting[], HTTPValidationError>({
        path: `/meeting/get`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Meeting
     * @name GetMyMeetingsMeetingMinePost
     * @summary Get My Meetings
     * @request POST:/meeting/mine
     * @secure
     */
    getMyMeetingsMeetingMinePost: (data: GetMyMeetings, params: RequestParams = {}) =>
      this.request<(MeetingWithApp | MeetingWithHandlerName | ModelsMeetingModelsMeeting)[], HTTPValidationError>({
        path: `/meeting/mine`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Meeting
     * @name CreateMeetingMeetingCreatePost
     * @summary Create Meeting
     * @request POST:/meeting/create
     * @secure
     */
    createMeetingMeetingCreatePost: (data: CreateMeetingPayload, params: RequestParams = {}) =>
      this.request<ModelsMeetingModelsMeeting, HTTPValidationError>({
        path: `/meeting/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Meeting
     * @name UpdateMeetingMeetingUpdateMeetingIdPost
     * @summary Update Meeting
     * @request POST:/meeting/update/{meeting_id}
     * @secure
     */
    updateMeetingMeetingUpdateMeetingIdPost: (
      meetingId: string,
      data: UpdateMeetingPayload,
      params: RequestParams = {},
    ) =>
      this.request<ModelsMeetingModelsMeeting, HTTPValidationError>({
        path: `/meeting/update/${meetingId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Meeting
     * @name CancelMeetingsMeetingCancelMeetingIdDelete
     * @summary Cancel Meetings
     * @request DELETE:/meeting/cancel/{meeting_id}
     * @secure
     */
    cancelMeetingsMeetingCancelMeetingIdDelete: (meetingId: string, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/meeting/cancel/${meetingId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Meeting
     * @name GetBookableTimesMeetingAvailableDayGet
     * @summary Get Bookable Times
     * @request GET:/meeting/available/{day}
     * @secure
     */
    getBookableTimesMeetingAvailableDayGet: (day: string, params: RequestParams = {}) =>
      this.request<BookableSlot[], HTTPValidationError>({
        path: `/meeting/available/${day}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  document = {
    /**
     * No description
     *
     * @tags Document
     * @name GetDocumentMetaDocumentGetMetaCustomerIdDocumentIdGet
     * @summary Get Document Meta
     * @request GET:/document/get/meta/{customer_id}/{document_id}
     * @secure
     */
    getDocumentMetaDocumentGetMetaCustomerIdDocumentIdGet: (
      customerId: string,
      documentId: string,
      params: RequestParams = {},
    ) =>
      this.request<DocumentMeta, HTTPValidationError>({
        path: `/document/get/meta/${customerId}/${documentId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name GetDocumentDocumentGetCustomerIdDocumentIdGet
     * @summary Get Document
     * @request GET:/document/get/{customer_id}/{document_id}
     * @secure
     */
    getDocumentDocumentGetCustomerIdDocumentIdGet: (
      customerId: string,
      documentId: string,
      params: RequestParams = {},
    ) =>
      this.request<CustomerDocument, HTTPValidationError>({
        path: `/document/get/${customerId}/${documentId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name CreateDocumentDocumentSavePost
     * @summary Create Document
     * @request POST:/document/save
     * @secure
     */
    createDocumentDocumentSavePost: (data: DocumentCreate, params: RequestParams = {}) =>
      this.request<CustomerDocument, HTTPValidationError>({
        path: `/document/save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name DocumentTypesDocumentTypesGet
     * @summary Document Types
     * @request GET:/document/types
     * @secure
     */
    documentTypesDocumentTypesGet: (params: RequestParams = {}) =>
      this.request<DocumentType[], any>({
        path: `/document/types`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name GetAttachedDocumentsOfApplicationDocumentAttachedApplicationIdGet
     * @summary Get Attached Documents Of Application
     * @request GET:/document/attached/{application_id}
     * @secure
     */
    getAttachedDocumentsOfApplicationDocumentAttachedApplicationIdGet: (
      applicationId: string,
      params: RequestParams = {},
    ) =>
      this.request<DocumentAssignation[], HTTPValidationError>({
        path: `/document/attached/${applicationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name AttachDocumentToApplicationDocumentAttachApplicationIdPost
     * @summary Attach Document To Application
     * @request POST:/document/attach/{application_id}
     * @secure
     */
    attachDocumentToApplicationDocumentAttachApplicationIdPost: (
      applicationId: string,
      data: DocumentAssignation,
      params: RequestParams = {},
    ) =>
      this.request<DocumentAssignation[], HTTPValidationError>({
        path: `/document/attach/${applicationId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  banks = {
    /**
     * No description
     *
     * @tags Banks
     * @name GetAllBanksBanksAllGet
     * @summary Get All Banks
     * @request GET:/banks/all
     * @secure
     */
    getAllBanksBanksAllGet: (params: RequestParams = {}) =>
      this.request<Bank[], any>({
        path: `/banks/all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Banks
     * @name GetBanksBanksGetPost
     * @summary Get Banks
     * @request POST:/banks/get
     * @secure
     */
    getBanksBanksGetPost: (data: GetBanksPayload, params: RequestParams = {}) =>
      this.request<Bank[], HTTPValidationError>({
        path: `/banks/get`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Banks
     * @name GetAllIntegrationsBanksAllIntegrationsGet
     * @summary Get All Integrations
     * @request GET:/banks/all_integrations
     * @secure
     */
    getAllIntegrationsBanksAllIntegrationsGet: (params: RequestParams = {}) =>
      this.request<Integration[], any>({
        path: `/banks/all_integrations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Banks
     * @name GetIntegrationsBanksGetIntegrationsPost
     * @summary Get Integrations
     * @request POST:/banks/get_integrations
     * @secure
     */
    getIntegrationsBanksGetIntegrationsPost: (data: IntegrationQuery, params: RequestParams = {}) =>
      this.request<Integration[], HTTPValidationError>({
        path: `/banks/get_integrations`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  schedule = {
    /**
     * No description
     *
     * @tags Advisor Schedule
     * @name ScheduleDaySchedulePost
     * @summary Schedule Day
     * @request POST:/schedule/
     * @secure
     */
    scheduleDaySchedulePost: (data: DaySlotDocPayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/schedule/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advisor Schedule
     * @name GetScheduleOfDayScheduleDayGet
     * @summary Get Schedule Of Day
     * @request GET:/schedule/{day}
     * @secure
     */
    getScheduleOfDayScheduleDayGet: (day: string, params: RequestParams = {}) =>
      this.request<DaySlotDoc, HTTPValidationError>({
        path: `/schedule/${day}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advisor Schedule
     * @name GetScheduleOfDayByCountryAndProductScheduleDayProductCountryGet
     * @summary Get Schedule Of Day By Country And Product
     * @request GET:/schedule/{day}/{product}/{country}
     * @secure
     */
    getScheduleOfDayByCountryAndProductScheduleDayProductCountryGet: (
      day: string,
      product: Product,
      country: Country,
      params: RequestParams = {},
    ) =>
      this.request<DaySlotDoc, HTTPValidationError>({
        path: `/schedule/${day}/${product}/${country}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  employee = {
    /**
     * No description
     *
     * @tags Employee
     * @name GetAllEmployeesEmployeeAllGet
     * @summary Get All Employees
     * @request GET:/employee/all
     * @secure
     */
    getAllEmployeesEmployeeAllGet: (
      query?: {
        /** Page */
        page?: number | null;
        /** Limit */
        limit?: number | null;
        /**
         * Without Deactivated
         * @default false
         */
        without_deactivated?: boolean | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<Employee[], HTTPValidationError>({
        path: `/employee/all`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name GetSverkerUsersEmployeeGetSverkerUsersGet
     * @summary Get Sverker Users
     * @request GET:/employee/get_sverker_users
     * @secure
     */
    getSverkerUsersEmployeeGetSverkerUsersGet: (
      query: {
        /** User Name */
        user_name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string[], HTTPValidationError>({
        path: `/employee/get_sverker_users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name GetEmployeesByZoordinatesEmployeeByZoordinatesPost
     * @summary Get Employees By Zoordinates
     * @request POST:/employee/by_zoordinates
     * @secure
     */
    getEmployeesByZoordinatesEmployeeByZoordinatesPost: (data: AllOptionalZoordinates, params: RequestParams = {}) =>
      this.request<Employee[], HTTPValidationError>({
        path: `/employee/by_zoordinates`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name GetMyInfoEmployeeMeGet
     * @summary Get My Info
     * @request GET:/employee/me
     * @secure
     */
    getMyInfoEmployeeMeGet: (params: RequestParams = {}) =>
      this.request<Employee, any>({
        path: `/employee/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name DeactivateUserByIdEmployeeActionsBlockOrUnblockUserIdPatch
     * @summary Deactivate User By Id
     * @request PATCH:/employee/actions/block-or-unblock/{user_id}
     * @secure
     */
    deactivateUserByIdEmployeeActionsBlockOrUnblockUserIdPatch: (
      userId: string,
      query?: {
        /**
         * Block
         * @default true
         */
        block?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/employee/actions/block-or-unblock/${userId}`,
        method: "PATCH",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name GetAllZoordinatesEmployeeZoordinatesGet
     * @summary Get All Zoordinates
     * @request GET:/employee/zoordinates
     * @secure
     */
    getAllZoordinatesEmployeeZoordinatesGet: (params: RequestParams = {}) =>
      this.request<Zoordinates[], any>({
        path: `/employee/zoordinates`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name GetAllRolesEmployeeRolesGet
     * @summary Get All Roles
     * @request GET:/employee/roles
     * @secure
     */
    getAllRolesEmployeeRolesGet: (params: RequestParams = {}) =>
      this.request<RoleType[], any>({
        path: `/employee/roles`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name GetMySettingsEmployeeMySettingsGet
     * @summary Get My Settings
     * @request GET:/employee/my-settings
     * @secure
     */
    getMySettingsEmployeeMySettingsGet: (params: RequestParams = {}) =>
      this.request<UserSettings, any>({
        path: `/employee/my-settings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name SaveMySettingsEmployeeMySettingsPost
     * @summary Save My Settings
     * @request POST:/employee/my-settings
     * @secure
     */
    saveMySettingsEmployeeMySettingsPost: (data: UserSettings, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/employee/my-settings`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name CreateEmployeeEmployeePost
     * @summary Create Employee
     * @request POST:/employee/
     * @secure
     */
    createEmployeeEmployeePost: (data: CreateAndUpdateEmployeeRequest, params: RequestParams = {}) =>
      this.request<Employee, HTTPValidationError>({
        path: `/employee/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name UpdateEmployeeEmployeeUserIdPut
     * @summary Update Employee
     * @request PUT:/employee/{user_id}
     * @secure
     */
    updateEmployeeEmployeeUserIdPut: (
      userId: string,
      data: CreateAndUpdateEmployeeRequest,
      params: RequestParams = {},
    ) =>
      this.request<Employee, HTTPValidationError>({
        path: `/employee/${userId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name GetByIdEmployeeUserIdGet
     * @summary Get By Id
     * @request GET:/employee/{user_id}
     * @secure
     */
    getByIdEmployeeUserIdGet: (userId: string, params: RequestParams = {}) =>
      this.request<Employee, HTTPValidationError>({
        path: `/employee/${userId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Employee
     * @name SearchEmployeeSearchPost
     * @summary Search
     * @request POST:/employee/search
     * @secure
     */
    searchEmployeeSearchPost: (data: RoutersEmployeeModelsSearchPayload, params: RequestParams = {}) =>
      this.request<Employee[], HTTPValidationError>({
        path: `/employee/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  property = {
    /**
     * No description
     *
     * @tags Property
     * @name GetPropertyPropertyGetPropertyIdGet
     * @summary Get Property
     * @request GET:/property/get/{property_id}
     * @secure
     */
    getPropertyPropertyGetPropertyIdGet: (propertyId: string, params: RequestParams = {}) =>
      this.request<House | VacationHome | Condominium, HTTPValidationError>({
        path: `/property/get/${propertyId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name GetPartialPropertyPropertyPartialGetPropertyIdGet
     * @summary Get Partial Property
     * @request GET:/property/partial/get/{property_id}
     * @secure
     */
    getPartialPropertyPropertyPartialGetPropertyIdGet: (propertyId: string, params: RequestParams = {}) =>
      this.request<PartialProperty, HTTPValidationError>({
        path: `/property/partial/get/${propertyId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name CreatePropertyPropertyCreateApplicationIdPost
     * @summary Create Property
     * @request POST:/property/create/{application_id}
     * @secure
     */
    createPropertyPropertyCreateApplicationIdPost: (
      applicationId: string,
      data: PartialHouse | PartialCondominium | PartialVacationHome,
      params: RequestParams = {},
    ) =>
      this.request<House | VacationHome | Condominium, HTTPValidationError>({
        path: `/property/create/${applicationId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name CreatePartialPropertyPropertyPartialCreateApplicationIdPost
     * @summary Create Partial Property
     * @request POST:/property/partial/create/{application_id}
     * @secure
     */
    createPartialPropertyPropertyPartialCreateApplicationIdPost: (
      applicationId: string,
      data: NewPartialProperty,
      params: RequestParams = {},
    ) =>
      this.request<PartialProperty, HTTPValidationError>({
        path: `/property/partial/create/${applicationId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name UpdatePropertyPropertyUpdatePropertyIdPut
     * @summary Update Property
     * @request PUT:/property/update/{property_id}
     * @secure
     */
    updatePropertyPropertyUpdatePropertyIdPut: (
      propertyId: string,
      data: PartialHouse | PartialCondominium | PartialVacationHome,
      params: RequestParams = {},
    ) =>
      this.request<House | VacationHome | Condominium, HTTPValidationError>({
        path: `/property/update/${propertyId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name UpdatePartialPropertyPropertyPartialUpdatePropertyIdPut
     * @summary Update Partial Property
     * @request PUT:/property/partial/update/{property_id}
     * @secure
     */
    updatePartialPropertyPropertyPartialUpdatePropertyIdPut: (
      propertyId: string,
      data: NewPartialProperty,
      params: RequestParams = {},
    ) =>
      this.request<PartialProperty, HTTPValidationError>({
        path: `/property/partial/update/${propertyId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Valuation
     * @name SearchPropertyValuationSearchPropertyIdPost
     * @summary Search
     * @request POST:/property/valuation/search/{property_id}
     * @secure
     */
    searchPropertyValuationSearchPropertyIdPost: (
      propertyId: string,
      data: RoutersPropertyPayloadSearchPayload,
      params: RequestParams = {},
    ) =>
      this.request<PropertySearchResult[], HTTPValidationError>({
        path: `/property/valuation/search/${propertyId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Creation
     * @name SearchForAddressPropertyV2SearchPropertyTypePost
     * @summary Search For Address
     * @request POST:/property/v2/search/{property_type}
     * @secure
     */
    searchForAddressPropertyV2SearchPropertyTypePost: (
      propertyType: MortgagePropertyType,
      data: RoutersPropertyPayloadSearchPayload,
      params: RequestParams = {},
    ) =>
      this.request<PropertySearchResult[], HTTPValidationError>({
        path: `/property/v2/search/${propertyType}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Creation
     * @name GetPropertyInfoForCreationPropertyV2InfoSearchIdGet
     * @summary Get Property Info For Creation
     * @request GET:/property/v2/info/{search_id}
     * @secure
     */
    getPropertyInfoForCreationPropertyV2InfoSearchIdGet: (searchId: string, params: RequestParams = {}) =>
      this.request<PropertyInfo, HTTPValidationError>({
        path: `/property/v2/info/${searchId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Creation
     * @name GetPropertyInfoByExternalIdPropertyV2InfoByExternalIdExternalIdGet
     * @summary Get Property Info By External Id
     * @request GET:/property/v2/info-by-external-id/{external_id}
     * @secure
     */
    getPropertyInfoByExternalIdPropertyV2InfoByExternalIdExternalIdGet: (
      externalId: string,
      params: RequestParams = {},
    ) =>
      this.request<PropertyInfo, HTTPValidationError>({
        path: `/property/v2/info-by-external-id/${externalId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Valuation
     * @name GetPropertyValuationV2PropertyValuationV2Post
     * @summary Get Property Valuation V2
     * @request POST:/property/valuation/v2
     * @secure
     */
    getPropertyValuationV2PropertyValuationV2Post: (data: ValuationPayloadV2, params: RequestParams = {}) =>
      this.request<PropertyValuation, HTTPValidationError>({
        path: `/property/valuation/v2`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Valuation
     * @name GetPropertyInfoPropertyValuationInfoSearchIdGet
     * @summary Get Property Info
     * @request GET:/property/valuation/info/{search_id}
     * @secure
     */
    getPropertyInfoPropertyValuationInfoSearchIdGet: (searchId: string, params: RequestParams = {}) =>
      this.request<PropertyInfo, HTTPValidationError>({
        path: `/property/valuation/info/${searchId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Valuation
     * @name GetPropertyValuationPropertyValuationValuationPost
     * @summary Get Property Valuation
     * @request POST:/property/valuation/valuation
     * @secure
     */
    getPropertyValuationPropertyValuationValuationPost: (data: ValuationPayload, params: RequestParams = {}) =>
      this.request<PropertyValuation, HTTPValidationError>({
        path: `/property/valuation/valuation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Creation
     * @name BooliSearchForAddressPropertyV2BooliSearchPost
     * @summary Booli Search For Address
     * @request POST:/property/v2/booli/search
     * @deprecated
     * @secure
     */
    booliSearchForAddressPropertyV2BooliSearchPost: (data: BooliSearchPayload, params: RequestParams = {}) =>
      this.request<BooliPropertySearchResult[], HTTPValidationError>({
        path: `/property/v2/booli/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property Valuation
     * @name GetPropertyBooliValuationPropertyValuationBooliPost
     * @summary Get Property Booli Valuation
     * @request POST:/property/valuation/booli
     * @deprecated
     * @secure
     */
    getPropertyBooliValuationPropertyValuationBooliPost: (data: BooliValuationPayload, params: RequestParams = {}) =>
      this.request<PropertyValuation, HTTPValidationError>({
        path: `/property/valuation/booli`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetPropertyByPniPropertyEvcheckCachePost
     * @summary Get Property By Pni
     * @request POST:/property/evcheck/cache
     * @secure
     */
    getPropertyByPniPropertyEvcheckCachePost: (data: EvCheckPayload, params: RequestParams = {}) =>
      this.request<(PartialHouse | PartialCondominium)[], HTTPValidationError>({
        path: `/property/evcheck/cache`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  message = {
    /**
     * No description
     *
     * @tags Message
     * @name RemoveTemplateMessageTemplateDelete
     * @summary Remove Template
     * @request DELETE:/message/template/
     * @secure
     */
    removeTemplateMessageTemplateDelete: (data: GetTemplatePayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/message/template/`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name GetOneTemplateMessageTemplateGetPost
     * @summary Get One Template
     * @request POST:/message/template/get
     * @secure
     */
    getOneTemplateMessageTemplateGetPost: (data: GetTemplatePayload, params: RequestParams = {}) =>
      this.request<RootModelUnionEmailTemplateSmsTemplate, HTTPValidationError>({
        path: `/message/template/get`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name CreateTemplateMessageTemplateAddPost
     * @summary Create Template
     * @request POST:/message/template/add
     * @secure
     */
    createTemplateMessageTemplateAddPost: (data: RootModelUnionEmailTemplateSmsTemplate, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/message/template/add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name TemplateListMessageTemplateListMessageTypeGet
     * @summary Template List
     * @request GET:/message/template/list/{message_type}
     * @secure
     */
    templateListMessageTemplateListMessageTypeGet: (
      messageType: MessageType,
      query?: {
        /** Cutoff Date */
        cutoff_date?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<(EmailTemplate | SmsTemplate)[], HTTPValidationError>({
        path: `/message/template/list/${messageType}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name SendTemplateMessageMessageSendTemplateMessageTypeTemplateEventPost
     * @summary Send Template Message
     * @request POST:/message/send/template/{message_type}/{template_event}
     * @secure
     */
    sendTemplateMessageMessageSendTemplateMessageTypeTemplateEventPost: (
      messageType: MessageType,
      templateEvent: TemplateEvent,
      data: SendTemplatePayload,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/message/send/template/${messageType}/${templateEvent}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name SendRawMessageMessageSendRawMessageTypePost
     * @summary Send Raw Message
     * @request POST:/message/send/raw/{message_type}
     * @secure
     */
    sendRawMessageMessageSendRawMessageTypePost: (
      messageType: MessageType,
      data: SendRawPayload,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/message/send/raw/${messageType}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name GetUnknownSenderSmsMessageSmsUnknownSenderPost
     * @summary Get Unknown Sender Sms
     * @request POST:/message/sms/unknown-sender
     * @secure
     */
    getUnknownSenderSmsMessageSmsUnknownSenderPost: (data: GetMessagesPayload, params: RequestParams = {}) =>
      this.request<IncomingSMS[], HTTPValidationError>({
        path: `/message/sms/unknown-sender`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name GetMessagesForUserMessageUserPost
     * @summary Get Messages For User
     * @request POST:/message/user
     * @secure
     */
    getMessagesForUserMessageUserPost: (data: GetUserMessagesPayload, params: RequestParams = {}) =>
      this.request<SavedMessage[], HTTPValidationError>({
        path: `/message/user`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name GetMessagesForApplicationMessageApplicationPost
     * @summary Get Messages For Application
     * @request POST:/message/application
     * @secure
     */
    getMessagesForApplicationMessageApplicationPost: (
      data: GetApplicationMessagesPayload,
      params: RequestParams = {},
    ) =>
      this.request<SavedMessage[], HTTPValidationError>({
        path: `/message/application`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name GetMessagesByCustomerIdMessageByCustomerPost
     * @summary Get Messages By Customer Id
     * @request POST:/message/by-customer
     * @secure
     */
    getMessagesByCustomerIdMessageByCustomerPost: (
      data: GetMessages,
      query?: {
        /**
         * Page
         * @min 0
         * @default 0
         */
        page?: number;
        /**
         * Limit
         * @min 0
         * @max 50
         * @default 50
         */
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<SavedMessageWithName[], HTTPValidationError>({
        path: `/message/by-customer`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name GetConversationsMessageConversationsGet
     * @summary Get Conversations
     * @request GET:/message/conversations
     * @secure
     */
    getConversationsMessageConversationsGet: (params: RequestParams = {}) =>
      this.request<Conversation[], any>({
        path: `/message/conversations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name SaveConversationsMessageConversationsPost
     * @summary Save Conversations
     * @request POST:/message/conversations
     * @secure
     */
    saveConversationsMessageConversationsPost: (data: Conversation[], params: RequestParams = {}) =>
      this.request<Conversation[], HTTPValidationError>({
        path: `/message/conversations`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name StreamConversationsMessageConversationsStreamGet
     * @summary Stream Conversations
     * @request GET:/message/conversations/stream
     * @secure
     */
    streamConversationsMessageConversationsStreamGet: (params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, any>({
        path: `/message/conversations/stream`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name UploadAttachmentMessageAttachmentsUploadPost
     * @summary Upload Attachment
     * @request POST:/message/attachments/upload
     * @secure
     */
    uploadAttachmentMessageAttachmentsUploadPost: (
      data: BodyUploadAttachmentMessageAttachmentsUploadPost,
      params: RequestParams = {},
    ) =>
      this.request<string, HTTPValidationError>({
        path: `/message/attachments/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Message
     * @name GetAttachmentsMessageAttachmentsAllGet
     * @summary Get Attachments
     * @request GET:/message/attachments/all
     * @secure
     */
    getAttachmentsMessageAttachmentsAllGet: (params: RequestParams = {}) =>
      this.request<SavedAttachment[], any>({
        path: `/message/attachments/all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ELK46 Callbacks
     * @name Elk46SmsWebhookMessageElk46ConfirmationPost
     * @summary Elk46 Sms Webhook
     * @request POST:/message/elk46/confirmation
     */
    elk46SmsWebhookMessageElk46ConfirmationPost: (params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/message/elk46/confirmation`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ELK46 Callbacks
     * @name Elk46CallWebhookMessageElk46CallPost
     * @summary Elk46 Call Webhook
     * @request POST:/message/elk46/call
     */
    elk46CallWebhookMessageElk46CallPost: (
      data: BodyElk46CallWebhookMessageElk46CallPost,
      params: RequestParams = {},
    ) =>
      this.request<Elk46CallResponse, HTTPValidationError>({
        path: `/message/elk46/call`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),
  };
  mortgage = {
    /**
     * No description
     *
     * @tags Mortgage
     * @name GetKalpSettingsMortgageKalpCountryGet
     * @summary Get Kalp Settings
     * @request GET:/mortgage/kalp/{country}
     * @secure
     */
    getKalpSettingsMortgageKalpCountryGet: (country: Country, params: RequestParams = {}) =>
      this.request<(BankKALP | BankAPIDrivenKALP)[], HTTPValidationError>({
        path: `/mortgage/kalp/${country}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  other = {
    /**
     * No description
     *
     * @tags Other
     * @name GetAllTaxesForCountryOtherTaxesCountryGet
     * @summary Get All Taxes For Country
     * @request GET:/other/taxes/{country}
     * @secure
     */
    getAllTaxesForCountryOtherTaxesCountryGet: (country: Country, params: RequestParams = {}) =>
      this.request<Tax[], HTTPValidationError>({
        path: `/other/taxes/${country}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Other
     * @name TaxesForIdentifierOtherTaxesCalculationPost
     * @summary Taxes For Identifier
     * @request POST:/other/taxes/calculation
     * @secure
     */
    taxesForIdentifierOtherTaxesCalculationPost: (data: GetTaxPayload, params: RequestParams = {}) =>
      this.request<GetTaxPayloadResponse, HTTPValidationError>({
        path: `/other/taxes/calculation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Other
     * @name GetAllMunicipalitiesForCountryOtherMunicipalityCountryGet
     * @summary Get All Municipalities For Country
     * @request GET:/other/municipality/{country}
     * @secure
     */
    getAllMunicipalitiesForCountryOtherMunicipalityCountryGet: (country: Country, params: RequestParams = {}) =>
      this.request<Municipality[], HTTPValidationError>({
        path: `/other/municipality/${country}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Other
     * @name DeactivationReasonsOtherDeactivationReasonsGet
     * @summary Deactivation Reasons
     * @request GET:/other/deactivation-reasons
     * @secure
     */
    deactivationReasonsOtherDeactivationReasonsGet: (
      query: {
        product: Product;
      },
      params: RequestParams = {},
    ) =>
      this.request<string[], HTTPValidationError>({
        path: `/other/deactivation-reasons`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Other, websocket
     * @name GetTokenOtherWsTokenForWebsocketGet
     * @summary Get Token
     * @request GET:/other/ws/token-for-websocket
     * @secure
     */
    getTokenOtherWsTokenForWebsocketGet: (params: RequestParams = {}) =>
      this.request<TokenResp, any>({
        path: `/other/ws/token-for-websocket`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  bankId = {
    /**
     * No description
     *
     * @tags BankID
     * @name VerifyCustomerBankIdVerifyPost
     * @summary Verify Customer
     * @request POST:/bank-id/verify
     * @secure
     */
    verifyCustomerBankIdVerifyPost: (data: BankIDPayload, params: RequestParams = {}) =>
      this.request<RoutersBankIdModelsBankIDResp, HTTPValidationError>({
        path: `/bank-id/verify`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name CancelVerifyCustomerBankIdCancelDelete
     * @summary Cancel Verify Customer
     * @request DELETE:/bank-id/cancel
     * @secure
     */
    cancelVerifyCustomerBankIdCancelDelete: (
      query: {
        /** Ticket Id */
        ticket_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/bank-id/cancel`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name IsAlreadyVerifiedBankIdVerifiedPost
     * @summary Is Already Verified
     * @request POST:/bank-id/verified
     * @secure
     */
    isAlreadyVerifiedBankIdVerifiedPost: (data: BankIDPayload, params: RequestParams = {}) =>
      this.request<PollResp, HTTPValidationError>({
        path: `/bank-id/verified`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name GetTicketStatusBankIdVerifyTicketIdGet
     * @summary Get Ticket Status
     * @request GET:/bank-id/verify/{ticket_id}
     * @secure
     */
    getTicketStatusBankIdVerifyTicketIdGet: (ticketId: string, params: RequestParams = {}) =>
      this.request<PollResp, HTTPValidationError>({
        path: `/bank-id/verify/${ticketId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name IdKollenRedirectNoBankIdIdKollenCallbackGet
     * @summary Id Kollen Redirect No
     * @request GET:/bank-id/id-kollen/callback
     */
    idKollenRedirectNoBankIdIdKollenCallbackGet: (
      query?: {
        /** Refid */
        refId?: string | null;
        /** Id */
        id?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, EmploymentTypeBase | HTTPValidationError>({
        path: `/bank-id/id-kollen/callback`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name IdKollenCallbackSeBankIdIdKollenCallbackPost
     * @summary Id Kollen Callback Se
     * @request POST:/bank-id/id-kollen/callback
     */
    idKollenCallbackSeBankIdIdKollenCallbackPost: (data: IDKollenCollectResponse, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/bank-id/id-kollen/callback`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name QrCodeBankIdQrCodeGet
     * @summary Qr Code
     * @request GET:/bank-id/qr-code
     */
    qrCodeBankIdQrCodeGet: (
      query: {
        /** Ticket Id */
        ticket_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<QRCodeContent, HTTPValidationError>({
        path: `/bank-id/qr-code`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name RedirectFromAppBankIdRedirectGet
     * @summary Redirect From App
     * @request GET:/bank-id/redirect
     */
    redirectFromAppBankIdRedirectGet: (
      query: {
        /** Ticket Id */
        ticket_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/bank-id/redirect`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name LoginBankIdAuthPost
     * @summary Login
     * @request POST:/bank-id/auth
     */
    loginBankIdAuthPost: (data: LoginPayload, params: RequestParams = {}) =>
      this.request<RoutersBankIdLoginPayloadBankIDResp, HTTPValidationError>({
        path: `/bank-id/auth`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankID
     * @name PollTicketBankIdTicketIdGet
     * @summary Poll Ticket
     * @request GET:/bank-id/{ticket_id}
     */
    pollTicketBankIdTicketIdGet: (ticketId: string, params: RequestParams = {}) =>
      this.request<BankIDLoginResponse, HTTPValidationError>({
        path: `/bank-id/${ticketId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  notification = {
    /**
     * No description
     *
     * @tags Notifications
     * @name UserNotificationsNotificationGet
     * @summary User Notifications
     * @request GET:/notification/
     * @secure
     */
    userNotificationsNotificationGet: (
      query?: {
        /** Status */
        status?: NotificationEvent[] | null;
        /** Notification Type */
        notification_type?: ModelsNotificationsNotificationTypesNotificationType | null;
        /**
         * Page
         * @min 0
         * @default 0
         */
        page?: number;
        /**
         * Limit
         * @min 0
         * @max 50
         * @default 50
         */
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        (
          | ({
              type: "SMS_RECEIVED";
            } & SMSReceived)
          | ({
              type: "BANK_MESSAGE_RECEIVED";
            } & BankMessageReceived)
          | ({
              type: "OFFER_RECEIVED";
            } & OfferReceived)
          | ({
              type: "INTERNAL_COMMENT_RECEIVED";
            } & ModelsNotificationsNotificationInternalComment)
          | ({
              type: "APPLICATION_ASSIGNED";
            } & ApplicationAssigned)
          | ({
              type: "GENERAL_MESSAGE_RECEIVED";
            } & GeneralMessage)
          | ({
              type: "LEAD_ASSIGNED";
            } & LeadAssigned)
          | ({
              type: "LEAD_COMMENT_RECEIVED";
            } & LeadComment)
          | ({
              type: "APPLICATION_DISBURSED";
            } & ApplicationDisbursed)
        )[],
        HTTPValidationError
      >({
        path: `/notification/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name GetNotificationNotificationByIdNotificationIdGet
     * @summary Get Notification
     * @request GET:/notification/by-id/{notification_id}
     * @secure
     */
    getNotificationNotificationByIdNotificationIdGet: (notificationId: string, params: RequestParams = {}) =>
      this.request<
        | ({
            type: "SMS_RECEIVED";
          } & SMSReceived)
        | ({
            type: "BANK_MESSAGE_RECEIVED";
          } & BankMessageReceived)
        | ({
            type: "OFFER_RECEIVED";
          } & OfferReceived)
        | ({
            type: "INTERNAL_COMMENT_RECEIVED";
          } & ModelsNotificationsNotificationInternalComment)
        | ({
            type: "APPLICATION_ASSIGNED";
          } & ApplicationAssigned)
        | ({
            type: "GENERAL_MESSAGE_RECEIVED";
          } & GeneralMessage)
        | ({
            type: "LEAD_ASSIGNED";
          } & LeadAssigned)
        | ({
            type: "LEAD_COMMENT_RECEIVED";
          } & LeadComment)
        | ({
            type: "APPLICATION_DISBURSED";
          } & ApplicationDisbursed),
        HTTPValidationError
      >({
        path: `/notification/by-id/${notificationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotifyNotificationNotifyIncomingPost
     * @summary Notify
     * @request POST:/notification/notify/incoming
     * @secure
     */
    notifyNotificationNotifyIncomingPost: (data: Notification, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/notification/notify/incoming`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotifyInsuranceNotificationNotifyInsurancePost
     * @summary Notify Insurance
     * @request POST:/notification/notify/insurance
     * @secure
     */
    notifyInsuranceNotificationNotifyInsurancePost: (data: InsuranceNotification, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/notification/notify/insurance`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name AcknowledgeNotificationNotificationActionPost
     * @summary Acknowledge Notification
     * @request POST:/notification/action
     * @secure
     */
    acknowledgeNotificationNotificationActionPost: (data: ActionPayload, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/notification/action`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name CustomAuthUserNotificationsNotificationNotificationIdReceivedPatch
     * @summary Custom Auth User Notifications
     * @request PATCH:/notification/{notification_id}/received
     */
    customAuthUserNotificationsNotificationNotificationIdReceivedPatch: (
      notificationId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/notification/${notificationId}/received`,
        method: "PATCH",
        ...params,
      }),
  };
  ponytail = {
    /**
     * No description
     *
     * @tags Ponytail
     * @name GetDisbursedStatsPonytailDisbursedAllPost
     * @summary Get Disbursed Stats
     * @request POST:/ponytail/disbursed/all
     * @secure
     */
    getDisbursedStatsPonytailDisbursedAllPost: (data: DashboardPayload, params: RequestParams = {}) =>
      this.request<AllDisbursedLoans, HTTPValidationError>({
        path: `/ponytail/disbursed/all`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetMyDisbursedPonytailDisbursedMePost
     * @summary Get My Disbursed
     * @request POST:/ponytail/disbursed/me
     * @secure
     */
    getMyDisbursedPonytailDisbursedMePost: (data: DashboardPayload, params: RequestParams = {}) =>
      this.request<MyDisbursedLoans, HTTPValidationError>({
        path: `/ponytail/disbursed/me`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetAcceptedPonytailAcceptedAllPost
     * @summary Get Accepted
     * @request POST:/ponytail/accepted/all
     * @secure
     */
    getAcceptedPonytailAcceptedAllPost: (data: DashboardPayload, params: RequestParams = {}) =>
      this.request<AcceptedLoans, HTTPValidationError>({
        path: `/ponytail/accepted/all`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetMyAcceptedPonytailAcceptedMePost
     * @summary Get My Accepted
     * @request POST:/ponytail/accepted/me
     * @secure
     */
    getMyAcceptedPonytailAcceptedMePost: (data: DashboardPayload, params: RequestParams = {}) =>
      this.request<AcceptedLoans, HTTPValidationError>({
        path: `/ponytail/accepted/me`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetAllInsurancePonytailInsuranceAllPost
     * @summary Get All Insurance
     * @request POST:/ponytail/insurance/all
     * @secure
     */
    getAllInsurancePonytailInsuranceAllPost: (data: DashboardPayload, params: RequestParams = {}) =>
      this.request<AllInsurances, HTTPValidationError>({
        path: `/ponytail/insurance/all`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetMyInsurancePonytailInsuranceMePost
     * @summary Get My Insurance
     * @request POST:/ponytail/insurance/me
     * @secure
     */
    getMyInsurancePonytailInsuranceMePost: (data: DashboardPayload, params: RequestParams = {}) =>
      this.request<AllInsurances, HTTPValidationError>({
        path: `/ponytail/insurance/me`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetAllMyStatsPonytailMyStatsPost
     * @summary Get All My Stats
     * @request POST:/ponytail/my-stats
     * @secure
     */
    getAllMyStatsPonytailMyStatsPost: (data: DashboardPayload, params: RequestParams = {}) =>
      this.request<RootModelUnionMyStatsMyStatsMb, HTTPValidationError>({
        path: `/ponytail/my-stats`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name SummarizedViewPonytailSummarizedPost
     * @summary Summarized View
     * @request POST:/ponytail/summarized
     * @secure
     */
    summarizedViewPonytailSummarizedPost: (data: DashboardPayload, params: RequestParams = {}) =>
      this.request<RootModelUnionSummarizedViewMyStatsMb, HTTPValidationError>({
        path: `/ponytail/summarized`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetWorkingDaysPonytailWorkingDaysPost
     * @summary Get Working Days
     * @request POST:/ponytail/working-days
     * @secure
     */
    getWorkingDaysPonytailWorkingDaysPost: (data: GoalPayload, params: RequestParams = {}) =>
      this.request<WorkingDays, HTTPValidationError>({
        path: `/ponytail/working-days`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name PostBlancoGoalPonytailGoalsCreateBlancoPost
     * @summary Post Blanco Goal
     * @request POST:/ponytail/goals/create/blanco
     * @secure
     */
    postBlancoGoalPonytailGoalsCreateBlancoPost: (data: BlancoGoalPayload, params: RequestParams = {}) =>
      this.request<BlancoGoal, HTTPValidationError>({
        path: `/ponytail/goals/create/blanco`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name PostBlancoGoalMbPonytailGoalsCreateBlancoMbPost
     * @summary Post Blanco Goal Mb
     * @request POST:/ponytail/goals/create/blanco-mb
     * @secure
     */
    postBlancoGoalMbPonytailGoalsCreateBlancoMbPost: (data: BlancoGoalMbPayload, params: RequestParams = {}) =>
      this.request<BlancoGoalMb, HTTPValidationError>({
        path: `/ponytail/goals/create/blanco-mb`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name PostInitialBlancoGoalPonytailGoalsCreateInitialBlancoPost
     * @summary Post Initial Blanco Goal
     * @request POST:/ponytail/goals/create/initial-blanco
     * @secure
     */
    postInitialBlancoGoalPonytailGoalsCreateInitialBlancoPost: (data: BlancoGoalPayload, params: RequestParams = {}) =>
      this.request<BlancoGoal, HTTPValidationError>({
        path: `/ponytail/goals/create/initial-blanco`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name PostInitialBudgetPonytailGoalsCreateInitialBlancoMbPost
     * @summary Post Initial Budget
     * @request POST:/ponytail/goals/create/initial-blanco-mb
     * @secure
     */
    postInitialBudgetPonytailGoalsCreateInitialBlancoMbPost: (data: BudgetPayload, params: RequestParams = {}) =>
      this.request<Budget, HTTPValidationError>({
        path: `/ponytail/goals/create/initial-blanco-mb`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetMyGoalsPonytailGoalsPost
     * @summary Get My Goals
     * @request POST:/ponytail/goals
     * @secure
     */
    getMyGoalsPonytailGoalsPost: (data: GoalPayload, params: RequestParams = {}) =>
      this.request<BlancoGoalMb | BlancoGoal | MortgageGoal | null, HTTPValidationError>({
        path: `/ponytail/goals`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ponytail
     * @name GetInitialGoalPonytailGoalsInitialGoalPost
     * @summary Get Initial Goal
     * @request POST:/ponytail/goals/initial-goal
     * @secure
     */
    getInitialGoalPonytailGoalsInitialGoalPost: (data: GoalPayload, params: RequestParams = {}) =>
      this.request<Budget | BlancoGoal | MortgageGoal | null, HTTPValidationError>({
        path: `/ponytail/goals/initial-goal`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  lead = {
    /**
     * No description
     *
     * @tags Lead
     * @name GetLeadLeadGetLeadIdGet
     * @summary Get Lead
     * @request GET:/lead/get/{lead_id}
     * @secure
     */
    getLeadLeadGetLeadIdGet: (leadId: string, params: RequestParams = {}) =>
      this.request<Lead, HTTPValidationError>({
        path: `/lead/get/${leadId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name GetLeadsLeadListPost
     * @summary Get Leads
     * @request POST:/lead/list
     * @secure
     */
    getLeadsLeadListPost: (data: GetLeads, params: RequestParams = {}) =>
      this.request<Lead[], HTTPValidationError>({
        path: `/lead/list`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name CreateLeadLeadCreatePost
     * @summary Create Lead
     * @request POST:/lead/create
     * @secure
     */
    createLeadLeadCreatePost: (data: CreateLeadPayload, params: RequestParams = {}) =>
      this.request<Lead, HTTPValidationError>({
        path: `/lead/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name SearchLeadSearchPost
     * @summary Search
     * @request POST:/lead/search
     * @secure
     */
    searchLeadSearchPost: (data: RoutersLeadPayloadSearchPayload, params: RequestParams = {}) =>
      this.request<Lead[], HTTPValidationError>({
        path: `/lead/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name ConvertAndCloseLeadConvertAndMarkHandledPost
     * @summary Convert And Close
     * @request POST:/lead/convert_and_mark_handled
     * @secure
     */
    convertAndCloseLeadConvertAndMarkHandledPost: (data: ConvertPayload, params: RequestParams = {}) =>
      this.request<ConvertResult, HTTPValidationError>({
        path: `/lead/convert_and_mark_handled`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name CloseLeadMarkHandledPost
     * @summary Close
     * @request POST:/lead/mark_handled
     * @secure
     */
    closeLeadMarkHandledPost: (data: LeadHandledPayload, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/lead/mark_handled`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name UnhandleLeadLeadUnhandlePost
     * @summary Unhandle Lead
     * @request POST:/lead/unhandle
     * @secure
     */
    unhandleLeadLeadUnhandlePost: (
      query: {
        /** Lead Id */
        lead_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/lead/unhandle`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name UpdateLeadLeadUpdateLeadIdPost
     * @summary Update Lead
     * @request POST:/lead/update/{lead_id}
     * @secure
     */
    updateLeadLeadUpdateLeadIdPost: (leadId: string, data: LeadUpdatePayload, params: RequestParams = {}) =>
      this.request<Lead, HTTPValidationError>({
        path: `/lead/update/${leadId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name ReprocessSubmissionLeadReprocessSubmissionLeadIdPost
     * @summary Reprocess Submission
     * @request POST:/lead/reprocess_submission/{lead_id}
     * @secure
     */
    reprocessSubmissionLeadReprocessSubmissionLeadIdPost: (leadId: string, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/lead/reprocess_submission/${leadId}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name ReprocessSubmissionsLeadReprocessSubmissionsPost
     * @summary Reprocess Submissions
     * @request POST:/lead/reprocess_submissions
     * @secure
     */
    reprocessSubmissionsLeadReprocessSubmissionsPost: (data: ReprocessSubmissionPayload, params: RequestParams = {}) =>
      this.request<ReprocessResult[], HTTPValidationError>({
        path: `/lead/reprocess_submissions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name GetCommentsLeadCommentLeadIdGet
     * @summary Get Comments
     * @request GET:/lead/comment/{lead_id}
     * @secure
     */
    getCommentsLeadCommentLeadIdGet: (leadId: string, params: RequestParams = {}) =>
      this.request<InternalCommentWithName[], HTTPValidationError>({
        path: `/lead/comment/${leadId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name AddInternalCommentLeadCommentLeadIdPost
     * @summary Add Internal Comment
     * @request POST:/lead/comment/{lead_id}
     * @secure
     */
    addInternalCommentLeadCommentLeadIdPost: (leadId: string, data: CommentPayload, params: RequestParams = {}) =>
      this.request<InternalCommentWithName[], HTTPValidationError>({
        path: `/lead/comment/${leadId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name AssignLeadToHandlerLeadAssignLeadIdPost
     * @summary Assign Lead To Handler
     * @request POST:/lead/assign/{lead_id}
     * @secure
     */
    assignLeadToHandlerLeadAssignLeadIdPost: (
      leadId: string,
      data: AssignApplicationPayload,
      params: RequestParams = {},
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/lead/assign/${leadId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name GetAssignedHandlerLeadAssigneeLeadIdGet
     * @summary Get Assigned Handler
     * @request GET:/lead/assignee/{lead_id}
     * @secure
     */
    getAssignedHandlerLeadAssigneeLeadIdGet: (leadId: string, params: RequestParams = {}) =>
      this.request<Assignee, HTTPValidationError>({
        path: `/lead/assignee/${leadId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lead
     * @name GetLeadsLeadListMinePost
     * @summary Get Leads
     * @request POST:/lead/list/mine
     * @secure
     */
    getLeadsLeadListMinePost: (data: GetMyLeads, params: RequestParams = {}) =>
      this.request<Lead[], HTTPValidationError>({
        path: `/lead/list/mine`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  formprefs = {
    /**
     * No description
     *
     * @tags Jotform
     * @name UpdatePreferenceFormprefsPut
     * @summary Update Preference
     * @request PUT:/formprefs/
     * @secure
     */
    updatePreferenceFormprefsPut: (data: UpdateFormPreferencePayload, params: RequestParams = {}) =>
      this.request<FormPreferenceExt, HTTPValidationError>({
        path: `/formprefs/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name CreatePreferenceFormprefsPost
     * @summary Create Preference
     * @request POST:/formprefs/
     * @secure
     */
    createPreferenceFormprefsPost: (data: FormPreferencePayload, params: RequestParams = {}) =>
      this.request<FormPreferenceExt, HTTPValidationError>({
        path: `/formprefs/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name DuplicatePreferenceFormprefsDuplicatePost
     * @summary Duplicate Preference
     * @request POST:/formprefs/duplicate
     * @secure
     */
    duplicatePreferenceFormprefsDuplicatePost: (data: DuplicateFormPreferencePayload, params: RequestParams = {}) =>
      this.request<FormPreferenceExt, HTTPValidationError>({
        path: `/formprefs/duplicate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name ValidatePreferenceFormprefsValidatePost
     * @summary Validate Preference
     * @request POST:/formprefs/validate
     * @secure
     */
    validatePreferenceFormprefsValidatePost: (data: ValidatePayload, params: RequestParams = {}) =>
      this.request<FormPreferenceExt, HTTPValidationError>({
        path: `/formprefs/validate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name GetMappingFormprefsGetMappingPost
     * @summary Get Mapping
     * @request POST:/formprefs/get_mapping
     * @secure
     */
    getMappingFormprefsGetMappingPost: (data: BaseFormPreferencePayload, params: RequestParams = {}) =>
      this.request<FormMapping, HTTPValidationError>({
        path: `/formprefs/get_mapping`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name ListPreferencesFormprefsListGet
     * @summary List Preferences
     * @request GET:/formprefs/list
     * @secure
     */
    listPreferencesFormprefsListGet: (params: RequestParams = {}) =>
      this.request<FormPreference[], any>({
        path: `/formprefs/list`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name ListPreferencesExtFormprefsListExtGet
     * @summary List Preferences Ext
     * @request GET:/formprefs/list_ext
     * @secure
     */
    listPreferencesExtFormprefsListExtGet: (params: RequestParams = {}) =>
      this.request<FormPreferenceExt[], any>({
        path: `/formprefs/list_ext`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name ListFormsFormprefsFormsGet
     * @summary List Forms
     * @request GET:/formprefs/forms
     * @secure
     */
    listFormsFormprefsFormsGet: (params: RequestParams = {}) =>
      this.request<Form[], any>({
        path: `/formprefs/forms`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name CheckAllSubmissionsFormprefsCheckAllGet
     * @summary Check All Submissions
     * @request GET:/formprefs/check-all
     * @secure
     */
    checkAllSubmissionsFormprefsCheckAllGet: (params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, any>({
        path: `/formprefs/check-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name GetAllSubmissionsFormprefsSubmissionsGet
     * @summary Get All Submissions
     * @request GET:/formprefs/submissions
     * @secure
     */
    getAllSubmissionsFormprefsSubmissionsGet: (
      query?: {
        /** Form Id */
        form_id?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/formprefs/submissions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name GetFormPreferenceFormprefsFormPreferenceIdGet
     * @summary Get Form Preference
     * @request GET:/formprefs/{form_preference_id}
     * @secure
     */
    getFormPreferenceFormprefsFormPreferenceIdGet: (
      formPreferenceId: string,
      query?: {
        /**
         * Add Defaults
         * @default false
         */
        add_defaults?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<FormPreferenceExt, HTTPValidationError>({
        path: `/formprefs/${formPreferenceId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name CreateWebhookFormprefsWebhookPost
     * @summary Create Webhook
     * @request POST:/formprefs/webhook
     * @secure
     */
    createWebhookFormprefsWebhookPost: (data: FormWebhookPayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/formprefs/webhook`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jotform
     * @name DeleteWebhookFormprefsWebhookDelete
     * @summary Delete Webhook
     * @request DELETE:/formprefs/webhook
     * @secure
     */
    deleteWebhookFormprefsWebhookDelete: (data: FormWebhookPayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/formprefs/webhook`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  heimdall = {
    /**
     * No description
     *
     * @tags Heimdall
     * @name GetAllHeimdallRecordsForDateHeimdallDateGet
     * @summary Get All Heimdall Records For Date
     * @request GET:/heimdall/{date}
     * @secure
     */
    getAllHeimdallRecordsForDateHeimdallDateGet: (date: string, params: RequestParams = {}) =>
      this.request<HeimdallResponse[], HTTPValidationError>({
        path: `/heimdall/${date}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Heimdall
     * @name ReportAndVerifyUsageToHeimdallHeimdallUserIdPatch
     * @summary Report And Verify Usage To Heimdall
     * @request PATCH:/heimdall/{user_id}
     * @secure
     */
    reportAndVerifyUsageToHeimdallHeimdallUserIdPatch: (
      userId: string,
      data: HeimdallReportingPayload,
      params: RequestParams = {},
    ) =>
      this.request<HeimdallReportingResponse, HTTPValidationError>({
        path: `/heimdall/${userId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Heimdall
     * @name ResetHeimdallRateLimitHeimdallUserIdToResetResetPatch
     * @summary Reset Heimdall Rate Limit
     * @request PATCH:/heimdall/{user_id_to_reset}/reset
     * @secure
     */
    resetHeimdallRateLimitHeimdallUserIdToResetResetPatch: (userIdToReset: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/heimdall/${userIdToReset}/reset`,
        method: "PATCH",
        secure: true,
        ...params,
      }),
  };
  larsMagnus = {
    /**
     * No description
     *
     * @tags Lars Magnus
     * @name CheckTelavoxIdsLarsMagnusTelavoxCheckGet
     * @summary Check Telavox Ids
     * @request GET:/lars-magnus/telavox-check
     * @secure
     */
    checkTelavoxIdsLarsMagnusTelavoxCheckGet: (params: RequestParams = {}) =>
      this.request<TelavoxMismatch, any>({
        path: `/lars-magnus/telavox-check`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lars Magnus
     * @name CurrentTelavoxUserLarsMagnusTelavoxUserGet
     * @summary Current Telavox User
     * @request GET:/lars-magnus/telavox-user
     * @secure
     */
    currentTelavoxUserLarsMagnusTelavoxUserGet: (params: RequestParams = {}) =>
      this.request<TelavoxExtension, any>({
        path: `/lars-magnus/telavox-user`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  myPages = {
    /**
     * No description
     *
     * @tags My Pages
     * @name ImpersonateCustomerMyPagesImpersonatePost
     * @summary Impersonate Customer
     * @request POST:/my-pages/impersonate
     * @secure
     */
    impersonateCustomerMyPagesImpersonatePost: (data: ImpersonateCustomerPayload, params: RequestParams = {}) =>
      this.request<BankIDLoginResponse, HTTPValidationError>({
        path: `/my-pages/impersonate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name ValidateClearingMyPagesOffersClearingValidateClearingGet
     * @summary Validate Clearing
     * @request GET:/my-pages/offers/clearing/validate/{clearing}
     */
    validateClearingMyPagesOffersClearingValidateClearingGet: (clearing: string, params: RequestParams = {}) =>
      this.request<EmptyModel, HTTPValidationError>({
        path: `/my-pages/offers/clearing/validate/${clearing}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Gather request md and proceed with accepting terms for insurance used for customers that are not authenticated and cannot change the amount
     *
     * @tags My Pages
     * @name ConfirmInsuranceTermsMyPagesInsurancesConfirmPost
     * @summary Confirm Insurance Terms
     * @request POST:/my-pages/insurances/confirm
     */
    confirmInsuranceTermsMyPagesInsurancesConfirmPost: (
      query: {
        /** Encrypted Insurance Id */
        encrypted_insurance_id: string;
        /** Email */
        email: string;
        /** Phone Number */
        phone_number: string;
        /** Success Url */
        success_url: string;
        /** Fail Url */
        fail_url: string;
        /** Insured Amount */
        insured_amount?: number | null;
        /** Premium */
        premium?: number | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<AcceptTermsResponse, HTTPValidationError>({
        path: `/my-pages/insurances/confirm`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name GetInsuranceMyPagesInsuranceGet
     * @summary Get Insurance
     * @request GET:/my-pages/insurance
     */
    getInsuranceMyPagesInsuranceGet: (
      query: {
        /** Id */
        id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UnauthenticatedInsurance, HTTPValidationError>({
        path: `/my-pages/insurance`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name GetLatestActiveApplicationsV2MyPagesV2ApplicationsLatestGet
     * @summary Get Latest Active Applications V2
     * @request GET:/my-pages/v2/applications/latest
     * @secure
     */
    getLatestActiveApplicationsV2MyPagesV2ApplicationsLatestGet: (params: RequestParams = {}) =>
      this.request<CustomerApplicationV2[], any>({
        path: `/my-pages/v2/applications/latest`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name GetLatestActiveApplicationMyPagesApplicationLatestGet
     * @summary Get Latest Active Application
     * @request GET:/my-pages/application/latest
     * @secure
     */
    getLatestActiveApplicationMyPagesApplicationLatestGet: (params: RequestParams = {}) =>
      this.request<CustomerApplication | null, any>({
        path: `/my-pages/application/latest`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name GetCreditCardOffersMyPagesCreditCardOffersGet
     * @summary Get Credit Card Offers
     * @request GET:/my-pages/credit_card/offers
     * @secure
     */
    getCreditCardOffersMyPagesCreditCardOffersGet: (params: RequestParams = {}) =>
      this.request<CreditorOfferCreditCardOfferCreditCardTerms[], any>({
        path: `/my-pages/credit_card/offers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name GetApplicantMyPagesApplicantMeGet
     * @summary Get Applicant
     * @request GET:/my-pages/applicant/me
     * @secure
     */
    getApplicantMyPagesApplicantMeGet: (params: RequestParams = {}) =>
      this.request<
        | ({
            country: "SE";
          } & ApplicantSE)
        | ({
            country: "NO";
          } & ApplicantNO),
        any
      >({
        path: `/my-pages/applicant/me`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Legacy blanco endpoint (Sverker)
     *
     * @tags My Pages
     * @name AcceptOfferLegacyMyPagesOfferAcceptPost
     * @summary Accept Offer Legacy
     * @request POST:/my-pages/offer/accept
     * @secure
     */
    acceptOfferLegacyMyPagesOfferAcceptPost: (data: AcceptOfferPayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/my-pages/offer/accept`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Legacy blanco endpoint (Sverker)
     *
     * @tags My Pages
     * @name AcceptOfferLegacyV2MyPagesV2OfferAcceptPost
     * @summary Accept Offer Legacy V2
     * @request POST:/my-pages/v2/offer/accept
     * @secure
     */
    acceptOfferLegacyV2MyPagesV2OfferAcceptPost: (data: AcceptOfferPayloadV2, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/my-pages/v2/offer/accept`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name AcceptOfferMyPagesOfferAccept2Post
     * @summary Accept Offer
     * @request POST:/my-pages/offer/accept2
     * @secure
     */
    acceptOfferMyPagesOfferAccept2Post: (data: AcceptOffer, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/my-pages/offer/accept2`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name BanksToExcludeFromInsuranceOfferingForMyPagesInsurancesBanksExcludedGet
     * @summary Banks To Exclude From Insurance Offering For
     * @request GET:/my-pages/insurances/banks/excluded
     * @deprecated
     * @secure
     */
    banksToExcludeFromInsuranceOfferingForMyPagesInsurancesBanksExcludedGet: (params: RequestParams = {}) =>
      this.request<BanksToExcludeInsuranceOfferingFor, any>({
        path: `/my-pages/insurances/banks/excluded`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name InsurancesRulesMyPagesInsurancesRulesGet
     * @summary Insurances Rules
     * @request GET:/my-pages/insurances/rules
     * @secure
     */
    insurancesRulesMyPagesInsurancesRulesGet: (params: RequestParams = {}) =>
      this.request<InsuranceTerms[], any>({
        path: `/my-pages/insurances/rules`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name LoanInsurancesRulesMyPagesInsurancesLoanInsuranceRulesGet
     * @summary Loan Insurances Rules
     * @request GET:/my-pages/insurances/loan-insurance-rules
     * @secure
     */
    loanInsurancesRulesMyPagesInsurancesLoanInsuranceRulesGet: (params: RequestParams = {}) =>
      this.request<InsuranceTerms, any>({
        path: `/my-pages/insurances/loan-insurance-rules`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name IdCheckMyPagesIdCheckGet
     * @summary Id Check
     * @request GET:/my-pages/id-check
     * @secure
     */
    idCheckMyPagesIdCheckGet: (params: RequestParams = {}) =>
      this.request<
        | (
            | ({
                country: "SE";
              } & ApplicantSE)
            | ({
                country: "NO";
              } & ApplicantNO)
          )
        | (
            | ({
                country: "SE";
              } & OptionalApplicantSE)
            | ({
                country: "NO";
              } & OptionalApplicantNO)
          )
        | IDCheckedPerson,
        any
      >({
        path: `/my-pages/id-check`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name ActiveInsuranceByPniMyPagesInsurancesGet
     * @summary Active Insurance By Pni
     * @request GET:/my-pages/insurances
     * @secure
     */
    activeInsuranceByPniMyPagesInsurancesGet: (params: RequestParams = {}) =>
      this.request<InsuranceCustomer[], any>({
        path: `/my-pages/insurances`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name CreateInsuranceV2MyPagesInsurancesV2Post
     * @summary Create Insurance V2
     * @request POST:/my-pages/insurances/v2
     * @secure
     */
    createInsuranceV2MyPagesInsurancesV2Post: (data: CreateInsuranceCustomer, params: RequestParams = {}) =>
      this.request<AcceptTermsResponseCustomer, HTTPValidationError>({
        path: `/my-pages/insurances/v2`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name DebtRegisterConsentMyPagesDebtRegisterConsentPost
     * @summary Debt Register Consent
     * @request POST:/my-pages/debt-register/consent
     * @secure
     */
    debtRegisterConsentMyPagesDebtRegisterConsentPost: (params: RequestParams = {}) =>
      this.request<
        | (
            | ({
                country: "SE";
              } & ApplicantSE)
            | ({
                country: "NO";
              } & ApplicantNO)
          )
        | (
            | ({
                country: "SE";
              } & OptionalApplicantSE)
            | ({
                country: "NO";
              } & OptionalApplicantNO)
          ),
        HTTPValidationError
      >({
        path: `/my-pages/debt-register/consent`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name DebtRegisterCheckConsentMyPagesDebtRegisterCheckConsentPost
     * @summary Debt Register Check Consent
     * @request POST:/my-pages/debt-register/check-consent
     * @secure
     */
    debtRegisterCheckConsentMyPagesDebtRegisterCheckConsentPost: (params: RequestParams = {}) =>
      this.request<
        | (
            | ({
                country: "SE";
              } & ApplicantSE)
            | ({
                country: "NO";
              } & ApplicantNO)
          )
        | (
            | ({
                country: "SE";
              } & OptionalApplicantSE)
            | ({
                country: "NO";
              } & OptionalApplicantNO)
          ),
        any
      >({
        path: `/my-pages/debt-register/check-consent`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name DebtRegisterRenewMyPagesDebtRegisterRenewPost
     * @summary Debt Register Renew
     * @request POST:/my-pages/debt-register/renew
     * @secure
     */
    debtRegisterRenewMyPagesDebtRegisterRenewPost: (params: RequestParams = {}) =>
      this.request<
        | (
            | ({
                country: "SE";
              } & ApplicantSE)
            | ({
                country: "NO";
              } & ApplicantNO)
          )
        | (
            | ({
                country: "SE";
              } & OptionalApplicantSE)
            | ({
                country: "NO";
              } & OptionalApplicantNO)
          ),
        any
      >({
        path: `/my-pages/debt-register/renew`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name UpsertMarketingConsentMyPagesMarketingConsentsPost
     * @summary Upsert Marketing Consent
     * @request POST:/my-pages/marketing-consents
     * @secure
     */
    upsertMarketingConsentMyPagesMarketingConsentsPost: (data: ConsentsPayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/my-pages/marketing-consents`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags My Pages
     * @name GetTrustlyUrlForNewMandateMyPagesInsurancesTrustlyUrlGet
     * @summary Get Trustly Url For New Mandate
     * @request GET:/my-pages/insurances/trustly-url
     * @secure
     */
    getTrustlyUrlForNewMandateMyPagesInsurancesTrustlyUrlGet: (
      query: {
        /** Id */
        id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/my-pages/insurances/trustly-url`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  zms = {
    /**
     * No description
     *
     * @tags ZMS
     * @name SaveZmsConfigZmsCountryPost
     * @summary Save Zms Config
     * @request POST:/zms/{country}
     * @secure
     */
    saveZmsConfigZmsCountryPost: (country: Country, data: Config, params: RequestParams = {}) =>
      this.request<ZMSConfig, HTTPValidationError>({
        path: `/zms/${country}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ZMS
     * @name GetZmsConfigZmsCountryGet
     * @summary Get Zms Config
     * @request GET:/zms/{country}
     */
    getZmsConfigZmsCountryGet: (country: Country, params: RequestParams = {}) =>
      this.request<ZMSConfig, HTTPValidationError>({
        path: `/zms/${country}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ZMS
     * @name GetAllConfigsZmsGet
     * @summary Get All Configs
     * @request GET:/zms
     */
    getAllConfigsZmsGet: (params: RequestParams = {}) =>
      this.request<ZMSConfig[], any>({
        path: `/zms`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  affiliates = {
    /**
     * No description
     *
     * @tags Affiliate
     * @name CreateAffiliateAffiliatesPost
     * @summary Create Affiliate
     * @request POST:/affiliates/
     * @secure
     */
    createAffiliateAffiliatesPost: (data: AffiliateData, params: RequestParams = {}) =>
      this.request<Affiliate, HTTPValidationError>({
        path: `/affiliates/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name GetAffiliatesAffiliatesGet
     * @summary Get Affiliates
     * @request GET:/affiliates/
     * @secure
     */
    getAffiliatesAffiliatesGet: (
      query?: {
        /**
         * Page
         * @min 0
         * @default 0
         */
        page?: number;
        /**
         * Limit
         * @min 0
         * @max 50
         * @default 50
         */
        limit?: number;
        /** Status */
        status?: string | null;
        /**
         * Network
         * @default false
         */
        network?: boolean | null;
        /**
         * Publisher
         * @default false
         */
        publisher?: boolean | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<Affiliate[], HTTPValidationError>({
        path: `/affiliates/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name SearchAffiliatesAffiliatesSearchGet
     * @summary Search Affiliates
     * @request GET:/affiliates/search
     * @secure
     */
    searchAffiliatesAffiliatesSearchGet: (
      query: {
        /** Keyword */
        keyword: string;
        /**
         * Page
         * @min 0
         * @default 0
         */
        page?: number;
        /**
         * Limit
         * @min 0
         * @max 50
         * @default 50
         */
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Affiliate[], HTTPValidationError>({
        path: `/affiliates/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name GetAffiliateAffiliatesAffiliateIdGet
     * @summary Get Affiliate
     * @request GET:/affiliates/{affiliate_id}
     * @secure
     */
    getAffiliateAffiliatesAffiliateIdGet: (affiliateId: string, params: RequestParams = {}) =>
      this.request<Affiliate, HTTPValidationError>({
        path: `/affiliates/${affiliateId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name UpdateAffiliateAffiliatesAffiliateIdPut
     * @summary Update Affiliate
     * @request PUT:/affiliates/{affiliate_id}
     * @secure
     */
    updateAffiliateAffiliatesAffiliateIdPut: (affiliateId: string, data: AffiliateData, params: RequestParams = {}) =>
      this.request<Affiliate, HTTPValidationError>({
        path: `/affiliates/${affiliateId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name DeactivateAffiliateAffiliatesAffiliateIdDelete
     * @summary Deactivate Affiliate
     * @request DELETE:/affiliates/{affiliate_id}
     * @secure
     */
    deactivateAffiliateAffiliatesAffiliateIdDelete: (affiliateId: string, params: RequestParams = {}) =>
      this.request<string, HTTPValidationError>({
        path: `/affiliates/${affiliateId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name CreateCampaignAffiliatesAffiliateIdCampaignsPost
     * @summary Create Campaign
     * @request POST:/affiliates/{affiliate_id}/campaigns
     * @secure
     */
    createCampaignAffiliatesAffiliateIdCampaignsPost: (
      affiliateId: string,
      data: CampaignData,
      params: RequestParams = {},
    ) =>
      this.request<Affiliate, HTTPValidationError>({
        path: `/affiliates/${affiliateId}/campaigns`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name GetCampaignsAffiliatesAffiliateIdCampaignsGet
     * @summary Get Campaigns
     * @request GET:/affiliates/{affiliate_id}/campaigns
     * @secure
     */
    getCampaignsAffiliatesAffiliateIdCampaignsGet: (affiliateId: string, params: RequestParams = {}) =>
      this.request<ModelsAffiliateAffiliateCampaign[], HTTPValidationError>({
        path: `/affiliates/${affiliateId}/campaigns`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name UpdateCampaignAffiliatesAffiliateIdCampaignsCampaignIdPut
     * @summary Update Campaign
     * @request PUT:/affiliates/{affiliate_id}/campaigns/{campaign_id}
     * @secure
     */
    updateCampaignAffiliatesAffiliateIdCampaignsCampaignIdPut: (
      campaignId: string,
      affiliateId: string,
      data: CampaignData,
      params: RequestParams = {},
    ) =>
      this.request<Affiliate, HTTPValidationError>({
        path: `/affiliates/${affiliateId}/campaigns/${campaignId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name RemoveCampaignAffiliatesAffiliateIdCampaignsCampaignIdDelete
     * @summary Remove Campaign
     * @request DELETE:/affiliates/{affiliate_id}/campaigns/{campaign_id}
     * @secure
     */
    removeCampaignAffiliatesAffiliateIdCampaignsCampaignIdDelete: (
      campaignId: string,
      affiliateId: string,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/affiliates/${affiliateId}/campaigns/${campaignId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name GetPersonEventAffiliatesPersonsZidGet
     * @summary Get Person Event
     * @request GET:/affiliates/persons/{zid}
     * @secure
     */
    getPersonEventAffiliatesPersonsZidGet: (zid: string, params: RequestParams = {}) =>
      this.request<Person, HTTPValidationError>({
        path: `/affiliates/persons/${zid}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name GetAffiliatePersonEventsAffiliatesAffiliateIdPersonsGet
     * @summary Get Affiliate Person Events
     * @request GET:/affiliates/{affiliate_id}/persons
     * @secure
     */
    getAffiliatePersonEventsAffiliatesAffiliateIdPersonsGet: (
      affiliateId: string,
      query?: {
        /**
         * Page
         * @min 0
         * @default 0
         */
        page?: number;
        /**
         * Limit
         * @min 0
         * @max 50
         * @default 50
         */
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Person[], HTTPValidationError>({
        path: `/affiliates/${affiliateId}/persons`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name GetAffiliateCallbacksAffiliatesAffiliateNetworkIdCallbacksPost
     * @summary Get Affiliate Callbacks
     * @request POST:/affiliates/{affiliate_network_id}/callbacks
     * @secure
     */
    getAffiliateCallbacksAffiliatesAffiliateNetworkIdCallbacksPost: (
      affiliateNetworkId: string,
      data: AffiliateNetworkCallbackStatus[] | null,
      query?: {
        /**
         * Page
         * @min 0
         * @default 0
         */
        page?: number;
        /**
         * Limit
         * @min 0
         * @max 100
         * @default 50
         */
        limit?: number;
        /** Publisher */
        publisher?: string | null;
        /** Start Date */
        start_date?: string | null;
        /** End Date */
        end_date?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<AffiliateNetworkCallbackEvent[], HTTPValidationError>({
        path: `/affiliates/${affiliateNetworkId}/callbacks`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name GetAffiliatePublishersAffiliatesAffiliateIdPublishersGet
     * @summary Get Affiliate Publishers
     * @request GET:/affiliates/{affiliate_id}/publishers
     * @secure
     */
    getAffiliatePublishersAffiliatesAffiliateIdPublishersGet: (affiliateId: string, params: RequestParams = {}) =>
      this.request<Affiliate[], HTTPValidationError>({
        path: `/affiliates/${affiliateId}/publishers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name AdtractionNetworkCallbackAffiliatesDevAdtractionCallbackSiteTtGet
     * @summary Adtraction Network Callback
     * @request GET:/affiliates/dev/adtraction/callback/{site}/t/t
     * @secure
     */
    adtractionNetworkCallbackAffiliatesDevAdtractionCallbackSiteTTGet: (site: string, params: RequestParams = {}) =>
      this.request<HTTPStatus, HTTPValidationError>({
        path: `/affiliates/dev/adtraction/callback/${site}/t/t`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name AdserviceNetworkCallbackAffiliatesDevAdserviceCallbackCgiBinApiConversionServiceCallbackGet
     * @summary Adservice Network Callback
     * @request GET:/affiliates/dev/adservice/callback/cgi-bin/API/ConversionService/Callback
     * @secure
     */
    adserviceNetworkCallbackAffiliatesDevAdserviceCallbackCgiBinApiConversionServiceCallbackGet: (
      params: RequestParams = {},
    ) =>
      this.request<HTTPStatus, any>({
        path: `/affiliates/dev/adservice/callback/cgi-bin/API/ConversionService/Callback`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affiliate
     * @name SmartresponseNetworkCallbackAffiliatesDevSmartresponseCallbackPAshxGet
     * @summary Smartresponse Network Callback
     * @request GET:/affiliates/dev/smartresponse/callback/p.ashx
     * @secure
     */
    smartresponseNetworkCallbackAffiliatesDevSmartresponseCallbackPAshxGet: (params: RequestParams = {}) =>
      this.request<HTTPStatus, any>({
        path: `/affiliates/dev/smartresponse/callback/p.ashx`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  online = {
    /**
     * @description Get all submissions for a country
     *
     * @tags Online
     * @name GetAllSubmissionsOnlineSubmissionsGet
     * @summary Get All Submissions
     * @request GET:/online/submissions
     * @secure
     */
    getAllSubmissionsOnlineSubmissionsGet: (
      query?: {
        /** Failed */
        failed?: boolean | null;
        /** Handled */
        handled?: boolean | null;
        /** Form Validation */
        form_validation?: boolean | null;
        /** Quick Application */
        quick_application?: boolean | null;
        /** Get Customers */
        get_customers?: boolean | null;
        /** Has Recent App Check */
        has_recent_app_check?: boolean | null;
        /** Created Application */
        created_application?: boolean | null;
        /** App Created Hours Ago */
        app_created_hours_ago?: number | null;
        /** Limit */
        limit?: number | null;
        /** Page */
        page?: number | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<SubmissionReviewResponse, HTTPValidationError>({
        path: `/online/submissions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get submission by id
     *
     * @tags Online
     * @name GetASubmissionOnlineSubmissionsSubmissionIdGet
     * @summary Get A Submission
     * @request GET:/online/submissions/{submission_id}
     * @secure
     */
    getASubmissionOnlineSubmissionsSubmissionIdGet: (submissionId: string, params: RequestParams = {}) =>
      this.request<SubmissionReview, HTTPValidationError>({
        path: `/online/submissions/${submissionId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Retry processing of submissions in case of temporary error
     *
     * @tags Online
     * @name PostReprocessSubmissionsOnlineSubmissionsReprocessPost
     * @summary Post Reprocess Submissions
     * @request POST:/online/submissions/reprocess
     * @secure
     */
    postReprocessSubmissionsOnlineSubmissionsReprocessPost: (data: string[], params: RequestParams = {}) =>
      this.request<EnqueuedSubmissionJobResult[], HTTPValidationError>({
        path: `/online/submissions/reprocess`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retry processing of submission in case of temporary error
     *
     * @tags Online
     * @name PostReprocessSubmissionOnlineSubmissionsSubmissionIdReprocessPost
     * @summary Post Reprocess Submission
     * @request POST:/online/submissions/{submission_id}/reprocess
     * @secure
     */
    postReprocessSubmissionOnlineSubmissionsSubmissionIdReprocessPost: (
      submissionId: string,
      params: RequestParams = {},
    ) =>
      this.request<EnqueuedSubmissionJobResult, HTTPValidationError>({
        path: `/online/submissions/${submissionId}/reprocess`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name PostResubmitSubmissionOnlineSubmissionsSubmissionIdResubmitPost
     * @summary Post Resubmit Submission
     * @request POST:/online/submissions/{submission_id}/resubmit
     * @secure
     */
    postResubmitSubmissionOnlineSubmissionsSubmissionIdResubmitPost: (
      submissionId: string,
      data: ResubmitPayload,
      params: RequestParams = {},
    ) =>
      this.request<EnqueuedSubmissionJobResult, HTTPValidationError>({
        path: `/online/submissions/${submissionId}/resubmit`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name PostReviewSubmissionOnlineSubmissionsSubmissionIdReviewPost
     * @summary Post Review Submission
     * @request POST:/online/submissions/{submission_id}/review
     * @secure
     */
    postReviewSubmissionOnlineSubmissionsSubmissionIdReviewPost: (
      submissionId: string,
      query?: {
        /**
         * Handled
         * @default true
         */
        handled?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<HandledSubmission, HTTPValidationError>({
        path: `/online/submissions/${submissionId}/review`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name PostReviewSubmissionsOnlineSubmissionsReviewPost
     * @summary Post Review Submissions
     * @request POST:/online/submissions/review
     * @secure
     */
    postReviewSubmissionsOnlineSubmissionsReviewPost: (data: ReviewSubmissions, params: RequestParams = {}) =>
      this.request<HandledSubmission[], HTTPValidationError>({
        path: `/online/submissions/review`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Converts a credit card submission to a blanco submission
     *
     * @tags Online
     * @name PostConvertToBlancoOnlineSubmissionsSubmissionIdConvertToBlancoPost
     * @summary Post Convert To Blanco
     * @request POST:/online/submissions/{submission_id}/convert_to_blanco
     * @secure
     */
    postConvertToBlancoOnlineSubmissionsSubmissionIdConvertToBlancoPost: (
      submissionId: string,
      params: RequestParams = {},
    ) =>
      this.request<EnqueuedSubmissionJobResult, HTTPValidationError>({
        path: `/online/submissions/${submissionId}/convert_to_blanco`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name OnlineAppsMessagesOnlineSendBlancoSmsMessagesPost
     * @summary Online Apps Messages
     * @request POST:/online/send_blanco_sms_messages
     * @secure
     */
    onlineAppsMessagesOnlineSendBlancoSmsMessagesPost: (
      query: {
        country: Country;
        product: Product;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/online/send_blanco_sms_messages`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name WhitelistedPniOnlineTestingPnisGet
     * @summary Whitelisted Pni
     * @request GET:/online/testing-pnis
     * @secure
     */
    whitelistedPniOnlineTestingPnisGet: (params: RequestParams = {}) =>
      this.request<OnlineFormsTestingPNIResponse, any>({
        path: `/online/testing-pnis`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name GetBookingsOnlineBookingsCountryGet
     * @summary Get Bookings
     * @request GET:/online/bookings/{country}
     */
    getBookingsOnlineBookingsCountryGet: (country: Country, params: RequestParams = {}) =>
      this.request<EmptyModel, HTTPValidationError>({
        path: `/online/bookings/${country}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name BookMeetingOnlineBookingsCountryPost
     * @summary Book Meeting
     * @request POST:/online/bookings/{country}
     */
    bookMeetingOnlineBookingsCountryPost: (country: Country, data: BookingPayload, params: RequestParams = {}) =>
      this.request<ServicesSverkerResponsesMeeting, HTTPValidationError>({
        path: `/online/bookings/${country}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name ContactFormOnlineContactCountryPost
     * @summary Contact Form
     * @request POST:/online/contact/{country}
     */
    contactFormOnlineContactCountryPost: (country: Country, data: ContactFormPayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/online/contact/${country}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name MortgageFormOnlineMortgageCountryPost
     * @summary Mortgage Form
     * @request POST:/online/mortgage/{country}
     */
    mortgageFormOnlineMortgageCountryPost: (country: Country, data: MortgageFormPayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/online/mortgage/${country}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name BlancoApplicationOnlineBlancoApplicationCountryPost
     * @summary Blanco Application
     * @request POST:/online/blanco-application/{country}
     */
    blancoApplicationOnlineBlancoApplicationCountryPost: (
      country: Country,
      data: EmptyModel,
      params: RequestParams = {},
    ) =>
      this.request<FormApplicationSubmissionResult, HTTPValidationError>({
        path: `/online/blanco-application/${country}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Online
     * @name OnlineQuickCreateOnlineQuickCreatePost
     * @summary Online Quick Create
     * @request POST:/online/quick-create
     */
    onlineQuickCreateOnlineQuickCreatePost: (
      data:
        | (
            | ({
                product: "blanco";
              } & QuickCreateBlancoApplication)
            | ({
                product: "mortgage";
              } & QuickCreateMortgageApplication)
          )
        | object,
      params: RequestParams = {},
    ) =>
      this.request<FormApplicationSubmissionResult, HTTPValidationError>({
        path: `/online/quick-create`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Norway only
     *
     * @tags Online
     * @name NorwegianMortgageApplicationOnlineNorwegianMortgageApplicationPost
     * @summary Norwegian Mortgage Application
     * @request POST:/online/norwegian-mortgage-application
     */
    norwegianMortgageApplicationOnlineNorwegianMortgageApplicationPost: (
      data: EmptyModel,
      params: RequestParams = {},
    ) =>
      this.request<FormApplicationSubmissionResult, HTTPValidationError>({
        path: `/online/norwegian-mortgage-application`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  rq = {
    /**
     * No description
     *
     * @tags Monitor
     * @name GetListOfJobsRqJobsGet
     * @summary Get List Of Jobs
     * @request GET:/rq/jobs
     * @secure
     */
    getListOfJobsRqJobsGet: (
      query: {
        job_type: JobType;
      },
      params: RequestParams = {},
    ) =>
      this.request<RqJobStatusResponse[], HTTPValidationError>({
        path: `/rq/jobs`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Monitor
     * @name GetFailedJobsRqManualFailGet
     * @summary Get Failed Jobs
     * @request GET:/rq/manual-fail
     * @secure
     */
    getFailedJobsRqManualFailGet: (params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, any>({
        path: `/rq/manual-fail`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Monitor
     * @name ClearFailedJobRqDeleteJobJobIdGet
     * @summary Clear Failed Job
     * @request GET:/rq/delete-job/{job_id}
     * @secure
     */
    clearFailedJobRqDeleteJobJobIdGet: (jobId: string, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/rq/delete-job/${jobId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Monitor
     * @name ReScheduleRqReScheduleGet
     * @summary Re Schedule
     * @request GET:/rq/re-schedule
     * @secure
     */
    reScheduleRqReScheduleGet: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/rq/re-schedule`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Monitor
     * @name GetSingleJobRqJobIdGet
     * @summary Get Single Job
     * @request GET:/rq/{job_id}
     * @secure
     */
    getSingleJobRqJobIdGet: (jobId: string, params: RequestParams = {}) =>
      this.request<RqJobStatusResponse | null, HTTPValidationError>({
        path: `/rq/${jobId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  kdlt = {
    /**
     * No description
     *
     * @tags kdlt
     * @name CallbackKdltCallbackPost
     * @summary Callback
     * @request POST:/kdlt/callback
     * @secure
     */
    callbackKdltCallbackPost: (data: KDLTCallbackPayload, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/kdlt/callback`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  dialMaker = {
    /**
     * No description
     *
     * @tags Dial Maker
     * @name GetLeadsForCampaignDialMakerTmLeadsCampaignIdPost
     * @summary Get Leads For Campaign
     * @request POST:/dial-maker/tm-leads/{campaign_id}
     * @secure
     */
    getLeadsForCampaignDialMakerTmLeadsCampaignIdPost: (
      campaignId: string,
      data: RoutersDialMakerPayloadsLeadExtraction,
      params: RequestParams = {},
    ) =>
      this.request<TMLeadsJob, HTTPValidationError>({
        path: `/dial-maker/tm-leads/${campaignId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name GetJobStatusOrResultDialMakerJobStatusJobIdGet
     * @summary Get Job Status Or Result
     * @request GET:/dial-maker/job-status/{job_id}
     * @secure
     */
    getJobStatusOrResultDialMakerJobStatusJobIdGet: (jobId: string, params: RequestParams = {}) =>
      this.request<TMLeadsExtraction | TMLeadsJob, HTTPValidationError>({
        path: `/dial-maker/job-status/${jobId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name GetDownloadUrlDialMakerJobResultJobIdDownloadUrlGet
     * @summary Get Download Url
     * @request GET:/dial-maker/job-result/{job_id}/download-url
     * @secure
     */
    getDownloadUrlDialMakerJobResultJobIdDownloadUrlGet: (jobId: string, params: RequestParams = {}) =>
      this.request<RoutersDialMakerPayloadsSignedUrl, HTTPValidationError>({
        path: `/dial-maker/job-result/${jobId}/download-url`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name GetCampaignsDialMakerCampaignsGet
     * @summary Get Campaigns
     * @request GET:/dial-maker/campaigns
     * @secure
     */
    getCampaignsDialMakerCampaignsGet: (
      query: {
        /** Page */
        page: number;
        /** Limit */
        limit: number;
        /** Department */
        department?: ZDepartment | null;
        /** Country */
        country?: Country | null;
        /** Product */
        product?: Product | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<TMCampaignList, HTTPValidationError>({
        path: `/dial-maker/campaigns`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name CreateCampaignDialMakerCampaignsPost
     * @summary Create Campaign
     * @request POST:/dial-maker/campaigns
     * @secure
     */
    createCampaignDialMakerCampaignsPost: (data: CreateTMCampaign, params: RequestParams = {}) =>
      this.request<TMCampaign, HTTPValidationError>({
        path: `/dial-maker/campaigns`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name UpdateCampaignDialMakerCampaignsCampaignIdPut
     * @summary Update Campaign
     * @request PUT:/dial-maker/campaigns/{campaign_id}
     * @secure
     */
    updateCampaignDialMakerCampaignsCampaignIdPut: (
      campaignId: string,
      data: CreateTMCampaign,
      params: RequestParams = {},
    ) =>
      this.request<TMCampaign, HTTPValidationError>({
        path: `/dial-maker/campaigns/${campaignId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name GetCampaignDialMakerCampaignsCampaignIdGet
     * @summary Get Campaign
     * @request GET:/dial-maker/campaigns/{campaign_id}
     * @secure
     */
    getCampaignDialMakerCampaignsCampaignIdGet: (campaignId: string, params: RequestParams = {}) =>
      this.request<TMCampaign, HTTPValidationError>({
        path: `/dial-maker/campaigns/${campaignId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name DeleteCampaignDialMakerCampaignsCampaignIdDelete
     * @summary Delete Campaign
     * @request DELETE:/dial-maker/campaigns/{campaign_id}
     * @secure
     */
    deleteCampaignDialMakerCampaignsCampaignIdDelete: (campaignId: string, params: RequestParams = {}) =>
      this.request<boolean, HTTPValidationError>({
        path: `/dial-maker/campaigns/${campaignId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name GetCampaignExtractionsDialMakerCampaignsCampaignIdExtractionsGet
     * @summary Get Campaign Extractions
     * @request GET:/dial-maker/campaigns/{campaign_id}/extractions
     * @secure
     */
    getCampaignExtractionsDialMakerCampaignsCampaignIdExtractionsGet: (
      campaignId: string,
      query: {
        /** Page */
        page: number;
        /** Limit */
        limit: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<TMLeadsExtraction[], HTTPValidationError>({
        path: `/dial-maker/campaigns/${campaignId}/extractions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker
     * @name DownloadXlsxDialMakerDownloadSignatureGet
     * @summary Download Xlsx
     * @request GET:/dial-maker/download/{signature}
     */
    downloadXlsxDialMakerDownloadSignatureGet: (signature: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/dial-maker/download/${signature}`,
        method: "GET",
        ...params,
      }),
  };
  dialmaker = {
    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name CreateCampaignDialmakerCampaignPost
     * @summary Create Campaign
     * @request POST:/dialmaker/campaign
     * @secure
     */
    createCampaignDialmakerCampaignPost: (data: CreateCampaignData, params: RequestParams = {}) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign, HTTPValidationError>({
        path: `/dialmaker/campaign`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name UpdateCampaignDialmakerCampaignCampaignIdPut
     * @summary Update Campaign
     * @request PUT:/dialmaker/campaign/{campaign_id}
     * @secure
     */
    updateCampaignDialmakerCampaignCampaignIdPut: (
      campaignId: string,
      data: UpdateCampaignData,
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetCampaignDialmakerCampaignCampaignIdGet
     * @summary Get Campaign
     * @request GET:/dialmaker/campaign/{campaign_id}
     * @secure
     */
    getCampaignDialmakerCampaignCampaignIdGet: (campaignId: string, params: RequestParams = {}) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name UpdateCampaignAutoDialDialmakerCampaignCampaignIdAutoDialPut
     * @summary Update Campaign Auto Dial
     * @request PUT:/dialmaker/campaign/{campaign_id}/auto-dial
     * @secure
     */
    updateCampaignAutoDialDialmakerCampaignCampaignIdAutoDialPut: (
      campaignId: string,
      query: {
        /** Auto Dial */
        auto_dial: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}/auto-dial`,
        method: "PUT",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetCampaignSourceDialmakerCampaignCampaignIdDataSourceGet
     * @summary Get Campaign Source
     * @request GET:/dialmaker/campaign/{campaign_id}/data/source
     * @secure
     */
    getCampaignSourceDialmakerCampaignCampaignIdDataSourceGet: (campaignId: string, params: RequestParams = {}) =>
      this.request<SheetCampaignSource | BigQueryCampaignSource | ProcessCampaignSource, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}/data/source`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetCampaignExtractionsDialmakerCampaignCampaignIdExtractionsGet
     * @summary Get Campaign Extractions
     * @request GET:/dialmaker/campaign/{campaign_id}/extractions
     * @secure
     */
    getCampaignExtractionsDialmakerCampaignCampaignIdExtractionsGet: (
      campaignId: string,
      query?: {
        /**
         * Page
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * @min 1
         * @max 100000
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageCampaignExtraction, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}/extractions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetCampaignSheetsDialmakerCampaignCampaignIdDataSheetsGet
     * @summary Get Campaign Sheets
     * @request GET:/dialmaker/campaign/{campaign_id}/data/sheets
     * @secure
     */
    getCampaignSheetsDialmakerCampaignCampaignIdDataSheetsGet: (
      campaignId: string,
      query?: {
        /**
         * Page
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * @min 1
         * @max 100000
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageSheet, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}/data/sheets`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name DeactivateCampaignDialmakerCampaignDeactivatePost
     * @summary Deactivate Campaign
     * @request POST:/dialmaker/campaign/deactivate
     * @secure
     */
    deactivateCampaignDialmakerCampaignDeactivatePost: (
      data: BodyDeactivateCampaignDialmakerCampaignDeactivatePost,
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign, HTTPValidationError>({
        path: `/dialmaker/campaign/deactivate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name ActivateCampaignDialmakerCampaignActivatePost
     * @summary Activate Campaign
     * @request POST:/dialmaker/campaign/activate
     * @secure
     */
    activateCampaignDialmakerCampaignActivatePost: (
      data: BodyActivateCampaignDialmakerCampaignActivatePost,
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign, HTTPValidationError>({
        path: `/dialmaker/campaign/activate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name ExtractCampaignsDialmakerCampaignExtractPost
     * @summary Extract Campaigns
     * @request POST:/dialmaker/campaign/extract
     * @secure
     */
    extractCampaignsDialmakerCampaignExtractPost: (
      data: BodyExtractCampaignsDialmakerCampaignExtractPost,
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign, HTTPValidationError>({
        path: `/dialmaker/campaign/extract`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name DeleteExtractionDialmakerCampaignExtractionDelete
     * @summary Delete Extraction
     * @request DELETE:/dialmaker/campaign/extraction
     * @secure
     */
    deleteExtractionDialmakerCampaignExtractionDelete: (
      data: BodyDeleteExtractionDialmakerCampaignExtractionDelete,
      params: RequestParams = {},
    ) =>
      this.request<string, HTTPValidationError>({
        path: `/dialmaker/campaign/extraction`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name FindCampaignsDialmakerCampaignFindPost
     * @summary Find Campaigns
     * @request POST:/dialmaker/campaign/find
     * @secure
     */
    findCampaignsDialmakerCampaignFindPost: (
      data: BodyFindCampaignsDialmakerCampaignFindPost,
      query?: {
        /**
         * Page
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * @min 1
         * @max 100000
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageCampaign, HTTPValidationError>({
        path: `/dialmaker/campaign/find`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetLeadsDialmakerCampaignLeadsCampaignIdGet
     * @summary Get Leads
     * @request GET:/dialmaker/campaign/leads/{campaign_id}
     * @secure
     */
    getLeadsDialmakerCampaignLeadsCampaignIdGet: (
      campaignId: string,
      query?: {
        /**
         * Page
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * @min 1
         * @max 100000
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDMLead, HTTPValidationError>({
        path: `/dialmaker/campaign/leads/${campaignId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name ImportLeadsDialmakerCampaignImportPost
     * @summary Import Leads
     * @request POST:/dialmaker/campaign/import
     * @secure
     */
    importLeadsDialmakerCampaignImportPost: (
      data: BodyImportLeadsDialmakerCampaignImportPost,
      query?: {
        /**
         * Synchronous
         * Import leads synchronously
         * @default false
         */
        synchronous?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign | string, HTTPValidationError>({
        path: `/dialmaker/campaign/import`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetJobStatusOrResultDialmakerCampaignJobStatusJobIdGet
     * @summary Get Job Status Or Result
     * @request GET:/dialmaker/campaign/job-status/{job_id}
     * @secure
     */
    getJobStatusOrResultDialmakerCampaignJobStatusJobIdGet: (jobId: string, params: RequestParams = {}) =>
      this.request<InterimLeadJob | InterimLead[], HTTPValidationError>({
        path: `/dialmaker/campaign/job-status/${jobId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name UploadAttachmentDialmakerCampaignImageUploadPost
     * @summary Upload Attachment
     * @request POST:/dialmaker/campaign/image/upload
     * @secure
     */
    uploadAttachmentDialmakerCampaignImageUploadPost: (
      data: BodyUploadAttachmentDialmakerCampaignImageUploadPost,
      params: RequestParams = {},
    ) =>
      this.request<string, HTTPValidationError>({
        path: `/dialmaker/campaign/image/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name ExportAndExtractLeadsUrlDialmakerCampaignExportPost
     * @summary Export And Extract Leads Url
     * @request POST:/dialmaker/campaign/export
     * @secure
     */
    exportAndExtractLeadsUrlDialmakerCampaignExportPost: (
      data: BodyExportAndExtractLeadsUrlDialmakerCampaignExportPost,
      params: RequestParams = {},
    ) =>
      this.request<RoutersDialMaker2PayloadsSignedUrl, HTTPValidationError>({
        path: `/dialmaker/campaign/export`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name ExportAndExtractLeadsUrlDownloadDialmakerCampaignExtractionExportPost
     * @summary Export And Extract Leads Url Download
     * @request POST:/dialmaker/campaign/extraction/export
     * @secure
     */
    exportAndExtractLeadsUrlDownloadDialmakerCampaignExtractionExportPost: (
      data: BodyExportAndExtractLeadsUrlDownloadDialmakerCampaignExtractionExportPost,
      params: RequestParams = {},
    ) =>
      this.request<RoutersDialMaker2PayloadsSignedUrl, HTTPValidationError>({
        path: `/dialmaker/campaign/extraction/export`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetOngoingExtractionsDialmakerCampaignCampaignIdOngoingExtractionsPost
     * @summary Get Ongoing Extractions
     * @request POST:/dialmaker/campaign/{campaign_id}/ongoing_extractions
     * @secure
     */
    getOngoingExtractionsDialmakerCampaignCampaignIdOngoingExtractionsPost: (
      campaignId: string,
      params: RequestParams = {},
    ) =>
      this.request<CampaignOngoingExtraction[], HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}/ongoing_extractions`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetOutcomesReportDialmakerCampaignSearchLeadsPost
     * @summary Get Outcomes Report
     * @request POST:/dialmaker/campaign/search-leads
     * @secure
     */
    getOutcomesReportDialmakerCampaignSearchLeadsPost: (
      data: SearchLeadsPayload,
      query?: {
        /**
         * Page
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * @min 1
         * @max 100000
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageDMLead | null, HTTPValidationError>({
        path: `/dialmaker/campaign/search-leads`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint is used to get statistics: - Leads with 0 outcomes - Available leads to call - Already extracted count - Leads left to extract (cold campaigns only)
     *
     * @tags Dial Maker 2
     * @name GetExtractionsStatsDialmakerCampaignCampaignIdExtractionsStatsGet
     * @summary Get Extractions Stats
     * @request GET:/dialmaker/campaign/{campaign_id}/extractions_stats
     * @secure
     */
    getExtractionsStatsDialmakerCampaignCampaignIdExtractionsStatsGet: (
      campaignId: string,
      params: RequestParams = {},
    ) =>
      this.request<CampaignExtractionsStats, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}/extractions_stats`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetCampaignsByIdsDialmakerCampaignGetCampaignsByIdsPost
     * @summary Get Campaigns By Ids
     * @request POST:/dialmaker/campaign/get_campaigns_by_ids
     * @secure
     */
    getCampaignsByIdsDialmakerCampaignGetCampaignsByIdsPost: (data: string[], params: RequestParams = {}) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign[], HTTPValidationError>({
        path: `/dialmaker/campaign/get_campaigns_by_ids`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name SeedCampaignsDialmakerCampaignProcessSeedPost
     * @summary Seed Campaigns
     * @request POST:/dialmaker/campaign/process/seed
     * @secure
     */
    seedCampaignsDialmakerCampaignProcessSeedPost: (params: RequestParams = {}) =>
      this.request<Record<string, UpsertCampaignStatus>, any>({
        path: `/dialmaker/campaign/process/seed`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name FetchAndHandleInsurancesDialmakerCampaignProcessFetchAndHandlePost
     * @summary Fetch And Handle Insurances
     * @request POST:/dialmaker/campaign/process/fetch_and_handle
     * @secure
     */
    fetchAndHandleInsurancesDialmakerCampaignProcessFetchAndHandlePost: (
      query: {
        /** Used only for Dial Maker 2.0 for now, but can be used for other non-banking products in the future if needed */
        product: ExtendedProduct;
        country: Country;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/dialmaker/campaign/process/fetch_and_handle`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name SendMailToCampaignManuallyDialmakerCampaignCampaignIdMailSendManuallyPost
     * @summary Send Mail To Campaign Manually
     * @request POST:/dialmaker/campaign/{campaign_id}/mail/send_manually
     * @secure
     */
    sendMailToCampaignManuallyDialmakerCampaignCampaignIdMailSendManuallyPost: (
      campaignId: string,
      query?: {
        /** Limit Amount Of Recipients */
        limit_amount_of_recipients?: number | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}/mail/send_manually`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetMailStatisticsDialmakerCampaignMailStatisticsPost
     * @summary Get Mail Statistics
     * @request POST:/dialmaker/campaign/mail/statistics
     * @secure
     */
    getMailStatisticsDialmakerCampaignMailStatisticsPost: (
      data: BodyGetMailStatisticsDialmakerCampaignMailStatisticsPost,
      params: RequestParams = {},
    ) =>
      this.request<CampaignMailStats[], HTTPValidationError>({
        path: `/dialmaker/campaign/mail/statistics`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name DryRunMailTemplateDialmakerCampaignCampaignIdMailTemplateSlugDryRunPost
     * @summary Dry Run Mail Template
     * @request POST:/dialmaker/campaign/{campaign_id}/mail/template/{slug}/dry-run
     * @secure
     */
    dryRunMailTemplateDialmakerCampaignCampaignIdMailTemplateSlugDryRunPost: (
      campaignId: string,
      slug: string,
      params: RequestParams = {},
    ) =>
      this.request<TemplateDryRunResult, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}/mail/template/${slug}/dry-run`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name SendTestMailDialmakerCampaignMailSendTestPost
     * @summary Send Test Mail
     * @request POST:/dialmaker/campaign/mail/send_test
     * @secure
     */
    sendTestMailDialmakerCampaignMailSendTestPost: (data: TestTemplatePayload, params: RequestParams = {}) =>
      this.request<SentTemplateResponse[], HTTPValidationError>({
        path: `/dialmaker/campaign/mail/send_test`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetMailLeadsOverviewDialmakerCampaignCampaignIdMailLeadsGet
     * @summary Get Mail Leads Overview
     * @request GET:/dialmaker/campaign/{campaign_id}/mail/leads
     * @secure
     */
    getMailLeadsOverviewDialmakerCampaignCampaignIdMailLeadsGet: (campaignId: string, params: RequestParams = {}) =>
      this.request<CampaignMailOverview, HTTPValidationError>({
        path: `/dialmaker/campaign/${campaignId}/mail/leads`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetUserCampaignsDialmakerCampaignsGet
     * @summary Get User Campaigns
     * @request GET:/dialmaker/campaigns
     * @deprecated
     * @secure
     */
    getUserCampaignsDialmakerCampaignsGet: (params: RequestParams = {}) =>
      this.request<UserCampaignsExt | null, any>({
        path: `/dialmaker/campaigns`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name AvailableCampaignsDialmakerCampaignsAvailablePost
     * @summary Available Campaigns
     * @request POST:/dialmaker/campaigns/available
     * @secure
     */
    availableCampaignsDialmakerCampaignsAvailablePost: (params: RequestParams = {}) =>
      this.request<Available, any>({
        path: `/dialmaker/campaigns/available`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name JoinCampaignDialmakerCampaignsJoinPost
     * @summary Join Campaign
     * @request POST:/dialmaker/campaigns/join
     * @secure
     */
    joinCampaignDialmakerCampaignsJoinPost: (
      data: BodyJoinCampaignDialmakerCampaignsJoinPost,
      params: RequestParams = {},
    ) =>
      this.request<Available, HTTPValidationError>({
        path: `/dialmaker/campaigns/join`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name LeaveCampaignDialmakerCampaignsLeavePost
     * @summary Leave Campaign
     * @request POST:/dialmaker/campaigns/leave
     * @secure
     */
    leaveCampaignDialmakerCampaignsLeavePost: (
      data: BodyLeaveCampaignDialmakerCampaignsLeavePost,
      params: RequestParams = {},
    ) =>
      this.request<Available, HTTPValidationError>({
        path: `/dialmaker/campaigns/leave`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name PlayCampaignDialmakerCampaignsPlayPost
     * @summary Play Campaign
     * @request POST:/dialmaker/campaigns/play
     * @secure
     */
    playCampaignDialmakerCampaignsPlayPost: (
      data: BodyPlayCampaignDialmakerCampaignsPlayPost,
      params: RequestParams = {},
    ) =>
      this.request<Available, HTTPValidationError>({
        path: `/dialmaker/campaigns/play`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name PauseCampaignDialmakerCampaignsPausePost
     * @summary Pause Campaign
     * @request POST:/dialmaker/campaigns/pause
     * @secure
     */
    pauseCampaignDialmakerCampaignsPausePost: (
      data: BodyPauseCampaignDialmakerCampaignsPausePost,
      params: RequestParams = {},
    ) =>
      this.request<Available, HTTPValidationError>({
        path: `/dialmaker/campaigns/pause`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetCardDialmakerCardNextPost
     * @summary Get Card
     * @request POST:/dialmaker/card/next
     * @secure
     */
    getCardDialmakerCardNextPost: (params: RequestParams = {}) =>
      this.request<DMLeadExt | null, any>({
        path: `/dialmaker/card/next`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name ViewDialmakerCardViewPost
     * @summary View
     * @request POST:/dialmaker/card/view
     * @secure
     */
    viewDialmakerCardViewPost: (data: BodyViewDialmakerCardViewPost, params: RequestParams = {}) =>
      this.request<DMLeadExt, HTTPValidationError>({
        path: `/dialmaker/card/view`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name UpdateDialmakerCardUpdatePost
     * @summary Update
     * @request POST:/dialmaker/card/update
     * @secure
     */
    updateDialmakerCardUpdatePost: (data: BodyUpdateDialmakerCardUpdatePost, params: RequestParams = {}) =>
      this.request<DMLead, HTTPValidationError>({
        path: `/dialmaker/card/update`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name HangupDialmakerCardHangupPost
     * @summary Hangup
     * @request POST:/dialmaker/card/hangup
     * @secure
     */
    hangupDialmakerCardHangupPost: (params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, any>({
        path: `/dialmaker/card/hangup`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name CallSuccessDialmakerCardOutcomeSuccessPost
     * @summary Call Success
     * @request POST:/dialmaker/card/outcome/success
     * @secure
     */
    callSuccessDialmakerCardOutcomeSuccessPost: (
      data: BodyCallSuccessDialmakerCardOutcomeSuccessPost,
      params: RequestParams = {},
    ) =>
      this.request<DMLeadExt | null, HTTPValidationError>({
        path: `/dialmaker/card/outcome/success`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name CallBackDialmakerCardOutcomeCallBackPost
     * @summary Call Back
     * @request POST:/dialmaker/card/outcome/call_back
     * @secure
     */
    callBackDialmakerCardOutcomeCallBackPost: (
      data: BodyCallBackDialmakerCardOutcomeCallBackPost,
      params: RequestParams = {},
    ) =>
      this.request<DMLeadExt | null, HTTPValidationError>({
        path: `/dialmaker/card/outcome/call_back`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name CallBackDialmakerCardOutcomeLaterPost
     * @summary Call Back
     * @request POST:/dialmaker/card/outcome/later
     * @secure
     */
    callBackDialmakerCardOutcomeLaterPost: (
      data: BodyCallBackDialmakerCardOutcomeLaterPost,
      params: RequestParams = {},
    ) =>
      this.request<DMLeadExt | null, HTTPValidationError>({
        path: `/dialmaker/card/outcome/later`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name CallNoContactDialmakerCardOutcomeNoContactPost
     * @summary Call No Contact
     * @request POST:/dialmaker/card/outcome/no_contact
     * @secure
     */
    callNoContactDialmakerCardOutcomeNoContactPost: (
      data: BodyCallNoContactDialmakerCardOutcomeNoContactPost,
      params: RequestParams = {},
    ) =>
      this.request<DMLeadExt | null, HTTPValidationError>({
        path: `/dialmaker/card/outcome/no_contact`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name CallDiscardsDialmakerCardOutcomeDiscardPost
     * @summary Call Discards
     * @request POST:/dialmaker/card/outcome/discard
     * @secure
     */
    callDiscardsDialmakerCardOutcomeDiscardPost: (
      data: BodyCallDiscardsDialmakerCardOutcomeDiscardPost,
      params: RequestParams = {},
    ) =>
      this.request<DMLeadExt | null, HTTPValidationError>({
        path: `/dialmaker/card/outcome/discard`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name CallFailureDialmakerCardOutcomeFailurePost
     * @summary Call Failure
     * @request POST:/dialmaker/card/outcome/failure
     * @secure
     */
    callFailureDialmakerCardOutcomeFailurePost: (
      data: BodyCallFailureDialmakerCardOutcomeFailurePost,
      params: RequestParams = {},
    ) =>
      this.request<DMLeadExt | null, HTTPValidationError>({
        path: `/dialmaker/card/outcome/failure`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetOutcomesDialmakerCardOutcomesPost
     * @summary Get Outcomes
     * @request POST:/dialmaker/card/outcomes
     * @secure
     */
    getOutcomesDialmakerCardOutcomesPost: (
      data: BodyGetOutcomesDialmakerCardOutcomesPost,
      params: RequestParams = {},
    ) =>
      this.request<Outcomes, HTTPValidationError>({
        path: `/dialmaker/card/outcomes`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name SearchDialmakerCardSearchPost
     * @summary Search
     * @request POST:/dialmaker/card/search
     * @secure
     */
    searchDialmakerCardSearchPost: (data: BodySearchDialmakerCardSearchPost, params: RequestParams = {}) =>
      this.request<DMLeadExt[], HTTPValidationError>({
        path: `/dialmaker/card/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name LockDialmakerCardLockPost
     * @summary Lock
     * @request POST:/dialmaker/card/lock
     * @secure
     */
    lockDialmakerCardLockPost: (data: BodyLockDialmakerCardLockPost, params: RequestParams = {}) =>
      this.request<DMLead, HTTPValidationError>({
        path: `/dialmaker/card/lock`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name PersonalCallbacksDialmakerCardPersonalCallbacksPost
     * @summary Personal Callbacks
     * @request POST:/dialmaker/card/personal_callbacks
     * @secure
     */
    personalCallbacksDialmakerCardPersonalCallbacksPost: (params: RequestParams = {}) =>
      this.request<DMLeadExt[], any>({
        path: `/dialmaker/card/personal_callbacks`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetSlotsDialmakerCardSlotsGet
     * @summary Get Slots
     * @request GET:/dialmaker/card/slots
     * @secure
     */
    getSlotsDialmakerCardSlotsGet: (
      query: {
        /**
         * Date
         * @format date-time
         */
        date: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BookableSlot[], HTTPValidationError>({
        path: `/dialmaker/card/slots`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetPreviousAppsDialmakerCardPreviousAppsPost
     * @summary Get Previous Apps
     * @request POST:/dialmaker/card/previous_apps
     * @secure
     */
    getPreviousAppsDialmakerCardPreviousAppsPost: (
      query: {
        /**
         * Customer Id
         * @format uuid
         */
        customer_id: string;
        country: Country;
        /** Pni */
        pni?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<PreviousApplicationsList, HTTPValidationError>({
        path: `/dialmaker/card/previous_apps`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get all insurances for a customer, sorted by creation date.
     *
     * @tags Dial Maker 2
     * @name GetCustomerInsurancesDialmakerCardCustomerInsurancesPost
     * @summary Get Customer Insurances
     * @request POST:/dialmaker/card/customer_insurances
     * @secure
     */
    getCustomerInsurancesDialmakerCardCustomerInsurancesPost: (
      query: {
        /**
         * Customer Id
         * @format uuid
         */
        customer_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Insurance[], HTTPValidationError>({
        path: `/dialmaker/card/customer_insurances`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name MineDialmakerCardOutcomesMineTodayPost
     * @summary Mine
     * @request POST:/dialmaker/card/outcomes/mine_today
     * @secure
     */
    mineDialmakerCardOutcomesMineTodayPost: (
      query: {
        /**
         * Start Date
         * @format date-time
         */
        start_date: string;
        /**
         * End Date
         * @format date-time
         */
        end_date: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OutcomeCount, HTTPValidationError>({
        path: `/dialmaker/card/outcomes/mine_today`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Upload file and return a preview of sheet, together with the file id needed to import leads. Uploaded file whether it's csv or xlsx should have headers in the first row to be able to map the columns to the lead data model.
     *
     * @tags Dial Maker 2
     * @name UploadDialmakerSheetCampaignIdUploadWithPreviewPost
     * @summary Upload
     * @request POST:/dialmaker/sheet/{campaign_id}/upload_with_preview
     * @secure
     */
    uploadDialmakerSheetCampaignIdUploadWithPreviewPost: (
      campaignId: string,
      data: BodyUploadDialmakerSheetCampaignIdUploadWithPreviewPost,
      params: RequestParams = {},
    ) =>
      this.request<SheetPreview, HTTPValidationError>({
        path: `/dialmaker/sheet/${campaignId}/upload_with_preview`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint is used to import leads from a file to a global leads collection with campaign_id and file_id metadata
     *
     * @tags Dial Maker 2
     * @name ImportFromFileDialmakerSheetCampaignIdImportFromFileIdPost
     * @summary Import From File
     * @request POST:/dialmaker/sheet/{campaign_id}/import_from/{file_id}
     * @secure
     */
    importFromFileDialmakerSheetCampaignIdImportFromFileIdPost: (
      campaignId: string,
      fileId: string,
      data: Record<string, string>,
      query?: {
        /**
         * Synchronous
         * Import leads synchronously
         * @default false
         */
        synchronous?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign | string, HTTPValidationError>({
        path: `/dialmaker/sheet/${campaignId}/import_from/${fileId}`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint is used to delete a file from the database if it was not imported yet
     *
     * @tags Dial Maker 2
     * @name DeleteCampaignSheetDialmakerSheetCampaignIdDeleteFileFileIdDelete
     * @summary Delete Campaign Sheet
     * @request DELETE:/dialmaker/sheet/{campaign_id}/delete_file/{file_id}
     * @secure
     */
    deleteCampaignSheetDialmakerSheetCampaignIdDeleteFileFileIdDelete: (
      campaignId: string,
      fileId: string,
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign | null, HTTPValidationError>({
        path: `/dialmaker/sheet/${campaignId}/delete_file/${fileId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint is used to delete import of a given sheet_id with all interim leads
     *
     * @tags Dial Maker 2
     * @name DeleteInterimLeadsDialmakerSheetCampaignIdImportSheetIdDelete
     * @summary Delete Interim Leads
     * @request DELETE:/dialmaker/sheet/{campaign_id}/import/{sheet_id}
     * @secure
     */
    deleteInterimLeadsDialmakerSheetCampaignIdImportSheetIdDelete: (
      campaignId: string,
      sheetId: string,
      query?: {
        /**
         * Force
         * Force delete import even if status is IN_PROGRESS
         * @default false
         */
        force?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ModelsDialMaker2CampaignAggregateCampaign | null, HTTPValidationError>({
        path: `/dialmaker/sheet/${campaignId}/import/${sheetId}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetOutcomesDialmakerMiscOutcomesGet
     * @summary Get Outcomes
     * @request GET:/dialmaker/misc/outcomes
     * @secure
     */
    getOutcomesDialmakerMiscOutcomesGet: (params: RequestParams = {}) =>
      this.request<Outcomes, any>({
        path: `/dialmaker/misc/outcomes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name SaveOutcomesDialmakerMiscOutcomesPost
     * @summary Save Outcomes
     * @request POST:/dialmaker/misc/outcomes
     * @secure
     */
    saveOutcomesDialmakerMiscOutcomesPost: (
      data: BodySaveOutcomesDialmakerMiscOutcomesPost,
      params: RequestParams = {},
    ) =>
      this.request<Outcomes, HTTPValidationError>({
        path: `/dialmaker/misc/outcomes`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name AdminDialmakerAdminOutcomesPost
     * @summary Admin
     * @request POST:/dialmaker/admin/outcomes
     * @secure
     */
    adminDialmakerAdminOutcomesPost: (
      query: {
        /**
         * Start Date
         * @format date-time
         */
        start_date: string;
        /**
         * End Date
         * @format date-time
         */
        end_date: string;
      },
      data: BodyAdminDialmakerAdminOutcomesPost,
      params: RequestParams = {},
    ) =>
      this.request<OutcomeCount, HTTPValidationError>({
        path: `/dialmaker/admin/outcomes`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name BlockFieldsDialmakerAdminBlockPost
     * @summary Block Fields
     * @request POST:/dialmaker/admin/block
     * @secure
     */
    blockFieldsDialmakerAdminBlockPost: (data: BlockCustomerFieldManuallyPayload, params: RequestParams = {}) =>
      this.request<BlockCustomerFieldManuallyResponse, HTTPValidationError>({
        path: `/dialmaker/admin/block`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name StatsDialmakerAdminOutcomesUsersPost
     * @summary Stats
     * @request POST:/dialmaker/admin/outcomes/users
     * @secure
     */
    statsDialmakerAdminOutcomesUsersPost: (
      data: BodyStatsDialmakerAdminOutcomesUsersPost,
      params: RequestParams = {},
    ) =>
      this.request<OutcomeCount, HTTPValidationError>({
        path: `/dialmaker/admin/outcomes/users`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetMailTemplatesDialmakerMailTemplatesAvailableGet
     * @summary Get Mail Templates
     * @request GET:/dialmaker/mail/templates/available
     * @secure
     */
    getMailTemplatesDialmakerMailTemplatesAvailableGet: (params: RequestParams = {}) =>
      this.request<MandrillTemplate[], any>({
        path: `/dialmaker/mail/templates/available`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetVariablesTemplateVariablesDialmakerMailTemplateVariablesPost
     * @summary Get Variables Template Variables
     * @request POST:/dialmaker/mail/template/variables
     * @secure
     */
    getVariablesTemplateVariablesDialmakerMailTemplateVariablesPost: (
      data: BodyGetVariablesTemplateVariablesDialmakerMailTemplateVariablesPost,
      params: RequestParams = {},
    ) =>
      this.request<TemplateVariables, HTTPValidationError>({
        path: `/dialmaker/mail/template/variables`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetAvailableTemplateVariablesDialmakerMailTemplateAvailableVariablesGet
     * @summary Get Available Template Variables
     * @request GET:/dialmaker/mail/template/available/variables
     * @secure
     */
    getAvailableTemplateVariablesDialmakerMailTemplateAvailableVariablesGet: (
      query: {
        country: Country;
      },
      params: RequestParams = {},
    ) =>
      this.request<string[], HTTPValidationError>({
        path: `/dialmaker/mail/template/available/variables`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name TemplateRenderDialmakerMailTemplateRenderPost
     * @summary Template Render
     * @request POST:/dialmaker/mail/template/render
     * @secure
     */
    templateRenderDialmakerMailTemplateRenderPost: (data: RenderTemplatePayload, params: RequestParams = {}) =>
      this.request<string, HTTPValidationError>({
        path: `/dialmaker/mail/template/render`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  urlShortener = {
    /**
     * No description
     *
     * @tags URL Shortener
     * @name CreateUrlUrlShortenerUrlPost
     * @summary Create Url
     * @request POST:/url-shortener/url
     * @secure
     */
    createUrlUrlShortenerUrlPost: (data: BodyCreateUrlUrlShortenerUrlPost, params: RequestParams = {}) =>
      this.request<URL, HTTPValidationError>({
        path: `/url-shortener/url`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags URL Shortener
     * @name GetAllUrlsUrlShortenerUrlsGet
     * @summary Get All Urls
     * @request GET:/url-shortener/urls
     * @secure
     */
    getAllUrlsUrlShortenerUrlsGet: (params: RequestParams = {}) =>
      this.request<URL[], any>({
        path: `/url-shortener/urls`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags URL Shortener
     * @name GetUrlInfoUrlShortenerUrlKeyGet
     * @summary Get Url Info
     * @request GET:/url-shortener/{url_key}
     * @secure
     */
    getUrlInfoUrlShortenerUrlKeyGet: (urlKey: string, params: RequestParams = {}) =>
      this.request<URL, HTTPValidationError>({
        path: `/url-shortener/${urlKey}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags URL Shortener
     * @name GetShortUrlUrlShortenerUrlKeyShortenedCountryGet
     * @summary Get Short Url
     * @request GET:/url-shortener/{url_key}/shortened/{country}
     * @secure
     */
    getShortUrlUrlShortenerUrlKeyShortenedCountryGet: (urlKey: string, country: Country, params: RequestParams = {}) =>
      this.request<string, HTTPValidationError>({
        path: `/url-shortener/${urlKey}/shortened/${country}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  insurance = {
    /**
     * No description
     *
     * @tags Insurance
     * @name CreateInsuranceInsuranceInsurancePost
     * @summary Create Insurance
     * @request POST:/insurance/insurance
     * @secure
     */
    createInsuranceInsuranceInsurancePost: (data: CreateInsuranceCustomerV1, params: RequestParams = {}) =>
      this.request<Insurance, HTTPValidationError>({
        path: `/insurance/insurance`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  bank = {
    /**
     * No description
     *
     * @tags Bank Login
     * @name LoginBankLoginPost
     * @summary Login
     * @request POST:/bank/login
     */
    loginBankLoginPost: (data: Login, params: RequestParams = {}) =>
      this.request<LoginResp, HTTPValidationError>({
        path: `/bank/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Login
     * @name ValidateOtpCodeBankLoginValidatePost
     * @summary Validate Otp Code
     * @request POST:/bank/login/validate
     */
    validateOtpCodeBankLoginValidatePost: (data: OTPValidation, params: RequestParams = {}) =>
      this.request<OTPValidationResp, HTTPValidationError>({
        path: `/bank/login/validate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Login
     * @name SetNewPasswordBankSetPasswordPost
     * @summary Set New Password
     * @request POST:/bank/set-password
     */
    setNewPasswordBankSetPasswordPost: (data: SetPassword, params: RequestParams = {}) =>
      this.request<SetPasswordResp, HTTPValidationError>({
        path: `/bank/set-password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank Login
     * @name RequestNewOtpBankNewOtpPost
     * @summary Request New Otp
     * @request POST:/bank/new-otp
     */
    requestNewOtpBankNewOtpPost: (data: RequestNewOTP, params: RequestParams = {}) =>
      this.request<SetPasswordResp, HTTPValidationError>({
        path: `/bank/new-otp`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank User Administration
     * @name CreateNewBankUserBankCreateUserPost
     * @summary Create New Bank User
     * @request POST:/bank/create-user
     * @secure
     */
    createNewBankUserBankCreateUserPost: (data: CreateUser, params: RequestParams = {}) =>
      this.request<CreateUserResp, HTTPValidationError>({
        path: `/bank/create-user`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank User Administration
     * @name GetBankUsersBankGetPost
     * @summary Get Bank Users
     * @request POST:/bank/get
     * @secure
     */
    getBankUsersBankGetPost: (data: GetUsersPayload, params: RequestParams = {}) =>
      this.request<BankUserWithBankNames[], HTTPValidationError>({
        path: `/bank/get`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank User Administration
     * @name GetBankUserBankUserIdGet
     * @summary Get Bank User
     * @request GET:/bank/{user_id}
     * @secure
     */
    getBankUserBankUserIdGet: (userId: string, params: RequestParams = {}) =>
      this.request<BankUserWithBankNames, HTTPValidationError>({
        path: `/bank/${userId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank User Administration
     * @name EditBankUserBankUserIdPut
     * @summary Edit Bank User
     * @request PUT:/bank/{user_id}
     * @secure
     */
    editBankUserBankUserIdPut: (userId: string, data: EditUser, params: RequestParams = {}) =>
      this.request<BankUserWithBankNames, HTTPValidationError>({
        path: `/bank/${userId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank User Administration
     * @name ResetBankUserPasswordBankResetPasswordPost
     * @summary Reset Bank User Password
     * @request POST:/bank/reset-password
     * @secure
     */
    resetBankUserPasswordBankResetPasswordPost: (data: ResetPasswordPayload, params: RequestParams = {}) =>
      this.request<CreateUserResp, HTTPValidationError>({
        path: `/bank/reset-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank User Administration
     * @name DeactivateBankUserBankDeactivatePut
     * @summary Deactivate Bank User
     * @request PUT:/bank/deactivate
     * @secure
     */
    deactivateBankUserBankDeactivatePut: (data: DeactivateUserPayload, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/bank/deactivate`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name GetApplicationsBankApplicationsPost
     * @summary Get Applications
     * @request POST:/bank/applications
     * @secure
     */
    getApplicationsBankApplicationsPost: (data: GetApplications, params: RequestParams = {}) =>
      this.request<BankBaseApp[], HTTPValidationError>({
        path: `/bank/applications`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name GetApplicationBankApplicationApplicationIdGet
     * @summary Get Application
     * @request GET:/bank/application/{application_id}
     * @secure
     */
    getApplicationBankApplicationApplicationIdGet: (applicationId: string, params: RequestParams = {}) =>
      this.request<BankBaseApp, HTTPValidationError>({
        path: `/bank/application/${applicationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name GetDocumentMetaBankDocumentMetaCustomerIdDocumentIdGet
     * @summary Get Document Meta
     * @request GET:/bank/document/meta/{customer_id}/{document_id}
     * @secure
     */
    getDocumentMetaBankDocumentMetaCustomerIdDocumentIdGet: (
      customerId: string,
      documentId: string,
      params: RequestParams = {},
    ) =>
      this.request<DocumentMeta, HTTPValidationError>({
        path: `/bank/document/meta/${customerId}/${documentId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name GetDocumentBankGetCustomerIdDocumentIdGet
     * @summary Get Document
     * @request GET:/bank/get/{customer_id}/{document_id}
     * @secure
     */
    getDocumentBankGetCustomerIdDocumentIdGet: (customerId: string, documentId: string, params: RequestParams = {}) =>
      this.request<CustomerDocument, HTTPValidationError>({
        path: `/bank/get/${customerId}/${documentId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name GetAllBanksBankGetBanksPost
     * @summary Get All Banks
     * @request POST:/bank/get-banks
     * @secure
     */
    getAllBanksBankGetBanksPost: (data: GetBanksPayload, params: RequestParams = {}) =>
      this.request<Bank[], HTTPValidationError>({
        path: `/bank/get-banks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name GetAppEventsBankGetAppEventsApplicationIdGet
     * @summary Get App Events
     * @request GET:/bank/get_app_events/{application_id}
     * @secure
     */
    getAppEventsBankGetAppEventsApplicationIdGet: (applicationId: string, params: RequestParams = {}) =>
      this.request<EventsResponse, HTTPValidationError>({
        path: `/bank/get_app_events/${applicationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name AddCommentBankCommentPost
     * @summary Add Comment
     * @request POST:/bank/comment
     * @secure
     */
    addCommentBankCommentPost: (data: BankComment, params: RequestParams = {}) =>
      this.request<EventsResponse, HTTPValidationError>({
        path: `/bank/comment`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name PostOfferBankOfferPost
     * @summary Post Offer
     * @request POST:/bank/offer
     * @secure
     */
    postOfferBankOfferPost: (data: BankOfferPayload, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/bank/offer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank, Application
     * @name RejectBankRejectPost
     * @summary Reject
     * @request POST:/bank/reject
     * @secure
     */
    rejectBankRejectPost: (data: BankRejectPayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/bank/reject`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank, Application
     * @name DisburseBankDisbursePost
     * @summary Disburse
     * @request POST:/bank/disburse
     * @secure
     */
    disburseBankDisbursePost: (data: BankDisbursePayload, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/bank/disburse`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bank
     * @name SearchApplicationsBankSearchPost
     * @summary Search Applications
     * @request POST:/bank/search
     * @secure
     */
    searchApplicationsBankSearchPost: (
      data: RoutersBankPayloadsSearchPayload,
      query?: {
        /**
         * Page
         * @min 0
         * @default 0
         */
        page?: number;
        /**
         * Limit
         * @min 0
         * @max 50
         * @default 50
         */
        limit?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BankBaseApp[], HTTPValidationError>({
        path: `/bank/search`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  jotform = {
    /**
     * No description
     *
     * @tags Jotform
     * @name SubmissionJotformWebhookFormIdPost
     * @summary Submission
     * @request POST:/jotform/webhook/{form_id}
     */
    submissionJotformWebhookFormIdPost: (
      formId: string,
      data: BodySubmissionJotformWebhookFormIdPost,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/jotform/webhook/${formId}`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),
  };
  campaign = {
    /**
     * No description
     *
     * @tags Affiliate
     * @name CreateCampaignInitializedEventCampaignCampaignIdGet
     * @summary Create Campaign Initialized Event
     * @request GET:/campaign/{campaign_id}
     */
    createCampaignInitializedEventCampaignCampaignIdGet: (campaignId: string, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/campaign/${campaignId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  visit = {
    /**
     * No description
     *
     * @tags Affiliate
     * @name CreateVisitEventVisitCountryZidPost
     * @summary Create Visit Event
     * @request POST:/visit/{country}/{zid}
     */
    createVisitEventVisitCountryZidPost: (
      country: Country,
      zid: string,
      data: WebsiteVisitPayload,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/visit/${country}/${zid}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  slack = {
    /**
     * No description
     *
     * @tags Slack
     * @name SlackCallbackSlackCallbackPost
     * @summary Slack Callback
     * @request POST:/slack/callback
     */
    slackCallbackSlackCallbackPost: (data: BodySlackCallbackSlackCallbackPost, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/slack/callback`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),
  };
  dialMaker2 = {
    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name GetImageDialMaker2ImageGet
     * @summary Get Image
     * @request GET:/dial-maker2/image
     */
    getImageDialMaker2ImageGet: (
      query: {
        /** File Id */
        file_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/dial-maker2/image`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dial Maker 2
     * @name DownloadLeadsXlsxDialMaker2ExportDownloadSignatureGet
     * @summary Download Leads Xlsx
     * @request GET:/dial-maker2/export/download/{signature}
     */
    downloadLeadsXlsxDialMaker2ExportDownloadSignatureGet: (signature: string, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/dial-maker2/export/download/${signature}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  wellKnown = {
    /**
     * No description
     *
     * @tags Well known
     * @name GetJwksWellKnownJwksJsonGet
     * @summary Get Jwks
     * @request GET:/.well-known/jwks.json
     */
    getJwksWellKnownJwksJsonGet: (params: RequestParams = {}) =>
      this.request<EmptyModel, any>({
        path: `/.well-known/jwks.json`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  health = {
    /**
     * No description
     *
     * @tags Health
     * @name GetHealthHealthGet
     * @summary Get Health
     * @request GET:/health
     */
    getHealthHealthGet: (params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, any>({
        path: `/health`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  validator = {
    /**
     * No description
     *
     * @tags Validator
     * @name ValidateValidatorPost
     * @summary Validate
     * @request POST:/validator/
     */
    validateValidatorPost: (
      data: Email | PNI | ModelsValidatorServicePayloadPhoneNumber | BankAccount,
      params: RequestParams = {},
    ) =>
      this.request<ValidatorServiceResponseModel, HTTPValidationError>({
        path: `/validator/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Validator
     * @name ValidateValidatorPost2
     * @summary Validate
     * @request POST:/validator
     * @originalName validateValidatorPost
     * @duplicate
     */
    validateValidatorPost2: (
      data: Email | PNI | ModelsValidatorServicePayloadPhoneNumber | BankAccount,
      params: RequestParams = {},
    ) =>
      this.request<ValidatorServiceResponseModel, HTTPValidationError>({
        path: `/validator`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Validator
     * @name ValidateSafelyValidatorSafePost
     * @summary Validate Safely
     * @request POST:/validator/safe/
     */
    validateSafelyValidatorSafePost: (data: EmptyModel, params: RequestParams = {}) =>
      this.request<ValidatorServiceResponseModel, HTTPValidationError>({
        path: `/validator/safe/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Validator
     * @name ValidateSafelyValidatorSafePost2
     * @summary Validate Safely
     * @request POST:/validator/safe
     * @originalName validateSafelyValidatorSafePost
     * @duplicate
     */
    validateSafelyValidatorSafePost2: (data: EmptyModel, params: RequestParams = {}) =>
      this.request<ValidatorServiceResponseModel, HTTPValidationError>({
        path: `/validator/safe`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  mandrill = {
    /**
     * No description
     *
     * @tags Mail Webhook
     * @name MessageOpenedMandrillWebhookMessagePost
     * @summary Message Opened
     * @request POST:/mandrill/webhook/message
     */
    messageOpenedMandrillWebhookMessagePost: (
      data: BodyMessageOpenedMandrillWebhookMessagePost,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/mandrill/webhook/message`,
        method: "POST",
        body: data,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),
  };
  mail = {
    /**
     * No description
     *
     * @tags MailMaker
     * @name UnsubscribeFromMailMarketingMailPublicUnsubscribePost
     * @summary Unsubscribe From Mail Marketing
     * @request POST:/mail/public/unsubscribe
     */
    unsubscribeFromMailMarketingMailPublicUnsubscribePost: (
      query: {
        /** Mail Key */
        mail_key: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/mail/public/unsubscribe`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  p = {
    /**
     * No description
     *
     * @tags URL Shortener
     * @name ForwardToTargetUrlPUrlKeyPathGet
     * @summary Forward To Target Url
     * @request GET:/p/{url_key}/{path}
     */
    forwardToTargetUrlPUrlKeyPathGet: (urlKey: string, path: string, params: RequestParams = {}) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/p/${urlKey}/${path}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  formAnalytics = {
    /**
     * No description
     *
     * @tags Online
     * @name StoreEventsFormAnalyticsStoreEventsPost
     * @summary Store Events
     * @request POST:/form-analytics/store-events
     */
    storeEventsFormAnalyticsStoreEventsPost: (
      data: BodyStoreEventsFormAnalyticsStoreEventsPost,
      params: RequestParams = {},
    ) =>
      this.request<EmploymentTypeBase, HTTPValidationError>({
        path: `/form-analytics/store-events`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  infra = {
    /**
     * No description
     *
     * @tags Other
     * @name Auth0EventsProxyInfraAuth0EventsPost
     * @summary Auth0 Events Proxy
     * @request POST:/infra/auth0/events
     * @secure
     */
    auth0EventsProxyInfraAuth0EventsPost: (data: object[], params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/infra/auth0/events`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  callmaker = {
    /**
     * No description
     *
     * @tags Callmaker Callbacks
     * @name GetCallmakerDataCallmakerCallbackCallPost
     * @summary Get Callmaker Data
     * @request POST:/callmaker/callback/call
     * @secure
     */
    getCallmakerDataCallmakerCallbackCallPost: (data: EmptyModel, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/callmaker/callback/call`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Callmaker Callbacks
     * @name GetProcessedDataCallmakerCallbackProcessPost
     * @summary Get Processed Data
     * @request POST:/callmaker/callback/process
     * @secure
     */
    getProcessedDataCallmakerCallbackProcessPost: (data: EmptyModel, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/callmaker/callback/process`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  v1 = {
    /**
     * No description
     *
     * @tags V1 Affiliate API
     * @name CreateBlancoApplicationV1AffiliateApplicationsBlancoPost
     * @summary Create Blanco Application
     * @request POST:/v1/affiliate/applications/blanco
     * @secure
     */
    createBlancoApplicationV1AffiliateApplicationsBlancoPost: (
      data: AffiliateCreateBlancoApplication,
      params: RequestParams = {},
    ) =>
      this.request<AffiliateResponse, HTTPValidationError>({
        path: `/v1/affiliate/applications/blanco`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags V1 Affiliate API
     * @name GetBookingsBlancoV1AffiliateBookingsBlancoGet
     * @summary Get Bookings Blanco
     * @request GET:/v1/affiliate/bookings/blanco
     * @secure
     */
    getBookingsBlancoV1AffiliateBookingsBlancoGet: (
      query: {
        /**
         * Start Date
         * @format date
         */
        start_date: string;
        /**
         * End Date
         * @format date
         */
        end_date: string;
        country: Country;
      },
      params: RequestParams = {},
    ) =>
      this.request<AvailableBookings, HTTPValidationError>({
        path: `/v1/affiliate/bookings/blanco`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags V1 Affiliate API
     * @name CreateMortgageLeadV1AffiliateLeadsMortgagePost
     * @summary Create Mortgage Lead
     * @request POST:/v1/affiliate/leads/mortgage
     * @secure
     */
    createMortgageLeadV1AffiliateLeadsMortgagePost: (data: AffiliateLeadCreationPayload, params: RequestParams = {}) =>
      this.request<AffiliateResponse, HTTPValidationError>({
        path: `/v1/affiliate/leads/mortgage`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags V1 Affiliate API
     * @name GetBookingsMortgageV1AffiliateBookingsMortgageGet
     * @summary Get Bookings Mortgage
     * @request GET:/v1/affiliate/bookings/mortgage
     * @secure
     */
    getBookingsMortgageV1AffiliateBookingsMortgageGet: (
      query: {
        /**
         * Start Date
         * @format date
         */
        start_date: string;
        /**
         * End Date
         * @format date
         */
        end_date: string;
        country: Country;
      },
      params: RequestParams = {},
    ) =>
      this.request<AvailableBookings, HTTPValidationError>({
        path: `/v1/affiliate/bookings/mortgage`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns all allowed enum values
     *
     * @tags V1 Affiliate API
     * @name GetOptionsV1AffiliateOptionsCountryProductGet
     * @summary Get Options
     * @request GET:/v1/affiliate/options/{country}/{product}
     * @secure
     */
    getOptionsV1AffiliateOptionsCountryProductGet: (country: Country, product: Product, params: RequestParams = {}) =>
      this.request<AffiliateOptionsResponse, HTTPValidationError>({
        path: `/v1/affiliate/options/${country}/${product}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags V1 Affiliate API
     * @name GetBlancoStatusV1AffiliateApplicationsBlancoSubmissionIdGet
     * @summary Get Blanco Status
     * @request GET:/v1/affiliate/applications/blanco/{submission_id}
     * @secure
     */
    getBlancoStatusV1AffiliateApplicationsBlancoSubmissionIdGet: (submissionId: string, params: RequestParams = {}) =>
      this.request<AffiliateCallback, HTTPValidationError>({
        path: `/v1/affiliate/applications/blanco/${submissionId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags V1 Affiliate API
     * @name GetMortgageStatusV1AffiliateLeadsMortgageSubmissionIdGet
     * @summary Get Mortgage Status
     * @request GET:/v1/affiliate/leads/mortgage/{submission_id}
     * @secure
     */
    getMortgageStatusV1AffiliateLeadsMortgageSubmissionIdGet: (submissionId: string, params: RequestParams = {}) =>
      this.request<AffiliateCallback, HTTPValidationError>({
        path: `/v1/affiliate/leads/mortgage/${submissionId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
