import { FC, useEffect } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import getValueByPath from 'src/utils/lodashLike/getValueByPath';

import FieldWithSlider from './base/FieldWithSlider';

interface RefinanceAmountFieldProps {
  name: string;
}

const min = 0;

const RefinanceAmountField: FC<RefinanceAmountFieldProps> = (props) => {
  const { name } = props;
  const loanAmountKey = 'loanAmount';

  const { t } = useTranslation();
  const { values, touched, setFieldValue } = useFormikContext<{ [key: string]: any; }>();
  const isTouched = getValueByPath<boolean>(touched, name);
  const loanAmount = getValueByPath<number>(values, loanAmountKey) || 0;
  const refinanceAmount = getValueByPath<number>(values, name);

  useEffect(() => {
    if (!isTouched || refinanceAmount > loanAmount) {setFieldValue(name, loanAmount);}
  }, [isTouched, loanAmount, name]);

  return (
    <FieldWithSlider
      name={name}
      title={t('Amount to Refinance')}
      min={min}
      max={loanAmount}
      resetOnFirstFocus
    />
  );
};


export default RefinanceAmountField;
