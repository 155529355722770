import { createContext, FC, ReactNode, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Country, EmploymentType } from 'src/api/zrm';
import BlancoNorwayConstants from 'src/defaults/BlancoNorway/constants';
import BlancoSwedenConstants from 'src/defaults/BlancoSweden/constants';
import { isSweden } from 'src/utils/country/isCountry';
import { showEmploymentEndDate, showEmploymentStartDate } from 'src/utils/employmentType/employmentTypeRequiredDates';
import formatCurrency from 'src/utils/format/formatCurrency';

import { useValidatorContext } from './ValidatorContext';


interface YupSchemaContextProps {
  schemaFields: Record<string, any>;
}

const YupSchemaContext = createContext<YupSchemaContextProps>({
  schemaFields: {},
});

export const YupSchemaContextProvider: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;

  const { t } = useTranslation();
  const { externalValidator } = useValidatorContext();
  const isCountrySweden = isSweden();

  const consts = isCountrySweden ? BlancoSwedenConstants : BlancoNorwayConstants;

  const schemaFields = {
    startPage: {
      consent: Yup.boolean(),
      loanAmount: Yup.number().required(t('Select the amount that you want to borrow'))
        .min(consts.minLoanAmount, t('The loan amount must be at least {{amount}}', { amount: formatCurrency(consts.minLoanAmount, Country.SE, 0) }))
        .max(consts.maxLoanAmount, t('The loan amount must be less than {{amount}}', { amount: formatCurrency(consts.maxLoanAmount, Country.SE, 0) })),
      newLoanPurpose: Yup.string().when('refinance', {
        is: false,
        then: (schema) => schema.required(t('Specify a loan purpose')),
        otherwise: (schema) => schema.when(['loanAmount', 'refinanceAmount'], {
          is: (loanAmount: number, refinanceAmount: number) => loanAmount > refinanceAmount,
          then: (s) => s.required(t('Specify a loan purpose')),
          otherwise: (s) => s.nullable(),
        }),
      }),
      refinanceAmount: Yup.number(),
      refinance: Yup.boolean().required(t('Choose if you want to refinance existing loans')),
      repaymentTime: Yup.number().required(t('Choose the repayment time')),
    },
    housing: {
      livingSituation: Yup.string().required(t('Specify living situation')),
      livingCost: Yup.number().required(t('Enter your share of the living cost'))
        .max(consts.maxLivingCost, t('Living cost must be less than {{amount}}', { amount: formatCurrency(consts.maxLivingCost, Country.SE, 0) })).min(0, t('Enter housing cost in numbers only')),
    },
    family: {
      maritalStatus: Yup.string().required(t('Specify marital status')),
      children: Yup.string().required(t('Specify the number of children')),
    },
    income: {
      employerName: Yup.string().required(t('Enter the name of the employer')),
      monthlyIncome: Yup.number().required(t('Enter the monthly income before tax'))
        .min(consts.minMonthlyIncome, t('Monthly income must be at least {{amount}}', { amount: formatCurrency(consts.minMonthlyIncome, Country.SE, 0) }))
        .max(consts.maxMonthlyIncome, t('Monthly income must be at less than {{amount}}', { amount: formatCurrency(consts.maxMonthlyIncome, Country.SE, 0) })),
      yearlyIncome: Yup.number().required(t('Enter yearly income before tax'))
        .min(consts.minYearlyIncome, t('Yearly income must be at least {{amount}}', { amount: formatCurrency(consts.minYearlyIncome, Country.NO, 0) }))
        .max(consts.maxYearlyIncome, t('Yearly income must be at less than {{amount}}', { amount: formatCurrency(consts.maxYearlyIncome, Country.NO, 0) })),
      spouseYearlyIncome: Yup.number().required(t('Enter your spouse yearly income before tax')),
      employmentType: Yup.string().required(t('Choose employment type')),
      employmentStartDateMonth: Yup.number().when('employmentType', {
        is: (value: EmploymentType) => showEmploymentStartDate(value),
        then: schema => schema.required(t('Enter the start month of employment'))
          .when('employmentStartDateYear', {
            is: (v: string | number) => {
              // Sometimes v is a number
              return `${v}` === new Date().getFullYear().toString();
            },
            then: s => s.min(0).max(new Date().getMonth(), t('Employment start month must be in the past')),
          }),
      }),
      employmentStartDateYear: Yup.number().when('employmentType', {
        is: (value: EmploymentType) => showEmploymentStartDate(value),
        then: schema => schema.required(t('Enter the start year of employment')),
      }).max(new Date().getFullYear(), t('Employment start year must be in the past')),
      employmentEndDateYear: Yup.number().when('employmentType', {
        is: (value: EmploymentType) => showEmploymentEndDate(value),
        then: schema => schema.required(t('Enter the end year of employment')),
      }).min(new Date().getFullYear(), t('Employment end year must be in the future')),

      employmentEndDateMonth: Yup.number().when('employmentType', {
        is: (value: EmploymentType) => showEmploymentEndDate(value),
        then: schema => schema.required(t('Enter the end month of employment'))
          .when('employmentEndDateYear', {
            is: (v: string | number) => {
              // Sometimes v is a number
              return `${v}` === new Date().getFullYear().toString();
            },
            then: s => s.min(new Date().getMonth(), t('Employment end date must be in the future')),
          }),
      }),
      hasYearlyRentIncome: Yup.boolean().required(t('Specify if you have yearly rent income')),
      yearlyRentIncome: Yup.number().when('hasYearlyRentIncome', {
        is: true,
        then: schema => schema.moreThan(0, t('Yearly rent income must be more than {{amount}}', { amount: formatCurrency(0, Country.SE, 0) })).required(t('Enter yearly rent income before tax')),
        otherwise: schema => schema.nullable(),
      }),
    },
    contact: {
      email: Yup.string()
        .email(t('The email is not a valid email address'))
        .test(
          '',
          t('The email is not a valid email address'),
          (value) => value && externalValidator({ email: value }),
        )
        .required(t('Enter a valid email address')),
      phone: Yup.string()
        .test(
          '',
          t('The phone number is not a valid mobile number'),
          (value) => value && externalValidator({ phone_number: value }),
        )
        .required(t('Enter a valid mobile number')),
    },
    pni: {
      pni: Yup.string()
        .test(
          '',
          t('The personal number is not a valid personal number'),
          (value) => value && externalValidator({ pni: value }),
        )
        .required(t('Enter a valid personal number')),
      isNorwegianCitizen: Yup.boolean().required(t('Specify if you are a Norwegian citizen')),
    },
    coApplicant: {
      hasCoApplicant: Yup.boolean().required(t('Specify if you have a co-applicant')),
      email: Yup.string()
        .when('hasCoApplicant', {
          is: true,
          then: (schema) => schema
            .email(t('The email is not a valid email address'))
            .test(
              '',
              t('The email is not a valid email address'),
              (value) => value && externalValidator({ email: value }),
            )
            .required(t('Enter a valid email address')),
          otherwise: (schema) => schema.notRequired(),
        }),
      phone: Yup.string()
        .when('hasCoApplicant', {
          is: true,
          then: (schema) => schema
            .test(
              '',
              t('The phone number is not a valid mobile number'),
              (value) => value && externalValidator({ phone_number: value }),
            )
            .required(t('Enter a valid mobile number')),
          otherwise: (schema) => schema.notRequired(),
        }),
      pni: Yup.string()
        .when('hasCoApplicant', {
          is: true,
          then: (schema) => schema
            .test(
              '',
              t('The personal number is not a valid personal number'),
              (value) => value && externalValidator({ pni: value }),
            )
            .required(t('Enter a valid personal number')),
          otherwise: (schema) => schema.notRequired(),
        }),
    },
    securedLoans: {
      hasLoan: Yup.boolean().required(t('Specify if you have a loan')),
      totalAmount: Yup.number().when('hasLoan', {
        is: true,
        then: (schema) => schema.moreThan(0, t('Total amount must be more than {{amount}}', { amount: formatCurrency(0, Country.SE, 0) })).required(t('Enter the total amount of your loan')),
        otherwise: (schema) => schema.nullable(),
      }),
      rate: Yup.number().when('hasLoan', {
        is: true,
        then: (schema) => schema.moreThan(0, t('Interest rate must be more than 0%')).required(t('Enter the interest rate of your loan')),
        otherwise: (schema) => schema.nullable(),
      }),
      monthlyPayment: Yup.number().when('hasLoan', {
        is: true,
        then: (schema) => schema.required(t('Enter the monthly payment of your loan')),
        otherwise: (schema) => schema.nullable(),
      }),
    },
  };


  return (
    <YupSchemaContext.Provider
      value={{
        schemaFields,
      }}
    >
      {children}
    </YupSchemaContext.Provider>
  );
};

export const useYupSchemaContext = (): YupSchemaContextProps => useContext(YupSchemaContext);
