import { FC } from 'react';

import FormStepperSubStepsContainer from 'src/components/Stepper/subSteps/FormStepperSubStepsContainer';
import BlancoEmployerNameSection from 'src/forms/BlancoShared/sections/income/BlancoEmployerNameSection';
import BlancoEmploymentTypeSection from 'src/forms/BlancoShared/sections/income/BlancoEmploymentTypeSection';

import BlancoNorwayIncomeAmountSection from './BlancoNorwayIncomeAmountSection';
import BlancoNorwayRentIncomeAmountSection from './BlancoNorwayRentIncomeAmountSection';

interface BlancoNorwayIncomeStepProps {
}

const BlancoNorwayIncomeStep: FC<BlancoNorwayIncomeStepProps> = () => {
  return (
    <FormStepperSubStepsContainer
      stepsInOrder={[
        <BlancoEmploymentTypeSection key='BlancoEmploymentTypeSection' />,
        <BlancoNorwayIncomeAmountSection key='BlancoNorwayIncomeAmountSection' />,
        <BlancoEmployerNameSection key='BlancoEmployerNameSection' />,
        <BlancoNorwayRentIncomeAmountSection key='BlancoNorwayRentIncomeAmountSection' />,
      ]}
    />
  );
};


export default BlancoNorwayIncomeStep;
