import { FC } from 'react';

import { useFormSelectorContext } from 'src/contexts/FormSelectorContext';
import BlancoNorwayStartPage from 'src/forms/BlancoNorway/BlancoNorwayStartPage';
import BlancoSwedenStartPage from 'src/forms/BlancoSweden/BlancoSwedenStartPage';
import OnlineFormType from 'src/types/OnlineFormType';

import FormEntryCard from './FormEntryCard';

interface FormSelectorProps {
}

const FormSelector: FC<FormSelectorProps> = (props) => {
  const { } = props;

  const { onlineFormType } = useFormSelectorContext();

  return (
    <FormEntryCard>
      {onlineFormType === OnlineFormType.BlancoSweden && (
        <BlancoSwedenStartPage />
      )}
      {onlineFormType === OnlineFormType.BlancoNorway && (
        <BlancoNorwayStartPage />
      )}
    </FormEntryCard>
  );
};


export default FormSelector;
