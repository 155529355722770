import { FC, useEffect, useState } from 'react';

import { Check } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { useAnalyticsContext } from 'src/contexts/AnalyticsContext';
import BlancoSharedUcInfo from 'src/forms/BlancoShared/BlancoSharedUcInfo';

interface FinishButtonProps {
  saveFormCallback: () => void;
  updateFormContextCallback: () => void;
}

const FinishButton: FC<FinishButtonProps> = (props) => {
  const { saveFormCallback, updateFormContextCallback } = props;

  const { t } = useTranslation();
  const { validateForm, setTouched } = useFormikContext();
  const { analyticsLogFormikErrors } = useAnalyticsContext();

  const [canSave, setCanSave] = useState(false);

  const validateAndSaveForm = async () => {

    const temp = await validateForm();

    // Touch all fields to show errors on fields that was not touched yet
    setTouched(Object.keys(temp).reduce((acc, key) => ({ ...acc, [key]: true }), {}));

    if (Object.keys(temp).length === 0) {
      updateFormContextCallback();
      setCanSave(true);
    } else {
      analyticsLogFormikErrors(temp);
    }
  };

  /**
   * `updateFormContextCallback` and `saveFormCallback` cannot be used together - because of how React.useState works.
   * We have to wait to next render to get state updated in context - if we use them together, `saveFormCallback` would have old data.
   * Thanks to `useEffect` we can trigger saving form when everything is up to date.
   */
  useEffect(() => {
    if (canSave) {
      saveFormCallback();
    }
  }, [canSave]);

  return (
    <>
      <BlancoSharedUcInfo />
      <Button
        variant="contained"
        startIcon={<Check />}
        sx={{ width: '100%', mt: 3 }}
        size='large'
        onClick={validateAndSaveForm}
      >
        {t('Apply now!')}
      </Button>
    </>
  );
};

export default FinishButton;
