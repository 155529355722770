import { Country } from 'src/api/zrm';
import { useSettingsContext } from 'src/contexts/SettingsContext';

const isCountry = (countryCode: string): boolean => {
  const { country } = useSettingsContext();

  return country === countryCode;
};

export function isSweden(): boolean { return isCountry(Country.SE); }
export function isNorway(): boolean { return isCountry(Country.NO); }
