export enum SkipStepMode {
  STEP = 'STEP',
  SUB_STEP = 'SUB_STEP',
}

interface SkipStep {
  mode: SkipStepMode;
  stepNumber: number; // count from 0
  subStepNumber: number; // count from 0
  skipOn: (values: any) => boolean; // returns true when step should be skipped
}

export default SkipStep;
