import { FC } from 'react';

import { Box } from '@mui/material';
import { useFormikContext } from 'formik';


interface DevToolsFormErrorsProps {
}

const DevToolsFormErrors: FC<DevToolsFormErrorsProps> = (props) => {
  const { } = props;

  const { errors } = useFormikContext<{ [key: string]: any; }>();

  return (
    <Box sx={{ width: '100%' }}>
      <pre>
        {JSON.stringify(errors, null, 2)}
      </pre>
    </Box>
  );
};


export default DevToolsFormErrors;
