import { Country, Currency } from 'src/api/zrm';

import { getLocale } from '../getLocale';

const formatCurrency = (amount: number, country: Country, decimals?: number) => new Intl
  .NumberFormat(getLocale(country), {
    style: 'currency',
    currency: Currency.SEK, // We use 'SEK' to format currency, because it has proper formatting for both countries ('NOK' formatting is off)
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    currencyDisplay: 'narrowSymbol',
  })
  .format(amount);

export default formatCurrency;
