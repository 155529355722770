const calculateMonthlyPayment = (loanAmount: number, interestRate: number, paybackTime: number) => {
  // the formula for calculating the monthly payment is:
  // M = P[r(1+r)^n]/[(1+r)^n-1]
  // where M is the monthly payment, P is the principal loan amount, r is the monthly interest rate, and n is the number of payments

  if (!interestRate) {interestRate = 0;}

  if (!paybackTime) {return loanAmount;}  // to avoid division by zero

  if (!loanAmount) {loanAmount = 0;}


  const monthlyInterestRate = interestRate / 12;
  const numberOfPayments = paybackTime * 12; // (some but very few loans are paid quarterly or semi-annually)

  // the formula for calculating the monthly payment, or payment per term, is:
  const mp = (
    loanAmount * monthlyInterestRate * (1 + monthlyInterestRate) ** numberOfPayments /
    ((1 + monthlyInterestRate) ** numberOfPayments - 1)
  );

  return mp;
};

export default calculateMonthlyPayment;
