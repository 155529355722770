import { FC } from 'react';

import { Box } from '@mui/material';
import { useFormikContext } from 'formik';


interface DevToolsFormTouchedProps {
}

const DevToolsFormTouched: FC<DevToolsFormTouchedProps> = (props) => {
  const { } = props;

  const { touched } = useFormikContext<{ [key: string]: any; }>();

  return (
    <Box sx={{ width: '100%' }}>
      <pre>
        {JSON.stringify(touched, null, 2)}
      </pre>
    </Box>
  );
};


export default DevToolsFormTouched;
